import { Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import SecurityAwarnessTemplate from '../SecurityAwarnessTemplate';
import { NoDataAvailable } from './../../Common/NoDataAvailable';
import { SecurityAwarnessManageListTable } from './ManageUsers';
import { SecurityAwarnessManageParticipents } from './ManageUsers/SecurityAwarnessManage';

const SecurityAwarnessManage = () => {
  const [isSkeleton, setIsSkeleton] = useState(false);
  return (
    <SecurityAwarnessTemplate>
      {isSkeleton ? (
        <Box sx={{ px: 4, py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : false ? (
        <NoDataAvailable title="No Data Available" message="Please provide your employee mailing list"/>
      ) : (
        <Box sx={{ py: 3, width: 1 }}>
          <SecurityAwarnessManageParticipents />
        </Box>
      )}
    </SecurityAwarnessTemplate>
  );
};
export default SecurityAwarnessManage;
