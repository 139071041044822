import RemidiationPopUpList from '@jibin/common/components/RemidiationPopUp/RemidiationPopupList';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { Box, Grid, Skeleton, Stack, SwipeableDrawer, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TopRemediationsApi } from './TopRemediations.Api';
import RemidiationPopUpListOld from '@jibin/common/components/RemidiationPopUp-old/RemidiationPopupList';
import CalenderIcon from '@jibin/common/icons/CalenderIcon/CalenderIcon';
import moment from 'moment';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import Style from '@jibin/common/style/Styles';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import { UserRoles } from '@jibin/common/utils/constants/ConstantName';
export interface TopRemediationProp {
  source: string;
  size: any;
  title: any;
  column: any;
  isDragable?: boolean;
  customIcon?: any;
  currentGrid?: any;
  dropdownMenu?: any;
}
export const TopRemediations: FC<TopRemediationProp> = (prop) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [isRefresh, setIsRefresh] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [rowData, setRowData] = useState({} as any);
  const [state, setState] = useState({
    right: false
  });
  type Anchor = 'top' | 'left' | 'bottom' | 'right';
  const [useParams, setUseParam] = useSearchParams();
  const toggleDrawer = (anchor: Anchor, open: boolean, row: any) => {
    if (!prop?.isDragable) {
      if (!open) {
        useParams.delete('remediation_uuids');
        setUseParam(useParams);
      } else {
        setParams(row.remediation_uuid);
      }
      setState({ ...state, [anchor]: open });
      setRowData(row);
      if (!open) {
        setRowData({});
      }
    }
  };
  const setParams = (index) => {
    const params = {
      remediation_uuids: index
    };
    setUseParam(params);
  };
  const [remediations, setRemediations] = useState({} as any);
  let data = { source: prop.source, size: prop.size, status: 'not_started,in_progress,reopened' };
  const navigate = useNavigate();

  const GetRemediations = useCallback(() => {
    TopRemediationsApi.RemediationsList(
      commonDetails?.data?.user_uuid,
      commonDetails?.data?.company.company_uuid,
      new URLSearchParams(data).toString()
    ).then(
      (q: any) => {
        setIsSkeleton(false);
        setRemediations(q.data);
      },
      (err: any) => {
        setIsSkeleton(false);
      }
    );
  }, [commonDetails, isRefresh]);
  const renderColor = (severity: any) => {
    var color = '#F73939';
    if (severity == 'critical') {
      color = '#F73939';
    } else if (severity == 'high') {
      color = '#F59E0B';
    } else if (severity == 'medium') {
      color = '#FBBF24';
    } else if (severity == 'low') {
      color = '#436AF3';
    }
    return color;
  };
  const renderColorBackGround = (severity: any = 'critical') => {
    var color = '#FFE6E2';
    if (severity == 'critical') {
      color = '#FFE6E2';
    } else if (severity == 'high') {
      color = '#FCD34D';
    } else if (severity == 'medium') {
      color = '#FEF3C7';
    } else if (severity == 'low') {
      color = '#D0DAFC';
    }
    return color;
  };
  useEffect(() => {
    GetRemediations();
  }, [GetRemediations]);

  return (
    <Box sx={{ display: remediations.data?.length == 0 ? 'flex' : 'block', height: 1, pb: 1 }}>
      <Box
        sx={{
          px: 3,
          pt: 3,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1
              }}>
              <Typography sx={{ fontWeight: 600 }}>{prop?.title}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography variant="caption">
                {prop?.size} of {remediations?.pagination?.total_records ?? ''}
              </Typography>
            </Box>
          </Box>
          <Box>
            {prop?.isDragable && (
              <>
                {prop?.customIcon}
                {prop?.currentGrid?.i === 'dashboard-remediation-tracker'
                  ? prop?.dropdownMenu
                  : null}
              </>
            )}
          </Box>
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center' }}>
          <Box
            id={'remediation_container'}
            sx={{
              maxHeight: '310px',
              p: 1,
              flexFlow: 1,
              overflowY: 'scroll',
              overflowX: 'hidden',
              '&::-webkit-scrollbar': {
                width: '6px',
                height: '0.2em'
              },
              '&::-webkit-scrollbar-track': {
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',

                borderRadius: '10px'
              }
            }}>
            {isSkeleton ? (
              <Box
                sx={{
                  border: '1px solid #EAECF0',
                  borderRadius: '8px',
                  width: 1,
                  mb: 1,
                  cursor: 'pointer'
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: 1,
                    p: 1.5
                  }}>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      color: '#696969',
                      whiteSpace: 'nowrap',
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: '1rem', width: '100%' }}
                    />
                  </Typography>
                </Box>
              </Box>
            ) : remediations.data?.length != 0 ? (
              remediations.data?.map((el, index) => (
                <Box
                  id={el.remediation_uuid}
                  className="remediationSingleBox"
                  onClick={() => {
                    toggleDrawer('right', true, el);
                  }}
                  key={index}
                  sx={{
                    border: '1px solid #EAECF0',
                    borderRadius: '8px',
                    mb: 1,
                    display: 'flex',
                    p: 1,
                    width: 1,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    justifyContent: 'space-between',
                    cursor: prop?.isDragable ? 'dafault' : 'pointer'
                  }}>
                  <Grid container spacing={1} sx={{ justifyContent: 'space-between' }}>
                    <Grid
                      item
                      xs={
                        prop.column == 6 || prop.column == 8
                          ? 9
                          : prop.column == 4
                          ? 10
                          : prop.column == 12 && 8
                      }>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,

                          color: '#696969',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden'
                        }}>
                        {prop.column == 12 ? (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Box
                                sx={{
                                  backgroundColor: renderColorBackGround(el.severity.toLowerCase()),
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                  justifyContent: 'center',
                                  borderRadius: '100px',
                                  py: 0.5,
                                  px: 2
                                }}>
                                <FlagIcon
                                  sx={{
                                    backgroundColor: renderColorBackGround(
                                      el.severity.toLowerCase()
                                    ),
                                    color: renderColor(el.severity.toLowerCase()),
                                    width: '12px'
                                  }}
                                />
                                <Typography variant="caption" sx={{ textTransform: 'capitalize' }}>
                                  {' '}
                                  {el.severity}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Box
                                sx={{
                                  backgroundColor: renderColorBackGround(el.severity.toLowerCase()),
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '32px',
                                  height: '32px',

                                  justifyContent: 'center',
                                  borderRadius: '50%'
                                }}>
                                <FlagIcon
                                  sx={{
                                    backgroundColor: renderColorBackGround(
                                      el.severity.toLowerCase()
                                    ),

                                    color: renderColor(el.severity.toLowerCase()),
                                    width: '16px'
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        )}
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            color: '#696969',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            p: 1.5
                          }}>
                          <Box sx={{ display: 'flex' }}>
                            <EllipsisContent
                              text={el.title ? el.title : el.issue_name}
                              maxWidth={800}
                              sx={{
                                fontWeight: 400,
                                fontSize: '14px',
                                color: '#696969'
                              }}
                            />
                          </Box>

                          {prop.column != 12 && (
                            <Typography
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                                color: '#787878'
                              }}
                              variant="caption">
                              <CalenderIcon
                                width="14px"
                                height="14px"
                                sx={{ color: '#464646', fontSize: '12px' }}
                              />{' '}
                              <span style={{ color: '#464646', fontSize: '12px' }}>
                                {moment(el.created_at).format('DD MMM YYYY')}
                              </span>
                            </Typography>
                          )}
                        </Box>{' '}
                      </Box>
                    </Grid>
                    {prop.column == 12 && (
                      <Grid sx={{ display: 'flex', alignItems: 'center', gap: 2 }} item xs={2}>
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                            color: '#787878'
                          }}
                          variant="caption">
                          <CalenderIcon
                            width="16px"
                            height="16px"
                            sx={{ color: '#464646', fontSize: '16px' }}
                          />{' '}
                          <span style={{ color: '#464646', fontSize: '12px' }}>
                            {moment(el.created_at).format('DD MMM YYYY')}
                          </span>
                        </Typography>
                      </Grid>
                    )}

                    {prop.column >= 6 ? (
                      <Grid sx={{ display: 'flex' }} item xs={prop.column == 12 ? 2 : 3}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            overflow: 'hidden',
                            width: 1,
                            justifyContent: 'flex-start'
                          }}>
                          <Box>
                            {el.owner.ico ? (
                              <Box
                                sx={{
                                  ...Style.versionsCard.infoRound,
                                  width: '32px',
                                  height: '32px'
                                }}>
                                <img
                                  width={'100%'}
                                  height="100%"
                                  style={{ objectFit: 'cover', borderRadius: '50%' }}
                                  src={`${process.env.REACT_APP_API_HOST}/${el.owner.ico}`}
                                  alt=""
                                />
                              </Box>
                            ) : (
                              <Typography
                                sx={{
                                  ...Style.versionsCard.infoRound,
                                  width: '32px',
                                  height: '32px',
                                  overflow: 'hidden'
                                }}>
                                {' '}
                                {el.owner.first_name
                                  ? el.owner.first_name.charAt(0) +
                                    '' +
                                    el.owner.last_name.charAt(0)
                                  : '-'}
                              </Typography>
                            )}
                          </Box>
                          {el.owner.first_name && (
                            <Box
                              sx={{
                                flexGrow: 1,
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                width: 'max-content'
                              }}>
                              <EllipsisContent
                                variant="caption"
                                sx={{ fontWeight: 600, width: 'calc(100% - 8px)' }}
                                text={el.owner.first_name + ' ' + el.owner.last_name}
                              />

                              <EllipsisContent
                                sx={{ width: 'calc(100% - 8px)' }}
                                variant="caption"
                                text={el.owner?.company.name}
                              />
                            </Box>
                          )}
                          {el?.is_unassigned && (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                              }}>
                              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                                Unassigned
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    ) : (
                      prop.column == 4 && (
                        <Grid sx={{ display: 'flex' }} item xs={2}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start'
                            }}>
                            <Box>
                              {el.owner.ico ? (
                                <Box
                                  sx={{
                                    ...Style.versionsCard.infoRound,
                                    width: '32px',
                                    height: '32px'
                                  }}>
                                  <img
                                    width={'100%'}
                                    height="100%"
                                    style={{ objectFit: 'cover', borderRadius: '50%' }}
                                    src={`${process.env.REACT_APP_API_HOST}/${el.owner.ico}`}
                                    alt=""
                                  />
                                </Box>
                              ) : (
                                <Typography
                                  sx={{
                                    ...Style.versionsCard.infoRound,
                                    width: '32px',
                                    height: '32px',
                                    overflow: 'hidden'
                                  }}>
                                  {' '}
                                  {el.owner.first_name
                                    ? el.owner.first_name.charAt(0) +
                                      '' +
                                      el.owner.last_name.charAt(0)
                                    : '-'}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              ))
            ) : (
              <Box
                sx={{ height: 1, textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                <Box
                  sx={{
                    maxWidth: '196px',
                    height: '196px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 1,
                    borderRadius: '50%',
                    border: '20px solid #EBEBEB',
                    p: 2.5
                  }}>
                  <Typography variant="body2">
                    No Open <br /> Remediations
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <SwipeableDrawer
          BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
          sx={{
            '.MuiDrawer-paper': {
              boxShadow: 'none'
            }
          }}
          anchor={'right'}
          open={state['right']}
          onClose={() => toggleDrawer('right', false, {})}
          onOpen={() => toggleDrawer('right', true, rowData)}>
          <RemidiationPopUpList
            el={rowData}
            isGap={false}
            remediation_uuid={rowData.remediation_uuid}
            closePopup={() => toggleDrawer('right', false, rowData)}
            isEngineer={commonDetails?.data?.group == UserRoles.CYBER_SECURITY_ENGINEER}
            setIsRefresh={setIsRefresh}
            isRefresh={isRefresh}
          />
        </SwipeableDrawer>
      </Box>
    </Box>
  );
};
