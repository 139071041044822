import { useMemo } from 'react';

import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Cell, Text, LabelList } from 'recharts';
import './BarChart.css';
const YAxisLeftTick = ({ y, payload: { value } }) => {
  return (
    <Text x={0} y={y} textAnchor="start" verticalAnchor="middle">
      {value}
    </Text>
  );
};

let ctx;

export const measureText14HelveticaNeue = (text) => {
  if (!ctx) {
    ctx = document.createElement('canvas').getContext('2d');
    ctx.font = "14px 'Helvetica Neue";
  }

  return ctx.measureText(text).width;
};

const BAR_AXIS_SPACE = 20;

const SimpleBarChart = ({ data, yKey, xKey, color = '#F87171' }) => {
  const blues = [[color], [color, '#10B981']];

  const getColor = (length, index) => {
    if (length <= blues.length) {
      return blues[length - 1][index];
    }

    return blues[blues.length - 1][index % blues.length];
  };
  const maxTextWidth = useMemo(
    () =>
      data.reduce((acc, cur) => {
        const value = cur[yKey];
        const width = measureText14HelveticaNeue(value.toLocaleString());
        if (width > acc) {
          return width;
        }
        return acc;
      }, 0),
    [data, yKey]
  );

  return (
    <ResponsiveContainer width={'100%'} height={24* data.length} debounce={50}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{ left: 40, right: maxTextWidth + (BAR_AXIS_SPACE - 8) }}>
        <XAxis hide axisLine={false} type="number" />
        <YAxis
          dataKey={'name'}
          type="category"
          axisLine={false}
          tickLine={false}
          tick={YAxisLeftTick}
        />

        <Bar dataKey={yKey} minPointSize={2} barSize={16}>
          <LabelList dataKey="pv" position="right" fill="black" />
          {data.map((d, idx) => {
            return <Cell key={d[xKey]} fill={getColor(data.length, idx)} />;
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
export default SimpleBarChart;
