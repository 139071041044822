import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';

import React, { FC, useEffect, useRef, useState } from 'react';

import Header from '../Header';
import Sidebar from '../../../app/src/Pages/Common/Sidebar';
import Style from '@jibin/common/style/Styles';
import CloseIcon from '@jibin/common/icons/Close/Close';
import ClockIcon from '@jibin/common/icons/ClockIcon/ClockIcon';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { shallowEqual, useSelector } from 'react-redux';
import UserPool from '@jibin/common/utils/UserPool';
import { ConatinerAPI } from '../../components/Container/Container.api';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useIdleTimer } from 'react-idle-timer';
import moment from 'moment';
import { NoDataAvailable } from 'src/Pages/Common/NoDataAvailable';
import { useSearchParams } from 'react-router-dom';
const timeout = parseInt(process.env.REACT_APP_MINS_TO_LOGOUT) * 60000;
const promptBeforeIdle = parseInt(process.env.REACT_APP_MINS_TO_SHOW_POPUP) * 60000;
export const MainContainer: FC<{ children: React.ReactNode | React.ReactNode[] }> = (props) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<string>('Active');
  const [remaining, setRemaining] = useState<number>(timeout);
  const [count, setCount] = useState<number>(0);
  const [searchParams, setSeacrParams] = useSearchParams();
  const [isBrowserMinimized, setBrowserMinimized] = useState(false);
  const onIdle = () => {    
    setState('Idle');
      setOpen(false);
      handleSignout(false);
  
  };
  const onMessage = () => {
    setCount(count + 1);
  };
  const onActive = () => {
    setState('Active');
    setOpen(false);
  };

  const onPrompt = () => {
    setState('Prompted');
    setOpen(true);
  };
  const { getRemainingTime, activate, getTabId, isLeader, message, isLastActiveTab } = useIdleTimer(
    {
      onIdle,
      onActive,
      onPrompt,
      timeout,
      promptBeforeIdle,
      throttle: 500
    }
  );

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const handleStillHere = () => {
    activate();
  };

  const handleSignout = (inActivity: boolean) => {
    localStorage.clear();
    localStorage.setItem('IsSessionActive', 'false');

    const user = new CognitoUser({
      Username: commonDetails.data.email,
      Pool: UserPool
    });
    user.signOut();

    handleClose();
    window.location.href = inActivity ? '/login?inactivity=true' : '';
  };

  const handleClose = () => {
    setOpen(false);
  };
  const converTime = (counter) => {
    var minutes = Math.floor(counter / 60);
    var secondsRemaining = counter % 60;
    return minutes + ' min ' + secondsRemaining.toString().padStart(2, '0') + ' sec ';
  };
  useEffect(() => {
    const isTabActive = sessionStorage.getItem('activeTab') === 'true';
    if (!isTabActive) {
      // Check if the user is logged in
      const isLoggedIn = localStorage.getItem('cognito-token');

      // If the user is not logged in, you can redirect them to the login page or take appropriate action
      if (!isLoggedIn) {
        handleSignout(true);
      }
      sessionStorage.setItem('activeTab', 'true');
    }
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Browser window is minimized
        setBrowserMinimized(true);
      } else {
        // Browser window is restored
        setBrowserMinimized(false);
      }
      // Check for idle when the window is restored
      if (!document.hidden) {
        checkIdleAndLogout();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      clearInterval(interval);
      document.removeEventListener('visibilitychange', handleVisibilityChange);

      window.removeEventListener('beforeunload', handleTabClose);
      sessionStorage.removeItem('activeTab');
    };
  });

  const checkIdleAndLogout = () => {
    // Log the user out or perform any other action
    // Your logout logic here
    if(remaining <= 1 ){
      setState('Idle');

      setOpen(false);
      handleSignout(false);
    }
  };
  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second';
  const getActiveSession = async () => {
    try {
      const res = await ConatinerAPI.GetActiveSession(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        localStorage.getItem('device_id')
      );
      if (res?.data?.data?.sessions?.length == 0 || !res?.data?.data?.sessions) {
         handleSignout(false);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (searchParams.get('is_login') == 'true') {
      searchParams.delete('is_login');
      setSeacrParams(searchParams);
    } else {
      getActiveSession();
    }
  }, []);
  const handleTabClose = () => {
    if (!sessionStorage.getItem('activeTab')) {
      localStorage.clear();
      sessionStorage.removeItem('activeTab');
    }
  };

  const [sideBarOpen, setSideBarOpen] = useState(false);

  const handleDrawerOpen = () => {
    setSideBarOpen(true);
  };

  const handleDrawerClose = () => {
    setSideBarOpen(false);
  };

  return (
    <Box>
      <Header showMenu={true} />
      <Box sx={Style.Layout.MainLayout}>
        <Sidebar
          open={sideBarOpen}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />

        {sideBarOpen ? (
          <IconButton
            onClick={() => {
              handleDrawerClose();
            }}
            sx={{
              transition: '0.3s all ease',
              background: '#436AF3',
              position: 'fixed',
              left: '269px',
              top: '90px',
              zIndex: 11,
              padding: '4px',
              ':hover': {
                background: '#436AF3'
              }
            }}>
            <ChevronLeftIcon
              sx={{
                color: 'white'
              }}
            />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              handleDrawerOpen();
            }}
            sx={{
              transition: '0.3s all ease',
              background: '#436AF3',
              position: 'fixed',
              left: '66px',
              top: '90px',
              zIndex: 11,
              padding: '4px',
              ':hover': {
                background: '#436AF3'
              }
            }}>
            <ChevronRightIcon
              sx={{
                color: 'white'
              }}
            />
          </IconButton>
        )}
        <Box
          component="main"
          className="main-content"
          sx={{ ...Style.Layout.MainContent, marginLeft: sideBarOpen ? '280px' : '75px' }}>
          {commonDetails?.data?.company?.status?.toLowerCase() == 'inactive' ? (
            <NoDataAvailable
              src="/images/InactiveCompany.png"
              title="Inactive Company"
              message="Please contact our Sales Team to activate this company."
              children={
                <Button
                  sx={{ mt: '10px' }}
                  variant="contained"
                  onClick={() => {
                    window.open('https://www.redesign-group.com/contact-us', '_blank');
                  }}>
                  Contact Us
                </Button>
              }
            />
          ) : (
            props.children
          )}
        </Box>
      </Box>

      <Box>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              px: { xs: 1, md: 2 },
              py: { xs: 0.5, md: 1 },
              bgcolor: 'error.red100',
              mb: 1
            }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ClockIcon sx={{ mr: 1 }} />
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#2D2D2D',
                    fontWeight: '600',

                    p: 1
                  }}>
                  Session Timeout
                </Typography>
              </Box>
              <IconButton sx={{ color: 'text.primary' }} aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
            <DialogContentText id="alert-dialog-description" sx={{ color: 'text.primary' }}>
              <Box sx={{ textAlign: 'center', width: 1 }}>
                <Typography variant="body2" sx={{ mb: 1.5 }}>
                  Your session will expire in:
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 600, mb: 2.5 }}>
                  {moment
                    .utc(moment.duration(remaining, 'seconds').asMilliseconds())
                    .format('mm:ss')}
                </Typography>
                <Box sx={{ width: 0.6, m: 'auto', display: 'flex' }}>
                  <Typography variant="caption">
                    Please click “Continue” to keep working or click “Log Off” to end your session
                    now.
                  </Typography>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0, justifyContent: 'center' }}>
            <Button
              variant="outlined"
              onClick={() => handleSignout(false)}
              sx={{ minWidth: 100, color: '#9D9D9D', borderColor: '#9D9D9D' }}>
              Log Off
            </Button>
            <Button
              variant="contained"
              onClick={handleStillHere}
              sx={{ minWidth: 100, color: 'Primary/500' }}>
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default MainContainer;
