import { useState } from 'react';
import {
  Button,
  Checkbox,
  Typography,
  Menu,
  MenuItem,
  Box,
  InputAdornment,
  TextField
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import SearchIcon from '@jibin/common/icons/Search/Search';

function FilterDropdown(props) {
  const {
    setFilterData,
    filterData,
    handleChange,
    assigneesOption,
    tagsOption,
    searchTag,
    handleSearchTag
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [subMenuAnchorElTools, setSubMenuAnchorElTools] = useState(null);

  const handleMainMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const handleSubMenuClick = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };
  const handleSubMenuClickTools = (event) => {
    setSubMenuAnchorElTools(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
    setSubMenuAnchorElTools(null);
  };

  const handleMenuItemClick = (type, item) => {
    setFilterData((prevState) => {
      const updatedArray = [...prevState[type]];
      const itemIndex = updatedArray.indexOf(item);
      if (itemIndex !== -1) {
        updatedArray.splice(itemIndex, 1);
      } else {
        updatedArray.push(item);
      }
      handleChange({ target: { value: updatedArray, name: type } });
      return {
        ...prevState,
        [type]: updatedArray
      };
    });
  };
  const generateMenuItems = () => {
    return assigneesOption.flatMap((item, index) => [
      item.company_name && (
        <Box sx={{ width: '100px' }}>
          <EllipsisContent
            maxTextWidth={250}
            maxWidth={250}
            text={item.company_name}
            sx={{
              px: 2,

              py: 1,
              display: item.company_name == 'All Assignees' ? 'none' : 'block',
              alignItems: 'center'
            }}
          />
        </Box>
      ),
      item.users
        ?.sort((a: any, b: any) => (a.name < b.name ? -1 : 1))
        ?.map((el, index) => (
          <MenuItem
            key={el?.user_uuid}
            onClick={() => handleMenuItemClick('assignee_uuids', el?.user_uuid)}
            value={el.user_uuid}
            sx={{ py: 0, display: el.name == 'All Assignees' ? 'none' : 'flex' }}>
            <Checkbox checked={filterData.assignee_uuids.indexOf(el.user_uuid) > -1} />

            <EllipsisContent
              maxTextWidth={150}
              text={el.first_name + ' ' + el.last_name}
              sx={{
                fontSize: '14px',
                '>span': {
                  fontSize: '14px'
                }
              }}
            />
          </MenuItem>
        ))
    ]);
  };
  return (
    <div className="multi-dropdown">
      <Button aria-controls="main-menu" aria-haspopup="true" onClick={handleMainMenuClick}>
        <FilterAltOutlinedIcon /> +
      </Button>
      <Menu id="main-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem sx={{ fontWeight: 600 }} onClick={handleSubMenuClick}>
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Tags
        </MenuItem>
        <MenuItem sx={{ fontWeight: 600 }} onClick={handleSubMenuClickTools}>
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Assignees
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={subMenuAnchorEl}
        open={Boolean(subMenuAnchorEl)}
        onClose={handleClose}
        sx={{ height: '350px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, padding: '8px' }}
          variant="body1">
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Tags
        </Typography>
        <Box
          sx={{
            fontSize: '14px',
            py: 0,
            px: 2,
            '.MuiMenuItem-root': {
              py: '0px'
            }
          }}>
          <TextField
            onClickCapture={stopImmediatePropagation}
            onKeyDown={(e) => e.stopPropagation()}
            placeholder="Search"
            hiddenLabel
            size="small"
            name="keyword"
            value={searchTag}
            onChange={handleSearchTag}
            sx={{ backgroundColor: '#FFFFFF' }}
            inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Box>
        {tagsOption
          ?.filter((el) => {
            if (searchTag) {
              return el?.key?.toLowerCase()?.includes(searchTag.toLowerCase());
            } else {
              return el;
            }
          })
          ?.map((el: any, index) => (
            <MenuItem
              onClick={() => handleMenuItemClick('tag_uuids', el?.value)}
              sx={{ fontSize: '14px' }}
              value={el?.value}
              autoFocus={index === 0}>
              <Checkbox checked={filterData.tag_uuids.includes(el?.value)} /> {el?.key}
            </MenuItem>
          ))}
      </Menu>

      <Menu
        anchorEl={subMenuAnchorElTools}
        open={Boolean(subMenuAnchorElTools)}
        onClose={handleClose}
        sx={{ height: '350px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, padding: '8px' }}
          variant="body1">
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Assignees
        </Typography>
        {generateMenuItems()}
      </Menu>
    </div>
  );
}

export default FilterDropdown;
