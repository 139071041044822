import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useOutlet } from 'react-router-dom';
import MainContainer from '../../components/Container';
import { PageRouteConstants } from '../constants/PageRouteConstants';
import ProtectedRoute from '../RouteAccessor/ProtectedRoute';
import useFilterRoutes from '../../hooks/useFilterRoutes';
import { CompanyUserRoles } from '../constants/ConstantName';
function ServicesRoutes({ children }: { children: React.ReactElement }): React.ReactElement {
  let auth = localStorage.getItem('cognito-token');

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const servicesDetials = useSelector(
    ({ servicesDetials }: any) => servicesDetials?.servicesDetials,
    shallowEqual
  );
  const location = useLocation();

  const isAlow: boolean = useFilterRoutes(servicesDetials, location);
  //return children;

  if (!isAlow && commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR) {
    return <Navigate to={PageRouteConstants.DashBoard} state={{ from: location }} replace />;
  }
  return <>{children}</>;
}

export default ServicesRoutes;
