import { SvgIcon } from '@mui/material';
const AnnocementIcon = (props) => {
  const { color } = props;
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_24637_2841)">
        <path
          d="M19.8566 12.9635C21.6176 12.1663 21.845 8.8689 20.3645 5.59854C18.8841 2.32817 16.2564 0.323257 14.4954 1.12043M19.8566 12.9635C18.0957 13.7606 15.468 11.7557 13.9875 8.48535C12.507 5.21498 12.7344 1.9176 14.4954 1.12043M19.8566 12.9635L6.98316 16.1853C6.06867 16.4142 5.61142 16.5286 5.19385 16.5155C4.34435 16.4887 3.54607 16.1029 2.99735 15.4538C2.72763 15.1348 2.53325 14.7054 2.14448 13.8466C1.75571 12.9878 1.56132 12.5584 1.49957 12.1452C1.37395 11.3046 1.61077 10.4502 2.15119 9.79419C2.41684 9.47175 2.80454 9.20368 3.57994 8.66756L14.4954 1.12043M6.73329 16.7089L9.36607 21.5697C9.6161 22.0313 9.74111 22.2621 9.92671 22.3959C10.0901 22.5137 10.2853 22.5794 10.4867 22.5844C10.7154 22.59 10.9545 22.4818 11.4328 22.2653L13.0566 21.5302C13.6033 21.2827 13.8767 21.159 14.0297 20.9583C14.1641 20.7821 14.2361 20.5661 14.2344 20.3444C14.2325 20.0921 14.0881 19.8291 13.7994 19.3029L11.4945 15.1024"
          stroke={color ? color : '#B7B7B7'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_24637_2841">
          <rect width="24" height="24" fill="white" transform="translate(0.285645)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default AnnocementIcon;
