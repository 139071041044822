import { useState } from 'react';
import { Button, Checkbox, Typography, Menu, MenuItem } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

const FilterDropdown = (props) => {
  const { setFilterData, filterData, osList, manufacturerList, typeList } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [subMenuAnchorElManufacture, setSubMenuAnchorElManufacture] = useState(null);
  const [subMenuAnchorElAssetType, setSubMenuAnchorElAssetType] = useState(null);

  const handleMainMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubMenuClick = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };
  const handleSubMenuClickManufacture = (event) => {
    setSubMenuAnchorElManufacture(event.currentTarget);
  };
  const handleSubMenuClickAssetType = (event) => {
    setSubMenuAnchorElAssetType(event.currentTarget);
  };

  const handleClose = () => {
    setSubMenuAnchorEl(null);
    setSubMenuAnchorElManufacture(null);
    setSubMenuAnchorElAssetType(null);
  };

  const handleCloseMain = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (type, item) => {
    setFilterData((prevState) => {
      const updatedArray = [...prevState[type]];
      const itemIndex = updatedArray.indexOf(item);
      if (itemIndex !== -1) {
        updatedArray.splice(itemIndex, 1);
      } else {
        updatedArray.push(item);
      }
      return {
        ...prevState,
        [type]: updatedArray
      };
    });
  };

  return (
    <div className="multi-dropdown">
      <Button aria-controls="main-menu" aria-haspopup="true" onClick={handleMainMenuClick}>
        <FilterAltOutlinedIcon /> +
      </Button>
      <Menu id="main-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMain}>
        <MenuItem sx={{ fontWeight: 600 }} onClick={handleSubMenuClick}>
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          OS
        </MenuItem>
        <MenuItem sx={{ fontWeight: 600 }} onClick={handleSubMenuClickManufacture}>
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Manufacture
        </MenuItem>
        <MenuItem sx={{ fontWeight: 600 }} onClick={handleSubMenuClickAssetType}>
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Asset Type
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={subMenuAnchorEl}
        open={Boolean(subMenuAnchorEl)}
        onClose={handleClose}
        sx={{ height: '350px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, padding: '8px' }}
          variant="body1">
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          OS
        </Typography>
        {osList?.map((el: any, index) => (
          <MenuItem
            onClick={() => handleMenuItemClick('os', el)}
            sx={{ fontSize: '14px' }}
            value={el}
            autoFocus={index === 0}>
            <Checkbox checked={filterData.os.includes(el)} /> {el}
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={subMenuAnchorElManufacture}
        open={Boolean(subMenuAnchorElManufacture)}
        onClose={handleClose}
        sx={{ height: '350px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, padding: '8px' }}
          variant="body1">
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Manufacture
        </Typography>
        {manufacturerList?.map((el: any, index) => (
          <MenuItem
            onClick={() => handleMenuItemClick('manufacturer', el)}
            sx={{ fontSize: '14px' }}
            value={el}
            autoFocus={index === 0}>
            <Checkbox checked={filterData.manufacturer.includes(el)} /> {el}
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={subMenuAnchorElAssetType}
        open={Boolean(subMenuAnchorElAssetType)}
        onClose={handleClose}
        sx={{ height: '350px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, padding: '8px' }}
          variant="body1">
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Asset Type
        </Typography>
        {typeList?.map((el: any, index) => (
          <MenuItem
            onClick={() => handleMenuItemClick('asset_type', el)}
            sx={{ fontSize: '14px' }}
            value={el}
            autoFocus={index === 0}>
            <Checkbox checked={filterData.asset_type.includes(el)} /> {el}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default FilterDropdown;
