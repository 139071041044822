import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { TopRemediationsApi } from './CompanyTopRemediationsByFlag.Api';
import GlobalIcon from '@jibin/common/icons/GlobalIcon/GlobalIcon';
import moment from 'moment';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import ChartIcon from '@jibin/common/icons/ChartIcon/ChartIcon';
import Style from '@jibin/common/style/Styles';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import CalenderIcon from '@jibin/common/icons/CalenderIcon/CalenderIcon';
export interface TopRemediationProp {
  source: string;
  size: any;
  title: any;
  column: any;
  isDragable?: boolean;
  customIcon?: any;
  currentGrid?: any;
  dropdownMenu?: any;
}
export const CompanyTopRemediationsByFlag: FC<TopRemediationProp> = (prop) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [isSkeleton, setIsSkeleton] = useState(true);

  const [remediations, setRemediations] = useState({ data: [] } as any);

  const getAPIForWidget = (source) => {
    try {
      switch (source) {
        case 'open_risk':
          TopRemediationsApi.OpenRiskList(
            commonDetails?.data?.user_uuid,
            commonDetails?.data?.company.company_uuid,
            'all'
          ).then(
            (result: any) => {
              setIsSkeleton(false);
              const widgetData = [];
              if (result.data) {
                result.data?.data?.companies?.map((item) => {
                  widgetData.push({
                    remediation_uuid: item?.company_uuid,
                    logo_url: item?.company_logo_url,
                    name: item?.name,
                    owner_name: item?.name,
                    website: item?.website,
                    scoreC: item?.criticality.critical,
                    scoreH: item?.criticality.high
                  });
                });
              }

              setRemediations({ data: widgetData });
            },
            (err: any) => {
              setIsSkeleton(false);
            }
          );
          break;
        case 'security_incidents':
          TopRemediationsApi.EventsList(
            commonDetails?.data?.user_uuid,
            commonDetails?.data?.company.company_uuid
          ).then(
            (result: any) => {
              setIsSkeleton(false);
              const widgetData = [];
              if (result.data) {
                result.data?.data?.data?.companies?.forEach((item) => {
                  widgetData.push({
                    remediation_uuid: item?.company_uuid,
                    logo_url: item?.company_logo_url,
                    name: item?.name,
                    owner_name: item?.name,
                    website: item?.website,
                    scoreC: item?.criticality.critical,
                    scoreH: item?.criticality.high
                  });
                });
              }

              setRemediations({ data: widgetData });
            },
            (err: any) => {
              setIsSkeleton(false);
            }
          );
          break;
        case 'rem':
          TopRemediationsApi.OpenRemediationList(
            commonDetails?.data?.user_uuid,
            commonDetails?.data?.company.company_uuid
          ).then(
            (result: any) => {
              setIsSkeleton(false);
              const widgetData = [];
              if (result.data) {
                result.data?.data.map((item) => {
                  widgetData.push({
                    remediation_uuid: item?.company_uuid,
                    logo_url: item?.company_logo_url,
                    name: item?.name,
                    owner_name: item?.name,
                    website: item?.website,
                    scoreC: item?.criticality.critical,
                    scoreH: item?.criticality.high
                  });
                });
              }

              setRemediations({ data: widgetData });
            },
            (err: any) => {
              setIsSkeleton(false);
            }
          );
          break;
        case 'ooart':
          TopRemediationsApi.OpenOldestAssignedRemediationList(
            commonDetails?.data?.user_uuid,
            commonDetails?.data?.company.company_uuid
          ).then(
            (result: any) => {
              setIsSkeleton(false);
              const widgetData = [];
              if (result.data) {
                result.data?.data
                  ?.sort(function (left, right) {
                    return moment.utc(left.created_at).diff(moment.utc(right.created_at));
                  })
                  .map((item) => {
                    widgetData.push({
                      remediation_uuid: item?.company_uuid,
                      logo_url: item?.owner?.ico
                        ? `${process.env.REACT_APP_API_HOST}${item?.owner?.ico}`
                        : '',
                      name: item?.issue_name,
                      owner_name: `${item?.owner?.first_name} ${item?.owner?.last_name}`,
                      company_name: item?.company_name,
                      website: item?.website,
                      discovered_at: item?.created_at,
                      severity: item?.severity
                    });
                  });
              }

              setRemediations({ data: widgetData });
            },
            (err: any) => {
              setIsSkeleton(false);
            }
          );
          break;
        case 'vul':
          TopRemediationsApi.OpenRiskList(
            commonDetails?.data?.user_uuid,
            commonDetails?.data?.company.company_uuid,
            'vulnerability_management'
          ).then(
            (result: any) => {
              setIsSkeleton(false);
              const widgetData = [];
              if (result.data) {
                result.data?.data?.companies?.map((item) => {
                  widgetData.push({
                    remediation_uuid: item?.company_uuid,
                    logo_url: item?.company_logo_url,
                    name: item?.name,
                    owner_name: item?.name,
                    website: item?.website,
                    scoreC: item?.criticality.critical,
                    scoreH: item?.criticality.high
                  });
                });
              }

              setRemediations({ data: widgetData });
            },
            (err: any) => {
              setIsSkeleton(false);
            }
          );
          break;
        case 'attack_surface':
          TopRemediationsApi.AttackSurfaceRatingList(
            commonDetails?.data?.user_uuid,
            commonDetails?.data?.company.company_uuid
          ).then(
            (result: any) => {
              setIsSkeleton(false);
              const widgetData = [];
              if (result.data) {
                result.data?.data.map((item) => {
                  widgetData.push({
                    remediation_uuid: item?.company_uuid,
                    logo_url: item?.company_logo_url,
                    name: item?.name,
                    owner_name: item?.name,
                    company_name: item?.name,
                    website: item?.website,
                    grade: item?.ratting.grade,
                    score: item?.ratting.score
                  });
                });
              }

              setRemediations({ data: widgetData });
            },
            (err: any) => {
              setIsSkeleton(false);
            }
          );
          break;
        case 'sat':
          TopRemediationsApi.SecurityAwarenessTrainingList(
            commonDetails?.data?.user_uuid,
            commonDetails?.data?.company.company_uuid
          ).then(
            (result: any) => {
              setIsSkeleton(false);
              const widgetData = [];
              if (result.data) {
                result.data?.data?.companies
                  ?.sort(
                    (a, b) => a.training_completion_percentage - b.training_completion_percentage
                  )
                  .map((item) => {
                    widgetData.push({
                      remediation_uuid: '',
                      logo_url: item?.company_logo_url,
                      name: item?.company_name,
                      owner_name: item?.company_name,
                      website: item?.company_website,
                      percentage: `${item?.training_completion_percentage}%`
                    });
                  });
              }

              setRemediations({ data: widgetData });
            },
            (err: any) => {
              setIsSkeleton(false);
            }
          );
          break;
        case 'pen':
          TopRemediationsApi.OpenRiskList(
            commonDetails?.data?.user_uuid,
            commonDetails?.data?.company.company_uuid,
            'penetration_testing'
          ).then(
            (result: any) => {
              setIsSkeleton(false);
              const widgetData = [];
              if (result.data) {
                result.data?.data?.companies?.map((item) => {
                  widgetData.push({
                    remediation_uuid: item?.company_uuid,
                    logo_url: item?.company_logo_url,
                    name: item?.name,
                    owner_name: item?.name,
                    website: item?.website,
                    scoreC: item?.criticality.critical,
                    scoreH: item?.criticality.high
                  });
                });
              }

              setRemediations({ data: widgetData });
            },
            (err: any) => {
              setIsSkeleton(false);
            }
          );
          break;
        default:
          break;
      }
    } catch (error) {}
  };

  const GetWidgetDetails = useCallback(() => {
    getAPIForWidget(prop.source);
  }, [commonDetails]);

  const renderColor = (severity: any) => {
    var color = '#F73939';
    if (severity == 'critical') {
      color = '#F73939';
    } else if (severity == 'high') {
      color = '#F59E0B';
    } else if (severity == 'medium') {
      color = '#FBBF24';
    } else if (severity == 'low') {
      color = '#436AF3';
    }
    return color;
  };
  const renderColorBackGround = (severity: any = 'critical') => {
    var color = '#FFE6E2';
    if (severity == 'critical') {
      color = '#FFE6E2';
    } else if (severity == 'high') {
      color = '#FCD34D';
    } else if (severity == 'medium') {
      color = '#FEF3C7';
    } else if (severity == 'low') {
      color = '#D0DAFC';
    }
    return color;
  };

  const renderRatingColor = (rating: any) => {
    let data = {
      bgcolor: 'rgba(167, 243, 208, 0.2)',
      border: '1px solid #27AE60',
      color: '#27AE60'
    };
    if (rating > 800) {
      data = data;
    } else if (rating > 600 && rating <= 800) {
      data = {
        bgcolor: 'rgba(170, 215, 96, 0.16)',
        border: '1px solid #AAD760',
        color: '#34D399'
      };
    } else if (rating > 400 && rating <= 600) {
      data = {
        bgcolor: 'rgba(243, 197, 52, 0.16)',
        border: '1px solid #F3C534',
        color: '#FCD34D'
      };
    } else if (rating > 200 && rating <= 400) {
      data = {
        bgcolor: 'rgba(255, 166, 102, 0.16)',
        border: '1px solid #FFA666',
        color: '#F59E0B'
      };
    } else if (rating <= 200) {
      data = {
        bgcolor: 'rgba(242, 113, 100, 0.16);',
        border: '1px solid #F27164',
        color: '#F87171'
      };
    }
    return data;
  };

  useEffect(() => {
    GetWidgetDetails();
  }, [GetWidgetDetails]);

  return (
    <Box sx={{ display: remediations.data?.length == 0 ? 'flex' : 'block', height: 1, pb: 1 }}>
      <Box
        sx={{
          px: 3,
          pt: 3,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1
              }}>
              <Typography sx={{ fontWeight: 600 }}>{prop?.title}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography variant="caption">
                Last updated on {moment(new Date()).format('DD MMM YYYY')} at{' '}
                {moment(new Date()).format('hh:mmA')}
              </Typography>
            </Box>
          </Box>
          <Box>
            {prop?.isDragable && (
              <>
                {prop?.customIcon}
                {prop?.currentGrid?.i === 'dashboard-remediation-tracker'
                  ? prop?.dropdownMenu
                  : null}
              </>
            )}
          </Box>
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center' }}>
          <Box
            id={'remediation_container'}
            sx={{
              maxHeight: '310px',
              p: 1,
              flexFlow: 1,
              overflowY: 'scroll',
              overflowX: 'hidden',
              '&::-webkit-scrollbar': {
                width: '6px',
                height: '0.2em'
              },
              '&::-webkit-scrollbar-track': {
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',

                borderRadius: '10px'
              }
            }}>
            {isSkeleton ? (
              <Box
                sx={{
                  border: '1px solid #EAECF0',
                  borderRadius: '8px',
                  width: 1,
                  mb: 1
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: 1,
                    p: 1.5
                  }}>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      color: '#696969',
                      whiteSpace: 'nowrap',
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: '1rem', width: '100%' }}
                    />
                  </Typography>
                </Box>
              </Box>
            ) : remediations.data?.length != 0 ? (
              remediations.data?.map((el, index) => (
                <Box
                  id={el.remediation_uuid}
                  className="remediationSingleBox"
                  onClick={() => {}}
                  key={index}
                  sx={{
                    border: '1px solid #EAECF0',
                    borderRadius: '8px',
                    mb: 1,
                    display: 'flex',
                    p: 1,
                    width: 1,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    justifyContent: 'space-between'
                  }}>
                  <Grid container spacing={1} sx={{ justifyContent: 'space-between' }}>
                    <Grid
                      item
                      xs={
                        prop.source === 'ooart'
                          ? 11
                          : prop.column == 6 || prop.column == 8
                          ? 7
                          : prop.column == 4
                          ? 10
                          : prop.column == 12 && 8
                      }>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#696969',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden'
                        }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {el?.logo_url ? (
                              <Box sx={{ ...Style.UserNameRound, height: '42px', width: '42px' }}>
                                <img
                                  style={{ borderRadius: '50%', height: '100%', width: '100%' }}
                                  src={el?.logo_url}
                                />
                              </Box>
                            ) : (
                              <Typography
                                variant="caption"
                                sx={{
                                  ...Style.UserNameRound,
                                  height: '42px',
                                  width: '42px'
                                }}>
                                {el?.owner_name &&
                                  el?.owner_name?.charAt(0) + el?.owner_name?.charAt(1)}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            color: '#696969',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            p: 1.5,
                            pl: 1,
                            width: 1
                          }}>
                          <Box sx={{ display: 'flex' }}>
                            <EllipsisContent
                              text={el.name}
                              sx={{
                                fontWeight: 400,
                                fontSize: '14px',
                                color: '#696969',
                                maxWidth: '600px'
                              }}
                            />
                          </Box>

                          {prop.column != 12 && (
                            <Typography
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                                color: '#787878'
                              }}
                              variant="caption">
                              <GlobalIcon
                                width="14px"
                                height="14px"
                                sx={{ color: '#fff', fontSize: '12px' }}
                              />{' '}
                              <EllipsisContent
                                text={el?.website}
                                sx={{
                                  fontWeight: 400,
                                  fontSize: '12px',
                                  color: '#464646',
                                  maxWidth: '300px'
                                }}
                              />
                            </Typography>
                          )}
                          {prop.source === 'ooart' && (
                            <Grid container spacing={1} sx={{ justifyContent: 'space-between' }}>
                              <Grid item xs={6}>
                                <EllipsisContent
                                  text={el?.owner_name}
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: '12px',
                                    color: '#787878',
                                    maxWidth: '600px'
                                  }}
                                  variant="caption"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 0.5,
                                    color: '#787878'
                                  }}
                                  variant="caption">
                                  <CalenderIcon
                                    width="14px"
                                    height="14px"
                                    sx={{ color: '#464646', fontSize: '12px' }}
                                  />{' '}
                                  <span style={{ color: '#464646', fontSize: '12px' }}>
                                    {moment(el.discovered_at).format('DD MMM YYYY')}
                                  </span>
                                  <EllipsisContent
                                    text={`${moment().diff(
                                      moment(el.discovered_at),
                                      'days'
                                    )} days ago`}
                                    sx={{
                                      fontSize: '12px',
                                      color: '#ff0000',
                                      maxWidth: '600px'
                                    }}
                                  />
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </Box>{' '}
                      </Box>
                    </Grid>
                    {prop.column == 12 && (
                      <Grid sx={{ display: 'flex', alignItems: 'center', gap: 2 }} item xs={2}>
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                            color: '#787878'
                          }}
                          variant="caption">
                          <GlobalIcon
                            width="16px"
                            height="16px"
                            sx={{ color: '#464646', fontSize: '16px' }}
                          />{' '}
                          <span style={{ color: '#464646', fontSize: '12px' }}>
                            {moment(el.created_at).format('DD MMM YYYY')}
                          </span>
                        </Typography>
                      </Grid>
                    )}

                    <Grid
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                      item
                      xs={prop.column == 12 || prop.source === 'ooart' ? 1 : 5}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'end',
                          gap: 1.5,
                          width: 1
                        }}>
                        {prop?.source === 'attack_surface' ||
                        prop?.source === 'sat' ||
                        prop?.source === 'ooart' ? (
                          <Box>
                            {prop?.source === 'attack_surface' && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 0.5,
                                  alignItems: 'center',
                                  width: '110px'
                                }}>
                                <Box
                                  sx={{
                                    ...Style.Upguard.Grade,
                                    height: '32px',
                                    width: '32px',
                                    background: renderRatingColor(el?.score).bgcolor,
                                    border: renderRatingColor(el?.score).border
                                  }}>
                                  <Typography
                                    sx={{
                                      ...Style.Upguard.Arating,
                                      fontSize: '24px',
                                      color: renderRatingColor(el?.score).color
                                    }}>
                                    {el?.grade}
                                  </Typography>
                                </Box>
                                <Typography
                                  sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                                  {el?.score}
                                </Typography>
                              </Box>
                            )}
                            {prop?.source === 'sat' && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 0.5,
                                  alignItems: 'center',
                                  width: '110px'
                                }}>
                                <Box
                                  sx={{
                                    width: '32px',
                                    height: '32px',
                                    backgroundColor: '#F2F9FF',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                    borderRadius: '50%'
                                  }}>
                                  <ChartIcon
                                    sx={
                                      {
                                        // color: renderColor('critical'),
                                        // width: '16px'
                                      }
                                    }
                                  />
                                </Box>
                                <Typography
                                  sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                                  {el?.percentage}
                                </Typography>
                              </Box>
                            )}
                            {prop?.source === 'ooart' && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 0.5,
                                  alignItems: 'center'
                                  // width: '110px'
                                }}>
                                <Box
                                  sx={{
                                    minWidth: '32px',
                                    height: '32px',
                                    backgroundColor: renderColorBackGround(el.severity),
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '50%'
                                  }}>
                                  <FlagIcon
                                    sx={{
                                      color: renderColor(el.severity),
                                      width: '16px'
                                    }}
                                  />
                                </Box>
                              </Box>
                            )}
                          </Box>
                        ) : (
                          <Grid container spacing={1}>
                            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Box sx={{ display: 'flex', gap: 0.5, minWidth: '100px', width: 1 }}>
                                <Box
                                  sx={{
                                    minWidth: '32px',
                                    height: '32px',
                                    backgroundColor: renderColorBackGround('critical'),
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '50%'
                                  }}>
                                  <FlagIcon
                                    sx={{
                                      color: renderColor('critical'),
                                      width: '16px'
                                    }}
                                  />
                                </Box>
                                <EllipsisContent
                                  text={`${el?.scoreC}`}
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: '28px',
                                    lineHeight: '34px',
                                    maxWidth: '80px'
                                  }}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Box sx={{ display: 'flex', gap: 0.5, minWidth: '100px', width: 1 }}>
                                <Box
                                  sx={{
                                    minWidth: '32px',
                                    height: '32px',
                                    backgroundColor: renderColorBackGround('high'),
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '50%'
                                  }}>
                                  <FlagIcon
                                    sx={{
                                      color: renderColor('high'),
                                      width: '16px'
                                    }}
                                  />
                                </Box>
                                <EllipsisContent
                                  text={`${el?.scoreH}`}
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: '28px',
                                    lineHeight: '34px',
                                    maxWidth: '80px'
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))
            ) : (
              <Box
                sx={{ height: 1, textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                <Box
                  sx={{
                    maxWidth: '196px',
                    height: '196px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 1,
                    borderRadius: '50%',
                    border: '20px solid #EBEBEB',
                    p: 2.5
                  }}>
                  <Typography variant="body2">No {prop?.title}</Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
