import { useState, useEffect, useCallback } from "react";
import { Box, List, Typography, Tabs, Tab, FormControl, Autocomplete, TextField, Chip, Select, MenuItem, IconButton } from "@mui/material";
import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import { CompanySettingsService } from "../CompanySettings.Api";
import moment from 'moment';
import CalenderIconFilter from '@jibin/common/icons/CalenderIconFilter/CalenderIconFilter';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { LoadingButton } from '@mui/lab';
import UploadIcon from '@jibin/common/icons/UploadIcon/UploadIcon';
import GallaryIcon from '@jibin/common/icons/GallaryIcon/GallaryIcon';
import VideoPlay from '@jibin/common/icons/VideoPlay/VideoPlay';
import FileIcon from '@jibin/common/icons/FileIcon/FileIcon';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import { ServiceReviewApi } from "src/Pages/CustomerSuccess/ServicesReview/ServiceReview.Api";
import PinIcon from '@jibin/common/icons/PinIcon/PinIcon';
import CloseIcon from '@jibin/common/icons/Close/Close';
import { toast } from 'react-toastify';








interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}


const LicenseDialog = (prop) => {
    const { closePopup, servicesOption, commonDetails, GetLicenses, setGloblClose, setEditLicense, editLicense, rowData, setRowData } = prop
    const [valueTab, setValueTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [value, setValue] = useState<any>([]);
    const [vendors, setVendors] = useState<any>([]);
    const [tools, setTools] = useState<any>([]);
    const [selectedVendor, setSelectedVendor] = useState<any>([]);
    const [selectedTool, setSelectedTool] = useState<any>([]);
    const [fileData, setFileData] = useState([] as any);
    const [historyData, setHistoryData] = useState([] as any);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const today = new Date();

    const years = Array.from({ length: 2199 - 1989 }, (_, index) => 1990 + index);
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    const [licenseData, setLicenseData] = useState({
        service_name: '',
        vendor_name: '',
        tool_name: '',
        services_uuid: '',
        vendor_uuid: '',
        integration_tools_uuid: '',
        status: 'Active',
        start_date: '',
        end_date: '',
        licenses_used: '',
        total_licenses: ''
    });

    const resetLicenseData = () => {
        setLicenseData({
            service_name: '',
            vendor_name: '',
            tool_name: '',
            services_uuid: '',
            vendor_uuid: '',
            integration_tools_uuid: '',
            status: 'Active',
            start_date: '',
            end_date: '',
            licenses_used: '',
            total_licenses: ''
        });
        setValue([]);
        setVendors([]);
        setTools([]);
        setSelectedVendor([]);
        setSelectedTool([]);
        setFileData([])
        setHistoryData([])
        setValueTab(0)
        setUploadedFiles([])
    };

    const handleCloseLicense = () => {
        resetLicenseData();
        setRowData(null);
        setEditLicense(false);
        setGloblClose(true);
    };

    const GetVendorServices = useCallback(async () => {
        const uuid = servicesOption.filter((item) => item?.name === value[0]);
        if (uuid?.length) {
            try {
                const res = await CompanySettingsService.licenseVendors(
                    commonDetails?.data?.company.company_uuid,
                    commonDetails?.data?.user_uuid,
                    uuid[0]?.value
                );
                setVendors(res?.data?.data);
            } catch (error) { }
        }
    }, [commonDetails, value]);

    useEffect(() => {
        GetVendorServices();
    }, [GetVendorServices, value]);
    const maketagsVendor = (data: any) => {
        const facility = [];

        data.forEach((element) => {
            let facilitytype = vendors.filter((q) => q.name == element);
            if (facilitytype?.length > 0) {
                facility.push({ name: element, tag_uuid: facilitytype[0].tag_uuid });
            } else {
                facility.push({ name: element });
            }
        });

        setSelectedVendor([...facility]);
    };
    const handleChangeData = (e: any, value?: any) => {
        if (e === 'vendor_uuid') {
            setSelectedTool([]);

            const lastElementArray = value[value?.length - 1];
            const service = vendors.filter((item) => item?.name === lastElementArray);
            if (service?.length) {
                setSelectedVendor([service[0]?.name]);
                const uuid = vendors.filter((item) =>
                    item?.name === lastElementArray ? lastElementArray[0] : ''
                );
                setLicenseData((prevLicenseData) => ({
                    ...prevLicenseData,
                    vendor_uuid: uuid[0]?.vendor_uuid
                }));
            } else {
                setSelectedVendor(value[value?.length - 1] ? [value[value?.length - 1]] : []);
                setLicenseData((prevLicenseData) => ({
                    ...prevLicenseData,
                    vendor_uuid: '',
                    vendor_name: lastElementArray
                }));
            }
            if (value?.length === 0) {
                setTools([]);
                setSelectedVendor([]);

                setLicenseData((prevLicenseData) => ({
                    ...prevLicenseData,
                    vendor_uuid: '',
                    integration_tools_uuid: ''
                }));
            }
        } else if (e === 'integration_tools_uuid') {
            const lastElementArray = value[value?.length - 1];
            const service = tools.filter((item) => item?.name === lastElementArray);
            if (service?.length) {
                setSelectedTool([service[0]?.name]);
                const uuid = tools.filter((item) =>
                    item?.name === lastElementArray ? lastElementArray[0] : ''
                );
                setLicenseData((prevLicenseData) => ({
                    ...prevLicenseData,
                    integration_tools_uuid: uuid[0]?.integration_tools_uuid
                }));
            } else {
                setSelectedTool(value[value?.length - 1] ? [value[value?.length - 1]] : []);
                setLicenseData((prevLicenseData) => ({
                    ...prevLicenseData,
                    integration_tools_uuid: '',
                    tool_name: lastElementArray
                }));
            }
            if (value?.length === 0) {
                setSelectedTool([]);

                setLicenseData((prevLicenseData) => ({
                    ...prevLicenseData,

                    integration_tools_uuid: ''
                }));
            }
        } else if (e === 'services_uuid') {
            setSelectedVendor([]);
            setSelectedTool([]);
            setTools([]);
            setVendors([]);
            const lastElementArray = value[value?.length - 1];
            const service = servicesOption.filter((item) => item?.name === lastElementArray);
            if (service?.length) {
                setValue([service[0]?.name]);
                const uuid = servicesOption.filter((item) =>
                    item?.name === lastElementArray ? lastElementArray[0] : ''
                );
                setLicenseData((prevLicenseData) => ({
                    ...prevLicenseData,
                    services_uuid: uuid[0]?.value
                }));
            } else {
                setValue(value[value?.length - 1] ? [value[value?.length - 1]] : []);
                setLicenseData((prevLicenseData) => ({
                    ...prevLicenseData,
                    services_uuid: '',
                    service_name: lastElementArray
                }));
            }
            if (value?.length === 0) {
                setValue([]);
                setTools([]);
                setVendors([]);
                setSelectedTool([]);
                setSelectedVendor([]);
                setLicenseData((prevLicenseData) => ({
                    ...prevLicenseData,
                    vendor_uuid: '',
                    integration_tools_uuid: '',
                    services_uuid: ''
                }));
            }
        } else {
            setLicenseData((prevLicenseData) => ({
                ...prevLicenseData,
                [e?.target?.name]: e?.target?.value
            }));
        }
    };

    const handleChangeDate = (date, field) => {
        if (field === 'start_date') {
            if (licenseData.end_date && date > licenseData.end_date) {
                setLicenseData((prevLicenseData) => ({
                    ...prevLicenseData,
                    [field]: date,
                    end_date: null
                }));
            } else {
                setLicenseData((prevLicenseData) => ({ ...prevLicenseData, [field]: date }));
            }
        } else if (field === 'end_date') {
            if (licenseData.start_date && date < licenseData.start_date) {
                setLicenseData((prevLicenseData) => ({
                    ...prevLicenseData,
                    [field]: date,
                    start_date: null
                }));
            } else {
                setLicenseData((prevLicenseData) => ({ ...prevLicenseData, [field]: date }));
            }
        }
    };

    const validate = () => {
        let isValid = true;

        if (vendors?.length && !licenseData.vendor_uuid && !licenseData?.vendor_name) {
            isValid = false;
        }

        if (tools?.length && !licenseData.integration_tools_uuid && !licenseData?.tool_name) {
            isValid = false;
        }

        if (
            servicesOption?.length &&
            !licenseData.services_uuid &&
            (!licenseData?.service_name || !licenseData?.service_name?.length)
        ) {
            isValid = false;
        }

        if (
            (!licenseData.licenses_used && licenseData.licenses_used != '0') ||
            !licenseData.total_licenses ||
            licenseData.total_licenses == '0' ||
            !licenseData.start_date ||
            !licenseData.end_date
        ) {
            isValid = false;
        }

        setIsComplete(isValid);
    };

    const GetTools = useCallback(async () => {
        try {
            const res = await CompanySettingsService.licenseTools(
                commonDetails?.data?.company.company_uuid,
                commonDetails?.data?.user_uuid,
                licenseData?.services_uuid,
                licenseData?.vendor_uuid
            );
            setTools(res?.data?.data);
        } catch (error) { }
    }, [commonDetails, licenseData?.vendor_uuid]);

    useEffect(() => {
        if (licenseData?.vendor_uuid) {
            GetTools();
        }
    }, [licenseData?.vendor_uuid, GetTools]);

    const handleConfirm = async () => {
        if (!isComplete) return;
        const service = servicesOption.filter((item) => item?.value === licenseData?.services_uuid);
        const vendor = vendors.filter((item) => item?.vendor_uuid === licenseData?.vendor_uuid);
        const tool = tools.filter(
            (item) => item?.integration_tools_uuid === licenseData?.integration_tools_uuid
        );
        const startDate = moment(licenseData?.start_date).format('YYYY-MM-DDTHH:mm:ssZ');
        const endDate = moment(licenseData?.end_date).format('YYYY-MM-DDTHH:mm:ssZ');

        let obj: any = {
            status: licenseData?.status,
            start_date: startDate,
            end_date: endDate,
            licenses_used: parseInt(licenseData?.licenses_used),
            total_licenses: parseInt(licenseData?.total_licenses),
            files: []
        };

        for (let i = 0; i < fileData.length; i++) {
            obj.files.push({
                file_name: fileData[i].name,
                file_size: fileData[i].size,
                file_type: fileData[i].type
            });
        }

        if (editLicense) {
            if (licenseData.services_uuid) {
                obj.service = {
                    name: licenseData?.service_name,
                    services_uuid: licenseData?.services_uuid
                };
            } else {
                obj.service = {
                    name: licenseData?.service_name
                };
            }

            if (licenseData?.vendor_uuid) {
                obj.vendor = {
                    name: licenseData.vendor_name,
                    vendor_uuid: licenseData.vendor_uuid
                };
            } else {
                obj.vendor = {
                    name: licenseData?.vendor_name
                };
            }

            if (licenseData?.integration_tools_uuid) {
                obj.tool = {
                    name: licenseData?.tool_name,
                    integration_tools_uuid: licenseData?.integration_tools_uuid
                };
            } else {
                obj.tool = {
                    name: licenseData?.tool_name
                };
            }
        } else {
            if (licenseData?.services_uuid) {
                obj.service = {
                    name: service[0]?.name,
                    services_uuid: service[0]?.value
                };
            } else {
                obj.service = {
                    name: licenseData?.service_name
                };
            }

            if (licenseData?.vendor_uuid) {
                obj.vendor = {
                    name: vendor[0]?.name,
                    vendor_uuid: vendor[0]?.vendor_uuid
                };
            } else {
                obj.vendor = {
                    name: licenseData?.vendor_name
                };
            }

            if (licenseData?.integration_tools_uuid) {
                obj.tool = {
                    name: tool[0]?.name,
                    integration_tools_uuid: tool[0]?.integration_tools_uuid
                };
            } else {
                obj.tool = {
                    name: licenseData?.tool_name
                };
            }
        }

        try {
            setIsLoading(true)
            if (editLicense) {
                const res = await CompanySettingsService.updateLicense(
                    commonDetails?.data?.company.company_uuid,
                    commonDetails?.data?.user_uuid,
                    rowData?.license_utilizations_uuid,
                    obj
                );
                await ServiceReviewApi.UploadDocuments(res, fileData);
            } else {
                const res = await CompanySettingsService.createLicense(
                    commonDetails?.data?.company.company_uuid,
                    commonDetails?.data?.user_uuid,
                    obj
                );
                await ServiceReviewApi.UploadDocuments(res, fileData);
            }
            closePopup()
            setIsLoading(false)
            GetLicenses();
            handleCloseLicense();
        } catch (err) {
            setIsLoading(false)
            console.log(err);
        }
    };

    const GetLicensesHistory = async () => {
        try {
            const res = await CompanySettingsService.historyLicense(
                commonDetails?.data?.company.company_uuid,
                commonDetails?.data?.user_uuid,
                rowData?.license_utilizations_uuid
            );
            setHistoryData(res?.data?.data)
        } catch (err) { console.log(err) }
    }

    useEffect(() => {
        validate();
    }, [licenseData, vendors, tools]);

    useEffect(() => {
        if (editLicense) {
            setLicenseData({
                service_name: rowData?.service?.name,
                vendor_name: rowData?.vendor?.name,
                tool_name: rowData?.tool?.name,
                services_uuid: rowData?.service?.services_uuid,
                vendor_uuid: rowData?.vendor?.vendor_uuid,
                integration_tools_uuid: rowData?.tool?.integration_tools_uuid,
                status: rowData?.status,
                start_date: rowData?.start_date,
                end_date: rowData?.end_date,
                licenses_used: rowData?.licenses_used,
                total_licenses: rowData?.total_licenses
            });
            setValue([rowData?.service?.name]);
            setSelectedVendor(rowData?.vendor?.name ? [rowData?.vendor?.name] : []);
            setSelectedTool(rowData?.tool?.name ? [rowData?.tool?.name] : []);
            setUploadedFiles(rowData?.files)
        } else {
            resetLicenseData()
        }
    }, [editLicense]);



    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
        if (newValue == 1 && editLicense) {
            GetLicensesHistory();
        }
    };

    const stopReload = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleImageUpload = (e, files) => {
        stopReload(e);

        if (!e) return;
        const selectedFiles = Array.from(files);
        const newFileCount = selectedFiles.length + fileData.length;

        if (newFileCount > 10) {
            toast.error("Maximum 10 files can be uploaded");
            return;
        }

        setFileData([...fileData, ...selectedFiles]);
    };

    const handleDragEvent = (e: any) => {
        handleImageUpload(e, e.dataTransfer.files);
    };

    const handleBrowseEvent = (e: any) => {
        const selectedFiles = Array.from(e.target.files);
        const newFileCount = selectedFiles.length + fileData.length;
        
        if (newFileCount > 10) {
            toast.error("Maximum 10 files can be uploaded");
            return;
        }

        setFileData([...fileData, ...selectedFiles]);
    };

    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const niceBytes = (x) => {
        let l = 0,
            n = parseInt(x, 10) || 0;

        while (n >= 1024 && ++l) {
            n = n / 1024;
        }

        return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
    }
    const handleRemoveFile = (i) => {
        const upadatedFileData = fileData?.filter((el, index) => index != i);
        setFileData(upadatedFileData);
    };

    const getFile = async (el: any) => {
        try {
            const res = await CompanySettingsService.GetLicenseFile(
                commonDetails?.data?.company.company_uuid,
                commonDetails?.data?.user_uuid,
                rowData.license_utilizations_uuid,
                el.file_name
            );

            const urlName = res?.data?.data;
            const a = document.createElement('a');
            a.href = urlName;
            a.target = '_blank';
            //a.download = `downloadfile.${res.headers['content-type'].split('/')[1]}`;
            a.download = `${el.file_name}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.log('ERROR ::', error);
        }
    };



    const DeleteActionTakenFile = async (element) => {
        try {
            const upadatedFileData = uploadedFiles?.filter((el) => el.file_uuid !== element.file_uuid);
            setUploadedFiles(upadatedFileData);
            try {
                const res = await CompanySettingsService.removeLicenseFile(
                    commonDetails?.data?.company.company_uuid,
                    commonDetails?.data?.user_uuid,
                    rowData.license_utilizations_uuid,
                    element?.file_uuid
                );
            } catch (err) { console.log(err) }
        } catch (error) { }
    };

    return (
        <>
            <Box
                sx={{
                    width: { md: 520, xs: 300 },
                    height: '100vh',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column'
                }}
                role="presentation">
                <List sx={{ p: 0 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 1,
                            justifyContent: 'space-between',
                            backgroundColor: '#003260',
                            px: 2.5,
                            alignItems: 'center',
                            py: 1
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: 1,
                                alignItems: 'center'
                            }}>
                            <Typography
                                variant="body1"
                                sx={{ fontSize: '19px', fontWeight: 700, color: '#ffffff', my: 1.25 }}>
                                {editLicense ? 'Edit' : 'Add'} License
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box
                                    onClick={(event) => {
                                        closePopup(event);
                                    }}>
                                    <CrossCancle
                                        sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </List>

                <Box sx={{ borderColor: 'divider' }}>
                    <Tabs
                        value={valueTab}
                        sx={{ '.Mui-selected': { textDecoration: 'underline' }, minHeight: '0px' }}
                        TabIndicatorProps={{ sx: { display: 'none' } }}
                        onChange={handleChange}
                        aria-label="basic tabs example">
                        <Tab
                            sx={{ justifyContent: 'flex-end', padding: '0px', textTransform: 'none' }}
                            label="License"
                            {...a11yProps(0)}
                        />
                        {editLicense ? <Tab
                            label="History"
                            sx={{ justifyContent: 'flex-end', padding: '0px', textTransform: 'none' }}
                            {...a11yProps(2)}
                        /> : null}
                    </Tabs>
                </Box>

                <Box sx={{ padding: '8px 20px' }}>

                    <TabPanel value={valueTab} index={0}>

                        <FormControl variant="outlined" fullWidth sx={{ mb: 3 }}>
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body2" sx={{ fontWeight: '600', color: '#696969' }}>Cybersecurity Domain</Typography>
                            </Box>
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                options={servicesOption
                                    .sort((a: any, b: any) => {
                                        return a?.name > b?.name ? 1 : -1;
                                    })
                                    .map((option) => option.name)}
                                freeSolo
                                value={value}
                                renderTags={(value, getTagProps) => {
                                    return value?.map((option, index) => (
                                        <Chip variant="outlined" label={option} sx={{ fontSize: '12px' }} {...getTagProps({ index })} />
                                    ));
                                }}
                                onChange={(e, value) => handleChangeData('services_uuid', value)}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder={value?.length > 0 ? '' : 'Select Cybersecurity Domain'}
                                        sx={{
                                            '& .MuiInputBase-input': { fontSize: '12px' },
                                            '& .MuiInputLabel-root': { fontSize: '12px' },
                                            '& .MuiAutocomplete-input': { fontSize: '12px' }
                                        }}
                                    />
                                )}
                                sx={{
                                    '&.MuiAutocomplete-root': {
                                        '.MuiOutlinedInput-root': {
                                            p: 0,
                                            pl: 1
                                        },
                                        '.MuiAutocomplete-tag': {
                                            m: 1,
                                            bgcolor: 'common.lightBlue',
                                            border: 'none'
                                        }
                                    }
                                }}
                            />
                        </FormControl>

                        <FormControl variant="outlined" fullWidth sx={{ mb: 3 }}>
                            <Typography sx={{ mb: 2, fontWeight: '600', color: '#696969' }} variant="body2">
                                Vendor Name
                            </Typography>
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                freeSolo
                                options={vendors
                                    .sort((a: any, b: any) => {
                                        return a?.name > b?.name ? 1 : -1;
                                    })
                                    .map((option) => option.name)}
                                value={selectedVendor ? selectedVendor : null}
                                renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => (
                                        <Chip variant="outlined" label={option} sx={{ fontSize: '12px' }} {...getTagProps({ index })} />
                                    ));
                                }}
                                selectOnFocus
                                onChange={(event: any, newValue, reason) =>
                                    handleChangeData('vendor_uuid', newValue)
                                }
                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        {...params}
                                        placeholder={selectedVendor?.length > 0 ? '' : 'Select Vendor'}
                                        sx={{
                                            '& .MuiInputBase-input': { fontSize: '12px' },
                                            '& .MuiInputLabel-root': { fontSize: '12px' },
                                            '& .MuiAutocomplete-input': { fontSize: '12px' }
                                        }}
                                    />
                                )}
                                sx={{
                                    '&.MuiAutocomplete-root': {
                                        '.MuiAutocomplete-input': {
                                            minWidth: '70px'
                                        },
                                        '.MuiOutlinedInput-root': {
                                            p: 0,
                                            pl: 1,
                                            pr: 1,
                                            '.MuiAutocomplete-clearIndicator': {
                                                margin: '0px -6px 0px 0px'
                                            }
                                        },
                                        '.MuiAutocomplete-tag': {
                                            m: '7px',
                                            bgcolor: 'common.lightBlue',
                                            border: 'none'
                                        },
                                        '.MuiAutocomplete-endAdornment': {
                                            right: '5px'
                                        }
                                    }
                                }}
                            />
                        </FormControl>

                        <FormControl variant="outlined" fullWidth sx={{ mb: 3 }}>
                            <Typography sx={{ mb: 2, fontWeight: '600', color: '#696969' }} variant="body2">
                                Tool Name
                            </Typography>
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                freeSolo
                                options={tools
                                    .sort((a: any, b: any) => {
                                        return a?.name > b?.name ? 1 : -1;
                                    })
                                    .map((option) => option.name)}
                                value={selectedTool ? selectedTool : null}
                                renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => (
                                        <Chip variant="outlined" label={option} sx={{ fontSize: '12px' }} {...getTagProps({ index })} />
                                    ));
                                }}
                                selectOnFocus
                                onChange={(event: any, newValue, reason) =>
                                    handleChangeData('integration_tools_uuid', newValue)
                                }
                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        {...params}
                                        placeholder={selectedTool?.length > 0 ? '' : 'Select Tool'}
                                        sx={{
                                            '& .MuiInputBase-input': { fontSize: '12px' },
                                            '& .MuiInputLabel-root': { fontSize: '12px' },
                                            '& .MuiAutocomplete-input': { fontSize: '12px' }
                                        }}
                                    />
                                )}
                                sx={{
                                    '&.MuiAutocomplete-root': {
                                        '.MuiAutocomplete-input': {
                                            minWidth: '70px'
                                        },
                                        '.MuiOutlinedInput-root': {
                                            p: 0,
                                            pl: 1,
                                            pr: 1,
                                            '.MuiAutocomplete-clearIndicator': {
                                                margin: '0px -6px 0px 0px'
                                            }
                                        },
                                        '.MuiAutocomplete-tag': {
                                            m: '7px',
                                            bgcolor: 'common.lightBlue',
                                            border: 'none'
                                        },
                                        '.MuiAutocomplete-endAdornment': {
                                            right: '5px'
                                        }
                                    }
                                }}
                            />
                        </FormControl>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                            <Box>
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="body2" sx={{ fontWeight: '600', color: '#696969' }}>Total Licenses</Typography>
                                </Box>
                                <TextField
                                    size="small"
                                    placeholder="Total Licenses"
                                    id="total_licenses"
                                    variant="outlined"
                                    type={'number'}
                                    name="total_licenses"
                                    value={licenseData?.total_licenses}
                                    onChange={(e) => {
                                        handleChangeData(e);
                                        // formik.handleChange(e);
                                        // formik.errors.first_name = '';
                                    }}
                                    inputProps={{
                                        style: {
                                            textTransform: 'capitalize',
                                            fontSize: '14px'
                                        },
                                        min: 0
                                    }}
                                    aria-describedby="outlined-weight-helper-text"
                                />
                            </Box>
                            <Box>
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="body2" sx={{ fontWeight: '600', color: '#696969' }}>Current Usage</Typography>
                                </Box>
                                <TextField
                                    size="small"
                                    placeholder="Current Usage"
                                    id="licenses_used"
                                    variant="outlined"
                                    type={'number'}
                                    name="licenses_used"
                                    value={licenseData?.licenses_used}
                                    onChange={(e) => {
                                        handleChangeData(e);
                                        // formik.handleChange(e);
                                        // formik.errors.first_name = '';
                                    }}
                                    inputProps={{
                                        style: {
                                            textTransform: 'capitalize',
                                            fontSize: '14px'
                                        },
                                        min: 0
                                    }}
                                    aria-describedby="outlined-weight-helper-text"
                                />
                            </Box>
                        </Box>

                        <Box sx={{ mb: 3 }}>
                            <FormControl variant="outlined" sx={{ width: 1 }}>
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="body2" sx={{ fontWeight: '600', color: '#696969' }}>Status</Typography>
                                </Box>
                                <Select
                                    size="small"
                                    // displayEmpty
                                    id="status"
                                    name="status"
                                    value={licenseData?.status}
                                    sx={{ fontSize: '14px' }}
                                    // error={formik.errors.is_external ? true : false}
                                    onChange={(e) => {
                                        handleChangeData(e);
                                        // formik.handleChange(e);
                                        //   //formik.errors.is_external = '';
                                    }}>
                                    <MenuItem value={'Active'}>Active</MenuItem>
                                    <MenuItem value={'Inactive'}>Inactive</MenuItem>
                                    <MenuItem value={'Expired'}>Expired</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{ mb: 3 }}>
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body2" sx={{ fontWeight: '600', color: '#696969' }}>Upload File</Typography>
                            </Box>
                            <Box
                                onDragOver={(e) => stopReload(e)}
                                onDrop={(e) => handleDragEvent(e)}
                                sx={{
                                    width: '100%',
                                    backgroundColor: '#FBFBFF',
                                    py: 4,
                                    display: 'flex',
                                    border: '1px dashed #CCCCCC',
                                    borderRadius: '8px',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 2.5
                                }}>
                                <UploadIcon />
                                <Typography variant="body1" sx={{ fontWeight: 600, color: '#464646' }}>
                                    Drag & Drop or{' '}
                                    <label style={{ color: '#436AF3', cursor: 'pointer' }}>
                                        <input
                                            multiple
                                            hidden
                                            onChange={(e) => handleBrowseEvent(e)}
                                            style={{
                                                backgroundColor: 'transparent',
                                                color: 'transparent'
                                            }}
                                            type={'file'}
                                        />{' '}
                                        Choose File{' '}
                                    </label>
                                    to upload
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1.5,
                                        width: '100%',
                                        alignItem: 'center',
                                        m: 'auto'
                                    }}>
                                    {fileData.length == 0 ? (
                                        <Typography textAlign={'center'} variant="body2" sx={{ color: '#787878' }}>
                                            Document, Image, or Video
                                        </Typography>
                                    ) : (
                                        fileData?.map((el, index) => (
                                            <Box
                                                key={index + 1}
                                                sx={{
                                                    display: 'flex',
                                                    width: '80%',
                                                    m: 'auto',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    p: 1.5,
                                                    borderRadius: '8px',
                                                    backgroundColor: '#FFFFFF',
                                                    border: '1px solid #EBEBEB'
                                                }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    {el.type.split('/')[0] == 'image' ? (
                                                        <GallaryIcon />
                                                    ) : el.type.split('/')[0] == 'video' ? (
                                                        <VideoPlay />
                                                    ) : (
                                                        <FileIcon />
                                                    )}

                                                    <EllipsisContent
                                                        sx={{
                                                            fontSize: 14,
                                                            color: '#2D2D2D',
                                                            width: 'max-content',
                                                            maxWidth: {
                                                                xxs: 150,
                                                                md: 150
                                                            }
                                                        }}
                                                        text={el.name}
                                                    />

                                                    <Typography variant="body2" sx={{ color: '#787878' }}>
                                                        {niceBytes(el.size)}
                                                    </Typography>
                                                </Box>
                                                <Box onClick={() => handleRemoveFile(index)}>
                                                    <CrossCancle
                                                        sx={{
                                                            background: 'rgba(120, 120, 120,0.1)',
                                                            width: '20px',
                                                            height: '20px',
                                                            color: '#787878',
                                                            p: '6px',
                                                            borderRadius: '50%',
                                                            cursor: 'pointer'
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        ))
                                    )}
                                </Box>
                            </Box>
                        </Box>

                        <Box>
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body2" sx={{ fontWeight: '600', color: '#696969' }}>License Duration</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', height: '50px' }}>
                                <Box
                                    sx={{
                                        mr: 2,
                                        '.calendar-filter': {
                                            fontSize: 14
                                        }
                                    }}>
                                    <DatePicker
                                        showYearDropdown
                                        showMonthDropdown
                                        fixedHeight
                                        showIcon
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled
                                        }) => (
                                            <div
                                                style={{
                                                    margin: 10,
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}>
                                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                    {'<'}
                                                </button>
                                                <select
                                                    value={date.getFullYear()}
                                                    onChange={({ target: { value } }) => changeYear(value)}>
                                                    {years.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <select
                                                    value={months[date.getMonth()]}
                                                    onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                    {months.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <button
                                                    onClick={increaseMonth}
                                                    disabled={
                                                        nextMonthButtonDisabled ||
                                                        (date.getFullYear() === 2199 && date.getMonth() === 11)
                                                    }>
                                                    {'>'}
                                                </button>
                                            </div>
                                        )}
                                        className="calendar-filter"
                                        icon={<CalenderIconFilter />}
                                        dateFormat="dd MMM yyyy"
                                        placeholderText="Start Date"
                                        selected={licenseData?.start_date ? new Date(licenseData.start_date) : null}
                                        onChange={(date) => handleChangeDate(date, 'start_date')}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        '.calendar-filter': {
                                            fontSize: 14
                                        }
                                    }}>
                                    <DatePicker
                                        showYearDropdown
                                        showMonthDropdown
                                        fixedHeight
                                        showIcon
                                        className="calendar-filter"
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled
                                        }) => (
                                            <div
                                                style={{
                                                    margin: 10,
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}>
                                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                    {'<'}
                                                </button>
                                                <select
                                                    value={date.getFullYear()}
                                                    onChange={({ target: { value } }) => changeYear(value)}>
                                                    {years.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <select
                                                    value={months[date.getMonth()]}
                                                    onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                    {months.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <button
                                                    onClick={increaseMonth}
                                                    disabled={
                                                        nextMonthButtonDisabled ||
                                                        (date.getFullYear() === 2199 && date.getMonth() === 11)
                                                    }>
                                                    {'>'}
                                                </button>
                                            </div>
                                        )}
                                        icon={<CalenderIconFilter />}
                                        dateFormat="dd MMM yyyy"
                                        placeholderText="End Date"
                                        selected={licenseData?.end_date ? new Date(licenseData.end_date) : null}
                                        onChange={(date) => handleChangeDate(date, 'end_date')}
                                        minDate={licenseData?.start_date || today}
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Box>
                            {uploadedFiles?.length > 0 && (
                                <Box sx={{}}>
                                    <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600, pt: 3 }}>
                                        Files
                                    </Typography>

                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 1.5 }}>
                                        {uploadedFiles.map((element, index) => (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    gap: 1,
                                                    mt: 2,
                                                    alignItems: 'center',
                                                    backgroundColor: '#D9EDFF',
                                                    py: '5px',
                                                    px: 1.5,
                                                    borderRadius: '100px',
                                                    cursor: 'pointer',
                                                    width: 'fit-content'
                                                }}>
                                                <a
                                                    href={element?.file_url}
                                                    style={{ textDecoration: 'none' }}
                                                    target="_blank"
                                                    onClick={() => getFile(element)}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            gap: 1,
                                                            alignItems: 'center'
                                                        }}>
                                                        <PinIcon />
                                                        <EllipsisContent text={element.file_name} maxWidth={377} />
                                                    </Box>
                                                </a>
                                                {editLicense && (
                                                    <IconButton
                                                        sx={{ color: 'text.primary', width: '24px', height: '24px' }}
                                                        aria-label="close"
                                                        onClick={() => DeleteActionTakenFile(element)}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            )}
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                            <LoadingButton
                                loading={isLoading}
                                disabled={!isComplete}
                                variant="contained"
                                sx={{ minWidth: 100 }}
                                onClick={handleConfirm}>
                                {' '}
                                Submit
                            </LoadingButton>
                        </Box>

                    </TabPanel>

                    <TabPanel value={valueTab} index={1}>
                        <Box sx={{ display: 'inline-block' }}>
                            {historyData?.map((element: any, index) =>
                                <Typography variant="body2" sx={{ color: '#696969', mt: 2 }}>
                                    <span
                                        style={{ wordBreak: 'break-word' }}
                                        dangerouslySetInnerHTML={{
                                            __html: element.action.split(' on ')[0]
                                        }}></span>{' '}
                                    {moment(element.created_at).format('DD MMM YYYY').toUpperCase()} at{' '}
                                    {moment(element.created_at).format('hh:mm A zz').toUpperCase()}
                                </Typography>
                            )}
                        </Box>
                    </TabPanel>


                </Box>





            </Box>
        </>
    )
}


export default LicenseDialog