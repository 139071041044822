import SuccessConfirmation from '@jibin/common/components/ConformationModel/SuccessConfirmation';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import WarningRed from '@jibin/common/icons/WarningRed/WarningRed';
import { Button, Chip, Divider, Switch, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { CompanyManagementApi } from 'src/Pages/CompanyManagement/CompanyManagement.Api';
import ConfigureBox from '../ConfigureBox';
import ConfirmationBoxWarning from '../ConfirmationBoxWarning';

const IntegrationCard = ({ el, isRefresh, setIsRefresh, customerList, orgList }: any) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const commonDetails = useSelector(
        ({ commonDetails }: any) => commonDetails.commonDetails?.data,
        shallowEqual
    );
    const [searchParams, setSearchParams] = useSearchParams();
    const company_uuid = commonDetails.data.company.company_uuid;
    const openDetails = Boolean(anchorEl);
    const handleClickDetails = (event: any, row: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseDetails = () => {
        setAnchorEl(null);
    };
    const location = useLocation();
    const handleChangeToggle = async (integration_tools_uuid: string, is_enabled: boolean) => {
        const data = {
            company_uuid,
            integration_tools_uuid,
            is_enabled: !is_enabled
        };
        try {
            const res = await CompanyManagementApi.ChangeToggle(
                commonDetails.data.company.company_uuid,
                commonDetails.data.user_uuid,
                data
            );
            setIsRefresh(!isRefresh);
        } catch (error) { }
    };
    return (
        <Box
            sx={{
                border: '1px solid #CCCCCC',
                px: 3,
                py: 2,
                height: 1,
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',

                gap: 1
            }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Box sx={{ display: 'flex', gap: 2, height: '48px' }}>
                        <Box>
                            <img width={'48px'} style={{ maxHeight: '48px' }} src={el?.logo_url} />
                        </Box>
                        <Box>
                            <Typography variant="body1" sx={{ fontWeight: 600, color: '#696969' }}>
                                {el?.name}
                            </Typography>
                        </Box>
                    </Box>

                    <Box>
                        {!el?.company_integrations_uuid ? (
                            <ConfigureBox
                                customerList={customerList}
                                orgList={orgList}
                                handleConfirm={() => { }}
                                title={el?.name}
                                access_details={el?.access_details}
                                data={el}
                                type={'toogle'}
                                company_uuid={company_uuid}
                                handleClose={() => setIsRefresh(!isRefresh)}
                                description={''}
                                cancelButtonText="Test"
                                conformButtonText="Save"
                            />
                        ) : (
                            <SuccessConfirmation
                                checked={false}
                                handleClose={() => { }}
                                handleConfirm={(e) => {
                                    handleChangeToggle(el?.integration_tools_uuid, el?.is_enabled);
                                }}
                                title="Confirm Action"
                                data={el}
                                company_uuid={company_uuid}
                                description={`Are you sure, do you want to ${el?.is_enabled ? 'deactivate' : 'activate'
                                    } "${el?.name}"?`}
                                asking={``}
                                returnData={'Published'}
                                mainBtnTitle={'Archive'}
                                mainBtnType={el?.is_enabled ? 'error' : 'primary'}
                                cancelButtonText="Cancel"
                                conformButtonText="Confirm"
                            />
                        )}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                    {el?.services?.map((element: any, index: any) => (
                        <Box
                            key={index}
                            sx={{
                                py: '5px',
                                px: 1,
                                bgcolor: '#D9EDFF',
                                borderRadius: '100px',
                                width: 'fit-content'
                            }}>
                            <Typography variant="caption" sx={{ color: '#464646' }}>
                                {element}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box>
                    <Box>
                        <Typography variant="body2" sx={{ color: '#464646' }}>
                            {el?.description}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Divider />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {el?.status == 'inactive' && <WarningIcon sx={{ mr: 1, color: '#EF4444' }} />}
                        {!company_uuid && (
                            <Chip label="Disabled" disabled sx={{ fontSize: '12px', height: '24px' }} />
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        {el?.status == 'inactive' && <ConfirmationBoxWarning description={el?.error} />}
                        <ConfigureBox
                            customerList={customerList}
                            orgList={orgList}
                            handleConfirm={() => { }}
                            title={el?.name}
                            access_details={el?.access_details}
                            data={el}
                            type={'button'}
                            company_uuid={company_uuid}
                            handleClose={() => setIsRefresh(!isRefresh)}
                            description={''}
                            cancelButtonText="Test"
                            conformButtonText="Save"
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
export default IntegrationCard;
