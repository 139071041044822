import CheckEmptyCircle from '@jibin/common/icons/CheckEmptyCircle/CheckEmptyCircle';
import PinIcon from '@jibin/common/icons/PinIcon/PinIcon';
import WarningRed from '@jibin/common/icons/WarningRed/WarningRed';
import { CheckCircle, Warning } from '@mui/icons-material';
import {
  Typography,
  TextareaAutosize,
  TextField,
  SwipeableDrawer,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Stack
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import GapAnalysisSideBarEngineer from '../GapAnalysisSideBar/GapAnalysisSideBarEngineer';
import GapAnalysisSideBar from '../GapAnalysisSideBar/GapAnalysisSideBar';
import { useSearchParams } from 'react-router-dom';
import UploadIconMin from '@jibin/common/icons/UploadIconMin/UploadIconMin';
import { GapAnalysisApi } from '../../GapAnalysis.Api';
import { CompanyUserRoles } from '@jibin/common/utils/constants/ConstantName';
type Anchor = 'top' | 'left' | 'bottom' | 'right';
const GapAnalysisQ: FC<{
  data: any;
  getQuestions: any;
  index: any;
  isEdit: any;
  dataForSideBar: any;
  GetCategories: any;
  isAllComplete: boolean;
  isEngineer: boolean;
}> = (props) => {
  const {
    data,
    index,
    isAllComplete,
    dataForSideBar,
    GetCategories,
    isEdit,
    getQuestions,
    isEngineer
  } = props;
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [answer, setAnswer] = useState(data?.answer?.answer ?? '');
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [rowData, setRowData] = useState({} as any);
  const [state, setState] = useState({
    right: false
  });
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const files = [];
      setIsSkeleton(true);
      if (data?.answer?.answer_uuid) {
        let submitData = { answer: { comment: '', answer: e.target.value } };
        const res = await GapAnalysisApi.PutAnswers(
          commonDetails.data.user_uuid,
          commonDetails.data.company.company_uuid,
          data.question_uuid,
          data.answer?.answer_uuid,
          submitData
        );
        getQuestions();
        setAnswer(e.target.value);
        setIsSkeleton(false);
      } else {
        let submitData = { answer: { comment: '', answer: e.target.value } };

        const res = await GapAnalysisApi.PostAnswers(
          commonDetails.data.user_uuid,
          commonDetails.data.company.company_uuid,
          data.question_uuid,
          submitData
        );
        GetCategories();
        getQuestions();
        setAnswer(e.target.value);
        setIsSkeleton(false);
      }
    } catch (err) {
      setIsSkeleton(false);
    } finally {
      setIsSkeleton(false);
    }
  };

  const toggleDrawer = (anchor: Anchor, open: boolean, row: any) => {
    setState({ ...state, [anchor]: open });
    setRowData(row);
  };
  const setParams = (index) => {};

  return (
    <Box
      key={index}
      sx={{
        border: '1px solid #D5D5D5',
        borderRadius: 2,
        p: 2,
        mb: 2,
        backgroundColor: '#FFFFFF'
      }}>
      <Box>
        <Typography variant="body1" sx={{ mb: 1.25, color: '#464646', whiteSpace: 'pre-line' }}>
          {data?.question_id} - {data.question}
        </Typography>
        <Box sx={{ width: 1, my: 2, px: 1.5 }}>
          <RadioGroup
            row
            sx={{ gap: 2 }}
            defaultValue={data?.answer?.answer}
            value={answer}
            onChange={(e: any) => {
              handleSubmit(e);
            }}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group">
            <FormControlLabel
              disabled={
                isSkeleton || commonDetails?.data?.company?.user_role === CompanyUserRoles.AUDITOR
              }
              value="Yes"
              sx={{ pr: 1.5, border: '1px solid #CCCCCC', borderRadius: '4px' }}
              control={<Radio size="small" value="Yes" />}
              label="Yes"
            />

            <FormControlLabel
              disabled={
                isSkeleton || commonDetails?.data?.company?.user_role === CompanyUserRoles.AUDITOR
              }
              value="No"
              sx={{ pr: 1.5, border: '1px solid #CCCCCC', borderRadius: '4px' }}
              control={<Radio size="small" value="No" />}
              label="No"
            />
            <FormControlLabel
              disabled={
                isSkeleton || commonDetails?.data?.company?.user_role === CompanyUserRoles.AUDITOR
              }
              value="Not Applicable"
              sx={{ pr: 1.5, border: '1px solid #CCCCCC', borderRadius: '4px' }}
              control={<Radio size="small" value="Not Applicable" />}
              label="Not Applicable"
            />
          </RadioGroup>
        </Box>
        <Box sx={{ my: 3 }}>
          <Box>
            <Typography variant="body2" sx={{ my: 1 }}>
              Describe your answer
            </Typography>
          </Box>
          <TextField
            onClick={() => toggleDrawer('right', true, data)}
            disabled={answer && !isSkeleton ? false : true}
            maxRows={1}
            minRows={1}
            defaultValue={data?.answer?.comment}
            value={data?.answer?.comment}
            sx={{
              input: { cursor: 'pointer', fontSize: '14px' },
              width: 1,
              '.MuiInputBase-input': {
                py: 1
              },
              '.MuiInputBase-root': {
                py: 0
              }
            }}
            InputProps={{
              readOnly: true
            }}
            // sx={{ width: 1, mb: 1 }}
            placeholder={'Provide aditional information'}
          />
        </Box>

        <SwipeableDrawer
          anchor={'right'}
          open={state['right']}
          onClose={() => {
            GetCategories();
            getQuestions();
            toggleDrawer('right', false, {});
          }}
          onOpen={() => {
            toggleDrawer('right', true, data);
          }}>
          <GapAnalysisSideBar
            rowData={rowData}
            answerOption={answer}
            dataForSideBar={dataForSideBar}
            isEngineer={isEngineer}
            index={index}
            closePopup={(e, toggle = false) => {
              GetCategories();
              getQuestions();
              toggleDrawer('right', toggle, toggle ? data : {});
            }}
          />
          {/* )} */}
        </SwipeableDrawer>
      </Box>
    </Box>
  );
};
export default GapAnalysisQ;
