import React, { FC, useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import Style from '@jibin/common/style/Styles';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import GoldIcon from '@jibin/common/icons/MedalIcon/GoldIcon.svg';
import SilverIcon from '@jibin/common/icons/MedalIcon/SilverIcon.svg';
import BronzeIcon from '@jibin/common/icons/MedalIcon/BronzeIcon.svg';
import PlatniumIcon from '@jibin/common/icons/MedalIcon/PlatniumIcon.svg';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CustomerSuccessApi } from 'src/Pages/CustomerSuccess/CustomerSuccess.Api';
import subscriptionPlanSlice from 'src/store/reducers/subscriptionPlanReducer';
import { roleReducerSlice } from 'src/store/reducers/roleReducer';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { UserRoles } from '../utils/constants/ConstantName';
import HeadingHeader from 'src/Pages/Common/HeadingHeader';

const MenuItems = [
  { name: 'Reports', class: '', route: PageRouteConstants.ServicesReview },
  { name: 'Consulting Reports', class: '', route: PageRouteConstants.EngineeringConsulting }
];

export const CustomerSuccessTemplate: FC<{ children: React.ReactNode | React.ReactNode[] }> = (
  props
) => {
  const dispatch = useDispatch();
  const subscriptionPlan = useSelector(
    ({ subscriptionPlan }: any) => subscriptionPlan?.subscriptionPlan?.data,
    shallowEqual
  );

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const GetCompanyWebsites = useCallback(() => {
    CustomerSuccessApi.GetSubscriptionPlans(commonDetails.data.company.company_uuid).then(
      async (q: any) => {
        await dispatch(subscriptionPlanSlice.actions.setSubscriptionPlan(q));
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  useEffect(() => {
    GetCompanyWebsites();
  }, []);

  const navigate = useNavigate();

  const loadMedal = (type) => {
    switch (type) {
      case 'gold':
      case 'Gold':
        return <img width={'24px'} src={GoldIcon} />;

      case 'silver':
      case 'Silver':
        return <img width={'24px'} src={SilverIcon} />;
      case 'bronze':
      case 'Bronze':
        return <img width={'24px'} src={BronzeIcon} />;
      case 'platinum':
      case 'Platinum':
        return <img width={'24px'} src={PlatniumIcon} />;
    }
  };

  const handleRadioChange = (e: any) => {
    if (e.target.value == UserRoles.CYBER_SECURITY_ENGINEER) {
      dispatch(roleReducerSlice.actions.setRoleReducer({ engineer: true }));
    } else {
      dispatch(roleReducerSlice.actions.setRoleReducer({ engineer: false }));
    }
  };

  return (
    <>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', backgroundColor: '#FFFFFF' }}>
          <HeadingHeader subtitle1={'Reports'} subtitle2={'Review your cybersecurity services'} commonDetails={commonDetails} UserRoles={UserRoles} 
          handleRadioChange={handleRadioChange} />
          {subscriptionPlan && (
            <Box
              sx={{
                display: location.pathname == PageRouteConstants.ServicesReview ? 'flex' : 'none',
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'flex-start',
                p: '16px 32px',
                gap: 1
              }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {loadMedal(subscriptionPlan?.data?.plan_type)}
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  Subscription ({subscriptionPlan?.data?.plan_type})
                </Typography>

                {subscriptionPlan?.data?.status == 'active' ? (
                  <Box
                    sx={{
                      px: 1,
                      py: '5px',
                      background: 'rgba(16, 185, 129, 0.1)',
                      borderRadius: '100px'
                    }}>
                    <Typography variant="caption" sx={{ fontWeight: 500, color: '#27AE60' }}>
                      Active
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      px: 1,
                      py: '5px',
                      background: 'rgba(239, 68, 68, 0.1)',
                      borderRadius: '100px'
                    }}>
                    <Typography variant="caption" sx={{ fontWeight: 500, color: '#EF4444' }}>
                      Expired
                    </Typography>
                  </Box>
                )}
              </Box>
              <Typography variant="body2">
                {moment(subscriptionPlan?.data?.start_date).format('DD MMM YYYY').toUpperCase()} to{' '}
                {moment(subscriptionPlan?.data?.end_date).format('DD MMM YYYY').toUpperCase()}
              </Typography>
              {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' && (
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 600, color: '#436AF3', cursor: 'pointer' }}>
                  View Pricing
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>

      <List sx={Style.CompanySetting.MenuList}>
        {MenuItems.map((currenElement, index) => (
          <ListItem disablePadding key={index} sx={{ width: 'auto' }}>
            <Link
              onClick={() => {
                navigate(currenElement.route);
              }}
              style={{ cursor: 'pointer', fontFamily: 'SF UI Text' }}
              className={window.location.pathname === currenElement.route ? 'active' : ''}
              sx={Style.CompanySetting.MenuListItem}>
              {currenElement.name}
            </Link>
          </ListItem>
        ))}
      </List>
      {props.children}
    </>
  );
};

export default CustomerSuccessTemplate;
