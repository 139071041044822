import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
import { TickMarkBlue } from '@jibin/common/icons/Tickmark/Tickmark';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { Box, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import momentTimezone from 'moment-timezone';
import { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const NetwrokDetectionRow = ({ el, assignees, engineer, onClick }) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [rowData, setRowData] = useState({} as any);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setRowData(null);
    setAnchorEl(null);
  };
  const findLetestRemediation = (remediations: any) => {
    const sortRemediation = remediations.sort((a: any, b: any) => {
      return a?.created_at < b?.created_at ? 1 : -1;
    });
    return sortRemediation[0];
  };
  const renderColor = (severity: any) => {
    var color = '#F87171';
    if (severity == 'critical') {
      color = '#F87171';
    } else if (severity == 'suspicious') {
      color = '#F59E0B';
    } else if (severity == 'compliance') {
      color = '#FBBF24';
    } else if (severity == 'low') {
      color = '#436AF3';
    }
    return color;
  };
  const renderColorBackGround = (severity: any = 'critical') => {
    var color = '#FFE6E2';
    if (severity == 'critical') {
      color = '#FFE6E2';
    } else if (severity == 'suspicious') {
      color = '#FCD34D';
    } else if (severity == 'compliance') {
      color = '#FEF3C7';
    } else if (severity == 'low') {
      color = '#D0DAFC';
    }
    return color;
  };

  const navigate = useNavigate();
  return (
    <TableRow
      id={el.id}
      sx={{
        cursor: 'pointer',
        '.MuiTableCell-root': {
          //backgroundColor: '#FFFFFF ',
          px: '20px',

          borderBottom: '1px solid  #CCCCCC',
          borderTopRadius: '4px',
          borderTop: '1px solid  #CCCCCC',
          '&:last-child': {
            borderRight: '1px solid  #CCCCCC',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px'
          },
          '&:first-child': {
            borderLeft: '1px solid  #CCCCCC',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px'
          }
        }
      }}>
      <TableCell
        onClick={() => {
          onClick(false);
          handleClose();
        }}
        align="left">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box
              sx={{
                backgroundColor: renderColorBackGround(el?.severity),
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                justifyContent: 'center',
                borderRadius: '100px',
                py: 0.5,
                px: 2
              }}>
              <FlagIcon
                sx={{
                  color: renderColor(el?.severity),

                  width: '12px'
                }}
              />
              <Typography variant="caption" sx={{ textTransform: 'capitalize' }}>
                {' '}
                {el?.severity}
              </Typography>
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell
        onClick={() => {
          onClick(false);
          handleClose();
        }}
        component="th"
        scope="row">
        <Typography variant="caption">
          {momentTimezone(el?.received_at).format('DD MMM YYYY').toUpperCase()} <br />
          {momentTimezone(el?.received_at).format('hh:mm A').toUpperCase()}
        </Typography>
      </TableCell>
      <TableCell
        onClick={() => {
          onClick(false);
          handleClose();
        }}
        align="left">
        <Typography variant="caption">{el.title} </Typography>
      </TableCell>
      <TableCell
        onClick={() => {
          onClick(false);
          handleClose();
        }}
        align="left">
        <Typography variant="caption">{el.hostname}</Typography>
      </TableCell>
      <TableCell
        onClick={() => {
          onClick(false);
          handleClose();
        }}
        sx={{ maxWidth: 250 }}
        align="left">
        <Typography variant="caption">{el.mac} </Typography>
      </TableCell>

      <TableCell sx={{ maxWidth: 250 }} align="left">
        <IconButton
          onClick={handleClick}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}>
          <MoreHorizIcon color="primary" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id={'account-menu'}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          {el?.remediations?.length > 0 ? (
            <MenuItem
              onClick={() => {
                navigate(
                  `${PageRouteConstants.Remediation}?remediation_uuids=${
                    findLetestRemediation(el?.remediations)?.remediation_uuid
                  }`
                );

                handleClose();
              }}>
              <TickMarkBlue />
              <Typography sx={{ ml: 1 }} variant="caption">
                {' '}
                Track Remediation
              </Typography>
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                onClick(true);
                handleClose();
              }}>
              <TickMarkBlue />
              <Typography sx={{ ml: 1 }} variant="caption">
                {' '}
                Create Remediation
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default NetwrokDetectionRow;
