import { useState } from 'react';
import { Box, List, Typography, FormControl, Button } from '@mui/material';
import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import ViewAsset from './ViewAsset';
import CreateRemediation from './CreateRemediation';

const AssetPopUp = (props) => {
  const {
    rowData,
    closePopup,
    isViewAsset,
    isCreateRemediation,
    commonDetails,
    assetsList,
    selectedAssetList,
    associatedAssets,
    setAssociatedAssets,
    setIsCreateRemediation,
    GetAssets,
    assignees
  } = props;
  const [value, setValue] = useState(0);

  return (
    <>
      <Box
        sx={{
          width: { md: 520, xs: 300 },
          height: '100vh' || value == 1 || value == 2 ? '100vh' : 'auto',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}
        role="presentation">
        <List sx={{ p: 0 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: 1,
              justifyContent: 'space-between',
              backgroundColor: '#003260',
              px: 2.5,
              alignItems: 'center',
              py: 1
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 1,
                alignItems: 'center'
              }}>
              <Typography
                variant="body1"
                sx={{ fontSize: '19px', fontWeight: 700, color: '#ffffff', my: 1.25 }}>
                {isViewAsset ? rowData?.name : 'Create Remediation'}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  onClick={(event) => {
                    closePopup(event);
                  }}>
                  <CrossCancle
                    sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </List>

        {isCreateRemediation ? (
          <CreateRemediation
            assignees={assignees}
            closePopup={closePopup}
            commonDetails={commonDetails}
            rowData={rowData}
            assetsList={assetsList}
            selectedAssetList={selectedAssetList}
            setAssociatedAssets={setAssociatedAssets}
            associatedAssets={associatedAssets}
            GetAssets={GetAssets}
          />
        ) : (
          <ViewAsset rowData={rowData} setIsCreateRemediation={setIsCreateRemediation} />
        )}
      </Box>
    </>
  );
};

export default AssetPopUp;
