import HttpService from '@jibin/common/services/http/HttpService';
import axios from 'axios';

export const GapAnalysisApi = {
  GetCategories(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/categories`);
  },
  GetQuestionnaires(userId: string, companyId: string, category: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/categories/${category}/questionnaires`
    );
  },
  UploadDocuments(response: any, files: any) {
    const {
      data: { data: urls }
    } = response;

    return Promise.all(
      urls.map((
        {
          upload_url,
          callback_url,
          callback_data,
        },
        i,
      ) => {
        const file = files[i];
        return axios.put(upload_url, file, {
          headers: {
            'Content-Type': file.type
          }
        }).then(
          () => HttpService.post(callback_url, { data: callback_data })
        );
      })
    );
  },
  PostAnswers(userId: string, companyId: string, questionnaire_id: string, data: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.post(
      `companies/${companyId}/users/${userId}/questionnaires/${questionnaire_id}/answer`,
      data
    );
  },
  PostAnswersEngineer(
    userId: string,
    companyId: string,
    questionnaire_id: string,
    answer_id: string,
    data: any
  ) {
    //return HttpService.getAll("todos/1");
    return HttpService.post(
      `companies/${companyId}/users/${userId}/questionnaires/${questionnaire_id}/answers/${answer_id}/feedback`,
      data
    );
  },
  Submit(userId: string, companyId: string, data: any) {
    return HttpService.post(`companies/${companyId}/users/${userId}/questionnaires/submit`, data);
  },
  PutAnswers(
    userId: string,
    companyId: string,
    questionnaire_id: string,
    answer_id: string,
    data: any
  ) {
    //return HttpService.getAll("todos/1");
    return HttpService.putAll(
      `companies/${companyId}/users/${userId}/questionnaires/${questionnaire_id}/answers/${answer_id}`,
      data
    );
  },


  async GetFile(
    companyId: string,
    userId: string,
    questionId: string,
    answerId: string,
    fileId: string
  ) {
    
    return  HttpService.getAll(
      `companies/${companyId}/users/${userId}/questionnaires/${questionId}/answers/${answerId}/files/${fileId}`
    );
  },
  
  async RemoveFile(
    companyId: string,
    userId: string,
    questionId: string,
    answerId: string,
    fileId: string
  ) {
    return await HttpService.remove(
      `companies/${companyId}/users/${userId}/questionnaires/${questionId}/answers/${answerId}/files`,
      fileId
    );
  },
  GetUserData() {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll('users/me');
  }
};
