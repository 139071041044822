import SvgIcon from '@mui/material/SvgIcon';

export default function HelpIcon(props) {
  return (
    <SvgIcon width="15" height="15" viewBox="0 0 15 15" fill="none" {...props}>
      <path
        d="M5.72667 5.50016C5.8834 5.05461 6.19277 4.6789 6.59997 4.43958C7.00717 4.20027 7.48593 4.11279 7.95145 4.19264C8.41697 4.27249 8.83921 4.51451 9.14339 4.87585C9.44756 5.23718 9.61404 5.69451 9.61333 6.16683C9.61333 7.50016 7.61333 8.16683 7.61333 8.16683M7.66667 10.8335H7.67333M14.3333 7.50016C14.3333 11.1821 11.3486 14.1668 7.66667 14.1668C3.98477 14.1668 1 11.1821 1 7.50016C1 3.81826 3.98477 0.833496 7.66667 0.833496C11.3486 0.833496 14.3333 3.81826 14.3333 7.50016Z"
        stroke="#F59E0B"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
}
