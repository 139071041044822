const AttckSurafaceIcon = () => {
  return (
    <svg width="24" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M10 1.33923C10.4573 1.33923 10.8279 1.70991 10.8279 2.16715V11.2976C11.2735 10.7317 11.7217 10.1911 12.1777 9.73637C13.1872 8.7296 14.3896 7.96669 16.0268 7.96669C16.484 7.96669 16.8547 8.33736 16.8547 8.79461C16.8547 9.25186 16.484 9.62253 16.0268 9.62253C15.0413 9.62253 14.2661 10.0315 13.4807 10.7785L19.5048 13.422C19.8104 13.5561 20.0056 13.8607 19.9999 14.1944C19.9941 14.5282 19.7885 14.8258 19.4784 14.9493L10.3063 18.6019C10.105 18.6821 9.88025 18.6801 9.68034 18.5964L0.508244 14.7572C0.200339 14.6283 -5.50341e-05 14.3271 1.13373e-08 13.9933C5.51762e-05 13.6595 0.200549 13.3584 0.508496 13.2296L6.47233 10.7356C5.70257 10.0159 4.93876 9.62253 3.97321 9.62253C3.51596 9.62253 3.14529 9.25186 3.14529 8.79461C3.14529 8.33736 3.51596 7.96669 3.97321 7.96669C5.61078 7.96669 6.81325 8.73103 7.82251 9.73805C8.27824 10.1928 8.72645 10.7335 9.1721 11.2993V2.16715C9.1721 1.70991 9.54277 1.33923 10 1.33923ZM7.60844 11.992C7.53564 12.0633 7.44837 12.1222 7.34864 12.1639L2.97297 13.9938L10.0078 16.9385L17.0273 14.1431L12.5691 12.1867C12.4861 12.1502 12.412 12.1018 12.3481 12.0444C12.1261 12.3217 11.9011 12.6142 11.6649 12.9211C11.3501 13.3303 11.0156 13.7652 10.6421 14.224C10.4849 14.417 10.2493 14.5291 10.0004 14.5292C9.75145 14.5293 9.51571 14.4174 9.3584 14.2245C8.98209 13.763 8.64547 13.3258 8.32881 12.9144L8.32875 12.9143C8.0795 12.5905 7.84261 12.2828 7.60844 11.992Z"
        fill="white"
      />
    </svg>
  );
};
export const AttckSurafaceIconBlue = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M10 1.33923C10.4573 1.33923 10.8279 1.70991 10.8279 2.16715V11.2976C11.2735 10.7317 11.7217 10.1911 12.1777 9.73637C13.1872 8.7296 14.3896 7.96669 16.0268 7.96669C16.484 7.96669 16.8547 8.33736 16.8547 8.79461C16.8547 9.25186 16.484 9.62253 16.0268 9.62253C15.0413 9.62253 14.2661 10.0315 13.4807 10.7785L19.5048 13.422C19.8104 13.5561 20.0056 13.8607 19.9999 14.1944C19.9941 14.5282 19.7885 14.8258 19.4784 14.9493L10.3063 18.6019C10.105 18.6821 9.88025 18.6801 9.68034 18.5964L0.508244 14.7572C0.200339 14.6283 -5.50341e-05 14.3271 1.13373e-08 13.9933C5.51762e-05 13.6595 0.200549 13.3584 0.508496 13.2296L6.47233 10.7356C5.70257 10.0159 4.93876 9.62253 3.97321 9.62253C3.51596 9.62253 3.14529 9.25186 3.14529 8.79461C3.14529 8.33736 3.51596 7.96669 3.97321 7.96669C5.61078 7.96669 6.81325 8.73103 7.82251 9.73805C8.27824 10.1928 8.72645 10.7335 9.1721 11.2993V2.16715C9.1721 1.70991 9.54277 1.33923 10 1.33923ZM7.60844 11.992C7.53564 12.0633 7.44837 12.1222 7.34864 12.1639L2.97297 13.9938L10.0078 16.9385L17.0273 14.1431L12.5691 12.1867C12.4861 12.1502 12.412 12.1018 12.3481 12.0444C12.1261 12.3217 11.9011 12.6142 11.6649 12.9211C11.3501 13.3303 11.0156 13.7652 10.6421 14.224C10.4849 14.417 10.2493 14.5291 10.0004 14.5292C9.75145 14.5293 9.51571 14.4174 9.3584 14.2245C8.98209 13.763 8.64547 13.3258 8.32881 12.9144L8.32875 12.9143C8.0795 12.5905 7.84261 12.2828 7.60844 11.992Z"
        fill="#436AF3"
      />
    </svg>
  );
};
export default AttckSurafaceIcon;
