import Style from '@jibin/common/style/Styles';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  ListItemText,
  Menu,
  OutlinedInput,
  Skeleton,
  TextField
} from '@mui/material';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import SearchIcon from '@jibin/common/icons/Search/Search';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FC, useState, useContext, useCallback, useEffect, useMemo } from 'react';
import PoliciesTable from './PoliciesTable';
import DropDown from '@jibin/common/icons/Dropdown/Dropdown';
import { useNavigate } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import CreateTemplateModel from './CreatetemplateModel/CreateTemplateModel';

import { PoliciesAndProceduresApi } from './Policys.Api';
import { shallowEqual, useSelector } from 'react-redux';
import { ApiResponse } from './Models/ApiResponse';
import DownloadIcon from '@jibin/common/icons/DownloadIcon/DownloadIcon';
import { NoDataAvailable, NoSearchDataAvailable } from './../Common/NoDataAvailable';
import FilterIcon from '@jibin/common/icons/FilterIcon/FilterIcon';
import { Filter } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import HeadingHeader from '../Common/HeadingHeader';
import { CompanyUserRoles } from '@jibin/common/utils/constants/ConstantName';
var debouce = require('lodash.debounce');
export const PoliciesAndProcedures: FC = (props: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElFil, setAnchorElFil] = useState<null | HTMLElement>(null);
  const [anchorElNew, setAnchorElNew] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openFil = Boolean(anchorElFil);
  const openNew = Boolean(anchorElNew);
  const nevigate = useNavigate();
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isButtonLoader, setIsButtonLoading] = useState(false);
  const [policyList, setPolicyList] = useState(new ApiResponse());
  const [initialPolicyList, setInitialPolicyList] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const initialCheckBox =
    commonDetails?.data?.company?.user_role === CompanyUserRoles.AUDITOR
      ? {
          keyword: '',
          filter: 'published'
        }
      : {
          keyword: '',
          filter: 'published,draft'
        };
  const [checkBox, setCheckBox] = useState(initialCheckBox);

  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });
  const initialFilterData =
    commonDetails?.data?.company?.user_role === CompanyUserRoles.AUDITOR
      ? { status: ['published'] }
      : { status: ['published', 'draft'] };
  const [filterData, setFilterData] = useState(initialFilterData);
  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        fontSize: '14px',
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 180
      }
    }
  };
  const remediationStatusItems = [
    { key: 'Status', value: '' },
    { key: 'Draft', value: 'draft' },
    { key: 'Published', value: 'published' },
    { key: 'Archived', value: 'archived' }
  ];

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickNew = (event: any) => {
    setAnchorElNew(event.currentTarget);
  };
  const handleCloseNew = () => {
    setAnchorElNew(null);
  };

  const debouncedResults = (event: any) => {
    if (event.target.name == 'search') {
      setCheckBox({ ...checkBox, keyword: event.target.value });
    } else {
      if (event.target.name == 'draft') {
        if (event?.target.checked) {
          setCheckBox({
            ...checkBox,
            filter: `${checkBox.filter && `${checkBox.filter},`}${'draft'}`
          });
        } else {
          setCheckBox({
            ...checkBox,

            filter: `${checkBox.filter && `${checkBox.filter.replace('draft', '')}`}`
          });
        }
      }
      if (event.target.name == 'all') {
        if (event?.target.checked) {
          setCheckBox({
            ...checkBox,
            filter: `published,archived,draft`
          });
        } else {
          setCheckBox({
            ...checkBox,

            filter: `all`
          });
        }
      }
      if (event.target.name == 'published') {
        if (event?.target.checked) {
          setCheckBox({
            ...checkBox,
            filter: `${checkBox.filter && `${checkBox.filter},`}${'published'}`
          });
        } else {
          setCheckBox({
            ...checkBox,

            filter: `${checkBox.filter && `${checkBox.filter.replace('published', '')}`}`
          });
        }
      }
      if (event.target.name == 'archived') {
        if (event?.target.checked) {
          setCheckBox({
            ...checkBox,
            filter: `${checkBox.filter && `${checkBox.filter},`}${'archived'}`
          });
        } else {
          setCheckBox({
            ...checkBox,

            filter: `${checkBox.filter && `${checkBox.filter.replace('archived', '')}`}`
          });
        }
      }
    }
  };

  const handleChange = (event: any) => {
    let {
      target: { value, name }
    } = event;

    const filterValue = value.filter((el: any) => el != '');
    let string;
    if (filterValue.length == 0) {
      string = 'draft,published,archived';
      const item = { ...filterData };

      setCheckBox({
        ...checkBox,
        filter: string
      });
      item[name] = [''];

      setFilterData(item);
    } else {
      string = filterValue.join(',');
      setCheckBox({
        ...checkBox,
        filter: string
      });

      const item = { ...filterData };
      item[name] = typeof value === 'string' ? filterValue.split(',') : filterValue;

      setFilterData(item);
    }
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const handleClickCheck = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const downloadMultipleFiles = async () => {
    setIsButtonLoading(true);
    try {
      const policy = [];
      selected.forEach(async (el) => policy.push(el.policy_uuid));

      const res = await PoliciesAndProceduresApi.DownloadBulkFiles(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        {
          policies: policy
        }
      );
      const blob = new Blob([res.data], { type: res.headers['content-type'] });

      // Creating an object for downloading url
      const url = window.URL.createObjectURL(blob);
      const urlName = window.URL.createObjectURL(blob);

      const a = document.createElement('a');

      a.href = urlName;

      a.download = `policies.zip`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setIsButtonLoading(false);
      setSelected([]);
    } catch (error) {
      setIsButtonLoading(false);
    }
  };
  const handleUploadPolicy = async (e: any) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      console.log(formData);
      const data = JSON.stringify(formData);
      const res = await PoliciesAndProceduresApi.UploadPolicy(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        formData
      );
      handleCloseNew();
      setIsRefresh(!isRefresh);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleCloseNew();
    }
  };
  const downloadPolicy = async (id, policyName, version) => {
    try {
      const res = await PoliciesAndProceduresApi.DownloadPolicy(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        id,
        version
      );
      const blob = new Blob([res.data], { type: res.headers['content-type'] });

      // Creating an object for downloading url
      const url = window.URL.createObjectURL(blob);
      const urlName = window.URL.createObjectURL(blob);

      const a = document.createElement('a');

      a.href = urlName;

      a.download = `${policyName}-${version}.docx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {}
  };
  const openPopup = () => {};
  useEffect(() => {
    setPage(0);
  }, [checkBox]);
  const getPolicy = useCallback(() => {
    PoliciesAndProceduresApi.GetPolicyList(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      new URLSearchParams({ ...checkBox, ...pagination }).toString()
    ).then(
      (q: any) => {
        if (isSkeleton && q.data.data.length) {
          setInitialPolicyList(true);
        }
        setPolicyList(q.data);
        setTotalCount(q?.data?.pagination?.total_records);
        setIsSkeleton(false);
        handleClose();
        setAnchorEl(null);
      },
      (err: any) => {}
    );
  }, [commonDetails, checkBox, filterData, isRefresh, pagination.page_no, pagination.page_size]);

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);

  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });

    return selectedLabels.join(', ');
  };

  useEffect(() => {
    getPolicy();
  }, [getPolicy]);

  return (
    <Box>
      <HeadingHeader
        subtitle1={'Policies'}
        subtitle2={'Save time and gain agility by selecting the templates you need.'}
      />

      {isSkeleton ? (
        <Box sx={{ px: 4 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : (
        <Box sx={{ px: 4, py: { xs: 4, md: 3 } }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <TextField
                placeholder="Search Document"
                hiddenLabel
                name="search"
                value={checkBox.keyword}
                onChange={debouncedResults}
                sx={{ mr: 2 }}
                inputProps={{ sx: { py: '8px' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
              {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
                <FormControl sx={{ width: 150 }}>
                  <Select
                    size="small"
                    displayEmpty
                    multiple
                    name="status"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={filterData.status}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => renderValue(selected, remediationStatusItems)}
                    MenuProps={MenuProps}
                    sx={{
                      py: 0.55,
                      fontSize: '14px',
                      '.MuiSelect-select': {
                        py: '5.5px'
                      },
                      color: '#696969',
                      minWidth: { xxs: 0, md: '150px' },
                      backgroundColor: '#FFFFFF'
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    {remediationStatusItems?.map((name, index) => (
                      <MenuItem
                        sx={{
                          fontSize: '14px',
                          display: !name.value ? 'none' : 'flex',
                          py: 0,
                          '.MuiMenuItem-root': {
                            py: '0px'
                          }
                        }}
                        key={name.key}
                        value={name.value}
                        autoFocus={index === 0}>
                        <Checkbox checked={filterData.status.indexOf(name.value) > -1} />
                        <ListItemText
                          sx={{ '.MuiTypography-root': { fontSize: '14px', fontWeight: 400 } }}
                          primary={name.key}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <LoadingButton
                onClick={downloadMultipleFiles}
                loading={isButtonLoader}
                disabled={selected?.length == 0}
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                variant="outlined">
                <DownloadIcon /> Download All
              </LoadingButton>
              {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  variant="contained"
                  aria-expanded={openNew ? 'true' : undefined}
                  onClick={handleClickNew}>
                  Create new Policy{' '}
                  <Box sx={{ display: 'flex' }}>
                    <DropDown />
                  </Box>
                </Button>
              )}

              <Menu
                id="basic-menu"
                anchorEl={anchorElNew}
                open={openNew}
                onClose={handleCloseNew}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}>
                <MenuItem
                  value={30}
                  sx={{ fontSize: '14px' }}
                  onClick={() => {
                    nevigate(
                      PageRouteConstants.PoliciesAndProceduresTemplate +
                        '00000000-0000-0000-0000-000000000000'
                    );
                  }}>
                  New Document{' '}
                </MenuItem>

                <MenuItem value={30} sx={{ fontSize: '14px' }}>
                  <LoadingButton
                    disableRipple
                    sx={{
                      p: 0,
                      color: '#464646',
                      ':hover': {
                        bgcolor: 'transparent'
                      }
                    }}
                    variant="text"
                    loading={isLoading}
                    component="label">
                    <input
                      hidden
                      type={'file'}
                      accept=".docx,.pdf"
                      onChange={(e: any) => {
                        if (e.target.files[0]) {
                          handleUploadPolicy(e);
                        }
                      }}
                    />
                    Upload Document
                  </LoadingButton>
                </MenuItem>
                {/* <Button variant="contained" component="label">
                  Upload
                  <input
                    type="file"
                    hidden
                    accept=".csv"
                    onChange={(e) => {
                      setFileValue(e.target.files[0]);
                      setFileName(e.target.value);
                    }}
                  />
                </Button> */}
                <CreateTemplateModel />
              </Menu>
            </Box>
          </Box>
          <Box>
            {policyList?.data?.length > 0 ? (
              <PoliciesTable
                handleClickCheck={(event, data) => handleClickCheck(event, data)}
                isSelected={(data) => isSelected(data)}
                setIsRefresh={setIsRefresh}
                isSkeleton={isSkeleton}
                isRefresh={isRefresh}
                policyList={policyList}
                totalCount={totalCount}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            ) : initialPolicyList ? (
              <NoSearchDataAvailable
                title="No Search Results"
                message="Try adjusting your search or filter to find what you are looking for"
              />
            ) : (
              <NoDataAvailable
                title="No Data Available"
                message="Create a new security policy or create from one of our template.">
                <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', mt: 2 }}>
                  <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    variant="contained"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    Create new Policy{' '}
                    <Box sx={{ display: 'flex' }}>
                      <DropDown />
                    </Box>
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}>
                    <MenuItem
                      value={30}
                      sx={{ fontSize: '14px' }}
                      onClick={() => {
                        nevigate(
                          PageRouteConstants.PoliciesAndProceduresTemplate +
                            '00000000-0000-0000-0000-000000000000'
                        );
                      }}>
                      New Document{' '}
                    </MenuItem>

                    {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' && (
                      <MenuItem value={30} sx={{ fontSize: '14px' }}>
                        Upload Document
                      </MenuItem>
                    )}
                    <CreateTemplateModel />
                  </Menu>
                </Box>
              </NoDataAvailable>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PoliciesAndProcedures;
