import Style from '@jibin/common/style/Styles';
import { Box, Button, Typography } from '@mui/material';

export const CompanyOptionComponent = ({ details, handleSelect, index }) => {
  const renderColor = (status) => {
    let color = '#27AE60';
    if (status == 'Active') {
      color = '#27AE60';
    } else if (status == 'Pending') {
      color = '#FCD34D';
    } else {
      color = 'error.main';
    }
    return color;
  };
  return (
    <Box
      onClick={(event: any) => {
        handleSelect();
      }}
      key={index}
      id={details.company_uuid}
      sx={{
        borderBottom: '0.8px solid',
        bgcolor: 'common.white',
        borderColor: 'common.borderColor'
      }}>
      <Box sx={Style.Sidebar.CompanyDropDownMenuItem}>
        {details?.logo_url ? (
          <Box sx={Style.UserNameRound}>
            <img style={{ borderRadius: '50%' }} src={details?.logo_url} />
          </Box>
        ) : (
          <Typography variant="caption" sx={Style.UserNameRound}>
            {details.name.charAt(0) + details.name.charAt(1)}
          </Typography>
        )}
        <Button disableRipple sx={{ ...Style.Sidebar.CompanyButton, width: 'calc(100% - 50px)' }}>
          <Box sx={{ textAlign: 'left' }}>
            {details.name}
            <Typography
              variant="caption"
              sx={{ display: 'block', color: renderColor(details?.status), lineHeight: '12px' }}>
              {details?.status}
            </Typography>
          </Box>
        </Button>
      </Box>
      <Typography variant="body2" sx={{ fontStyle: 'italic', lineHeight: '12px', pb: 1, pl: 1 }}>
        {details?.website}
      </Typography>
    </Box>
  );
};
