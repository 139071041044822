import { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
import { TickMarkBlue } from '@jibin/common/icons/Tickmark/Tickmark';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SuccessConfirmation from '@jibin/common/components/ConformationModel/SuccessConfirmation';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import { RiskManagementApi } from './RiskManagement.Api';
import { useSelector, shallowEqual } from 'react-redux';
const IconButtonComponent = ({
  row,
  handleOpen,
  handleOpenEdit,
  isTodayGreaterThanGivenDate,
  setRowData,
  setOpenAcceptRisk,
  handleChangeRefresh,
  handleClickGetRowData,
  setOpenDelete
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [anchorElIcon, setAnchorElIcon] = useState(null);
  const openDD = Boolean(anchorElIcon);
  const handleClick = (event: any) => {
    event.stopPropagation();
    handleClickGetRowData(row);
    setAnchorElIcon(event.currentTarget);
  };
  const handleConfirmFalsePositive = async () => {
    try {
      const res = await RiskManagementApi.MakeFalsePositive(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        row?.risk_uuid
      );
      handleChangeRefresh();
      handleClose();
    } catch (error) {}
  };
  const handleClose = () => {
    setAnchorElIcon(null);
    // setRowData({})
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        id="basic-button"
        aria-controls={openDD ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openDD ? 'true' : undefined}>
        <MoreHorizIcon color="primary" />
      </IconButton>
      <Menu
        anchorEl={anchorElIcon}
        id={'account-menu'}
        open={openDD}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        {row?.remediation_uuid === '' && row.status !== 'Resolved' && (
          <MenuItem
            onClick={() => {
              handleOpen();
              handleClose();
            }}>
            <TickMarkBlue />
            <Typography sx={{ ml: 1 }} variant="caption">
              {' '}
              Create Remediation
            </Typography>
          </MenuItem>
        )}
        {row.status !== 'Risk Accepted' && row.status !== 'Resolved' && (
          <MenuItem
            onClick={() => {
              setOpenAcceptRisk(true);
              handleClose();
            }}>
            <ReportGmailerrorredOutlinedIcon sx={{ color: '#FBBF24' }} />
            <Typography sx={{ ml: 1 }} variant="caption">
              {' '}
              Accept Risk
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleOpenEdit();
            handleClose();
          }}>
          <EditOutlinedIcon sx={{ color: '#436AF3' }} />
          <Typography sx={{ ml: 1 }} variant="caption">
            {' '}
            Edit Risk
          </Typography>
        </MenuItem>
        {row?.status !== 'False Positive' && (
          <SuccessConfirmation
            isLoading={false}
            checked={false}
            title="Confirm Action"
            type="icon"
            text={<Typography variant="caption"> Mark as False Positive</Typography>}
            description={'Are you sure you want to change this Risk Status to “False Positive”?'}
            asking={``}
            iconComponent={<DoDisturbAltIcon />}
            returnData={'Published'}
            mainBtnTitle={
              <Typography sx={{ ml: 1 }} variant="caption">
                {' '}
                Mark as False Positive
              </Typography>
            }
            mainBtnType={'error'}
            cancelButtonText="Cancel"
            conformButtonText="Confirm"
            handleConfirm={handleConfirmFalsePositive}
            handleClose={() => {}}
          />
        )}
        <MenuItem
          onClick={() => {
            setOpenDelete(true);
            handleClose();
          }}>
          <DeleteOutlineOutlinedIcon sx={{ color: '#EF4444' }} />
          <Typography sx={{ ml: 1 }} variant="caption">
            {' '}
            Delete Risk
          </Typography>
        </MenuItem>
      </Menu>
      {/* <CreateRemediation
        text={'Create'}
        isRiskPage={true}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        engineer={'engineer'}
        GetRisks={() => GetRisks()}
        GetTagsForList={() => {}}
      /> */}
    </>
  );
};

export default IconButtonComponent;
