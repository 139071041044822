import ConformationModel from '@jibin/common/components/ConformationModel';
import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Table, { TableProps } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ListTableProps } from './Models/ListTableProps';
import { ApiResponse, Users } from './Models/UserList.model';
import { DeleteTypesEnum } from '@jibin/common/utils/enums/deleteTypeEnums';
import InviteUserModel from './InviteUserModel';
import { notifySuccess } from '@jibin/common/services/http/http-common';
import { UserModel } from './Models/User.Model';
import { UserProp } from './Models/UserProp';
import { NoDataAvailable } from '../../../Common/NoDataAvailable';
import TablePagination from '../../../Common/TablePagination';
import {
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  Skeleton,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
import SecurityAwarnessInviteModel from './InviteUserModel/SecurityAwarnessInviteModel';
import DownloadIcon from '@jibin/common/icons/DownloadIcon/DownloadIcon';
import UploadIconMin from '@jibin/common/icons/UploadIconMin/UploadIconMin';
import SearchIcon from '@jibin/common/icons/Search/Search';
import UploadFile from './UploadFile';
import { SecurityAwarenessApi } from '../../SecurityAwareness.Api';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
const label = { inputProps: { 'aria-label': 'Switch demo' } };
var debounce = require('lodash.debounce');
const RowUser = ({ row, index, handleRefresh, handleConfirm }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: any, row: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow
      id={row.user_uuid}
      key={row?.name + index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        <Typography variant="caption">{row?.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption"> {row?.email}</Typography>
      </TableCell>
      <TableCell>
        <Box>
          <Typography variant="caption">{row?.risk_score}</Typography>
        </Box>
      </TableCell>
      <TableCell align="left">
        <Typography variant="caption">{row?.phish_prone_percentage?.toFixed(2)}%</Typography>
      </TableCell>
    </TableRow>
  );
};
export const SecurityAwarnessManageListTable: FC<ListTableProps> = (props) => {
  const [UserListData, setUserListData] = useState(new ApiResponse());
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const data: UserProp = {
    UserModel: new UserModel(),
    IsEdit: false,
    handleSubmit() {}
  };
  const location = useLocation();

  const handleRefresh = () => {
    setIsRefresh(!isRefresh);
  };
  const [page, setPage] = useState(0);
  const [listFilter, setListFilter] = useState({
    keyword: '',
    campaign_id: ''
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(null);
  const [paginationData, setPaginationData] = useState({
    page_number: 0,
    page_size: 0,
    total_pages: 0,
    total_records: 0
  });
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });

  const [phisingData, setPhisingData] = useState([] as any);
  const handleChange = (e: any) => {
    setIsLoading(true);
    setListFilter({ ...listFilter, [e.target.name]: e.target.value });
  };
  const Getphising = useCallback(() => {
    SecurityAwarenessApi.phishingCampaigns(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setPhisingData(q.data.data);
        setIsSkeleton(false);
      },
      () => {
        setIsSkeleton(false);
        setIsLoading(false);
      }
    );
  }, [commonDetails]);
  const [currentPage, setCurrentPage] = useState(1);

  const [phisingDataEnrollments, setPhisingDataEnrollments] = useState([] as any);

  const GetPhisingEnrollments = useCallback(
    (id) => {
      SecurityAwarenessApi.phisingCampaignsEnrollments(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        listFilter.campaign_id || id,
        new URLSearchParams({
          ...pagination,
          keyword: listFilter.keyword
        }).toString()
      ).then(
        (q: any) => {
          setPhisingDataEnrollments(q.data.data.data);
          setTotalCount(q?.data?.data?.pagination?.total_records);

          setPaginationData(q?.data?.data?.pagination);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [commonDetails, pagination, listFilter]
  );
  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);
  useEffect(() => {
    if (phisingData.length > 0) {
      GetPhisingEnrollments(phisingData[0].campaign_id);
    }
  }, [GetPhisingEnrollments, phisingData]);
  useEffect(() => {
    Getphising();
  }, [Getphising]);

  return (
    <>
      <Box sx={Style.SiteListTable.SiteListTableWrapper}>
        <Box sx={Style.CompanySettingPage.AddButton}>
          {isSkeleton ? (
            <Box>
              <Skeleton sx={{ p: 3 }} />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 1
              }}>
              <Box sx={{ py: 2, display: 'flex', gap: 3, alignItems: 'center' }}>
                <TextField
                  placeholder="Search"
                  hiddenLabel
                  fullWidth
                  size="small"
                  onChange={handleChange}
                  value={listFilter.keyword}
                  name="keyword"
                  sx={{ backgroundColor: '#FFFFFF', width: '250px' }}
                  inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
                <FormControl size="small" sx={{ minWidth: 200 }}>
                  <Select
                    size="small"
                    displayEmpty
                    id="type"
                    defaultValue={phisingData[0]?.campaign_id}
                    name="campaign_id"
                    value={
                      listFilter.campaign_id != ''
                        ? listFilter.campaign_id
                        : phisingData[0]?.campaign_id
                    }
                    onChange={handleChange}
                    sx={{
                      fontSize: '14px',
                      color: '#696969',

                      backgroundColor: '#FFFFFF'
                    }}
                    MenuProps={{
                      sx: {
                        maxWidth: 200,
                        maxHeight: 200
                      }
                    }}
                    inputProps={{
                      'aria-label': 'Without label'
                    }}>
                    {phisingData?.map((el: any) => (
                      <MenuItem sx={{ fontSize: '14px' }} value={el?.campaign_id}>
                        <EllipsisContent variant="body2" text={el?.campaign_name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          )}
        </Box>
        <Box>
          {isLoading ? (
            <Box>
              <Skeleton sx={{ p: 3 }} />
            </Box>
          ) : phisingDataEnrollments.length == 0 || !phisingDataEnrollments ? (
            <NoDataAvailable title="No Data Available" message="" />
          ) : (
            <Box>
              <Table sx={{ minWidth: 650 }} aria-label="site list table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontSize: '14px', fontWeight: 500 }}>Name</TableCell>
                    <TableCell sx={{ fontSize: '14px', fontWeight: 500 }}>Email</TableCell>
                    <TableCell sx={{ fontSize: '14px', fontWeight: 500 }}>Risk Score</TableCell>
                    <TableCell sx={{ fontSize: '14px', fontWeight: 500 }}>Phish Prone</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {phisingDataEnrollments?.map((el: any) => (
                    <>
                      <RowUser
                        row={el}
                        index={'1'}
                        handleConfirm={(row) => {}}
                        handleRefresh={() => handleRefresh()}
                      />
                      <Box sx={Style.Common.Divider}></Box>
                    </>
                  ))}
                </TableBody>
              </Table>
              <Box>
                <TablePagination
                  totalCount={totalCount}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SecurityAwarnessManageListTable;
