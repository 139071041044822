import { useState, useEffect, useRef, useCallback } from 'react';
import CalenderIconFilter from '@jibin/common/icons/CalenderIconFilter/CalenderIconFilter';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton,
  TextField,
  List,
  Checkbox,
  InputAdornment
} from '@mui/material';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import CloseIcon from '@jibin/common/icons/Close/Close';
import CheckBoxOutLine from '@jibin/common/icons/CheckBoxOutLine/CheckBoxOutLine';
import CheckBoxLightBlue from '@jibin/common/icons/CheckBoxLightBlue/CheckBoxLightBlue';
import SearchIcon from '@jibin/common/icons/Search/Search';
import { LoadingButton } from '@mui/lab';
import { RiskManagementApi } from './RiskManagement.Api';
import { notifyError } from '@jibin/common/services/http/http-common';
import moment from 'moment';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';

const AcceptRiskDialog = ({
  openAcceptRisk,
  handleCloseAcceptRisk,
  risk_uuid,
  commonDetails,
  GetRisks,
  selectedRiskList,
  GetRiskSummary,
  risksList,
  rowData,
  setRowData,
  bulkSelectedRisks,
  setBulkSelectedRisks
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplate] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [searchAssets, setSearchAssets] = useState([]);
  const [selectedUUID, setSelectedUUID] = useState([]);
  const [associatedAssets, setAssociatedAssets] = useState([]);
  const [isBoxVisible, setIsBoxVisible] = useState(true);
  const box1Ref = useRef(null);
  const box2Ref = useRef(null);
  const box3Ref = useRef(null);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const [fields, setFields] = useState({
    risk_acceptance_justification: '',
    risk_acceptance_expire_at: tomorrow
  });
  const oneYearFromToday = new Date();
  oneYearFromToday.setFullYear(today.getFullYear() + 1);
  const handleChange = (e) => {
    if (e.target?.name === 'risk_acceptance_justification' && !e.target.value) {
      setIsComplate(false);
    } else {
      setIsComplate(true);
    }
    if(e.target?.name === 'risk_acceptance_justification') {
      if (e?.target?.value?.length <= 500) {
        setFields((prevFields) => ({
          ...prevFields,
          [e.target?.name]: e.target?.value
        }));
      }
    } else {
      setFields((prevFields) => ({
        ...prevFields,
        [e.target?.name]: e.target?.value
      }));
    }
  };
  const handleDateChange = (date) => {
    setFields((prevFields) => ({
      ...prevFields,
      risk_acceptance_expire_at: date
    }));
  };
  const handleConfirm = async () => {
    const associatedAssetUUIDs = associatedAssets.map((risk) => risk?.risk_uuid);
    const filteredIds = risksList
      .filter((asset) => associatedAssetUUIDs.includes(asset?.risk_uuid))
      .map((asset) => asset?.risk_uuid);
    if (!fields.risk_acceptance_justification || !associatedAssetUUIDs.length) {
      return;
    }
    const dateObject = moment(fields.risk_acceptance_expire_at).format('YYYY-MM-DD');
    const formattedDate = dateObject;
    setIsLoading(true);

    const data = {
      risk_uuids: associatedAssetUUIDs,
      action: {
        action_type: 'accept',
        action_data: {
          risk_acceptance_justification: fields?.risk_acceptance_justification,
          risk_acceptance_expire_at: formattedDate
        }
      }
    };

    const res = await RiskManagementApi.BulkAction(
      commonDetails?.data?.user_uuid,
      commonDetails?.data?.company?.company_uuid,
      data
    );
    // notifyError('You have Accepted this Risk');
    handleCloseAcceptRisk();
    setRowData({});
    GetRisks();
    GetRiskSummary();
    setIsLoading(false);
    setBulkSelectedRisks([]);
  };

  const handleClickOutside = (event) => {
    if (
      box1Ref.current &&
      !box1Ref.current.contains(event.target) &&
      box2Ref.current &&
      !box2Ref.current.contains(event.target) &&
      box3Ref.current &&
      !box3Ref.current.contains(event.target)
    ) {
      setIsBoxVisible(false);
    }
  };
  const isSelected = (name: string) => selectedUUID?.indexOf(name) !== -1;
  const handleClickCheck = (event: React.MouseEvent<unknown>, name: string) => {
    event.preventDefault();
    const selectedIndex = selectedUUID.indexOf(name);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUUID, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUUID.slice(1));
    } else if (selectedIndex === selectedUUID.length - 1) {
      newSelected = newSelected.concat(selectedUUID.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUUID.slice(0, selectedIndex),
        selectedUUID.slice(selectedIndex + 1)
      );
    }
    setSelectedUUID(newSelected);
  };
  const addRisks = () => {
    const filteredData = searchAssets
      ?.filter(
        (item) =>
          selectedUUID.includes(item?.risk_uuid) &&
          item?.status !== 'Risk Accepted' &&
          item?.status !== 'Resolved'
      )
      ?.slice(0, 10);
    setAssociatedAssets([...associatedAssets, ...filteredData]);
    GetSearchRisk(selectedUUID);
  };
  const removeRisk = (assetUUIDToRemove) => {
    const updatedAssetsList = associatedAssets.filter(
      (asset) => asset.risk_uuid !== assetUUIDToRemove
    );
    const updatedAssetUUIDs = selectedUUID.filter((uuid) => uuid !== assetUUIDToRemove);
    setSelectedUUID(updatedAssetUUIDs);
    setAssociatedAssets(updatedAssetsList);
    GetSearchRisk(updatedAssetUUIDs);
  };
  const assetColorBackGround = (status: string) => {
    let color = '';
    if (status === 'Resolved') {
      color = '#97e9d2';
    } else if (status === 'Not Started') {
      color = '#FFE6E2';
    } else if (status === 'Risk Accepted') {
      color = '#e5e5e7';
    } else if (status === 'In Remediation') {
      color = '#FEF3C7';
    }
    return color;
  };
  const assetColorIcon = (status: string) => {
    let color = '';
    if (status === 'Resolved') {
      color = '#10B981';
    } else if (status === 'Not Started') {
      color = '#F73939';
    } else if (status === 'Risk Accepted') {
      color = '#D9E1FD';
    } else if (status === 'In Remediation') {
      color = '#FBBF24';
    }
    return color;
  };
  const bgStatusColor = (status: string) => {
    let color = '';
    if (status === 'Resolved') {
      color = '#10B981';
    } else if (status === 'Not Started') {
      color = '#F87171';
    } else if (status === 'Risk Accepted') {
      color = '#D9E1FD';
    } else if (status === 'In Remediation') {
      color = '#FBBF24';
    }
    return color;
  };
  const GetSearchRisk = useCallback(
    async (selectedUUID?: any) => {
      try {
        const queryParams = new URLSearchParams({
          keyword: searchText
        }).toString();
        const res = await RiskManagementApi.RiskList(
          commonDetails.data.user_uuid,
          commonDetails.data.company.company_uuid,
          queryParams
        );
        const filteredData = res?.data?.data?.filter(
          (item) =>
            item?.status !== 'Risk Accepted' &&
            item?.status !== 'Resolved' &&
            !selectedUUID.includes(item?.risk_uuid)
        );
        setSearchAssets(filteredData);
      } catch (error) {}
    },
    [commonDetails, searchText]
  );

  useEffect(() => {
    if (searchText) {
      GetSearchRisk(selectedUUID);
    } else {
      setSearchAssets([]);
    }
  }, [GetSearchRisk, searchText]);

  useEffect(() => {
    if (Object.keys(rowData).length) {
      setAssociatedAssets([rowData]);
      setSelectedUUID([rowData?.risk_uuid]);
    } else if (bulkSelectedRisks?.length) {
      const filteredData = bulkSelectedRisks?.filter(
        (item) =>
          item?.status !== 'Resolved' &&
          item?.status !== 'Risk Accepted'
      );
      const filteredIds = filteredData.map((risk) => risk?.risk_uuid);
      setSelectedUUID(filteredIds);
      setAssociatedAssets(filteredData);
    }
  }, [rowData, bulkSelectedRisks]);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openAcceptRisk}
        onClose={handleCloseAcceptRisk}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            px: { xs: 1, md: 2 },
            py: { xs: 0.5, md: 1 },
            bgcolor: 'error.red100',
            mb: 1
          }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <WarningIcon sx={{ mr: 1, color: 'error.main' }} />
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'error.main',
                  fontWeight: '600',

                  p: 1
                }}>
                Accept Risk
              </Typography>
            </Box>
            <IconButton
              sx={{ color: 'text.primary' }}
              aria-label="close"
              onClick={handleCloseAcceptRisk}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          <DialogContentText id="alert-dialog-description" sx={{ color: 'text.primary' }}>
            <Box sx={{ width: 1, mb: 2 }}>
              <Typography>Are you sure you want to accept this risk?</Typography>
              <Typography variant="caption">
                Risks with a Resolved or Risk Accepted status cannot be added
              </Typography>
            </Box>
            <List sx={{ py: 1.5 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: 2
                }}>
                {associatedAssets?.map((asset) => {
                  return (
                    <Box
                      className="as-asset-wrapper"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 1,
                        border: '1px solid #CCCCCC',
                        borderRadius: '4px',
                        overflow: 'hidden',
                        padding: '8px',
                        position: 'relative',
                        cursor: 'pointer'
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: assetColorBackGround(asset?.status),
                          borderRadius: '50%',
                          width: '24px',
                          flex: ' 0 0 24px',
                          height: '24px',
                          mr: '6px'
                        }}>
                        <FlagIcon sx={{ fontSize: '12px', color: assetColorIcon(asset?.status) }} />
                      </Box>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontSize: '14px',
                          color: '#464646',
                          fontWeight: 400,
                          whiteSpace: 'nowrap'
                        }}>
                        <span>{asset?.name}</span>
                      </Typography>
                      <Box
                        className="hover-status"
                        sx={{ display: 'none', alignItems: 'center', gap: 1 }}>
                        <Box
                          sx={{
                            minWidth: '14px',
                            height: '14px',
                            width: '14px',
                            backgroundColor: bgStatusColor(asset?.status),
                            borderRadius: '50%'
                          }}></Box>
                        <Typography variant="caption">{asset?.status}</Typography>
                        <CrossCancle
                          onClick={() => removeRisk(asset?.risk_uuid)}
                          sx={{ color: '#000', height: '8px', width: '8px', cursor: 'pointer' }}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </List>
            <List sx={{ py: 1.5 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: 2
                }}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                  Add Associated Risks
                </Typography>
                <Box ref={box1Ref} sx={{ width: 1 }}>
                  <TextField
                    placeholder="Search by Risk Name"
                    hiddenLabel
                    fullWidth
                    size="small"
                    name="searchText"
                    value={searchText}
                    onClick={() => setIsBoxVisible(true)}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      setIsBoxVisible(true);
                    }}
                    sx={{
                      input: { backgroundColor: '#FFFFFF' },
                      '.MuiOutlinedInput-root': {
                        bgcolor: '#FFFFFF'
                      }
                    }}
                    inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment sx={{ bgcolor: '#FFFFFF' }} position="end">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
                {isBoxVisible && searchAssets.length ? (
                  <Box sx={{ width: 1 }}>
                    <Box
                      ref={box2Ref}
                      sx={{
                        border: '1px solid #CCCCCC',
                        padding: '8px',
                        borderRadius: '4px',
                        maxHeight: '170px',
                        overflowX: 'auto'
                      }}>
                      {searchAssets?.map((asset, index) => {
                        const isItemSelected = isSelected(asset?.risk_uuid);
                        const isLastItem = index === searchAssets.length - 1;
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              overflow: 'hidden',
                              mb: isLastItem ? 0 : 1
                            }}>
                            <Checkbox
                              sx={{ padding: 0, width: '16px', height: '16px', mr: 0.5 }}
                              onClick={(event) => {
                                handleClickCheck(event, asset?.risk_uuid);
                                event.stopPropagation();
                                event.preventDefault();
                              }}
                              checked={isItemSelected}
                              checkedIcon={<CheckBoxLightBlue />}
                              inputProps={
                                {
                                  // 'aria-labelledby': labelId
                                }
                              }
                              icon={
                                <CheckBoxOutLine
                                  sx={{
                                    fill: 'none',
                                    width: '16px',
                                    height: '16px'
                                  }}
                                />
                              }
                            />
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: assetColorBackGround(asset?.status),
                                borderRadius: '50%',
                                width: '24px',
                                height: '24px',
                                mr: '6px',
                                flex: ' 0 0 24px'
                              }}>
                              <FlagIcon
                                sx={{ fontSize: '12px', color: assetColorIcon(asset?.status) }}
                              />
                            </Box>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: '14px',
                                color: '#464646',
                                fontWeight: 400,
                                whiteSpace: 'nowrap'
                              }}>
                              <span>{asset?.name}</span>
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                ) : null}
                <Box ref={box3Ref} sx={{ textAlign: 'end', width: 1 }}>
                  <Button variant="outlined" onClick={addRisks}>
                    Add Risks
                  </Button>
                </Box>
              </Box>
            </List>
            <List sx={{ py: 1.5 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: 2
                }}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                  Business Justification
                </Typography>
                <TextField
                  size="small"
                  placeholder="Add a business justification"
                  name="risk_acceptance_justification"
                  fullWidth
                  value={fields.risk_acceptance_justification}
                  onChange={handleChange}
                  inputProps={{ sx: { fontSize: '14px' } }}
                  variant="outlined"
                  rows={7}
                  multiline
                  sx={{ fontSize: '14px' }}
                />
              </Box>
            </List>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 2,
                width: '210px'
              }}>
              <Typography
                variant="subtitle2"
                sx={{ fontSize: '14px', color: '#696969', fontWeight: 600, whiteSpace: 'nowrap' }}>
                Risk Acceptance Expiration Date
              </Typography>
              <label>
                <DatePicker
                  // showYearDropdown
                  showIcon
                  className="calendar-filter"
                  icon={<CalenderIconFilter />}
                  dateFormat="dd MMM yyyy"
                  placeholderText="DD MMM YYYY"
                  selected={fields.risk_acceptance_expire_at}
                  onChange={handleDateChange}
                  minDate={tomorrow}
                  maxDate={oneYearFromToday}
                />
              </label>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0, justifyContent: 'right' }}>
          <Button
            variant="outlined"
            onClick={handleCloseAcceptRisk}
            sx={{ minWidth: 100, color: '#9D9D9D', borderColor: '#9D9D9D' }}>
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            disabled={!isComplete}
            variant="contained"
            sx={{ minWidth: 100 }}
            color="error"
            onClick={handleConfirm}>
            {' '}
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AcceptRiskDialog;
