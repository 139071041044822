import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import Box from '@mui/material/Box';
import Settings from '@jibin/common/icons/Settings/Settings';
import Power from '@jibin/common/icons/Power/Power';
import VerifiedUser from '@jibin/common/icons/VerifiedUser/VerifiedUser';
import SignalCellular from '@jibin/common/icons/SignalCellular/SignalCellular';

import PictureAsPdf from '@jibin/common/icons/PictureAsPdf/PictureAsPdf';
import SpaceDashboard from '@jibin/common/icons/SpaceDashboard/SpaceDashboard';
import ExpandMore from '@jibin/common/icons/ExpandMore/ExpandMore';
import ExpandLess from '@jibin/common/icons/ExpandLess/ExpandLess';
import EndpointProtection from '@jibin/common/icons/EndpointProtection/EndpointProtection';
import { AssetsSideBarIcon } from '@jibin/common/icons/AssetsSideBarIcon';
import { LicenseIcon } from '@jibin/common/icons/License';
import TickMark from '@jibin/common/icons/Tickmark/Tickmark';
import RiskIcon from '@jibin/common/icons/RiskIcon/RiskIcon';

import PortalActivityIcon from '@jibin/common/icons/PortalActivityIcon/PortalActivityIcon';

import AttckSurafaceIcon from '@jibin/common/icons/AttckSurafaceIcon/AttckSurafaceIcon';
import HumanHead from '@jibin/common/icons/HumanHead/HumanHead';
import NetworkDetectionIcon from '@jibin/common/icons/NetworkDetectionIcon';
import OnboardingIcon from '@jibin/common/icons/OnboardingIcon';

import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Style from '@jibin/common/style/Styles';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import CustomerSuccess from '@jibin/common/icons/CustomerSuccess/CustomerSuccess';
import { ManageUserIcon } from '@jibin/common/icons/ManageUserIcon';
import { CompanyManagementIcon } from '@jibin/common/icons/CompanyManagementIcon';
import EmailProtection from '@jibin/common/icons/EmailProtection/EmailProtection';
import GapAnalysisIcon from '@jibin/common/icons/GapAnalysisIcon/GapAnalysisIcon';
import SiemIcon from '@jibin/common/icons/SiemIcon';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
// import { commonDetailSlice } from '../../store/reducers/commonReducer';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import { serviceDetailSlice } from '../../../store/reducers/servicesReducer';

import { SidebarService } from './Sidebar.api';
import { SidebarCompanySearchComponent } from './SidebarCompanySearchComponent';
import { CSSObject, IconButton, Skeleton, Theme, styled, useTheme, Tooltip } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import { CompanyUserRoles, UserRoles } from '@jibin/common/utils/constants/ConstantName';
import { setLoader } from './../../../store/reducers/loaderReducer';
const drawerWidth = 280;
const toolTipStyle = {
  tooltip: {
    sx: {
      bgcolor: '#46464699',
      '& .MuiTooltip-arrow': {
        color: '#46464699'
      },
      padding: '8px, 12px, 8px, 12px',
      borderRadius: '54px',
      fontSize: '14px',
      fontWeight: 500,
      color: '#FFFFFF'
    }
  }
};
const openedMixin = (theme: Theme): CSSObject => ({
  width: '100%',
  minWidth: '248px',
  maxWidth: '280px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  backgroundColor: '#003260',
  paddingBottom: '44px',
  position: 'sticky',
  overflowX: 'hidden',
  border: 'none'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 8px)`,
  backgroundColor: '#003260',
  position: 'sticky',
  padding: '0',
  paddingBottom: '44px',
  paddingRight: '24px',
  paddingLeft: '8px',
  border: 'none',

  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 8px)`
  }
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    padding: '0 16px 24px',
    minHeight: '100vh',
    border: 'none',

    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

export const Service = [
  {
    name: 'Remediation Tracker',
    showing_name: 'Remediation Tracker',
    tooltip_name: '',
    id: 1, // Updated ID
    route: PageRouteConstants.Remediation,
    supported_route: 'remediation-tracker',
    icon: TickMark
  },
  {
    name: 'Reports',
    showing_name: 'Reports',
    tooltip_name: '',
    id: 2, // Updated ID
    isStatic: true,
    route: PageRouteConstants.ServicesReview,
    supported_route: 'customer-success',
    icon: CustomerSuccess
  },
  {
    name: 'Events',
    showing_name: 'Events',
    tooltip_name: '',
    id: 3, // Updated ID
    route: PageRouteConstants.SIEM,
    supported_route: 'events',
    icon: SiemIcon
  },
  {
    name: 'Risks',
    showing_name: 'Risks',
    tooltip_name: '',
    id: 4,
    route: PageRouteConstants.RiskManagement,
    supported_route: 'risk-management',
    icon: RiskIcon
  },
  {
    name: 'Gap Analysis',
    showing_name: 'Compliance',
    tooltip_name: '',
    id: 5,
    route: PageRouteConstants.GapAnalysis,
    supported_route: 'gap-analysis',
    icon: GapAnalysisIcon
  },
  {
    name: 'Compliance',
    showing_name: 'Compliance',
    tooltip_name: '',
    id: 6,
    route: PageRouteConstants.GapAnalysisV2,
    supported_route: 'gap-analysis-v2',
    icon: GapAnalysisIcon
  },
  {
    name: 'Policies',
    showing_name: 'Policies',
    tooltip_name: '',
    id: 7,
    route: PageRouteConstants.PoliciesAndProcedures,
    supported_route: 'policies-procedures',
    icon: PictureAsPdf
  },
  {
    name: 'Assets',
    showing_name: 'Assets',
    tooltip_name: '',
    id: 8,
    route: PageRouteConstants.AssetManagement,
    supported_route: 'asset-management',
    icon: AssetsSideBarIcon
  },
  {
    name: 'Licenses',
    showing_name: 'Licenses',
    tooltip_name: '',
    id: 9,
    route: PageRouteConstants.Licenses,
    supported_route: 'licenses',
    icon: LicenseIcon
  },
  {
    name: 'Tools',
    showing_name: 'Tools',
    tooltip_name: '',
    id: 10,
    route: PageRouteConstants.EndpointProtection,
    supported_route: 'endpoint-protection',
    icon: EndpointProtection
  },
  {
    name: 'Framework',
    showing_name: 'Framework',
    tooltip_name: '',
    id: 11,
    isStatic: true,
    route: PageRouteConstants.Frameworks,
    supported_route: 'frameworks',
    icon: SpaceDashboard
  },
  {
    name: 'Security Awareness',
    showing_name: 'Security Awareness',
    tooltip_name: '',
    id: 12,
    route: PageRouteConstants.SecurityAwarenessResult,
    supported_route: 'security-awareness',
    icon: HumanHead
  },
  {
    name: 'Vulnerability Management',
    showing_name: 'Vulnerability Management',
    tooltip_name: '',
    id: 13,
    route: PageRouteConstants.Vulnerability,
    supported_route: 'vulnerability-management',
    icon: VerifiedUser
  },
  {
    name: 'Attack Surface Management',
    showing_name: 'Attack Surface Management',
    tooltip_name: '',
    id: 14,
    route: PageRouteConstants.UpGuard,
    supported_route: 'attack-surface-management',
    icon: AttckSurafaceIcon
  },
  {
    name: 'Penetration Testing',
    showing_name: 'Penetration Testing',
    tooltip_name: '',
    id: 15,
    route: PageRouteConstants.Penetration,
    supported_route: 'penetration-testing',
    icon: SignalCellular
  },
  {
    name: 'Email Protection',
    showing_name: 'Email Protection',
    tooltip_name: '',
    id: 16,
    route: PageRouteConstants.EmailProtection,
    supported_route: 'email-protection',
    icon: EmailProtection
  }
];

type SidebarProps = {
  open: boolean;

  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
};

export const Sidebar: FC = (props: SidebarProps) => {
  const { open, handleDrawerOpen, handleDrawerClose } = props;

  const theme = useTheme();

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const servicesDetials = useSelector(
    ({ servicesDetials }: any) => servicesDetials?.servicesDetials,
    shallowEqual
  );

  const navigate = useNavigate();
  const location = useLocation();
  const { emailProtection } = useFlags();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(location.pathname);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  useEffect(() => {
    if (location.pathname.includes('company/information')) {
      if (
        location.pathname.includes(PageRouteConstants.Applications) ||
        location.pathname.includes(PageRouteConstants.ExternalInfrastructureAssessment) ||
        location.pathname.includes(PageRouteConstants.WirelessAssessment)
      ) {
        setSelectedItem(PageRouteConstants.Applications);
        if (!menuOpen) {
          setMenuOpen(true);
        }
      } else if (
        location.pathname === PageRouteConstants.ManageUsers &&
        commonDetails?.data?.company?.type?.toUpperCase() == 'ENGINEERING'
      )
        setSelectedItem(PageRouteConstants.ManageUsers);
      else setSelectedItem(PageRouteConstants.General);
    } else {
      setSelectedItem(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!open) {
      setMenuOpen(false);
    }
  }, [open]);

  const menuClick = () => {
    setMenuOpen(!menuOpen);
  };
  const routeChange = (path: string) => {
    setSelectedItem(path);
    navigate(path);
  };
  const renderIcon = (serviceName) => {
    const maped = Service?.filter((el) => el.name == serviceName);

    const Icon = maped[0].icon;

    return <Icon />;
  };
  // Merge common objects into a single array
  const dispatch = useDispatch();
  const GetServices = async (company_uuid: any, user_uuid: any) => {
    dispatch(serviceDetailSlice.actions.removeserviceDetails());
    setIsSkeleton(true);
    try {
      const res = await SidebarService.GetServices(company_uuid, user_uuid);
      let commonObjects;
      if (res.data.data) {
        console.log(Service);
        commonObjects = Service?.filter((obj1) =>
          res.data.data
            ?.filter((el: any) => (el.name == obj1.name && el.enabled) || obj1.isStatic)
            .some((obj2) => (obj2.name === obj1.name && obj2.enabled) || obj1.isStatic)
        );
      } else {
        commonObjects = Service?.filter((obj1) => obj1.isStatic);
      }

      dispatch(serviceDetailSlice.actions.setserviceDetails(commonObjects));
      setServiceData(commonObjects);
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  if (commonDetails?.data?.company?.type == 'engineering') {
    return (
      <Box
        className={open ? 'isDrawerOpen drawer-menu' : 'drawer-menu'}
        sx={Style.Sidebar.SidebarMain}>
        <Box>
          <Drawer variant="permanent" open={open}>
            <List>
              <Box sx={{ height: '16px' }}></Box>

              <SidebarCompanySearchComponent
                GetServices={(company_uuid, user_uuid) => GetServices(company_uuid, user_uuid)}
                isOpen={open}
                handleDrawerOpen={handleDrawerOpen}
              />

              <MenuList sx={Style.Sidebar.sidebarMenuWrapper}>
                <Link
                  to={PageRouteConstants.CompanyDashBoard}
                  style={{ padding: 0, textDecoration: 'none', color: 'white' }}>
                  <Tooltip
                    componentsProps={toolTipStyle}
                    title={!open ? 'Dashboard' : ''}
                    placement={'right'}>
                    <MenuItem
                      onClick={() => routeChange(PageRouteConstants.CompanyDashBoard)}
                      selected={selectedItem.includes(PageRouteConstants.CompanyDashBoard)}
                      sx={Style.Sidebar.sidebarMenuButton}>
                      <ListItemIcon
                        sx={{
                          ...Style.Sidebar.sidebarMenuIcon,
                          justifyContent: 'start'
                        }}>
                        <Power />
                      </ListItemIcon>
                      <ListItemText sx={{ opacity: open ? 1 : 0 }}>Dashboard</ListItemText>
                    </MenuItem>
                  </Tooltip>
                </Link>
                <Link
                  to={PageRouteConstants.CompanyManagement}
                  style={{ padding: 0, textDecoration: 'none', color: 'white' }}>
                  <Tooltip
                    componentsProps={toolTipStyle}
                    title={!open ? 'Company Management' : ''}
                    placement={'right'}>
                    <MenuItem
                      onClick={() => routeChange(PageRouteConstants.CompanyManagement)}
                      selected={selectedItem.includes(PageRouteConstants.CompanyManagement)}
                      sx={Style.Sidebar.sidebarMenuButton}>
                      <ListItemIcon
                        sx={{
                          ...Style.Sidebar.sidebarMenuIcon,
                          justifyContent: 'start'
                        }}>
                        <CompanyManagementIcon />
                      </ListItemIcon>
                      <ListItemText sx={{ opacity: open ? 1 : 0 }}>Company Management</ListItemText>
                    </MenuItem>
                  </Tooltip>
                </Link>
                {(commonDetails?.data?.group.toLowerCase() == UserRoles.SUPER_ADMIN ||
                  commonDetails?.data?.group.toLowerCase() ==
                    UserRoles.SERVICE_DELIVERY_MANAGER) && (
                  <>
                    <Link
                      to={PageRouteConstants.ManageUsers}
                      style={{ padding: 0, textDecoration: 'none', color: 'white' }}>
                      <Tooltip
                        componentsProps={toolTipStyle}
                        title={!open ? 'Manage Users' : ''}
                        placement={'right'}>
                        <MenuItem
                          onClick={() => routeChange(PageRouteConstants.ManageUsers)}
                          selected={selectedItem === PageRouteConstants.ManageUsers}
                          sx={Style.Sidebar.sidebarMenuButton}>
                          <ListItemIcon
                            sx={{
                              ...Style.Sidebar.sidebarMenuIcon,
                              justifyContent: 'start'
                            }}>
                            <ManageUserIcon />
                          </ListItemIcon>
                          <ListItemText sx={{ opacity: open ? 1 : 0 }}>Manage Users</ListItemText>
                        </MenuItem>
                      </Tooltip>
                    </Link>
                  </>
                )}
                {(commonDetails?.data?.group.toLowerCase() == UserRoles.SUPER_ADMIN ||
                  commonDetails?.data?.group.toLowerCase() ==
                    UserRoles.SERVICE_DELIVERY_MANAGER) && (
                  <Link
                    to={PageRouteConstants.PortalActivity}
                    style={{ padding: 0, textDecoration: 'none', color: 'white' }}>
                    <Tooltip
                      componentsProps={toolTipStyle}
                      title={!open ? 'User Activity' : ''}
                      placement={'right'}>
                      <MenuItem
                        sx={Style.Sidebar.sidebarMenuButton}
                        onClick={() => routeChange(PageRouteConstants.PortalActivity)}
                        selected={selectedItem === PageRouteConstants.PortalActivity}>
                        <ListItemIcon
                          sx={{
                            ...Style.Sidebar.sidebarMenuIcon,
                            justifyContent: 'start'
                          }}>
                          <PortalActivityIcon />
                        </ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }}>User Activity</ListItemText>
                      </MenuItem>
                    </Tooltip>
                  </Link>
                )}
                <Link
                  to=""
                  onKeyDown={(e) => e.key === 'Enter' && setMenuOpen(!menuOpen)}
                  style={{ padding: 0, textDecoration: 'none', color: 'white' }}>
                  <Tooltip
                    componentsProps={toolTipStyle}
                    title={!open ? 'Settings' : ''}
                    placement={'right'}>
                    <MenuItem
                      onClick={open ? menuClick : handleDrawerOpen}
                      sx={Style.Sidebar.sidebarMenuButton}>
                      <ListItemIcon
                        sx={{
                          ...Style.Sidebar.sidebarMenuIcon,
                          justifyContent: 'start'
                        }}
                        onClick={handleDrawerOpen}>
                        <Settings width="30" />
                      </ListItemIcon>
                      <ListItemText sx={{ opacity: open ? 1 : 0 }}>Settings</ListItemText>
                      {menuOpen ? <ExpandLess /> : <ExpandMore sx={{ opacity: open ? 1 : 0 }} />}
                    </MenuItem>
                  </Tooltip>
                </Link>
                <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                  <MenuList disablePadding sx={{ pl: '37px' }}>
                    <Link
                      to={PageRouteConstants.MyAccount}
                      style={{ padding: 0, textDecoration: 'none', color: 'white' }}>
                      <MenuItem
                        onClick={() => routeChange(PageRouteConstants.MyAccount)}
                        selected={selectedItem === PageRouteConstants.MyAccount}
                        sx={Style.Sidebar.sidebarMenuButton}>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }}>My Account</ListItemText>
                      </MenuItem>
                    </Link>
                  </MenuList>
                </Collapse>
              </MenuList>
            </List>
          </Drawer>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      className={open ? 'isDrawerOpen drawer-menu' : 'drawer-menu'}
      sx={Style.Sidebar.SidebarMain}>
      <Box>
        <Drawer variant="permanent" open={open}>
          <List>
            <Box>
              <Box sx={{ height: '16px' }}></Box>

              <SidebarCompanySearchComponent
                GetServices={(company_uuid, user_uuid) => GetServices(company_uuid, user_uuid)}
                isOpen={open}
                handleDrawerOpen={handleDrawerOpen}
              />
              {commonDetails?.data?.company?.status == 'Inactive' ? (
                <></>
              ) : isSkeleton ? (
                <Skeleton variant="rectangular" width={210} height={60} />
              ) : (
                <MenuList sx={Style.Sidebar.sidebarMenuWrapper}>
                  {commonDetails?.data?.company?.onboarding?.filter(
                    (q: { status: string }) => q.status == 'DRAFT'
                  ).length != 0 &&
                    commonDetails?.data?.company?.onboarding?.length > 0 &&
                    commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
                      <Link
                        to={PageRouteConstants.OnBoarding}
                        style={{ padding: 0, textDecoration: 'none', color: 'white' }}>
                        <Tooltip
                          componentsProps={toolTipStyle}
                          title={!open ? 'Onboarding' : ''}
                          placement={'right'}>
                          <MenuItem
                            onClick={() => routeChange(PageRouteConstants.OnBoarding)}
                            sx={Style.Sidebar.sidebarMenuButton}
                            selected={selectedItem === PageRouteConstants.OnBoarding}>
                            <ListItemIcon
                              sx={{
                                ...Style.Sidebar.sidebarMenuIcon,
                                justifyContent: 'start'
                              }}>
                              <OnboardingIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>Onboarding</ListItemText>
                          </MenuItem>
                        </Tooltip>
                      </Link>
                    )}
                  {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
                    <Link
                      to={PageRouteConstants.DashBoard}
                      style={{ padding: 0, textDecoration: 'none', color: 'white' }}>
                      <Tooltip
                        componentsProps={toolTipStyle}
                        title={!open ? 'Dashboard' : ''}
                        placement={'right'}>
                        <MenuItem
                          sx={Style.Sidebar.sidebarMenuButton}
                          onClick={() => {
                            routeChange(PageRouteConstants.DashBoard);
                          }}
                          selected={selectedItem === PageRouteConstants.DashBoard}>
                          <ListItemIcon
                            sx={{
                              ...Style.Sidebar.sidebarMenuIcon,
                              justifyContent: 'start'
                            }}>
                            <Power />
                          </ListItemIcon>

                          <ListItemText sx={{ opacity: open ? 1 : 0 }}>Dashboard</ListItemText>
                        </MenuItem>
                      </Tooltip>
                    </Link>
                  )}

                  {/* {JSON.stringify(servicesDetials)} */}

                  {servicesDetials
                    ?.filter((service: any, index: any) => {
                      if (
                        (service?.name === 'Compliance' ||
                          service?.name === 'Policies' ||
                          service?.name === 'Gap Analysis') &&
                        commonDetails?.data?.company?.user_role === CompanyUserRoles.AUDITOR
                      ) {
                        return service;
                      } else if (
                        commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR
                      ) {
                        return service;
                      }
                    })
                    .map((service: any, index: any) => {
                      return (service?.name == 'Email Protection' && !emailProtection) ||
                        (service?.name == 'Framework' &&
                          servicesDetials.filter((q) => q.name == 'Compliance').length > 0) ||
                        (service?.name == 'Email Protection' &&
                          process.env.REACT_APP_SHOW_PARTIAL_FEATURES !== 'true') ? (
                        <></>
                      ) : (
                        <Link
                          to={service?.route}
                          style={{
                            padding: 0,
                            textDecoration: 'none',
                            color: 'white',
                            order: service?.id
                          }}>
                          <Tooltip
                            componentsProps={toolTipStyle}
                            title={!open ? service?.name : ''}
                            placement={'right'}>
                            <MenuItem
                              onClick={() =>
                                routeChange(
                                  commonDetails?.data?.company?.user_role ===
                                    CompanyUserRoles.AUDITOR && service?.name === 'Complince'
                                    ? PageRouteConstants.GapAnalysisV2Questionnaire
                                    : service?.route
                                )
                              }
                              sx={{
                                ...Style.Sidebar.sidebarMenuButton,
                                color: 'white',
                                display: 'flex'
                              }}
                              selected={
                                selectedItem?.includes(service?.supported_route) ||
                                location.pathname === service.route
                              }>
                              <ListItemIcon
                                sx={{
                                  ...Style.Sidebar.sidebarMenuIcon,
                                  justifyContent: 'start'
                                }}>
                                {renderIcon(service?.name)}
                              </ListItemIcon>

                              <Tooltip
                                arrow
                                title={service?.tooltip_name ? service?.tooltip_name : ''}
                                placement={'top'}>
                                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                  {service?.showing_name ? service?.showing_name : service?.name}
                                </ListItemText>
                              </Tooltip>
                            </MenuItem>
                          </Tooltip>
                        </Link>
                      );
                    })}

                  <Link
                    to=""
                    onKeyDown={(e) => e.key === 'Enter' && setMenuOpen(!menuOpen)}
                    style={{ padding: 0, textDecoration: 'none', color: 'white', order: 100 }}>
                    <Tooltip
                      componentsProps={toolTipStyle}
                      title={!open ? 'Settings' : ''}
                      placement={'right'}>
                      <MenuItem
                        onClick={open ? menuClick : handleDrawerOpen}
                        sx={Style.Sidebar.sidebarMenuButton}>
                        <ListItemIcon
                          sx={{
                            ...Style.Sidebar.sidebarMenuIcon,
                            justifyContent: 'start'
                          }}
                          onClick={handleDrawerOpen}>
                          <Settings width="30" />
                        </ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }}>Settings</ListItemText>
                        {menuOpen ? <ExpandLess /> : <ExpandMore sx={{ opacity: open ? 1 : 0 }} />}
                      </MenuItem>
                    </Tooltip>
                  </Link>
                  <Collapse
                    sx={{
                      order: 101
                    }}
                    in={menuOpen}
                    timeout="auto"
                    unmountOnExit>
                    <MenuList disablePadding sx={{ pl: '37px' }}>
                      <Link
                        to={PageRouteConstants.MyAccount}
                        style={{ padding: 0, textDecoration: 'none', color: 'white' }}>
                        <MenuItem
                          onClick={() => routeChange(PageRouteConstants.MyAccount)}
                          selected={selectedItem === PageRouteConstants.MyAccount}
                          sx={Style.Sidebar.sidebarMenuButton}>
                          <ListItemText sx={{ opacity: open ? 1 : 0 }}>My Account</ListItemText>
                        </MenuItem>
                      </Link>
                      {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
                        <Link
                          to={PageRouteConstants.General}
                          style={{ padding: 0, textDecoration: 'none', color: 'white' }}>
                          <MenuItem
                            onClick={() => routeChange(PageRouteConstants.General)}
                            selected={selectedItem === PageRouteConstants.General}
                            sx={Style.Sidebar.sidebarMenuButton}>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              Company Account
                            </ListItemText>
                          </MenuItem>
                        </Link>
                      )}
                      {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
                        <Link
                          to={PageRouteConstants.Applications}
                          style={{ padding: 0, textDecoration: 'none', color: 'white' }}>
                          <MenuItem
                            onClick={() => routeChange(PageRouteConstants.Applications)}
                            selected={selectedItem === PageRouteConstants.Applications}
                            sx={Style.Sidebar.sidebarMenuButton}>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              Technical Information
                            </ListItemText>
                          </MenuItem>
                        </Link>
                      )}
                    </MenuList>
                  </Collapse>
                </MenuList>
              )}
            </Box>
          </List>
        </Drawer>
      </Box>
    </Box>
  );
};

export default Sidebar;
