import SvgIcon from '@mui/material/SvgIcon';

export default function EditIconForPolicy(props) {
  return (
    <SvgIcon width="16px" height="16px" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M11.6402 2.10983C11.4937 1.96339 11.2563 1.96339 11.1098 2.10983L9.875 3.34467L12.6553 6.125L13.8902 4.89016C14.0366 4.74372 14.0366 4.50628 13.8902 4.35983L11.6402 2.10983Z"
        fill="#464646"
      />
      <path
        d="M12.125 6.65533L9.34467 3.875L4.46968 8.74999H4.625C4.8321 8.74999 5 8.91789 5 9.12499V9.49999H5.375C5.5821 9.49999 5.75 9.66789 5.75 9.87499V10.25H6.125C6.3321 10.25 6.5 10.4179 6.5 10.625V11H6.875C7.0821 11 7.25 11.1679 7.25 11.375V11.5303L12.125 6.65533Z"
        fill="#464646"
      />
      <path
        d="M6.52374 12.2566C6.50839 12.2156 6.5 12.1713 6.5 12.125V11.75H6.125C5.91789 11.75 5.75 11.5821 5.75 11.375V11H5.375C5.16789 11 5 10.8321 5 10.625V10.25H4.625C4.41789 10.25 4.25 10.0821 4.25 9.87499V9.49999H3.875C3.8287 9.49999 3.78436 9.4916 3.74341 9.47626L3.60984 9.60983C3.57393 9.64574 3.54569 9.68857 3.52683 9.73572L2.02683 13.4857C1.97112 13.625 2.00377 13.7841 2.10985 13.8902C2.21592 13.9962 2.375 14.0289 2.51428 13.9732L6.26428 12.4732C6.31143 12.4543 6.35426 12.4261 6.39017 12.3902L6.52374 12.2566Z"
        fill="#464646"
      />
    </SvgIcon>
  );
}
