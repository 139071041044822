import CloseIcon from '@jibin/common/icons/Close/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { TechinicalInformation } from '../../TechinicalInformation.Api';
import { WirelessProp } from '../Models/WirelessProp';
import EditIcon from '@jibin/common/icons/Edit/Edit';
import { Wireless } from '../Models/wireless';
import { useFormik } from 'formik';
import SearchIcon from '@jibin/common/icons/Search/Search';
import { FormHelperText, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';

var debounce = require('lodash.debounce');
const Protocols = [
  { title: '802.11ax (Wi-Fi 6)' },
  { title: '802.11ac (Wi-Fi 5)' },
  { title: '802.11n (Wi-Fi 4)' },
  { title: '802.11g' },
  { title: '802.11a' },
  { title: '802.11b' }
];

const Security = [{ title: 'WPA3' }, { title: 'WPA2' }, { title: 'WPA1' }, { title: 'WEP' }];

const filter = createFilterOptions<OptionType>();

export const AddWirelessAssessmentModel: FC<WirelessProp> = (prop) => {
  const [open, setOpen] = React.useState(false);
  const [desError, setDesError] = React.useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    prop.colosepill();
    formik.resetForm();
    setOpen(false);
    setDesError('');
  };
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [isSkeleton, setIsSkeleton] = useState(false);
  const [facilityList, setfacilityList] = useState({} as any);
  const [isWirelessButtonLoading, setIsWirelessButtonLoading] = useState(false);

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const getFacilities = useCallback(() => {
    TechinicalInformation.GetFacilitiesList(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setfacilityList(q.data);
        setIsSkeleton(false);
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  useEffect(() => {
    getFacilities();
  }, []);

  const validate = (values: Wireless) => {
    const errors = {};

    if (!values.description) {
      errors['description'] = 'This field is Required';
    }

    if (!values.protocol) {
      errors['protocol'] = 'This field is Required';
    }

    if (!values.protocol_type) {
      errors['protocol_type'] = 'This field is Required';
    }

    if (!values.security) {
      errors['security'] = 'This field is Required';
    }
    if (!values.company_facility_uuid) {
      errors['company_facility_uuid'] = 'This field is Required';
    }

    if (!values.security_type) {
      errors['security_type'] = 'This field is Required';
    }

    if (!values.ssid) {
      errors['ssid'] = 'This field is Required';
    }
    return errors;
  };
  const handleDescriptionChange = (event: any) => {
    if (event.target.value.length > 500) {
      setDesError('Description should be less than 500 characters');
    } else {
      setDesError('');
    }
  };

  const formik = useFormik({
    initialValues: new Wireless(prop.Wireless),
    validateOnChange: false,
    validate,
    onSubmit: useCallback(
      debounce(
        (values) => {
          try {
            setIsWirelessButtonLoading(true);
            if (desError) {
              return;
            }

            if (prop.Wireless.tech_info_wireless_uuid) {
              delete values.facility;
              TechinicalInformation.putWirelessAssessment(
                commonDetails.data.user_uuid,
                commonDetails.data.company.company_uuid,
                prop.Wireless.tech_info_wireless_uuid,
                values
              ).then(
                (q: any) => {
                  handleClose();
                  prop.handleSubmit();
                  formik.resetForm();
                },
                (err) => {}
              );
            } else {
              delete values.tech_info_wireless_uuid;
              delete values.facility;
              TechinicalInformation.postWirelessAssessment(
                commonDetails.data.user_uuid,
                commonDetails.data.company.company_uuid,
                values
              ).then(
                (q: any) => {
                  handleClose();
                  prop.handleSubmit();
                  formik.resetForm();
                },
                (err) => {}
              );
            }
          } catch (error) {
          } finally {
            setTimeout(() => {
              setIsWirelessButtonLoading(false);
            }, 1000);
          }
        },
        500,
        true
      ),
      [open]
    )
  });
  const handleChangeData = (e: any, value: any) => {
    formik.setFieldValue(e, value.company_facility_uuid);
  };

  const maketag = (data: any, fieldName: any) => {
    if (fieldName == 'security_type') {
      var security = Security.filter((q) => q.title == data.title);

      if (security.length > 0) {
        formik.setFieldValue('security', data.title, false);
        formik.setFieldValue('security_type', data.title);
      } else {
        formik.setFieldValue('security', data, false);
        formik.setFieldValue('security_type', 'Others');
      }
    } else {
      var protocol = Protocols.filter((q) => q.title == data.title);

      if (protocol.length > 0) {
        formik.setFieldValue('protocol', data.title, false);
        formik.setFieldValue('protocol_type', data.title);
      } else {
        formik.setFieldValue('protocol', data, false);
        formik.setFieldValue('protocol_type', 'Others');
      }
    }
  };

  return (
    <>
      {prop.IsEdit && (
        // <Box>
          <MenuItem onClick={handleClickOpen} autoFocus sx={{ fontSize: 12 }}>
            <EditIcon sx={{ mr: 2, color: 'primary.main' }} /> Edit
          </MenuItem>
        // </Box>
      )}
      {!prop.IsEdit && (
        <Button variant="contained" onClick={handleClickOpen}>
          Add Wireless
        </Button>
      )}
      {!isSkeleton && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle
            id="alert-dialog-title"
            sx={{ display: 'flex', justifyContent: 'space-between', p: { xs: 1, md: 2 } }}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: '600',
                maxWidth: 'calc(100% - 40px)',
                p: 1,
                color: 'text.primary'
              }}>
              Wireless Network
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={formik.handleSubmit} method="post" noValidate>
            <DialogContent sx={{ px: { xs: 2, md: 3 }, pt: 0 }}>
              <Box id="alert-dialog-description">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Url">Facility </label>
                    </Box>
                    <Autocomplete
                      noOptionsText={'No facility found'}
                      id="facility"
                      onChange={(e, value) => handleChangeData('company_facility_uuid', value)}
                      disableClearable
                      options={
                        facilityList?.data?.sort((a: any, b: any) => {
                          return a?.name > b?.name ? 1 : -1;
                        }) || []
                      }
                      getOptionLabel={(option: any) => {
                        if (option.constructor.name == 'Array') {
                          return option[0]?.name ? `${option[0]?.name} :${option[0]?.address}` : '';
                        }
                        return option?.name ? `${option?.name} :${option?.address}` : '';
                      }}
                      defaultValue={prop.IsEdit && [formik.values.facility]}
                      renderOption={(props, option) => (
                        <Box
                          {...props}
                          key={option?.company_facility_uuid + 'a'}
                          component="li"
                          sx={{ pr: 1 }}>
                          <p key={option?.company_facility_uuid + 'b'}>
                            <strong>{option?.name}</strong>&nbsp;-&nbsp;{option?.address}
                          </p>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={formik.errors.company_facility_uuid ? true : false}
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            )
                          }}
                          value={formik.values.company_facility_uuid}
                          helperText={formik.errors.company_facility_uuid}
                          placeholder="Data Center, Head Office, etc...."
                          size="small"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.errors.company_facility_uuid = '';
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <Box sx={{ mb: 1 }}>
                        <label htmlFor="Email">SSID</label>
                      </Box>
                      <TextField
                        size="small"
                        placeholder="Enter SSID"
                        id="ssid"
                        name="ssid"
                        InputProps={
                          {
                            // startAdornment: (
                            //   <InputAdornment position="start">
                            //     <SearchIcon />
                            //   </InputAdornment>
                            // )
                          }
                        }
                        value={formik.values.ssid}
                        error={formik.errors.ssid ? true : false}
                        helperText={formik.errors.ssid}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.errors.ssid = '';
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <Box sx={{ mb: 1 }}>
                        <label htmlFor="Email"> Wireless Network Description</label>
                      </Box>
                      <TextField
                        size="small"
                        placeholder="Enter description"
                        id="description"
                        name="description"
                        InputProps={
                          {
                            // startAdornment: (
                            //   <InputAdornment position="start">
                            //     <SearchIcon />
                            //   </InputAdornment>
                            // )
                          }
                        }
                        value={formik.values.description}
                        error={formik.errors.description ? true : false || desError ? true : false}
                        helperText={
                          formik.errors.description
                            ? formik.errors.description
                            : '' || desError
                            ? desError
                            : ''
                        }
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleDescriptionChange(e);
                          formik.errors.description = '';
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Url">Wireless Security Type </label>
                    </Box>
                    <Autocomplete
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="security_type"
                      options={Security.sort((a: any, b: any) => {
                        return a?.title > b?.title ? 1 : -1;
                      })}
                      defaultValue={[{ title: formik.values.security }]}
                      onChange={(event, newValue: any) => {
                        if (typeof newValue === 'string') {
                          maketag(newValue, 'security_type');
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          maketag(newValue.inputValue, 'security_type');
                        } else {
                          maketag(newValue, 'security_type');
                        }
                        formik.errors.security = '';
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option.title);
                        if (inputValue !== '' && !isExisting) {
                          filtered.push({
                            inputValue,
                            title: `Add "${inputValue}"`
                          });
                        }
                        return filtered;
                      }}
                      getOptionLabel={(option: any) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        if (option.constructor.name == 'Array') {
                          return option[0].title;
                        }
                        // Regular option
                        return option.title;
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                            sx={{ bgcolor: 'common.lightBlue' }}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={formik.errors.company_facility_uuid ? true : false}
                          placeholder="Ex:WPA3"
                          helperText={formik.errors.company_facility_uuid}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Url">Wireless Protocol</label>
                    </Box>

                    <Autocomplete
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      sx={{ borderColor: formik.errors.protocol_type ? 'red' : '' }}
                      handleHomeEndKeys
                      id="protocol_type"
                      options={Protocols.sort((a: any, b: any) => {
                        return a?.title > b?.title ? 1 : -1;
                      })}
                      defaultValue={[{ title: formik.values.protocol }]}
                      onChange={(event, newValue: any) => {
                        if (typeof newValue === 'string') {
                          maketag(newValue, 'protocol_type');
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          maketag(newValue.inputValue, 'protocol_type');
                        } else {
                          maketag(newValue, 'protocol_type');
                        }
                        formik.errors.protocol_type = '';
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option.title);
                        if (inputValue !== '' && !isExisting) {
                          filtered.push({
                            inputValue,
                            title: `Add "${inputValue}"`
                          });
                        }
                        return filtered;
                      }}
                      getOptionLabel={(option: any) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        if (option.constructor.name == 'Array') {
                          return option[0].title;
                        }

                        return option.title;
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                            sx={{ bgcolor: 'common.lightBlue' }}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={formik.errors.protocol_type ? true : false}
                          placeholder="802.11ax (Wi-Fi 6)"
                          helperText={formik.errors.protocol_type}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions
              sx={{ px: { xs: 2, md: 3 }, pb: { xs: 2, md: 3 }, pt: 0, flexWrap: 'wrap' }}>
              <Button variant="outlined" sx={{ minWidth: 100 }} color="error" onClick={handleClose}>
                Cancel
              </Button>
              <LoadingButton
                loading={isWirelessButtonLoading}
                variant="contained"
                sx={{ minWidth: 100 }}
                type="submit">
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  );
};

interface OptionType {
  inputValue?: string;
  title: string;
}
export default AddWirelessAssessmentModel;
