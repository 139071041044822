import { Amplify, Auth } from 'aws-amplify';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { shallowEqual, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useOutlet } from 'react-router-dom';
import MainContainer from '../../components/Container';
import { CommonFunctions } from '../Common';
import { CompanyUserRoles, dataforUserActivity } from '../constants/ConstantName';
import { PageRouteConstants } from '../constants/PageRouteConstants';
import ProtectedRoute from '../RouteAccessor/ProtectedRoute';
import ReactGA from 'react-ga4';
import { LoginContext } from '@jibin/common/Context/LoginContext';

function PrivateRoutes({ children }: { children: React.ReactElement }): React.ReactElement {
  let auth = localStorage.getItem('cognito-token');

  const [waitingToReconnect, setWaitingToReconnect] = useState(false);
  const { clientRef }: any = useContext(LoginContext);
  const [isOpen, setIsOpen] = useState(false);
  const { getTabId } = useIdleTimer({
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
    throttle: 500
  });
  const userAgent = navigator.userAgent;

  const tabId = getTabId() === null ? 'loading' : getTabId().toString();
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  let location = useLocation();
  Amplify.configure({
    Auth: {
      userPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID),
      userPoolWebClientId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_CLIENT_ID)
    }
  });
  const getToken = async () => {
    let idToken = '';
    let accessToken = '';
    try {
      const session = await Auth.currentSession();
      idToken = session.getIdToken().getJwtToken();
      accessToken = session.getAccessToken().getJwtToken();
      return accessToken;
    } catch {
      idToken = null;
    }
  };
  const checkAuditorAllow = () => {
    if (
      commonDetails?.data?.company?.user_role === CompanyUserRoles.AUDITOR &&
      location.pathname !== PageRouteConstants.GapAnalysis &&
      location.pathname !== PageRouteConstants.GapAnalysisV2Questionnaire &&
      location.pathname !== PageRouteConstants.PoliciesAndProcedures &&
      !location.pathname.includes(PageRouteConstants.PoliciesAndProceduresTemplate) &&
      location.pathname !== PageRouteConstants.MyAccount
    ) {
      return true;
    }
  };
  useEffect(() => {
    if (commonDetails) {
      ReactGA.event({
        category: 'page-view-by-company',
        action: `${commonDetails.data.company.name}-${location.pathname}-page-visit`,
        label: `${commonDetails.data.company.name}-${location.pathname}`,
        nonInteraction: true // optional, true/false
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (commonDetails) {
      // GetNotifications();
      if (waitingToReconnect) {
        return;
      }
      console.log('Trying to connect');
      try {
        // Only set up the websocket once

        if (!clientRef.current) {
          let client = new WebSocket(
            `${process.env.REACT_APP_WEBSOCKET_HOST}ws/user/${commonDetails.data.user_uuid}/subscribe`
          );
          console.log('Test');
          clientRef.current = client;
        }

        clientRef.current.onerror = (e) => console.error(e);
        const page_name = dataforUserActivity.filter((el: any) => el?.route == location.pathname);
        const device_id = localStorage.getItem('device_id') || '';
        const userActivityMessage = {
          type: 'report_user_activity',
          payload: {
            user_id: commonDetails.data.user_uuid,
            company_id: commonDetails.data.company.company_uuid,
            page_name: page_name?.[0]?.name,
            device_id: device_id
          }
        };
        clientRef.current.onopen = () => {
          console.log('ws opened for user activity');
          clientRef.current.send(JSON.stringify(userActivityMessage));
          //client.send('ping');
        };

        clientRef.current.send(JSON.stringify(userActivityMessage));
        clientRef.current.onmessage = (event) => {};

        clientRef.current.onclose = () => {
          if (clientRef.current) {
            // Connection failed
            console.log('ws closed by server');
          } else {
            // Cleanup initiated from app side, can return here, to not attempt a reconnect
            // console.log('ws closed by app component unmount');
            return;
          }

          if (waitingToReconnect) {
            return;
          }

          // Parse event code and log
          setIsOpen(false);
          // console.log('ws closed');

          // Setting this will trigger a re-run of the effect,
          // cleaning up the current websocket, but not setting
          // up a new one right away
          setWaitingToReconnect(true);

          // This will trigger another re-run, and because it is false,
          // the socket will be set up again
          setTimeout(() => setWaitingToReconnect(null), 5000);
        };
      } catch (error) {}
    }
  }, [waitingToReconnect, location.pathname]);
  if (!auth) {
    return <Navigate to={PageRouteConstants.Login} state={{ from: location }} replace />;
  }
  if (commonDetails?.data?.company?.type == 'engineering') {
    return (
      <ProtectedRoute>
        <MainContainer> {children} </MainContainer>
      </ProtectedRoute>
    );
  }
  if (checkAuditorAllow()) {
    return (
      <Navigate
        to={PageRouteConstants.GapAnalysisV2Questionnaire}
        state={{ from: location }}
        replace
      />
    );
  }

  //return children;
  return <MainContainer> {children} </MainContainer>;
}

export default PrivateRoutes;
