import CalenderIconFilter from '@jibin/common/icons/CalenderIconFilter/CalenderIconFilter';

import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Skeleton,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  SwipeableDrawer,
  IconButton,
  Menu
} from '@mui/material';

import { Box } from '@mui/system';
import dayjs from 'dayjs';
import moment from 'moment';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import { RemediationApi } from '../Remediation/Remediation.Api';
import NetwrokDetectionSideBarEngineer from './NetworkDetectionSideBar/NetwrokDetectionSideBarEngineer';
import NetwrokDetectionSideBarUser from './NetworkDetectionSideBar/NetwrokDetectionSideBarUser';
import { NetwrokDetectionApi } from './NetwrokDetection.Api';
//@ts-ignore
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import NetwrokDetectionRow from './NetwrokDetectionRow';
import { DateRangeIcon } from '@mui/x-date-pickers';
import { UserRoles } from '@jibin/common/utils/constants/ConstantName';
import TablePagination from '../Common/TablePagination';
import { NoDataAvailable, NoSearchDataAvailable } from '../Common/NoDataAvailable';
import HeadingHeader from '../Common/HeadingHeader';
import { LoginContext } from '@jibin/common/Context/LoginContext';
type Anchor = 'top' | 'left' | 'bottom' | 'right';
const momentTimezone = require('moment-timezone');
const dateFormatRegex =
  /^(0[1-9]|[12][0-9]|3[01]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/;
export const NetwrokDetection: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [useParams, setUseParam] = useSearchParams();
  const [filterData, setFilterData] = useState({
    status: [''],
    severity: [''],
    'start-time': new Date(new Date().setDate(new Date().getDate() - 30)),
    'end-time': new Date()
  });
  const [rowData, setRowData] = useState({} as any);
  const toggleDrawer = (anchor: Anchor, open: boolean, el: any) => {
    setclick(!click);
    if (!open) {
      useParams.delete('device_id');
      setUseParam(useParams);
    } else {
      setParams(el.id);
    }
    setRowData(el);
    setState({ ...state, [anchor]: open });
  };
  const setParams = (index) => {
    const params = {
      device_id: index
    };
    setUseParam(params);
  };
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [invalidDateRange, setInvalidDateRange] = useState(false);
  const [assignees, setAssignees] = useState({} as any);
  const [initialRemediation, setInitialRemediation] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });
  const [serach, setSearch] = useState({
    'start-time': moment(new Date(new Date().setDate(new Date().getDate() - 30)))
      .startOf('day')
      .valueOf()
      .toString(),
    'end-time': moment(new Date()).endOf('day').valueOf().toString(),
    status: ''
  });
  const [state, setState] = useState({
    right: false
  });

  const menuItems = [
    {
      value: 'critical',
      lable: 'Critical'
    },
    {
      value: 'compliance',
      lable: 'Compliance'
    },
    {
      value: 'suspicious',
      lable: 'Suspicious'
    },
    {
      value: '',
      lable: 'All Status'
    }
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const { engineer } = useContext(LoginContext);
  const [endpoint, setEndPoints] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [click, setclick] = useState(false);
  const [isRemediation, setIsRemediation] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const location = useLocation();
  const GetAssignees = useCallback(async () => {
    setIsSkeleton(true);
    try {
      const res = await RemediationApi.GetAssignees(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );
      setAssignees(res.data);
      setTimeout(function () {
        setIsSkeleton(false);
      }, 500);
    } catch (err) {
      setIsSkeleton(false);
      console.log(err);
    }
  }, [commonDetails]);
  useEffect(() => {
    GetAssignees();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [serach]);

  const GetEventAll = useCallback(async () => {
    try {
      const startTime = parseInt(serach['start-time']);
      const endTime = parseInt(serach['end-time']);
      if (startTime > endTime) {
        setInvalidDateRange(true);
        return;
      } else {
        setInvalidDateRange(false);
      }
      const res = await NetwrokDetectionApi.GetAllEvent(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        new URLSearchParams({ ...serach, ...pagination }).toString()
      );
      setTotalCount(res?.data?.pagination?.total_records);
      if (res?.data?.data?.length) {
        setInitialRemediation(true);
      }
      if (searchParams.get('device_id')) {
        const filterdData = res.data.data.filter(
          (el, index) => el.id == searchParams.get('device_id')
        );

        setTimeout(() => {
          const element = document.getElementById(filterdData[0].device_id);
          if (element) {
            //element.scrollIntoView({ behavior: 'smooth'});
            var headerOffset = 90;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
            element.classList.add('flash');
            setTimeout(() => {
              element.classList.remove('flash');
            }, 1000);
          }
        }, 1);

        toggleDrawer('right', true, filterdData[0]);
        //setRowData(filterdData[0]);
      }
      setEndPoints(res.data.data);
    } catch (error) {}
  }, [commonDetails, serach, location.search, pagination.page_no, pagination.page_size]);

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!click) {
      GetEventAll();
    }
  }, [GetEventAll]);
  const handleSearch = (e) => {
    setSearch({ ...serach, [e.target.name]: e.target.value });
  };

  return (
    <Box>
      <HeadingHeader
        subtitle1={'Network Detection & Response (NDR)'}
        subtitle2={'Discover potential threats detected by the NDR'}
        commonDetails={commonDetails}
        UserRoles={UserRoles}
      />
      {isSkeleton ? (
        <Box sx={{ px: 4, py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : (
        <Box sx={{ px: 4, backgroundColor: '#FBFBFF', width: 1 }}>
          <Box sx={{ py: 3, display: 'flex', gap: 3 }}>
            <Box sx={{ width: '132px' }}>
              <label className={invalidDateRange ? 'invalid-date' : ''}>
                <DatePicker
                  showIcon
                  className="calendar-filter"
                  icon={<CalenderIconFilter />}
                  dateFormat="dd MMM yyyy"
                  placeholderText="DD MMM YYYY"
                  selected={filterData['start-time']}
                  onChange={(date: any, event: any) => {
                    setFilterData({
                      ...filterData,
                      'start-time': date
                    });
                    const isValidFormat = dateFormatRegex.test(event.target.value);
                    if (event.target.tagName != 'INPUT' || isValidFormat) {
                      setSearch({
                        ...serach,
                        'start-time': moment(date).startOf('day').valueOf().toString()
                      });
                    }
                  }}
                  onBlur={(event: any) => {
                    setTimeout(function () {
                      const isValidFormat = dateFormatRegex.test(event.target.value);
                      if (isValidFormat) {
                        setSearch({
                          ...serach,
                          'start-time': moment(event.target.value)
                            .startOf('day')
                            .valueOf()
                            .toString()
                        });
                      } else {
                        setInvalidDateRange(true);
                      }
                    }, 100);
                  }}
                />
              </label>
            </Box>

            <Box sx={{ width: '132px' }}>
              <label className={invalidDateRange ? 'invalid-date' : ''}>
                <DatePicker
                  showIcon
                  className="calendar-filter"
                  icon={<CalenderIconFilter />}
                  selected={filterData['end-time']}
                  dateFormat="dd MMM yyyy"
                  placeholderText="DD MMM YYYY"
                  onChange={(date: any, event: any) => {
                    setFilterData({
                      ...filterData,
                      'end-time': date
                    });
                    const isValidFormat = dateFormatRegex.test(event.target.value);
                    if (event.target.tagName != 'INPUT' || isValidFormat) {
                      setSearch({
                        ...serach,
                        'end-time': moment(date).endOf('day').valueOf().toString()
                      });
                    }
                  }}
                  onBlur={(event: any) => {
                    setTimeout(function () {
                      const isValidFormat = dateFormatRegex.test(event.target.value);
                      if (isValidFormat) {
                        setSearch({
                          ...serach,
                          'end-time': moment(event.target.value).endOf('day').valueOf().toString()
                        });
                      } else {
                        setInvalidDateRange(true);
                      }
                    }, 100);
                  }}
                />
              </label>
            </Box>
            <Select
              size="small"
              displayEmpty
              id="type"
              name="status"
              onChange={handleSearch}
              value={serach.status || ''}
              sx={{
                fontSize: '14px',
                color: '#696969',
                minWidth: { xxs: 0, md: '188px' },
                backgroundColor: '#FFFFFF'
              }}
              inputProps={{ 'aria-label': 'Without label' }}>
              {menuItems.map((el, index) => (
                <MenuItem key={index + 1} sx={{ fontSize: '14px' }} value={el.value}>
                  {el.lable}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {invalidDateRange && (
            <Typography variant="caption" sx={{ color: '#EF4444' }}>
              Invalid date range. Please select another date range.
            </Typography>
          )}
          {endpoint?.length == 0 || !endpoint ? (
            initialRemediation ? (
              <NoSearchDataAvailable
                title="No Search Results"
                message="Try adjusting your search or filter to find what you are looking for"
              />
            ) : (
              <NoDataAvailable
                title="No Data Available"
                message="The Network Detection page will be populated once if there is any NDR Data"
              />
            )
          ) : (
            <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}>
              <Table
                sx={{
                  minWidth: 650,
                  borderCollapse: 'separate',
                  backgroundColor: '#FBFBFF',
                  boxShadow: 'none',
                  borderSpacing: '0px 16px',
                  border: 'none'
                }}
                aria-label="site list table">
                <TableHead>
                  <TableRow sx={{ '.MuiTableCell-root': { border: 'none' } }}>
                    <TableCell>
                      <Typography variant="body2">Criticality</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">Received</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">Incident Description</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">Device</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2"> MAC Address</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2"> Actions</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {endpoint?.map((el: any, index: any) => (
                    <NetwrokDetectionRow
                      onClick={(e) => {
                        setIsRemediation(e);
                        toggleDrawer('right', true, el);
                      }}
                      engineer={engineer}
                      assignees={assignees}
                      el={el}
                    />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                totalCount={totalCount}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
              <SwipeableDrawer
                BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
                sx={{
                  '.MuiDrawer-paper': {
                    boxShadow: 'none'
                  }
                }}
                anchor={'right'}
                open={state['right']}
                onClose={() => toggleDrawer('right', false, {})}
                onOpen={() => toggleDrawer('right', true, rowData)}>
                {engineer ? (
                  <NetwrokDetectionSideBarEngineer
                    rowData={rowData}
                    assignees={assignees}
                    isCreateRemediation={isRemediation}
                    onClose={() => toggleDrawer('right', false, {})}
                  />
                ) : (
                  <NetwrokDetectionSideBarUser
                    rowData={rowData}
                    isCreateRemediation={isRemediation}
                    assignees={assignees}
                    onClose={() => toggleDrawer('right', false, {})}
                  />
                )}
              </SwipeableDrawer>
            </TableContainer>
          )}
        </Box>
      )}
    </Box>
  );
};
