import { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import { RiskManagementApi } from './RiskManagement.Api';
import { shallowEqual, useSelector } from 'react-redux';
import { cp } from 'fs/promises';
import RemidiationPopUpList from '@jibin/common/components/RemidiationPopUp/RemidiationPopupList';
import RiskPopUp from '@jibin/common/components/RiskPopUp';

import { CustomIcon } from '@jibin/common/icons/AssetsIcons/CustomIcon';
import DomainIcon from '@jibin/common/icons/AssetsIcons/Domain';
import IpIcon from '@jibin/common/icons/AssetsIcons/IpIcon';
import MacAddressIcon from '@jibin/common/icons/AssetsIcons/macAddressIcon';
import MachineIcon from '@jibin/common/icons/AssetsIcons/MachineIcon';
import { SourceNameFilter, SourceTypeEnums } from '@jibin/common/utils/enums/SourceTypeEnums';
import Style from '@jibin/common/style/Styles';
import SearchIcon from '@jibin/common/icons/Search/Search';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import FilterCancelIcon from '@jibin/common/icons/FilterCancelIcon';
import { useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import CreateRisk from './CreateRisk';
import { UserRoles } from '@jibin/common/utils/constants/ConstantName';
import { NoDataAvailable, NoSearchDataAvailable } from '../Common/NoDataAvailable';
import DropDown from '@jibin/common/icons/Dropdown/Dropdown';
import checkUseHasWhichUseHasPermission from '@jibin/common/hooks/checkUseHasWhichUseHasPermission';
import { DeleteTypesEnum } from '@jibin/common/utils/enums/deleteTypeEnums';
import ConformationModel from '@jibin/common/components/ConformationModel';
import SuccessConfirmation from '@jibin/common/components/ConformationModel/SuccessConfirmation';
import { GetAssignImage } from '@jibin/common/utils/Common';
import CheckBoxOutLine from '@jibin/common/icons/CheckBoxOutLine/CheckBoxOutLine';
import CheckBoxLightBlue from '@jibin/common/icons/CheckBoxLightBlue/CheckBoxLightBlue';
import TablePagination from '../Common/TablePagination';
import IconButtonComponent from './IconButton';
import CreateRemediation from '../Remediation/CreateRemediation';
import HeadingHeader from '../Common/HeadingHeader';
import { LoginContext } from '@jibin/common/Context/LoginContext';
import AcceptRiskDialog from './AcceptRiskDialog';
import DeleteDialog from './DeleteDialog';
import FilterDropdown from './FilterDropdown';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalenderIconFilter from '@jibin/common/icons/CalenderIconFilter/CalenderIconFilter';
import AddTags from './AddTags';
var debounce = require('lodash.debounce');

type Anchor = 'top' | 'left' | 'bottom' | 'right';
export const RiskManagement: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [selected, setSelected] = useState<any[]>([]);
  const [useParams, setUseParam] = useSearchParams();
  const [rowData, setRowData] = useState({} as any);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(true);
  const [assignees, setAssignees] = useState({});
  const [searchTag, setSearchTag] = useState('');
  const [click, setclick] = useState(false);
  const [initialRemediation, setInitialRemediation] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const [actionTakenValue, setActionTakenValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [assigeData, setAssigeData] = useState(null);
  const [option, setOption] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [isCreateRemediation, setIsCreateRemediation] = useState(false);
  const [isMultiCreateRemediation, setIsMultiCreateRemediation] = useState(false);
  const [isEditDD, setIsEditDD] = useState(false);
  const [openAcceptRisk, setOpenAcceptRisk] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });
  const [bulkSelectedRisks, setBulkSelectedRisks] = useState([] as any);
  const [servicesOption, setServicesOption] = useState([] as any);
  const [state, setState] = useState({
    right: false
  });
  const [stateTags, setStateTags] = useState({
    right: false
  });
  const [isBulkCreateTags, setIsBulkCreateTags] = useState(false);
  const whichUserHasPermissionForMultiSelect = checkUseHasWhichUseHasPermission([
    UserRoles.SERVICE_DELIVERY_COORDINATOR,
    UserRoles.SUPER_ADMIN,
    UserRoles.CYBER_SECURITY_ENGINEER,
    UserRoles.SERVICE_DELIVERY_MANAGER
  ]);
  const services_uuids = useParams.get('services_uuids');
  const [riskFilterform, setRiskFilterform] = useState({
    keyword: '',
    criticalities: '',
    assignee_uuids: '',
    statuses: 'Not Started,In Remediation,Risk Accepted,False Positive',
    services_uuids: services_uuids || '',
    tag_uuids: '',
    discovered_start_date: new Date(new Date().setDate(new Date().getDate() - 30)),
    discovered_end_date: new Date()
  });
  const [filterData, setFilterData] = useState({
    criticalities: [''],
    assignee_uuids: [''],
    statuses: ['Not Started', 'In Remediation', 'Risk Accepted', 'False Positive'],
    services_uuids: [services_uuids || ''],
    tag_uuids: [''],
    discovered_start_date: '',
    discovered_end_date: ''
  });
  const [initialFilter, setInitialFilter] = useState({
    statusesForm: 'Not Started,In Remediation,Risk Accepted,False Positive',
    statusesFilter: ['Not Started', 'In Remediation', 'Risk Accepted', 'False Positive']
  });
  const location = useLocation();
  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        fontSize: '14px',
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 180
      }
    }
  };
  const [anchorElNew, setAnchorElNew] = useState<null | HTMLElement>(null);
  const [assigneeList, setAssigneeList] = useState([] as any);
  const [isInitialFilter, setIsInitialFilter] = useState(true);
  const openNew = Boolean(anchorElNew);
  const handleClickNew = (event: any) => {
    setAnchorElNew(event.currentTarget);
  };
  const handleCloseNew = () => {
    setAnchorElNew(null);
  };
  const handleCloseAcceptRisk = () => {
    setOpenAcceptRisk(false);
    setRowData({});
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
    setRowData({});
  };
  const handleChangeRefresh = () => {
    setIsRefresh(!isRefresh);
  };
  const toggleDrawer = (anchor: Anchor, open: boolean, row: any, isSetParams?: boolean) => {
    setclick(!click);

    if (!open) {
      useParams.delete('risk_uuids');

      setUseParam(useParams);
      setIsCreateRemediation(false);
      setIsMultiCreateRemediation(false);
      setIsEditDD(false);
      setIsBulkCreateTags(false);
      setRowData({});
    } else {
      if (isSetParams) {
        useParams.set('risk_uuids', row?.risk_uuid);
        setUseParam(useParams);
      }
    }
    setRowData(row);
    setState({ ...state, [anchor]: open });
  };
  const toggleDrawerTags = (anchor: Anchor, open: boolean, row: any, isSetParams?: boolean) => {
    if (!open) {
      setIsBulkCreateTags(false);
    }
    setStateTags({ ...state, [anchor]: open });
  };
  useEffect(() => {
    setPage(0);
  }, [
    riskFilterform?.keyword,
    riskFilterform?.criticalities,
    riskFilterform?.services_uuids,
    riskFilterform?.statuses
  ]);

  const handleParamFilter = () => {
    setRiskFilterform({
      ...riskFilterform,
      keyword: useParams.get('keyword') ? useParams.get('keyword') : '',
      criticalities: useParams.get('criticalities') ? useParams.get('criticalities') : '',
      assignee_uuids: useParams.get('assignee_uuids') ? useParams.get('assignee_uuids') : '',
      statuses: useParams.get('statuses')
        ? useParams.get('statuses')
        : isInitialFilter
        ? initialFilter.statusesForm
        : riskFilterform?.statuses,
      services_uuids: services_uuids || riskFilterform?.services_uuids,
      tag_uuids: useParams.get('tag_uuids') ? useParams.get('tag_uuids') : ''
    });
    setFilterData({
      ...filterData,
      criticalities: useParams.get('criticalities')
        ? useParams.get('criticalities').split(',')
        : [''],
      assignee_uuids: useParams.get('assignee_uuids')
        ? useParams.get('assignee_uuids').split(',')
        : [''],
      statuses: useParams.get('statuses')
        ? useParams.get('statuses').split(',')
        : isInitialFilter
        ? initialFilter.statusesFilter
        : [riskFilterform?.statuses],
      services_uuids: useParams.get('services_uuids')
        ? useParams.get('services_uuids').split(',')
        : [services_uuids || riskFilterform?.services_uuids],
      tag_uuids: useParams.get('tag_uuids') ? useParams.get('tag_uuids').split(',') : ['']
    });
    setIsInitialFilter(false);
  };

  useEffect(() => {
    handleParamFilter();
  }, [useParams]);

  const [risks, setRisks] = useState({} as any);
  const [risksSummary, setRisksSummary] = useState([] as any);
  const [risksSummaryCount, setRisksSummaryCount] = useState(0);

  const GetRiskSummary = useCallback(async () => {
    try {
      setIsSkeleton(true);
      const res = await RiskManagementApi.GetRistSummary(
        commonDetails?.data?.user_uuid,
        commonDetails?.data?.company.company_uuid
      );
      setRisksSummary(res?.data?.data);
      const totalCountReduce = res?.data?.data.reduce((acc, issue) => acc + issue.count, 0);
      setRisksSummaryCount(totalCountReduce);
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  }, [commonDetails]);

  const GetRisks = useCallback(() => {
    let queryParamsObject;
    if (useParams.get('risk_expire') === 'expire-next-30-days') {
      queryParamsObject = {
        ...riskFilterform,
        ...pagination,
        risk_acceptance_expiring_in_ndays: 30,
        risk_uuids: useParams.get('risk_uuids') || '',
        discovered_start_date: riskFilterform.discovered_start_date
          ? moment(riskFilterform.discovered_start_date).format('YYYY-MM-DD')
          : '',
        discovered_end_date: riskFilterform.discovered_end_date
          ? moment(riskFilterform.discovered_end_date).format('YYYY-MM-DD')
          : ''
      };
    } else {
      queryParamsObject = {
        ...riskFilterform,
        ...pagination,
        risk_uuids: useParams.get('risk_uuids') || '',
        discovered_start_date: riskFilterform.discovered_start_date
          ? moment(riskFilterform.discovered_start_date).format('YYYY-MM-DD')
          : '',
        discovered_end_date: riskFilterform.discovered_end_date
          ? moment(riskFilterform.discovered_end_date).format('YYYY-MM-DD')
          : ''
      };
    }

    // Convert the query parameters object to a URL-encoded string
    const queryParams = new URLSearchParams(queryParamsObject).toString();

    setIsDataLoaded(true);
    RiskManagementApi.RiskList(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      queryParams
    ).then(
      (q: any) => {
        if (useParams.get('risk_uuids') && !click) {
          const filterdData = q.data.data.filter(
            (el) => el.risk_uuid == useParams.get('risk_uuids')
          );

          if (filterdData[0]?.risk_uuid) {
            setTimeout(() => {
              const element = document.getElementById(filterdData[0]?.risk_uuid);
              if (element) {
                var headerOffset = 90;
                var elementPosition = element.getBoundingClientRect().top;
                var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                window.scrollTo({
                  top: offsetPosition,
                  behavior: 'smooth'
                });
                element.classList.add('flash');
                setTimeout(() => {
                  element.classList.remove('flash');
                }, 1000);
              }
            }, 1);
            toggleDrawer('right', true, filterdData[0], true);
            setRowData(filterdData[0]);
          }
        }

        if (isSkeleton && q.data.data.length) {
          setInitialRemediation(true);
        }
        setRisks(q.data?.data);
        setTotalCount(q?.data?.pagination?.total_records);

        setIsSkeleton(false);
        setIsDataLoaded(false);
        setUseParam(useParams);
      },
      () => {
        setIsSkeleton(false);
        setIsDataLoaded(false);
      }
    );
  }, [
    commonDetails,
    riskFilterform,
    isRefresh,
    location.search,
    pagination.page_no,
    pagination.page_size
  ]);

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);

  const GetAssignees = useCallback(async () => {
    try {
      const res = await RiskManagementApi.GetAssignees(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );
      res?.data?.data.push({
        company_name: 'All Assignees',
        users: [{ name: 'All Assignees', user_uuid: '', first_name: 'All', last_name: 'Assignees' }]
      });
      setAssigneeList(res?.data?.data);
      renderOption(res.data);
    } catch (err) {
      console.log(err);
    }
  }, [commonDetails]);
  const riskSummaryBgcolor = (type) => {
    let color = '#436AF3';
    if (type === 'Not Started') {
      color = '#EF4444';
    } else if (type === 'In Remediation') {
      color = '#FDE68A';
    } else if (type === 'Risk Accepted') {
      color = '#D9E1FD';
    } else if (type === 'Resolved') {
      color = '#10B981';
    } else if (type === 'False Positive') {
      color = '#F9A866';
    }
    return color;
  };
  const GetServiceFilterOption = useCallback(async () => {
    try {
      const res = await RiskManagementApi.GetServicesList();
      const ProviderListForFilter = res.data.data.map((el) => {
        return {
          key: `${el.code}`,
          value: el.services_uuid,
          name: el.name,
          tooltip: el.name,
          tools: el.tools
        };
      });
      // ProviderListForFilter.push({
      //   key: 'All Services',
      //   value: ''
      // });
      setServicesOption(ProviderListForFilter);
    } catch (error) {}
  }, [commonDetails]);

  useEffect(() => {
    GetServiceFilterOption();
  }, [GetServiceFilterOption]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!click) {
        GetRisks();
        GetAssignees();
      }
    }, 700);
    return () => {
      clearTimeout(timer);
    };
  }, [GetRisks, GetAssignees, riskFilterform, isRefresh, click]);
  useEffect(() => {
    GetRiskSummary();
  }, [isRefresh, GetRiskSummary]);
  const renderColor = (criticalities: any) => {
    var color = '#F87171';
    if (criticalities == 'critical') {
      color = '#F87171';
    } else if (criticalities == 'high') {
      color = '#F59E0B';
    } else if (criticalities == 'medium') {
      color = '#FBBF24';
    } else if (criticalities == 'low') {
      color = '#436AF3';
    }
    return color;
  };
  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const handleChange = useCallback(
    debounce(
      (event: any) => {
        let {
          target: { value, name }
        } = event;
        const filterValue = value.filter((el: any) => el != '');
        setIsInitialFilter(false);
        let string;
        if (filterValue.length === 0) {
          string = '';
          const dataForApi = { ...riskFilterform };
          dataForApi[name] = string;
          setMultiPaleUseSeachParams(dataForApi);
        } else {
          string = filterValue.join(',');
          const dataForApi = { ...riskFilterform };

          dataForApi[name] = string;
          setMultiPaleUseSeachParams(dataForApi);
        }
      },
      100,
      true
    ),
    [riskFilterform, setRiskFilterform, filterData, setFilterData]
  );

  const setMultiPaleUseSeachParams = (params: any) => {
    for (const key in params) {
      useParams.set(key, params[key]);
      setUseParam(useParams);
    }
  };

  const renderColorBackGround = (criticalities: any = 'critical') => {
    var color = '#FFE6E2';
    if (criticalities == 'critical') {
      color = '#FFE6E2';
    } else if (criticalities == 'high') {
      color = '#FCD34D';
    } else if (criticalities == 'medium') {
      color = '#FEF3C7';
    } else if (criticalities == 'low') {
      color = '#D0DAFC';
    }
    return color;
  };
  const remediationStatusItems = [
    { key: 'Not Started', value: 'Not Started' },
    { key: 'False Positive', value: 'False Positive' },

    { key: 'In Remediation', value: 'In Remediation' },
    { key: 'Resolved', value: 'Resolved' },
    { key: 'Risk Accepted', value: 'Risk Accepted' }
    // { key: 'All Statuses', value: '' }
  ];

  const SeverityList = [
    { key: 'Critical', value: 'Critical' },
    { key: 'High', value: 'High' },
    { key: 'Medium', value: 'Medium' },
    { key: 'Low', value: 'Low' }
  ];

  const handleClearFuntion = () => {
    handleParamFilter();
    useParams.delete('risk_expire');
    deleteMultiPlalParams(riskFilterform);
    setRiskFilterform((prev) => ({
      ...prev,
      services_uuids: '',
      statuses: '',
      discovered_start_date: null,
      discovered_end_date: null
    }));
    setFilterData((prev) => ({
      ...prev,
      services_uuids: [services_uuids || ''],
      statuses: ['']
    }));
  };
  const deleteMultiPlalParams = (params) => {
    for (const key in params) {
      useParams.delete(key);
    }
    setUseParam(useParams);
  };

  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected?.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });

    return selectedLabels.join(', ');
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = risks?.map((n) => n.risk_uuid);
      setBulkSelectedRisks(risks);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
    setBulkSelectedRisks([]);
  };

  const { engineer } = useContext(LoginContext);

  const handleSearch = (e) => {
    setMultiPaleUseSeachParams({ ...riskFilterform, [e.target.name]: e.target.value });
    setRiskFilterform({ ...riskFilterform, [e.target.name]: e.target.value });
    setIsInitialFilter(false);
  };
  const isSelected = (name: string) => selected?.indexOf(name) !== -1;
  const isSelectedBulk = (name: string) => {
    const riskUUIDs = bulkSelectedRisks.map((risk) => risk?.risk_uuid);
    return riskUUIDs?.indexOf(name) !== -1;
  };
  const handleClickCheck = (event: React.MouseEvent<unknown>, row: any) => {
    event.preventDefault();
    const name = row?.risk_uuid;
    const selectedIndex = selected.indexOf(name);
    let newSelected: any[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    setBulkSelectedRisks((prev: any) => {
      const isSelectedObj = prev.find((r) => r?.risk_uuid === row?.risk_uuid);
      if (isSelectedObj) {
        return prev.filter((r) => r.risk_uuid !== row.risk_uuid);
      } else {
        return [...prev, row];
      }
    });
  };
  const renderOption = (assignees) => {
    const options = assignees.data.map((option) => {
      const firstLetter = option.company_name.toUpperCase();

      const data = option?.users.map((el: any) => {
        return { firstLetter: firstLetter, ...el, company_uuid: option?.company_uuid };
      });
      return data;
    });

    const optionarray = options.concat.apply([], options).flat();
    setOption(optionarray);
  };
  const handleRowClick = (e, row) => {
    const trElement = (e.target as HTMLElement).closest('tr');
    if (trElement) {
      toggleDrawer('right', true, row, true);
    }
  };
  const isTodayGreaterThanGivenDate = (dateString) => {
    const parsedGivenDate = moment(dateString).endOf('day');
    const isPast = parsedGivenDate.isBefore(moment().startOf('day'), 'day');
    return isPast;
  };
  const handleClickGetRowData = (row: any) => {
    setRowData(row);
  };
  return (
    <Box>
      <HeadingHeader
        subtitle1={'Risk Management'}
        subtitle2={
          'Identify, assess, prioritize, and manage potential risks for your digital assets'
        }
        commonDetails={commonDetails}
        UserRoles={UserRoles}
      />
      {isSkeleton ? (
        <Box sx={{ px: 4, py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : (
        <Box sx={{ px: 4, backgroundColor: '#FBFBFF', width: 1 }}>
          <Box sx={{ py: 3 }}>
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                Summary
              </Typography>
            </Box>
            <Box sx={{ my: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={4} md={3} lg={2}>
                  <Box
                    sx={{
                      border: '0.8px solid #CCCCCC',
                      bgcolor: '#ffffff',
                      borderRadius: 2,
                      p: 2
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          backgroundColor: riskSummaryBgcolor('Total Events'),
                          borderRadius: '50%'
                        }}></Box>
                      <Typography
                        variant="body2"
                        sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#464646' }}>
                        {' '}
                        {'Total Risks'}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ color: '#464646' }} variant="h1">
                        {risksSummaryCount}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {risksSummary?.map((event) => {
                  return (
                    <Grid item xs={4} md={3} lg={2}>
                      <Box
                        sx={{
                          border: '0.8px solid #CCCCCC',
                          bgcolor: '#ffffff',
                          borderRadius: 2,
                          p: 2
                        }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Box
                            sx={{
                              width: '24px',
                              height: '24px !important',
                              backgroundColor: riskSummaryBgcolor(event?.type),
                              borderRadius: '50%'
                            }}></Box>
                          <EllipsisContent
                            text={event?.title}
                            variant="body2"
                            sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#464646' }}
                          />
                        </Box>
                        <Box>
                          <Typography sx={{ color: '#464646' }} variant="h1">
                            {event?.count}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              py: 3,
              alignItems: 'flex-start',
              justifyContent: 'space-between'
            }}>
            <Box sx={{ display: 'flex', gap: 3, flexWrap: 'nowrap', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', gap: 3 }}>
                <DatePicker
                  className="calendar-filter"
                  showIcon
                  icon={<CalenderIconFilter />}
                  dateFormat="dd MMM yyyy"
                  selectsStart
                  start_date={riskFilterform.discovered_start_date}
                  end_date={riskFilterform.discovered_end_date}
                  selected={riskFilterform.discovered_start_date}
                  onChange={(date) => {
                    setRiskFilterform((prev) => ({
                      ...prev,
                      discovered_start_date: date
                    }));
                    const event = {
                      target: {
                        value: date,
                        name: 'discovered_start_date'
                      }
                    };
                    handleSearch(event);
                  }}
                  name="discovered_start_date"
                  placeholderText="Start Date"
                  style={{
                    input: {
                      margin: '7px 10px 6px 25px !important'
                    }
                  }}
                />
                <DatePicker
                  className="calendar-filter"
                  showIcon
                  icon={<CalenderIconFilter />}
                  dateFormat="dd MMM yyyy"
                  placeholderText="End Date"
                  selected={riskFilterform.discovered_end_date}
                  onChange={(date) => {
                    setRiskFilterform((prev) => ({
                      ...prev,
                      discovered_end_date: date
                    }));
                    const event = {
                      target: {
                        value: date,
                        name: 'discovered_end_date'
                      }
                    };
                    handleSearch(event);
                  }}
                  name="discovered_end_date"
                  selectsEnd
                  start_date={riskFilterform.discovered_start_date}
                  end_date={riskFilterform.discovered_end_date}
                  minDate={riskFilterform.discovered_start_date}
                />
              </Box>

              <TextField
                placeholder="Search by Risk Name or Tag"
                hiddenLabel
                size="small"
                name="keyword"
                value={riskFilterform.keyword}
                onChange={handleSearch}
                sx={{
                  width: '270px',
                  input: { backgroundColor: '#FFFFFF' },
                  '.MuiOutlinedInput-root': {
                    bgcolor: '#FFFFFF'
                  }
                }}
                inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment sx={{ bgcolor: '#FFFFFF' }} position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />

              <FilterDropdown
                setRiskFilterform={setRiskFilterform}
                riskFilterform={riskFilterform}
                servicesOption={servicesOption}
                remediationStatusItems={remediationStatusItems}
                SeverityList={SeverityList}
                handleChange={handleChange}
              />

              <Box sx={{ width: 120 }}>
                <Button
                  onClick={handleClearFuntion}
                  sx={{
                    display: 'flex',
                    gap: 0.5,
                    alignItems: 'center',
                    py: 0.5,
                    whiteSpace: 'nowrap'
                  }}
                  variant="text">
                  {' '}
                  <FilterCancelIcon /> Clear filter
                </Button>
              </Box>
            </Box>

            <Box sx={{ display: (selected?.length || bulkSelectedRisks?.length) <= 0 && 'none' }}>
              {' '}
              <Button
                id="basic-button"
                aria-controls={openNew ? 'basic-menu' : undefined}
                aria-haspopup="true"
                sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}
                variant="contained"
                aria-expanded={openNew ? 'true' : undefined}
                onClick={handleClickNew}>
                Select Action
                <Box sx={{ display: 'flex' }}>
                  <DropDown />
                </Box>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorElNew}
                open={openNew}
                onClose={handleCloseNew}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}>
                {/* <MenuItem
                  onClick={() => {
                    document.getElementById('createRiskPopup').click();
                    setAnchorElNew(null);
                  }}>
                  <Typography sx={{ ml: 1 }} variant="caption">
                    {' '}
                    Create a Risk
                  </Typography>
                </MenuItem> */}
                <MenuItem
                  onClick={() => {
                    setIsCreateRemediation(true);
                    setIsMultiCreateRemediation(true);
                    toggleDrawer('right', true, {});
                    setAnchorElNew(null);
                  }}>
                  <Typography sx={{ ml: 1 }} variant="caption">
                    {' '}
                    Create Remediation
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenAcceptRisk(true);
                    setAnchorElNew(null);
                  }}>
                  <Typography sx={{ ml: 1 }} variant="caption">
                    {' '}
                    Accept Risk
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsBulkCreateTags(true);
                    toggleDrawerTags('right', true, {});
                    setAnchorElNew(null);
                  }}>
                  <Typography sx={{ ml: 1 }} variant="caption">
                    {' '}
                    Add Tags
                  </Typography>
                </MenuItem>
                {whichUserHasPermissionForMultiSelect && (
                  <MenuItem
                    onClick={() => {
                      setOpenDelete(true);
                      setAnchorElNew(null);
                    }}>
                    <Typography sx={{ ml: 1 }} variant="caption">
                      {' '}
                      Delete Risk
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            </Box>

            <Box sx={{ display: (selected?.length || bulkSelectedRisks?.length) > 0 && 'none' }}>
              <CreateRisk
                triggerId="createRiskPopup"
                text={'Create Risk'}
                servicesOption={servicesOption}
                engineer={engineer}
                GetRisks={() => GetRisks()}
                GetRiskSummary={() => GetRiskSummary()}
                // GetTagsForList={() => GetTags(searchTag)}
              />
            </Box>
          </Box>
          {risks?.length == 0 || !risks ? (
            initialRemediation ? (
              <NoSearchDataAvailable
                title="No Search Results"
                message="Try adjusting your search or filter to find what you are looking for"
              />
            ) : (
              <NoDataAvailable
                title="No Data Available"
                message="The Risks page will be populated once the first risk is discovered."
              />
            )
          ) : (
            <>
              <TableContainer
                component={Paper}
                sx={{ border: 'none', boxShadow: 'none', bgcolor: 'transparent' }}>
                <Table
                  sx={{
                    minWidth: 650,
                    borderCollapse: 'separate',
                    boxShadow: 'none',
                    borderSpacing: '0px 16px',
                    border: 'none'
                  }}
                  className="p10Table"
                  aria-label="site list table">
                  <TableHead>
                    <TableRow sx={{ '.MuiTableCell-root': { border: 'none' } }}>
                      {/* {whichUserHasPermissionForMultiSelect && ( */}
                      <TableCell sx={{ px: '20px' }}>
                        <Checkbox
                          disabled={isDataLoaded}
                          indeterminate={selected?.length > 0 && selected?.length < risks?.length}
                          indeterminateIcon={
                            selected?.length > 0 &&
                            selected?.length < risks?.length && (
                              <CheckBoxOutLine
                                disabled={isDataLoaded}
                                sx={{
                                  fill: 'none',
                                  width: '21px',
                                  height: '20px'
                                }}
                              />
                            )
                          }
                          checked={risks?.length > 0 && selected?.length === risks?.length}
                          onChange={handleSelectAllClick}
                          checkedIcon={<CheckBoxLightBlue />}
                          icon={
                            <CheckBoxOutLine
                              sx={{
                                fill: 'none',
                                width: '21px',
                                height: '20px'
                              }}
                            />
                          }
                        />
                      </TableCell>
                      {/* )} */}

                      <TableCell>
                        <Typography variant="body2">Criticality</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Domain</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Tool</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Risk Name</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Tags</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Asset</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Risk Status</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2"></Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {risks?.length > 0 ? (
                      risks?.map((row, index) => {
                        const isItemSelected = isSelected(row?.risk_uuid);
                        const isItemSelectedBulk = isSelectedBulk(row?.risk_uuid);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const tagNames = row?.tags?.map((tag) => tag?.tag_name);
                        return (
                          <>
                            <TableRow
                              id={row?.risk_uuid}
                              key={index}
                              onClick={(e) => handleRowClick(e, row)}
                              sx={{
                                cursor: 'pointer',

                                bgcolor: '#fff',
                                '.MuiTableCell-root': {
                                  px: '20px',

                                  borderBottom: '1px solid  #CCCCCC',
                                  borderTopRadius: '4px',
                                  borderTop: '1px solid  #CCCCCC',
                                  '&:last-child': {
                                    borderRight: '1px solid  #CCCCCC',
                                    borderTopRightRadius: '4px',
                                    borderBottomRightRadius: '4px'
                                  },
                                  '&:first-child': {
                                    borderLeft: '1px solid  #CCCCCC',
                                    borderTopLeftRadius: '4px',
                                    borderBottomLeftRadius: '4px'
                                  }
                                }
                              }}>
                              {/* {whichUserHasPermissionForMultiSelect && ( */}
                              <TableCell
                                key={row.name}
                                onClick={(event) => {
                                  if (!isDataLoaded) {
                                    handleClickCheck(event, row);
                                    stopImmediatePropagation(event);
                                  }
                                }}
                                role="checkbox"
                                aria-checked={isItemSelected || isItemSelectedBulk}
                                tabIndex={-1}
                                padding="checkbox">
                                <Checkbox
                                  disabled={isDataLoaded}
                                  checked={isItemSelected || isItemSelectedBulk}
                                  checkedIcon={<CheckBoxLightBlue />}
                                  inputProps={{
                                    'aria-labelledby': labelId
                                  }}
                                  icon={
                                    <CheckBoxOutLine
                                      sx={{
                                        fill: 'none',
                                        width: '21px',
                                        height: '20px'
                                      }}
                                    />
                                  }
                                />
                              </TableCell>
                              {/* )} */}

                              <TableCell component="th" scope="row">
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box
                                      sx={{
                                        backgroundColor: renderColorBackGround(
                                          row.criticality.toLowerCase()
                                        ),
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        justifyContent: 'center',
                                        borderRadius: '100px',
                                        py: 0.5,
                                        width: 100,
                                        px: 2
                                      }}>
                                      <FlagIcon
                                        sx={{
                                          backgroundColor: renderColorBackGround(
                                            row.criticality.toLowerCase()
                                          ),
                                          color: renderColor(row.criticality.toLowerCase()),
                                          width: '12px'
                                        }}
                                      />
                                      <Typography
                                        variant="caption"
                                        sx={{ textTransform: 'capitalize' }}>
                                        {' '}
                                        {row.criticality}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="left">{row?.service?.code}</TableCell>
                              <TableCell align="left">{row?.tool?.name}</TableCell>
                              <TableCell sx={{ maxWidth: 250 }} align="left">
                                <EllipsisContent
                                  variant="caption"
                                  maxWidth={200}
                                  text={row?.name}
                                />{' '}
                              </TableCell>
                              <TableCell align="left">
                                <>
                                  {tagNames && (
                                    <Tooltip arrow placement="top" title={tagNames || ''}>
                                      <Chip
                                        variant="outlined"
                                        label={tagNames ? tagNames[0] : ''}
                                        sx={{
                                          fontSize: '12px',
                                          backgroundColor: '#D9EDFF',
                                          mr: '6px',
                                          border: 0
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                  {tagNames?.length > 1 && (
                                    <span style={{ color: '#436AF3', fontSize: '16px' }}>
                                      +{tagNames?.length - 1}
                                    </span>
                                  )}
                                </>
                              </TableCell>
                              <TableCell sx={{ maxWidth: 250 }} align="left">
                                <Box>
                                  <Box sx={{ display: 'flex' }}>
                                    {row?.assets?.custom && (
                                      <Tooltip arrow placement={'top'} title={row?.assets?.custom}>
                                        <IconButton>
                                          <CustomIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                    {row?.assets?.ip && (
                                      <Tooltip arrow placement={'top'} title={row?.assets?.ip}>
                                        <IconButton>
                                          <IpIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                    {row?.assets?.mac && (
                                      <Tooltip arrow placement={'top'} title={row?.assets?.mac}>
                                        <IconButton>
                                          <MacAddressIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                    {row?.assets?.machine && (
                                      <Tooltip arrow placement={'top'} title={row?.assets?.machine}>
                                        <IconButton>
                                          <MachineIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                    {row?.assets?.hostnames?.length > 0 && (
                                      <Tooltip
                                        arrow
                                        placement={'top'}
                                        title={row?.assets?.hostnames?.join(',')}>
                                        <IconButton>
                                          <DomainIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </Box>
                                </Box>
                              </TableCell>

                              <TableCell align="left">
                                <Typography variant="caption">
                                  {' '}
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box
                                      sx={{
                                        minWidth: '14px',
                                        height: '14px',
                                        width: '14px',
                                        backgroundColor: riskSummaryBgcolor(row?.status),
                                        borderRadius: '50%'
                                      }}></Box>
                                    <Typography variant="caption">{row?.status}</Typography>
                                  </Box>
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ maxWidth: 250 }} align="left">
                                <IconButtonComponent
                                  row={row}
                                  handleChangeRefresh={handleChangeRefresh}
                                  isTodayGreaterThanGivenDate={isTodayGreaterThanGivenDate}
                                  handleOpen={() => {
                                    setIsCreateRemediation(true);
                                    toggleDrawer('right', true, rowData, true);
                                  }}
                                  handleOpenEdit={() => {
                                    setIsEditDD(true);
                                    toggleDrawer('right', true, rowData, true);
                                  }}
                                  setOpenAcceptRisk={setOpenAcceptRisk}
                                  handleClickGetRowData={handleClickGetRowData}
                                  setRowData={setRowData}
                                  setOpenDelete={setOpenDelete}
                                />
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })
                    ) : (
                      <>
                        <TableRow
                          sx={{
                            cursor: 'pointer',
                            '.MuiTableCell-root': {
                              backgroundColor: '#FFFFFF ',
                              px: '20px',
                              py: '30px',

                              borderBottom: '1px solid  #CCCCCC',
                              borderTopRadius: '4px',
                              borderTop: '1px solid  #CCCCCC',
                              '&:last-child': {
                                borderRight: '1px solid  #CCCCCC',
                                borderTopRightRadius: '4px',
                                borderBottomRightRadius: '4px'
                              },
                              '&:first-child': {
                                borderLeft: '1px solid  #CCCCCC',
                                borderTopLeftRadius: '4px',
                                borderBottomLeftRadius: '4px'
                              }
                            }
                          }}>
                          <TableCell align="left">
                            <Typography variant="caption">
                              {' '}
                              <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="caption">
                              {' '}
                              <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="caption">
                              <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="caption">
                              <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="caption">
                              <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  totalCount={totalCount}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                />
                <SwipeableDrawer
                  BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
                  sx={{
                    '.MuiDrawer-paper': {
                      boxShadow: 'none'
                    }
                  }}
                  anchor={'right'}
                  open={state['right']}
                  onClose={() => toggleDrawer('right', false, {})}
                  onOpen={() => toggleDrawer('right', true, rowData)}>
                  {!isSkeleton && (
                    <RiskPopUp
                      el={rowData}
                      isCreateRemediation={isCreateRemediation}
                      isMultiCreateRemediation={isMultiCreateRemediation}
                      isEditDD={isEditDD}
                      setIsEditDD={setIsEditDD}
                      isGap={false}
                      risk_uuid={rowData?.risk_uuid}
                      closePopup={() => toggleDrawer('right', false, {})}
                      isEngineer={engineer}
                      setIsRefresh={setIsRefresh}
                      isRefresh={isRefresh}
                      servicesOption={servicesOption}
                      selectedRiskList={selected}
                      risksList={risks}
                      bulkSelectedRisks={bulkSelectedRisks}
                      setBulkSelectedRisks={setBulkSelectedRisks}
                    />
                  )}
                </SwipeableDrawer>
                <SwipeableDrawer
                  BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
                  sx={{
                    '.MuiDrawer-paper': {
                      boxShadow: 'none'
                    }
                  }}
                  anchor={'right'}
                  open={stateTags['right']}
                  onClose={() => toggleDrawerTags('right', false, {})}
                  onOpen={() => toggleDrawerTags('right', true, rowData)}>
                  {!isSkeleton && (
                    <AddTags
                      isBulkCreateTags={isBulkCreateTags}
                      closePopup={() => toggleDrawerTags('right', false, {})}
                      bulkSelectedRisks={bulkSelectedRisks}
                      setBulkSelectedRisks={setBulkSelectedRisks}
                      setSelected={setSelected}
                      GetRisks={GetRisks}
                    />
                  )}
                </SwipeableDrawer>
              </TableContainer>
              <AcceptRiskDialog
                openAcceptRisk={openAcceptRisk}
                handleCloseAcceptRisk={handleCloseAcceptRisk}
                risk_uuid={rowData?.risk_uuid}
                commonDetails={commonDetails}
                GetRisks={GetRisks}
                selectedRiskList={selected}
                risksList={risks}
                rowData={rowData}
                GetRiskSummary={GetRiskSummary}
                setRowData={setRowData}
                bulkSelectedRisks={bulkSelectedRisks}
                setBulkSelectedRisks={setBulkSelectedRisks}
              />
              <DeleteDialog
                openDelete={openDelete}
                handleCloseDelete={handleCloseDelete}
                risk_uuid={rowData?.risk_uuid}
                commonDetails={commonDetails}
                GetRisks={GetRisks}
                GetRiskSummary={GetRiskSummary}
                selectedRiskList={selected}
                setRowData={setRowData}
                bulkSelectedRisks={bulkSelectedRisks}
                setBulkSelectedRisks={setBulkSelectedRisks}
              />
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
