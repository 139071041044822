import HttpService from '@jibin/common/services/http/HttpService';

export const AssetManagementApi = {
  GetAssetFiltersData(companyId: string, userId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/assets/filters`);
  },
  GetAssetsSummary(companyId: string, userId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/assets-summary`);
  },
  AssetList(companyId: string, userId: string, params: any) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/assets?` + params);
  },
  RemoveAsset(companyId: string, userId: string, assetId: string) {
    return HttpService.removeBulk(
      `companies/${companyId}/users/${userId}/assets?asset_uuids=` + assetId
    );
  },
  CreateRemediation(companyId: string, userId: string, data: any) {
    return HttpService.post(`companies/${companyId}/users/${userId}/assets/remediation`, data);
  }
};
