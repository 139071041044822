export const CompanyManagementIcon = () => {
  return (
    <svg width="24" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.58318 14.9998H3.45819C2.26652 14.9998 1.6665 14.3998 1.6665 13.2081V3.45819C1.6665 2.26652 2.26652 1.6665 3.45819 1.6665H7.04151C8.23318 1.6665 8.83315 2.26652 8.83315 3.45819V4.99984"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4751 7.01665V16.3167C14.4751 17.6583 13.8085 18.3333 12.4668 18.3333H7.60015C6.25848 18.3333 5.5835 17.6583 5.5835 16.3167V7.01665C5.5835 5.67499 6.25848 5 7.60015 5H12.4668C13.8085 5 14.4751 5.67499 14.4751 7.01665Z"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1665 4.99984V3.45819C11.1665 2.26652 11.7665 1.6665 12.9581 1.6665H16.5415C17.7331 1.6665 18.3332 2.26652 18.3332 3.45819V13.2081C18.3332 14.3998 17.7331 14.9998 16.5415 14.9998H14.4748"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3335 9.1665H11.6668"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3335 11.6665H11.6668"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 18.3335V15.8335"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const CompanyManagementIconBlue = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.58318 14.9998H3.45819C2.26652 14.9998 1.6665 14.3998 1.6665 13.2081V3.45819C1.6665 2.26652 2.26652 1.6665 3.45819 1.6665H7.04151C8.23318 1.6665 8.83315 2.26652 8.83315 3.45819V4.99984"
        stroke="#436AF3"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4751 7.01665V16.3167C14.4751 17.6583 13.8085 18.3333 12.4668 18.3333H7.60015C6.25848 18.3333 5.5835 17.6583 5.5835 16.3167V7.01665C5.5835 5.67499 6.25848 5 7.60015 5H12.4668C13.8085 5 14.4751 5.67499 14.4751 7.01665Z"
        stroke="#436AF3"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1665 4.99984V3.45819C11.1665 2.26652 11.7665 1.6665 12.9581 1.6665H16.5415C17.7331 1.6665 18.3332 2.26652 18.3332 3.45819V13.2081C18.3332 14.3998 17.7331 14.9998 16.5415 14.9998H14.4748"
        stroke="#436AF3"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3335 9.1665H11.6668"
        stroke="#436AF3"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3335 11.6665H11.6668"
        stroke="#436AF3"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 18.3335V15.8335"
        stroke="#436AF3"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
