import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React, { FC } from 'react';

import DeleteIcon from '@jibin/common/icons/Delete/Delete';
import { Divider, MenuItem, Switch } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import CloseIcon from '@jibin/common/icons/Close/Close';

export interface ConfirmationBoxWarning {
    description?: string;
}

export const ConfirmationBoxWarning: FC<ConfirmationBoxWarning> = (props) => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const commonDetails = useSelector(
        ({ commonDetails }: any) => commonDetails.commonDetails?.data,
        shallowEqual
    );
    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e: any) => {
        handleClickOpen();
    };

    return (
        <>
            <Box>
                <Button onClick={handleClickOpen} variant="text">
                    Details
                </Button>
            </Box>
            <Dialog
                fullWidth
                maxWidth="xs"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        px: { xs: 1, md: 2 },
                        py: { xs: 0.5, md: 1 },
                        bgcolor: 'error.red100'
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: 'error.main',
                                fontWeight: '600',
                                maxWidth: 'calc(100% - 40px)',
                                p: 1
                            }}>
                            <WarningIcon sx={{ mr: 1 }} />
                            Warning
                        </Typography>
                        <IconButton sx={{ color: 'text.primary' }} aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
                    <DialogContentText id="alert-dialog-description" sx={{ color: 'text.primary' }}>
                        {props.description}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ConfirmationBoxWarning;
