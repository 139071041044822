import { useState, useRef, useEffect, useCallback } from 'react';
import {
  Box,
  List,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Checkbox,
  Button,
  Autocomplete,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material';
import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import { useFormik } from 'formik';
import SearchIcon from '@jibin/common/icons/Search/Search';
import CheckBoxOutLine from '@jibin/common/icons/CheckBoxOutLine/CheckBoxOutLine';
import CheckBoxLightBlue from '@jibin/common/icons/CheckBoxLightBlue/CheckBoxLightBlue';
import CloseIcon from '@jibin/common/icons/Close/Close';
import { RiskManagementApi } from 'src/Pages/RiskManagement/RiskManagement.Api';
import { RemediationApi } from '../Remediation/Remediation.Api';
import { shallowEqual, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';

const AddTags = (prop) => {
  const {
    isBulkCreateTags,
    closePopup,
    bulkSelectedRisks,
    setBulkSelectedRisks,
    setSelected,
    GetRisks
  } = prop;

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [isComplete, setIsComplete] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [searchAssets, setSearchAssets] = useState([]);
  const [selectedUUID, setSelectedUUID] = useState([]);
  const [associatedAssets, setAssociatedAssets] = useState([]);
  const [isBoxVisible, setIsBoxVisible] = useState(true);
  const [tagOptions, setTagOptions] = useState([]);
  const [deleteOption, setDeleteOption] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [tagErrorMessage, setTagErrorMessage] = useState({ maxLimit: '', tagLimit: '' });
  const [isRemediationButtonLoading, setIsRemediationButtonLoading] = useState(false);

  const box1Ref = useRef(null);
  const box2Ref = useRef(null);
  const box3Ref = useRef(null);
  const handleClickOutside = (event) => {
    if (
      box1Ref.current &&
      !box1Ref.current.contains(event.target) &&
      box2Ref.current &&
      !box2Ref.current.contains(event.target) &&
      box3Ref.current &&
      !box3Ref.current.contains(event.target)
    ) {
      setIsBoxVisible(false);
    }
  };
  const isSelected = (name: string) => selectedUUID?.indexOf(name) !== -1;
  const handleClickCheck = (event: React.MouseEvent<unknown>, name: string) => {
    event.preventDefault();
    const selectedIndex = selectedUUID.indexOf(name);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUUID, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUUID.slice(1));
    } else if (selectedIndex === selectedUUID.length - 1) {
      newSelected = newSelected.concat(selectedUUID.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUUID.slice(0, selectedIndex),
        selectedUUID.slice(selectedIndex + 1)
      );
    }
    setSelectedUUID(newSelected);
  };
  const renderColorBackGround = (criticality: any = 'Medium') => {
    var color = '#FFE6E2';
    if (criticality == 'Critical') {
      color = '#FFE6E2';
    } else if (criticality == 'High') {
      color = '#FCD34D';
    } else if (criticality == 'Medium') {
      color = '#FEF3C7';
    } else if (criticality == 'Low') {
      color = '#D0DAFC';
    }
    return color;
  };
  const assetColorBackGround = (status: string) => {
    let color = '';
    if (status === 'Resolved') {
      color = '#97e9d2';
    } else if (status === 'Not Started') {
      color = '#FFE6E2';
    } else if (status === 'Risk Accepted') {
      color = '#e5e5e7';
    } else if (status === 'In Remediation') {
      color = '#FEF3C7';
    }
    return color;
  };
  const assetColorIcon = (status: string) => {
    let color = '';
    if (status === 'Resolved') {
      color = '#10B981';
    } else if (status === 'Not Started') {
      color = '#F73939';
    } else if (status === 'Risk Accepted') {
      color = '#D9E1FD';
    } else if (status === 'In Remediation') {
      color = '#FBBF24';
    }
    return color;
  };
  const bgStatusColor = (status: string) => {
    let color = '';
    if (status === 'Resolved') {
      color = '#10B981';
    } else if (status === 'Not Started') {
      color = '#F87171';
    } else if (status === 'Risk Accepted') {
      color = '#D9E1FD';
    } else if (status === 'In Remediation') {
      color = '#FBBF24';
    }
    return color;
  };

  const validate = (value: any) => {
    if (!value.tags) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      tags: [],
      criticality: 'Medium'
    },
    validate,
    onSubmit: async (event: any) => {
      try {
        setIsRemediationButtonLoading(true);

        const associatedAssetUUIDs = associatedAssets.map((risk) => risk?.risk_uuid);

        let data = {
          risk_uuids: associatedAssetUUIDs,
          action: {
            action_type: 'assign_tag',
            action_data: {
              tags: []
            }
          }
        };
        data.action.action_data.tags = facilities.map((facility) => {
          const { tag_uuid, tag_name } = facility;
          let tagObject: any = {
            tag_name,
            company_uuid: commonDetails.data.company.company_uuid
          };
          if (tag_uuid) {
            tagObject.tag_uuid = tag_uuid;
          }
          return tagObject;
        });

        await RiskManagementApi.BulkAction(
          commonDetails.data.user_uuid,
          commonDetails.data.company.company_uuid,
          data
        );

        setBulkSelectedRisks([]);
        setSelected([]);
        GetRisks();
        setIsRemediationButtonLoading(false);
        closePopup(event);
      } catch (err) {
        console.log(err);
      }
    }
  });

  const addRisks = () => {
    const filteredData = searchAssets
      ?.filter((item) => selectedUUID.includes(item?.risk_uuid))
      ?.slice(0, 10);
    setAssociatedAssets([...associatedAssets, ...filteredData]);
    GetSearchRisk(selectedUUID);
  };

  const removeRisk = (assetUUIDToRemove) => {
    const updatedAssetsList = associatedAssets.filter(
      (asset) => asset.risk_uuid !== assetUUIDToRemove
    );
    const updatedAssetUUIDs = selectedUUID.filter((uuid) => uuid !== assetUUIDToRemove);
    setSelectedUUID(updatedAssetUUIDs);
    setAssociatedAssets(updatedAssetsList);
    GetSearchRisk(updatedAssetUUIDs);
  };

  const GetSearchRisk = useCallback(
    async (selectedUUID?: any) => {
      try {
        const queryParams = new URLSearchParams({
          keyword: searchText
        }).toString();
        const res = await RiskManagementApi.RiskList(
          commonDetails.data.user_uuid,
          commonDetails.data.company.company_uuid,
          queryParams
        );
        const filteredData = res?.data?.data?.filter(
          (item) => !selectedUUID.includes(item?.risk_uuid)
        );
        setSearchAssets(filteredData);
      } catch (error) {}
    },
    [commonDetails, searchText]
  );

  const GetTags = useCallback(async () => {
    const query: any = {
      include_non_associated_tags: true
    };
    try {
      const res = await RiskManagementApi.GetTagsList(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        new URLSearchParams(query).toString()
      );
      if (res?.data?.data) {
        const TagList = res.data.data.map((el) => el);
        setTagOptions(TagList);
      }
    } catch (error) {}
  }, [commonDetails]);

  const handleClickOpenDelete = (option, value) => {
    setDeleteOption(option);

    formik.setFieldValue('tags', value);
    setOpenDelete(true);
  };

  const validateString = (inputString) => {
    if (!/^[a-zA-Z_\s]+$/.test(inputString)) {
      return false;
    }
    if (/^\d+$/.test(inputString)) {
      return false;
    }
    return true;
  };

  const maketags = (data: any) => {
    const facility = [];

    data.forEach((element) => {
      let facilitytype = tagOptions.filter((q) => q.tag_name == element);
      if (facilitytype?.length > 0) {
        facility.push({ tag_name: element, tag_uuid: facilitytype[0].tag_uuid });
      } else {
        facility.push({ tag_name: element });
      }
    });
    setFacilities([...facility]);
  };

  const handleTagChange = (event, value) => {
    var newArray = value.filter(function (elem, pos) {
      return value.indexOf(elem) == pos;
    });
    if (tagErrorMessage.maxLimit) {
      setTagErrorMessage({
        ...tagErrorMessage,
        maxLimit: ''
      });
      return;
    }
    formik.setFieldValue('tags', newArray);
    maketags(newArray);
  };

  const handleConfirmDelete = (deleteFacility) => {
    const updatedFacility = formik.values.tags.filter((el, index) => el !== deleteFacility);
    formik.setFieldValue('tags', updatedFacility);

    maketags(updatedFacility);
    setOpenDelete(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  useEffect(() => {
    if (searchText) {
      GetSearchRisk(selectedUUID);
    } else {
      setSearchAssets([]);
    }
  }, [GetSearchRisk, searchText]);

  useEffect(() => {
    if (bulkSelectedRisks?.length) {
      const filteredIds = bulkSelectedRisks.map((risk) => risk?.risk_uuid);
      setSelectedUUID(filteredIds);
      setAssociatedAssets(bulkSelectedRisks);
    }
  }, [bulkSelectedRisks]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isBulkCreateTags) {
      GetTags();
    }
  }, [isBulkCreateTags]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          width: { md: 520, xs: 300 },
          height: '100vh',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}
        role="presentation">
        <List sx={{ p: 0 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: 1,
              justifyContent: 'space-between',
              backgroundColor: '#003260',
              px: 2.5,
              alignItems: 'center',
              py: 1
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',

                width: 1,
                alignItems: 'center'
              }}>
              <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', my: 1.25 }}>
                Add Tags
              </Typography>
              <Box
                onClick={(event) => {
                  closePopup(event);
                }}>
                <CrossCancle
                  sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                />
              </Box>
            </Box>
          </Box>
        </List>

        <Box sx={{ flex: 1 }}>
          <Box sx={{ p: 3, pt: 0 }}>
            <Box sx={{ width: '100%' }}>
              <List sx={{ py: 1.5 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                  <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600 }}>
                    Criticality Level:
                  </Typography>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      value={formik?.values?.criticality || 'Medium'}
                      name="criticality"
                      defaultValue={formik?.values?.criticality}
                      onChange={formik.handleChange}
                      displayEmpty
                      sx={{
                        '.MuiSelect-select': {
                          py: 1,
                          display: 'flex',
                          alignItems: 'center'
                        },
                        '&.MuiInputBase-root': {
                          backgroundColor: renderColorBackGround(formik.values.criticality)
                        },
                        borderRadius: '100px'
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}>
                      <MenuItem sx={{ backgroundColor: '#FFE6E2' }} value="Critical">
                        <Box>
                          <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F73939' }} />
                        </Box>
                        <Typography variant="body2">Critical</Typography>
                      </MenuItem>
                      <MenuItem sx={{ backgroundColor: '#FCD34D' }} value="High">
                        <Box>
                          <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F59E0B' }} />
                        </Box>
                        <Typography variant="body2">High</Typography>
                      </MenuItem>
                      <MenuItem sx={{ backgroundColor: '#FEF3C7' }} value={'Medium'}>
                        <Box>
                          <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#FBBF24' }} />
                        </Box>
                        <Typography variant="body2">Medium</Typography>
                      </MenuItem>

                      <MenuItem sx={{ backgroundColor: '#D0DAFC' }} value={'Low'}>
                        {' '}
                        <Box>
                          <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#436AF3' }} />
                        </Box>
                        <Typography variant="body2">Low</Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </List>
              <List sx={{ py: 1.5 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 2
                  }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                    Add Associated Risks
                  </Typography>
                  <Box ref={box1Ref} sx={{ width: 1 }}>
                    <TextField
                      placeholder="Search by Risk Name"
                      hiddenLabel
                      fullWidth
                      size="small"
                      name="searchText"
                      value={searchText}
                      onClick={() => setIsBoxVisible(true)}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                        setIsBoxVisible(true);
                      }}
                      sx={{
                        input: { backgroundColor: '#FFFFFF' },
                        '.MuiOutlinedInput-root': {
                          bgcolor: '#FFFFFF'
                        }
                      }}
                      inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment sx={{ bgcolor: '#FFFFFF' }} position="end">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                  {isBoxVisible && searchAssets.length ? (
                    <Box sx={{ width: 1 }}>
                      <Box
                        ref={box2Ref}
                        sx={{
                          border: '1px solid #CCCCCC',
                          padding: '8px',
                          borderRadius: '4px',
                          maxHeight: '170px',
                          overflowX: 'auto'
                        }}>
                        {searchAssets?.map((asset, index) => {
                          const isItemSelected = isSelected(asset?.risk_uuid);
                          const isLastItem = index === searchAssets.length - 1;
                          return (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                overflow: 'hidden',
                                mb: isLastItem ? 0 : 1
                              }}>
                              <Checkbox
                                sx={{
                                  padding: 0,
                                  width: '16px',
                                  height: '16px',
                                  mr: 0.5
                                }}
                                onClick={(event) => {
                                  handleClickCheck(event, asset?.risk_uuid);
                                  event.stopPropagation();
                                  event.preventDefault();
                                }}
                                checked={isItemSelected}
                                checkedIcon={<CheckBoxLightBlue />}
                                inputProps={
                                  {
                                    // 'aria-labelledby': labelId
                                  }
                                }
                                icon={
                                  <CheckBoxOutLine
                                    sx={{
                                      fill: 'none',
                                      width: '16px',
                                      height: '16px'
                                    }}
                                  />
                                }
                              />
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  background: assetColorBackGround(asset?.status),
                                  borderRadius: '50%',
                                  width: '24px',
                                  height: '24px',
                                  mr: '6px',
                                  flex: ' 0 0 24px'
                                }}>
                                <FlagIcon
                                  sx={{
                                    fontSize: '12px',
                                    color: assetColorIcon(asset?.status)
                                  }}
                                />
                              </Box>
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  fontSize: '14px',
                                  color: '#464646',
                                  fontWeight: 400,
                                  whiteSpace: 'nowrap'
                                }}>
                                <span>{asset?.name}</span>
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  ) : null}
                  <Box ref={box3Ref} sx={{ textAlign: 'end', width: 1 }}>
                    <Button variant="outlined" onClick={addRisks}>
                      Add Risks
                    </Button>
                  </Box>
                </Box>
              </List>

              <List sx={{ py: 1.5 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 2
                  }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                    Associated Risks
                  </Typography>
                  {associatedAssets?.map((asset) => {
                    return (
                      <Box
                        className="as-asset-wrapper"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: 1,
                          border: '1px solid #CCCCCC',
                          borderRadius: '4px',
                          overflow: 'hidden',
                          padding: '8px',
                          position: 'relative',
                          cursor: 'pointer'
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: assetColorBackGround(asset?.status),
                            borderRadius: '50%',
                            width: '24px',
                            flex: ' 0 0 24px',
                            height: '24px',
                            mr: '6px'
                          }}>
                          <FlagIcon
                            sx={{
                              fontSize: '12px',
                              color: assetColorIcon(asset?.status)
                            }}
                          />
                        </Box>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: '14px',
                            color: '#464646',
                            fontWeight: 400,
                            whiteSpace: 'nowrap'
                          }}>
                          <span>{asset?.name}</span>
                        </Typography>
                        <Box
                          className="hover-status"
                          sx={{ display: 'none', alignItems: 'center', gap: 1 }}>
                          <Box
                            sx={{
                              minWidth: '14px',
                              height: '14px',
                              width: '14px',
                              backgroundColor: bgStatusColor(asset?.status),
                              borderRadius: '50%'
                            }}></Box>
                          <Typography variant="caption">{asset?.status}</Typography>
                          <CrossCancle
                            onClick={() => removeRisk(asset?.risk_uuid)}
                            sx={{
                              color: '#000',
                              height: '8px',
                              width: '8px',
                              cursor: 'pointer'
                            }}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </List>

              <List sx={{ py: 2 }}>
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
                    Tag
                  </Typography>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    freeSolo
                    options={tagOptions.map((el) => el.tag_name)}
                    value={formik.values.tags}
                    renderTags={(value, getTagProps) => {
                      return value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                          onDelete={() => handleClickOpenDelete(option, value)}
                        />
                      ));
                    }}
                    selectOnFocus
                    onChange={(event: any, newValue, reason) => {
                      if (
                        reason == 'selectOption' ||
                        reason == 'removeOption' ||
                        reason == 'clear'
                      ) {
                        handleTagChange(event, newValue);
                      } else {
                        const optionToSelect = tagOptions.find((option) => {
                          if (
                            option?.tag_name?.toLowerCase() === event.target.value.toLowerCase()
                          ) {
                            return option;
                          }
                        });
                        if (optionToSelect) {
                          handleTagChange(event, [...formik.values.tags, optionToSelect.tag_name]);
                        } else {
                          handleTagChange(event, newValue);
                        }
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        error={tagErrorMessage.maxLimit || tagErrorMessage.tagLimit ? true : false}
                        helperText={
                          tagErrorMessage.maxLimit
                            ? tagErrorMessage.maxLimit
                            : tagErrorMessage.tagLimit
                        }
                        {...params}
                        onChange={(e: any) => {
                          if (e.target.value) {
                            if (e.target.value.startsWith('_')) {
                              setTagErrorMessage({
                                ...tagErrorMessage,
                                maxLimit: `Please Don't Start the tag with (_)`
                              });
                            } else if (!validateString(e.target.value)) {
                              setTagErrorMessage({
                                ...tagErrorMessage,
                                maxLimit: `Please don't add specific characters or numbers just Alphapetical Letters ONLY`
                              });
                            } else if (e.target.value.length > 50) {
                              setTagErrorMessage({
                                ...tagErrorMessage,
                                maxLimit: 'You have reached the maximum limit of characters per Tag'
                              });
                            } else {
                              setTagErrorMessage({
                                ...tagErrorMessage,
                                maxLimit: ''
                              });
                            }
                          } else {
                            setTagErrorMessage({
                              ...tagErrorMessage,
                              maxLimit: ''
                            });
                          }
                        }}
                        placeholder={formik?.values?.tags?.length > 0 ? '' : 'Add Tags'}
                      />
                    )}
                    sx={{
                      '&.MuiAutocomplete-root': {
                        '.MuiAutocomplete-input': {
                          minWidth: '70px'
                        },
                        '.MuiOutlinedInput-root': {
                          p: 0,
                          pl: 1,
                          pr: 1,
                          '.MuiAutocomplete-clearIndicator': {
                            margin: '0px -6px 0px 0px'
                          }
                        },
                        '.MuiAutocomplete-tag': {
                          m: '7px',
                          bgcolor: 'common.lightBlue',
                          border: 'none'
                        },
                        '.MuiAutocomplete-endAdornment': {
                          right: '5px'
                        }
                      }
                    }}
                  />
                  <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={openDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle
                      id="alert-dialog-title"
                      sx={{
                        px: { xs: 1, md: 2 },
                        py: { xs: 0.5, md: 1 },
                        bgcolor: 'error.red100',
                        mb: { xs: 2, md: 3 }
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: 'error.main',
                            fontWeight: '600',
                            maxWidth: 'calc(100% - 40px)',
                            p: 1
                          }}>
                          <WarningIcon sx={{ mr: 1 }} />
                          Confirm Deletion
                        </Typography>
                        <IconButton
                          sx={{ color: 'text.primary' }}
                          aria-label="close"
                          onClick={handleCloseDelete}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </DialogTitle>
                    <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
                      <DialogContentText
                        id="alert-dialog-description"
                        sx={{ color: 'text.primary', fontWeight: 700, mb: 1 }}>
                        Are you sure do you want to delete this Tag?
                      </DialogContentText>

                      <DialogContentText
                        id="alert-dialog-description"
                        sx={{ color: 'text.primary' }}>
                        {deleteOption}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0 }}>
                      <Button variant="outlined" sx={{ minWidth: 100 }} onClick={handleCloseDelete}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ minWidth: 100 }}
                        color="error"
                        onClick={() => handleConfirmDelete(deleteOption)}>
                        {' '}
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                </FormControl>
              </List>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            px: 3,
            py: 2,
            textAlign: 'end'
          }}>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
            <Button
              onClick={(event) => closePopup(event)}
              sx={{ minWidth: 150 }}
              variant="outlined">
              Cancel
            </Button>
            <LoadingButton
              loading={isRemediationButtonLoading}
              disabled={isComplete || facilities.length === 0}
              variant="contained"
              sx={{
                minWidth: 150,
                border: '1px solid #436AF3',
                '&.Mui-disabled': {
                  border: '1px solid #E0E0E0'
                }
              }}
              type="submit">
              Save
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default AddTags;
