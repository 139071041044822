import CloseIcon from '@jibin/common/icons/Close/Close';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import { CommonFunctions } from '@jibin/common/utils/Common';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import { Amplify, Auth } from 'aws-amplify';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import commonDetailSlice from 'src/store/reducers/commonReducer';
import { MyAccountApi } from '../MyAccount.Api';
import { LoadingButton } from '@mui/lab';
const DeletaionBox: FC<{
  openDeletaion: boolean;
  titleText: string;
  flag: string;
  userDetails: any;
  discription: string;
  handleOpen: () => void;
  handleClose: () => void;
}> = (prop) => {
  Amplify.configure({
    Auth: {
      userPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID),
      userPoolWebClientId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_CLIENT_ID)
    }
  });
  const dispatch = useDispatch();
  const [isDisableButtonLoading, setIsDisableButtonLoading] = useState(false);
  const handleMfa = async () => {
    try {
      setIsDisableButtonLoading(true);
      const user = await Auth.currentAuthenticatedUser();

      const res = await Auth.setPreferredMFA(user, 'NOMFA');
      if (prop.flag == 'APP') {
        const data = { ...prop.userDetails, isMfaAppEnabled: false };
        const updateUserdetals = await MyAccountApi.PutUserData(data);
        const user = await MyAccountApi.GetUserData();

        dispatch(commonDetailSlice.actions.setcommonDetails(user));
        prop.handleClose();
      } else if (prop.flag == 'SMS') {
        const data = { ...prop.userDetails, isMfaSmsEnabled: false };
        const updateUserdetals = await MyAccountApi.PutUserData(data);
        const user = await MyAccountApi.GetUserData();

        dispatch(commonDetailSlice.actions.setcommonDetails(user));
        prop.handleClose();
      }
    } catch (error) {
    } finally {
      setTimeout(() => {
        setIsDisableButtonLoading(false);
      }, 1000);
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={prop.openDeletaion}
      onClose={prop.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          px: { xs: 1, md: 2 },
          py: { xs: 0.5, md: 1 },
          bgcolor: 'error.red100',
          mb: { xs: 2, md: 3 }
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'error.main',
              fontWeight: '600',
              maxWidth: 'calc(100% - 40px)',
              p: 1
            }}>
            <WarningIcon sx={{ mr: 1 }} />
            {prop.titleText}
          </Typography>
          <IconButton sx={{ color: 'text.primary' }} aria-label="close" onClick={prop.handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
        <DialogContentText id="alert-dialog-description" sx={{ color: 'text.primary' }}>
          {prop.discription}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0 }}>
        <Button variant="outlined" sx={{ minWidth: 100 }} onClick={prop.handleClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={isDisableButtonLoading}
          variant="contained"
          onClick={() => {
            handleMfa();
          }}
          sx={{ minWidth: 100 }}
          color="error">
          Disable
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default DeletaionBox;
