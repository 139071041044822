import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
export const ConstantName = {
  REDESIGN_TRUST_GROUP: 'THE REDESIGN GROUP',
  REDESIGN_TRUST_GROUP_ENGG: 'THE REDESIGN GROUP ENGG',
  REDESIGN_TRUST_PORTAL: 'THE REDESIGN TRUST PORTAL',
  REDESIGN_TRUST_TEAM_THE: 'THE REDESIGN TRUST TEAM',
  REDESIGN_TRUST_TEAM: 'REDESIGN TRUST TEAM',
  REDESIGN_TRUST_PORTAL_ENGG: 'THE REDESIGN TRUST PORTAL ENGG',
  REDESIGN_TEAM: 'REDESIGN'
};
export const UserRoles = {
  SUPER_ADMIN: 'superadmin',
  CUSTOMER_ADMIN: 'customer',
  CYBER_SECURITY_ENGINEER: 'cse',
  SERVICE_DELIVERY_COORDINATOR: 'sdc',
  SERVICE_DELIVERY_MANAGER: 'sdm'
};
export const CompanyUserRoles = {
  ADMIN: 'admin',
  USER: 'user',
  AUDITOR: 'auditor'
};
export const rolesArray = [
  {
    role: UserRoles.SERVICE_DELIVERY_COORDINATOR,
    tooltip: 'Service Delivery Coordinator'
  },
  {
    role: UserRoles.CYBER_SECURITY_ENGINEER,
    tooltip: 'Cybersecurity Engineer'
  },
  {
    role: UserRoles.SERVICE_DELIVERY_MANAGER,
    tooltip: 'Service Delivery Manager'
  },

  {
    role: UserRoles.SUPER_ADMIN,
    tooltip: ''
  }
];
export const dataforUserActivity = [
  { name: 'Dashboard', route: PageRouteConstants.Home },
  { name: 'Dashboard', route: PageRouteConstants.CompanyDashBoard },
  { name: 'Login', route: PageRouteConstants.Login },
  { name: 'On Boarding', route: PageRouteConstants.OnBoarding },
  { name: 'Welcome', route: PageRouteConstants.Welcome },
  { name: 'Mfa', route: PageRouteConstants.Mfa },
  { name: 'Signup', route: PageRouteConstants.Signup },
  { name: 'Network Detection', route: PageRouteConstants.NetwrokDetection },
  { name: 'Signup Create', route: PageRouteConstants.SignupCreate },
  { name: 'Security Information & Event Management', route: PageRouteConstants.SIEM },
  { name: 'Forgot Password', route: PageRouteConstants.ForgotPassword },
  { name: 'Notification', route: PageRouteConstants.Notification },
  { name: 'Portal Activity', route: PageRouteConstants.PortalActivity },
  { name: 'Location', route: PageRouteConstants.CompanyProfile },
  { name: 'Manage Users', route: PageRouteConstants.ManageUsers },
  { name: 'Meetings', route: PageRouteConstants.Meetings },
  { name: 'Company Information', route: PageRouteConstants.General },
  { name: 'Company Management', route: PageRouteConstants.CompanyManagement },
  { name: 'Company Account', route: PageRouteConstants.CompanyManagementAccount },
  { name: 'Contacts', route: PageRouteConstants.CompanyManagementContact },
  { name: 'Assets', route: PageRouteConstants.AssetManagement },
  {
    name: 'Integrations',
    route: PageRouteConstants.CompanyManagementIntegrations
  },
  { name: 'Authorizations', route: PageRouteConstants.Authorizations },
  { name: 'Plan And Billing', route: PageRouteConstants.PlanAndBilling },
  { name: 'Attack Surface Management', route: PageRouteConstants.UpGuard },
  { name: 'Applications', route: PageRouteConstants.Applications },
  {
    name: 'IP Ranges',
    route: PageRouteConstants.ExternalInfrastructureAssessment
  },
  { name: 'Wireless Networks', route: PageRouteConstants.WirelessAssessment },
  { name: 'Policies & Procedures', route: PageRouteConstants.PoliciesAndProcedures },
  {
    name: 'Policies & Procedures',
    route: PageRouteConstants.PoliciesAndProceduresTemplate
  },
  { name: 'Security Awareness Results', route: PageRouteConstants.SecurityAwarenessResult },
  {
    name: 'Security Awareness Participant',
    route: PageRouteConstants.SecurityAwarenessParticupant
  },
  { name: 'Vulnerability Management', route: PageRouteConstants.Vulnerability },
  { name: 'Remediation Tracker', route: PageRouteConstants.Remediation },
  { name: 'Penetration Testing', route: PageRouteConstants.Penetration },
  { name: 'Tools', route: PageRouteConstants.EndpointProtection },
  { name: 'Framework', route: PageRouteConstants.Frameworks },
  { name: 'Risks', route: PageRouteConstants.RiskManagement },
  { name: 'Plan And Billing', route: PageRouteConstants.PlanAndBilling },
  { name: 'Licenses', route: PageRouteConstants.Licenses },
  { name: 'Framework', route: PageRouteConstants.FrameworkControl },
  { name: 'Gap Analysis', route: PageRouteConstants.GapAnalysis },
  { name: 'Gap Analysis 2.0', route: PageRouteConstants.GapAnalysisV2 },
  { name: 'Gap Analysis 2.0', route: PageRouteConstants.GapAnalysisV2Questionnaire },
  { name: 'Dashboard', route: PageRouteConstants.DashBoard },
  { name: 'Reports', route: PageRouteConstants.ServicesReview },
  { name: 'Consulting Reports', route: PageRouteConstants.EngineeringConsulting },
  { name: 'Email Protection', route: PageRouteConstants.EmailProtection },
  { name: 'Account', route: PageRouteConstants.MyAccount }
];
