export default function ShareIconForPolicy() {
  return (
    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 10.7197C11.4933 10.7197 11.04 10.9197 10.6933 11.233L5.94 8.46634C5.97333 8.31301 6 8.15967 6 7.99967C6 7.83967 5.97333 7.68634 5.94 7.53301L10.64 4.79301C11 5.12634 11.4733 5.33301 12 5.33301C13.1067 5.33301 14 4.43967 14 3.33301C14 2.22634 13.1067 1.33301 12 1.33301C10.8933 1.33301 10 2.22634 10 3.33301C10 3.49301 10.0267 3.64634 10.06 3.79967L5.36 6.53967C5 6.20634 4.52667 5.99967 4 5.99967C2.89333 5.99967 2 6.89301 2 7.99967C2 9.10634 2.89333 9.99967 4 9.99967C4.52667 9.99967 5 9.79301 5.36 9.45967L10.1067 12.2263C10.0733 12.3663 10.0533 12.513 10.0533 12.6663C10.0533 13.7397 10.9267 14.6063 12 14.6063C13.0733 14.6063 13.9467 13.7397 13.9467 12.6663C13.9467 11.593 13.0733 10.7197 12 10.7197ZM12 2.66634C12.3667 2.66634 12.6667 2.96634 12.6667 3.33301C12.6667 3.69967 12.3667 3.99967 12 3.99967C11.6333 3.99967 11.3333 3.69967 11.3333 3.33301C11.3333 2.96634 11.6333 2.66634 12 2.66634ZM4 8.66634C3.63333 8.66634 3.33333 8.36634 3.33333 7.99967C3.33333 7.63301 3.63333 7.33301 4 7.33301C4.36667 7.33301 4.66667 7.63301 4.66667 7.99967C4.66667 8.36634 4.36667 8.66634 4 8.66634ZM12 13.333C11.6333 13.333 11.3333 13.033 11.3333 12.6663C11.3333 12.2997 11.6333 11.9997 12 11.9997C12.3667 11.9997 12.6667 12.2997 12.6667 12.6663C12.6667 13.033 12.3667 13.333 12 13.333Z"
        fill="#464646"
      />
    </svg>
  );
}
