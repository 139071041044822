import http from './http-common';
import 'react-toastify/dist/ReactToastify.css';

const baseUrl = process.env.REACT_APP_API_HOST;

const getAll = (url: string) => {
  if (process.env.REACT_APP_IS_DEMO === 'true') {
    let patharr = url.split('?');

    if (url.split('/')[url.split('/').length - 1].includes('companies')) {
      return fetch(`/mock/${patharr[0]}/index.json`).then((response) => response.json());
    } else if (url.includes('/settings/penetration/topremediation')) {
      return fetch(`/mock/${patharr[0]}/index.json`).then((response) => response.json());
    } else if (url.includes('remediation')) {
      return fetch(`/mock/${patharr[0]}/index.json`).then((response) => response.json());
    } else if (url.includes('/settings/vulnerabilities/stats')) {
      return fetch(`/mock/${patharr[0]}/index.json`).then((response) => response.json());
    } else if (url.includes('/phishing')) {
      return fetch(`/mock/${patharr[0]}/index.json`).then((response) => response.json());
    } else if (url.includes('/training')) {
      return fetch(`/mock/${patharr[0]}/index.json`).then((response) => response.json());
    } else if (url.includes('/settings/policies/stats')) {
      return fetch(`/mock/${patharr[0]}/index.json`).then((response) => response.json());
    } else if (url.includes('/frameworks/stats')) {
      return fetch(`/mock/mockdata/frameworks/stats.json`).then((response) => response.json());
    } else if (url.includes('/controls')) {
      return fetch(`/mock/mockdata/frameworks/controls.json`).then((response) => response.json());
    } else if (url.includes('/frameworks')) {
      return fetch(`/mock/mockdata/frameworks/index.json`).then((response) => response.json());
    } else if (url.includes('/settings/penetration/stats')) {
      return fetch(`/mock/${patharr[0]}/index.json`).then((response) => response.json());
    } else if (url.includes('/subscriptions/plan')) {
      return fetch(`/mock/${patharr[0]}/index.json`).then((response) => response.json());
    } else if (url.includes('/subscriptions/plan')) {
    } else if (url.includes('/services-review')) {
      return fetch(`/mock/mockdata/services-review/index.json`).then((response) => response.json());
    } else if (url.includes('/consulting/hours')) {
      return fetch(`/mock/${patharr[0]}/index.json`).then((response) => response.json());
    } else if (url.includes('/consulting/consumed-hour')) {
      return fetch(`/mock/${patharr[0]}/index.json`).then((response) => response.json());
    } else {
      return http.get(baseUrl + `${url}`, {});
    }
  } else return http.get(baseUrl + `${url}`, {});
};

const getDocument = (url: string) => {
  return http.get(baseUrl + `${url}`, { responseType: 'blob' });
};
const get = (url: string, id: any) => {
  return http.get(baseUrl + `${url}/${id}`);
};
const deleteFiles = (url: string) => {
  return http.delete(baseUrl + `${url}`);
};
const post = (url: string, data: any) => {
  return http.post(baseUrl + url, data);
};
const postDownload = (url: string, data: any) => {
  return http.post(baseUrl + url, data, { responseType: 'blob' });
};
const postDocument = (url: string, data: any) => {
  return http.post(baseUrl + url, data, { 'Content-Type': 'multipart/form-data' });
};
const put = (url: string, id: any, data: any) => {
  return http.put(baseUrl + `${url}/${id}`, data);
};
const putAll = (url: string, data: any) => {
  return http.put(baseUrl + `${url}`, data);
};
const patch = (url: string, id: any, data: any) => {
  return http.patch(baseUrl + `${url}/${id}`, data);
};
const patchData = (url: string, data: any) => {
  return http.patch(baseUrl + `${url}`, data);
};
const patchAll = (url: string, data: any) => {
  return http.patch(baseUrl + `${url}`, data);
};
const remove = (url: string, id: any) => {
  return http.delete(baseUrl + `${url}/${id}`);
};
const removeBulk = (url: string) => {
  return http.delete(baseUrl + `${url}`);
};

const HttpService = {
  getAll,
  get,
  put,
  patch,
  post,
  remove,
  patchData,
  putAll,
  postDownload,
  getDocument,
  patchAll,
  deleteFiles,
  postDocument,
  removeBulk
};

export default HttpService;
