import DownloadIcon from '@jibin/common/icons/DownloadIcon/DownloadIcon';
import {
  Box,
  Button,
  Checkbox,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import moment from 'moment';

const PlanAndBillingRow = ({ row, index, downloadPDF }) => {
  return (
    <TableRow
      key={index}
      id={row?.invoice_id}
      sx={{
        // cursor: 'pointer',
        bgcolor: '#fff',
        '.MuiTableCell-root': {
          px: '20px',

          borderBottom: '1px solid  #CCCCCC',
          borderTopRadius: '4px',
          borderTop: '1px solid  #CCCCCC',
          '&:last-child': {
            borderRight: '1px solid  #CCCCCC',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px'
          },
          '&:first-child': {
            borderLeft: '1px solid  #CCCCCC',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px'
          }
        }
      }}>
      <TableCell
        // key={row.name}
        sx={{ display: 'flex', alignItems: 'center' }}
        // onClick={(event) => handleClickCheck(event, row)}

        // aria-checked={isItemSelected}
        tabIndex={-1}>
        <Checkbox
          color="primary"
          //   checked={isItemSelected}
          inputProps={
            {
              // 'aria-labelledby': labelId
            }
          }
        />
        <Typography variant="caption">
          {' '}
          {row?.due_date &&
            moment(row?.due_date).format('DD MMM YYYY').toUpperCase()}
        </Typography>
      </TableCell>

      <TableCell align="left">${" "}{row?.total_amount?.toFixed(2)}</TableCell>
      <TableCell align="left">{row?.name}</TableCell>
      <TableCell align="left">
        <Typography variant="caption">
          {' '}
          {row?.status == 'Paid' ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box
                sx={{
                  minWidth: '14px',
                  height: '14px',
                  width: '14px',
                  backgroundColor: '#10B981',
                  borderRadius: '50%'
                }}></Box>
              <Typography variant="caption">Paid</Typography>
            </Box>
          ) : row?.status == 'Not Paid' ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box
                sx={{
                  minWidth: '14px',
                  width: '14px',
                  height: '14px',
                  backgroundColor: '#EF4444',
                  borderRadius: '50%'
                }}></Box>
              <Typography variant="caption">Not Paid</Typography>
            </Box>
          ) : row?.status == 'Pending' ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box
                sx={{
                  minWidth: '14px',
                  width: '14px',
                  height: '14px',
                  backgroundColor: '#FCD34D',
                  borderRadius: '50%'
                }}></Box>
              <Typography variant="caption">Pending</Typography>
            </Box>
          ) : null}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row" align="right" sx={{ maxWidth: 200, width: '200px' }}>
        <Button
          sx={{ display: 'flex', alignItems: 'center', gap: 1, maxWidth: 200 }}
          onClick={() => downloadPDF(row)}
          variant="text">
          Download <DownloadIcon />
        </Button>
      </TableCell>
    </TableRow>
  );

};
export default PlanAndBillingRow;
