import BorderLinearProgressBlue from '@jibin/common/components/LinearLoading/BorderLinearProgressBlue';
import WarningRed from '@jibin/common/icons/WarningRed/WarningRed';
import CheckEmptyCircle from '@jibin/common/icons/CheckEmptyCircle/CheckEmptyCircle';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { FC, useState, useEffect, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import './index.css';
import { GapAnalysisApi } from '../GapAnalysis.Api';
import GapAnalysisQ from './GapAnalysisQ/GapAnalysisQ';
import commonDetailSlice from 'src/store/reducers/commonReducer';
import { useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { CompanyUserRoles } from '@jibin/common/utils/constants/ConstantName';
var debounce = require('lodash.debounce');

const GapAnalysisForm: FC<{
  el: any;
  GetCategories: any;
  isAllComplete: boolean;
  isEngineer: boolean;
}> = (prop) => {
  const dispatch = useDispatch();

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [questions, setQuestions] = useState([] as any);
  const [completeScore, setCompleteScore] = useState(0);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [isGapButtonLoading, setIsGapButtonLoading] = useState(false);

  const getQuestions = () => {
    setIsSkeleton(true);
    GapAnalysisApi.GetQuestionnaires(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      prop.el?.category
    ).then(
      (q: any) => {
        setQuestions(q.data.data);
        setIsSkeleton(false);

        let answered = q.data?.data.filter((q) => q.answer?.questionnaire_answers_uuid);
        setCompleteScore((answered.length / q.data?.data.length) * 100);
      },
      (err: any) => {}
    );
  };
  async function getEntries() {
    GapAnalysisApi.GetUserData().then(
      async (t: any) => {
        await dispatch(commonDetailSlice.actions.setcommonDetails(t));
        setIsSkeleton(false);
      },
      async (err: any) => {}
    );
  }

  const postAnswers = useCallback(
    debounce(
      () => {
        try {
          setIsGapButtonLoading(true);
          let data = {
            status: 'submitted'
          };
          GapAnalysisApi.Submit(
            commonDetails.data.user_uuid,
            commonDetails.data.company.company_uuid,
            data
          ).then(
            async (q: any) => {
              await getEntries();
            },
            (err: any) => {}
          );
        } catch (error) {
        } finally {
          setTimeout(() => {
            setIsGapButtonLoading(false);
          }, 1000);
        }
      },
      500,
      true
    ),
    [commonDetails]
  );

  useEffect(() => {
    getQuestions();
    return () => {
      setQuestions([]);
    };
  }, [commonDetails]);

  return (
    <Box sx={{ position: 'relative', maxHeight: '72vh', minHeight: '72vh', pb: 4 }}>
      <Box
        sx={{
          pr: 2,
          maxHeight: '72vh',
          minHeight: '72vh',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '0.2em'
          },
          '&::-webkit-scrollbar-track': {
            borderRadius: '10px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',

            borderRadius: '10px'
          }
        }}>
        {/* {commonDetails?.data?.group == 'superadmin' &&
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="user"
              name="radio-buttons-group"
              onChange={(e) => { handleRadioChange(e) }}
              row
            >
              <FormControlLabel value=UserRoles.CYBER_SECURITY_ENGINEER control={<Radio />} label="Engineer" />
              <FormControlLabel value="user" control={<Radio />} label="User" />
            </RadioGroup>
          } */}

        {questions?.map((data, index: string) => (
          <GapAnalysisQ
            data={data}
            index={index}
            getQuestions={() => getQuestions()}
            GetCategories={() => prop.GetCategories()}
            isAllComplete={prop.isAllComplete}
            isEngineer={prop.isEngineer}
          />
        ))}
        <br />
        <br />
        {!prop.isEngineer && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              position: 'absolute',
              bottom: 0,
              right: 0,
              gap: 2,
              pt: 2,
              backgroundColor: '#fbfbff',
              left: 0
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 1,
                  gap: 1
                }}>
                {/* <Typography variant="body1" sx={{ fontWeight: 600, color: '#787878' }}>
                    Save all questions before submit your Gap Analysis Questionnaire
                  </Typography> */}
              </Box>
            </Box>
            {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
              <LoadingButton
                loading={isGapButtonLoading}
                variant="contained"
                disabled={commonDetails.data.company.gap_status != 'draft' || !prop.isAllComplete}
                sx={{ minWidth: 100 }}
                type="button"
                onClick={() => {
                  postAnswers();
                }}>
                Submit
              </LoadingButton>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default GapAnalysisForm;
