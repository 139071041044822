import { Box, Typography, FormControl, List, Button } from "@mui/material"
import moment from 'moment'

const ViewAsset = ({ rowData, setIsCreateRemediation }) => {

    const capitalizeFirstLetter = (text) => {
        return text?.charAt(0)?.toUpperCase() + text?.slice(1);
    };

    const renderColor = (status: string) => {
        let color = null
        if (status === 'Healthy') {
            color = '#10B981'
        } else if (status === 'Unhealthy') {
            color = '#F87171'
        } else if (status === 'Unknown') {
            color = '#B7B7B7'
        } else if (status === 'Alert') {
            color = '#FCD34D'
        }
        return color
    }

    return (
        <>
            <Box sx={{ flex: 1, mt: 3 }}>
                <Box sx={{ p: 3, pt: 0 }}>
                    <Box sx={{ width: '100%' }}>
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600 }}>
                                    Asset Status
                                </Typography>
                                <FormControl sx={{ m: 1 }}>
                                    <Box
                                        sx={{
                                            py: 1,
                                            px: 2,
                                            borderRadius: '100px',
                                            border: '1px solid #EBEBEB'
                                        }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Box
                                                sx={{
                                                    minWidth: '14px',
                                                    height: '14px',
                                                    width: '14px',
                                                    backgroundColor: renderColor(rowData?.status),
                                                    borderRadius: '50%'
                                                }}></Box>
                                            <Typography variant="body2" sx={{ color: '#464646' }}>
                                                {' '}
                                                {capitalizeFirstLetter(rowData?.status)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </FormControl>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600, mb: 2 }}>
                                    Asset Details
                                </Typography>

                                <Box>
                                    {rowData?.name ? <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                                        <Typography variant="body2" sx={{ color: '#696969', fontWeight: 400, width: '50%' }}>
                                            Asset Name
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#464646', fontWeight: 400, width: '50%', wordBreak: 'break-word' }}>
                                            {rowData?.name}
                                        </Typography>
                                    </Box> : null}

                                    {rowData?.type ? <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                                        <Typography variant="body2" sx={{ color: '#696969', fontWeight: 400, width: '50%' }}>
                                            Asset Type
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#464646', fontWeight: 400, width: '50%', wordBreak: 'break-word' }}>
                                            {rowData?.type}
                                        </Typography>
                                    </Box> : null}

                                    {rowData?.last_check_in ? <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                                        <Typography variant="body2" sx={{ color: '#696969', fontWeight: 400, width: '50%' }}>
                                            Last Check-IN
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#464646', fontWeight: 400, width: '50%', wordBreak: 'break-word' }}>
                                            {moment(rowData?.last_check_in).format('DD MMM YYYY').toUpperCase()}
                                        </Typography>
                                    </Box> : null}

                                    {rowData?.last_login_user ? <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                                        <Typography variant="body2" sx={{ color: '#696969', fontWeight: 400, width: '50%' }}>
                                            Last Login User
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#464646', fontWeight: 400, width: '50%', wordBreak: 'break-word' }}>
                                            {rowData?.last_login_user}
                                        </Typography>
                                    </Box> : null}

                                    {rowData?.os ? <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                                        <Typography variant="body2" sx={{ color: '#696969', fontWeight: 400, width: '50%' }}>
                                            Operation System
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#464646', fontWeight: 400, width: '50%', wordBreak: 'break-word' }}>
                                            {rowData?.os}
                                        </Typography>
                                    </Box> : null}

                                    {rowData?.manufacturer ? <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                                        <Typography variant="body2" sx={{ color: '#696969', fontWeight: 400, width: '50%' }}>
                                            Manufacturer
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#464646', fontWeight: 400, width: '50%', wordBreak: 'break-word' }}>
                                            {rowData?.manufacturer}
                                        </Typography>
                                    </Box> : null}

                                    {rowData?.model ? <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                                        <Typography variant="body2" sx={{ color: '#696969', fontWeight: 400, width: '50%' }}>
                                            Modal
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#464646', fontWeight: 400, width: '50%', wordBreak: 'break-word' }}>
                                            {rowData?.model}
                                        </Typography>
                                    </Box> : null}

                                    {rowData?.time_zone ? <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                                        <Typography variant="body2" sx={{ color: '#696969', fontWeight: 400, width: '50%' }}>
                                            Time Zone
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#464646', fontWeight: 400, width: '50%', wordBreak: 'break-word' }}>
                                            {rowData?.time_zone}
                                        </Typography>
                                    </Box> : null}

                                    {rowData?.up_time ? <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                                        <Typography variant="body2" sx={{ color: '#696969', fontWeight: 400, width: '50%' }}>
                                            Uptime
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#464646', fontWeight: 400, width: '50%', wordBreak: 'break-word' }}>
                                            {rowData?.up_time}
                                        </Typography>
                                    </Box> : null}

                                    {rowData?.organization ? <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                                        <Typography variant="body2" sx={{ color: '#696969', fontWeight: 400, width: '50%' }}>
                                            Organization
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#464646', fontWeight: 400, width: '50%', wordBreak: 'break-word' }}>
                                            {rowData?.organization}
                                        </Typography>
                                    </Box> : null}

                                    {rowData?.agent_version ? <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                                        <Typography variant="body2" sx={{ color: '#696969', fontWeight: 400, width: '50%' }}>
                                            Agent Version
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#464646', fontWeight: 400, width: '50%', wordBreak: 'break-word' }}>
                                            {rowData?.agent_version}
                                        </Typography>
                                    </Box> : null}

                                    {rowData?.public_ip_address?.map((publicIp) => {
                                        return (
                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                                                <Typography variant="body2" sx={{ color: '#696969', fontWeight: 400, width: '50%' }}>
                                                    Public IP Address
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: '#464646', fontWeight: 400, width: '50%', wordBreak: 'break-word' }}>
                                                    {publicIp}
                                                </Typography>
                                            </Box>
                                        )
                                    })}

                                    {rowData?.ip_v4_addresses?.map((ip) => {
                                        let splittedIP  = ip.split('|');
                                        for (let i = 0; i < splittedIP.length; i++) {
                                            return (
                                                <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                                                    <Typography variant="body2" sx={{ color: '#696969', fontWeight: 400, width: '50%' }}>
                                                        IP Address v4
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color: '#464646', fontWeight: 400, width: '50%', wordBreak: 'break-word' }}>
                                                        {splittedIP[i]}
                                                    </Typography>
                                                </Box>
                                            )
                                        }
                                    })}
                                    {rowData?.ip_v6_addresses?.map((ip) => {
                                        let splittedIP  = ip.split('|');
                                        for (let i = 0; i < splittedIP.length; i++) {
                                            return (
                                                <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                                                    <Typography variant="body2" sx={{ color: '#696969', fontWeight: 400, width: '50%' }}>
                                                        IP Address v6
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color: '#464646', fontWeight: 400, width: '50%', wordBreak: 'break-word' }}>
                                                        {splittedIP[i]}
                                                    </Typography>
                                                </Box>
                                            )
                                        }
                                    })}

                                    {rowData?.mac_address?.map((mac) => {
                                        return (
                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                                                <Typography variant="body2" sx={{ color: '#696969', fontWeight: 400, width: '50%' }}>
                                                    MAC  Address
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: '#464646', fontWeight: 400, width: '50%', wordBreak: 'break-word' }}>
                                                    {mac}
                                                </Typography>
                                            </Box>
                                        )
                                    })}





                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>

            {rowData?.remediation_uuid === '' && <Box
                sx={{
                    px: 2,
                    py: 2,
                    textAlign: 'end',
                    borderTop: '1px solid #0000000D'
                }}>
                <Button
                    sx={{ minWidth: 150 }}
                    onClick={() => setIsCreateRemediation(true)}
                    variant="contained">
                    Create Remediation
                </Button>
            </Box>}
        </>
    )
}

export default ViewAsset