import { TablePagination } from '@mui/material';

const Index = (prop) => {
  const {
    totalCount,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    isArrowButtonDisable = false
  } = prop;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    !isArrowButtonDisable && setPage && setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage && setRowsPerPage(parseInt(event.target.value, 10));
    setPage && setPage(0);
  };

  const labelDisplayedRows = ({ from, to, count }: any) => {
    if (count === undefined || rowsPerPage === undefined) {
      return `Page 0 of 0`;
    }
    return `Page ${page + 1} of ${Math.ceil(count / rowsPerPage)}`;
  };

  const CustomLabel = ({ value, totalCount }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      {value} <p className="dropdown-custom-label">out of {totalCount}</p>
    </div>
  );

  const customRowsPerPageOptions: any = [
    { label: 'All', value: totalCount, defaultValue: 'All' },
    { label: <CustomLabel value={10} totalCount={totalCount} />, value: 10, defaultValue: 10 },
    { label: <CustomLabel value={25} totalCount={totalCount} />, value: 25, defaultValue: 25 },
    { label: <CustomLabel value={50} totalCount={totalCount} />, value: 50, defaultValue: 50 }
  ];

  const changePos = (e) => {
    const targetElement = document.querySelector('.custom-table-pagination');
    let topPos = 0;
    if (targetElement) {
      const rect = targetElement.getBoundingClientRect();
      topPos = rect?.top - 144;
    }
    setTimeout(() => {
      const closestParent = document.querySelector('.pagination-open .MuiMenu-paper');
      if (closestParent) {
        const closestParentWithStyle = closestParent as HTMLElement;
        closestParentWithStyle.style.top = `${topPos}px`;
      }
    }, 100);
  };

  return (
    <>
      <TablePagination
        //@ts-ignore
        slotProps={{
          select: {
            onOpen: (e) => {
              changePos(e);
            },
            MenuProps: { className: 'pagination-open' }
          }
        }}
        style={{ position: 'relative' }}
        className="custom-table-pagination"
        component="div"
        count={totalCount}
        rowsPerPageOptions={customRowsPerPageOptions}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={labelDisplayedRows}
        SelectProps={{
          renderValue: (value) => {
            const selectedOption = customRowsPerPageOptions.find(
              (option) => option.value === value
            );
            return selectedOption?.defaultValue || '';
          }
        }}
      />
    </>
  );
};

export default Index;
