import { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
import { TickMarkBlue } from '@jibin/common/icons/Tickmark/Tickmark';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SuccessConfirmation from '@jibin/common/components/ConformationModel/SuccessConfirmation';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { SIEMApi } from './SIEM.Api';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import { useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';

const IconButtonComponent = (prop) => {
  const {
    row,
    rowData,
    setRowData,
    setAnchorEl,
    anchorEl,
    setOpenDelete,
    setIsEdit,
    remidiationDrawer,
    GetEndPoint,
    GetEventSummary,
    setIsCreateRemediation
  } = prop;
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const openDD = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event: any, row: any) => {
    event.stopPropagation();
    setRowData(row);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setRowData(null);
  };
  const handleConfirmFalsePositive = async () => {
    try {
      const res = await SIEMApi.MakeFalsePositive(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        rowData?.event_uuid
      );
      GetEndPoint();
      GetEventSummary();
      handleClose();
    } catch (error) { }
  };
  return (
    <>
      <IconButton
        className="event-icon-dots"
        onClick={(e) => {
          handleClick(e, row);
        }}
        id="basic-button"
        aria-controls={openDD ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openDD ? 'true' : undefined}>
        <MoreHorizIcon color="primary" />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id={'account-menu'}
        open={openDD}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        {rowData?.status === 'Not Started' ? (
          <MenuItem
            onClick={() => {
              // handleOpen();
              remidiationDrawer('right', true, rowData);
              setIsCreateRemediation(true)
              handleClose();
            }}>
            <TickMarkBlue />
            <Typography sx={{ ml: 1 }} variant="caption">
              {' '}
              Create Remediation
            </Typography>
          </MenuItem>
        ) : null}
        {(rowData?.remediation_uuid && (rowData?.status === 'Resolved' || rowData?.status === 'In Remediation')) && (
          <MenuItem
            onClick={() => {
              // handleOpen();
              // remidiationDrawer('right', true, rowData);
              navigate(
                `${PageRouteConstants.Remediation}?remediation_uuids=${rowData?.remediation_uuid}`
              )
              handleClose();
            }}>
            <TickMarkBlue />
            <Typography sx={{ ml: 1 }} variant="caption">
              {' '}
              Open Remediation
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            // handleOpenEdit();
            // handleClose();
            setIsEdit(true);
          }}>
          <EditOutlinedIcon sx={{ color: '#436AF3' }} />
          <Typography sx={{ ml: 1 }} variant="caption">
            {' '}
            Edit Event
          </Typography>
        </MenuItem>
        {rowData?.status !== 'False Positive' && (
          <SuccessConfirmation
            isLoading={false}
            checked={false}
            title="Confirm Action"
            type="icon"
            text={<Typography variant="caption"> Mark as False Positive</Typography>}
            description={'Are you sure you want to change this Event Status to “False Positive”?'}
            asking={``}
            iconComponent={<DoDisturbAltIcon />}
            returnData={'Published'}
            mainBtnTitle={
              <Typography sx={{ ml: 1 }} variant="caption">
                {' '}
                Mark as False Positive
              </Typography>
            }
            mainBtnType={'error'}
            cancelButtonText="Cancel"
            conformButtonText="Confirm"
            handleConfirm={handleConfirmFalsePositive}
            handleClose={() => { }}
          />
        )}

        <MenuItem
          onClick={() => {
            setOpenDelete(true);
          }}>
          <DeleteOutlineOutlinedIcon sx={{ color: '#EF4444' }} />
          <Typography sx={{ ml: 1 }} variant="caption">
            {' '}
            Delete Event
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default IconButtonComponent;
