import { Stack, Button, SwipeableDrawer } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { shallowEqual, useSelector } from 'react-redux';
import { useState } from 'react';
import ControlHistoryPopup from './ControlHistoryPopup';

const ControlHistory = ({ control_uuid }) => {
  type Anchor = 'top' | 'left' | 'bottom' | 'right';
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [state, setState] = useState({
    right: false
  });
  const toggleDrawer = (anchor: Anchor, open: boolean) => {
    setState({ ...state, [anchor]: open });
  };
  return (
    <Stack direction={'row'} justifyContent={'flex-end'} sx={{ my: 2 }}>
      <Button
        variant="outlined"
        sx={{ fontSize: '12px' }}
        onClick={() => toggleDrawer('right', true)}>
        <AccessTimeIcon color="primary" sx={{ fontSize: '14px', mr: 1 }} />
        Control History
      </Button>
      <SwipeableDrawer
        BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
        sx={{
          '.MuiDrawer-paper': {
            boxShadow: 'none'
          }
        }}
        anchor={'right'}
        open={state['right']}
        onClose={() => toggleDrawer('right', false)}
        onOpen={() => toggleDrawer('right', true)}>
        <ControlHistoryPopup
          control_uuid={control_uuid}
          isOpen={state.right}
          closePopup={() => toggleDrawer('right', false)}
        />
      </SwipeableDrawer>
    </Stack>
  );
};

export default ControlHistory;
