import HttpService from '@jibin/common/services/http/HttpService';

export const SidebarService = {
  GetUserCompanyData(userId: string, q: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`users/${userId}/companies?keyword=${q}`);
  },
  GetUserData() {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll('users/me');
  },
  GetServices(company_id: string, user_id: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${company_id}/users/${user_id}/services`);
  },
  PatchUserData(userId: string, companyId: string) {
    return HttpService.patchAll(`users/${userId}/companies/${companyId}/switch`, {});
  }
};
