export const ManageUserIcon = () => {
  return (
    <svg width="24" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.87136 4.37345C6.87136 2.64665 8.27319 1.24481 9.99998 1.24481C11.7268 1.24481 13.1286 2.64666 13.1286 4.37345C13.1286 6.10024 11.7268 7.50209 9.99998 7.50209C8.27319 7.50209 6.87136 6.10025 6.87136 4.37345ZM9.99998 0C7.58569 0 5.62654 1.95917 5.62654 4.37345C5.62654 6.78773 7.58569 8.7469 9.99998 8.7469C12.4143 8.7469 14.3734 6.78774 14.3734 4.37345C14.3734 1.95916 12.4143 0 9.99998 0ZM3.1203 16.3776C3.1203 14.2359 4.85825 12.498 6.99999 12.498H13C15.1417 12.498 16.8797 14.2359 16.8797 16.3776C16.8797 17.6895 15.8139 18.7552 14.5021 18.7552H5.49791C4.18605 18.7552 3.1203 17.6895 3.1203 16.3776ZM6.99999 11.2532C4.17077 11.2532 1.87549 13.5484 1.87549 16.3776C1.87549 18.377 3.49857 20 5.49791 20H14.5021C16.5014 20 18.1245 18.377 18.1245 16.3776C18.1245 13.5484 15.8292 11.2532 13 11.2532H6.99999Z"
        fill="white"
      />
    </svg>
  );
};
export const ManageUserIconBlue = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.87136 4.37345C6.87136 2.64665 8.27319 1.24481 9.99998 1.24481C11.7268 1.24481 13.1286 2.64666 13.1286 4.37345C13.1286 6.10024 11.7268 7.50209 9.99998 7.50209C8.27319 7.50209 6.87136 6.10025 6.87136 4.37345ZM9.99998 0C7.58569 0 5.62654 1.95917 5.62654 4.37345C5.62654 6.78773 7.58569 8.7469 9.99998 8.7469C12.4143 8.7469 14.3734 6.78774 14.3734 4.37345C14.3734 1.95916 12.4143 0 9.99998 0ZM3.1203 16.3776C3.1203 14.2359 4.85825 12.498 6.99999 12.498H13C15.1417 12.498 16.8797 14.2359 16.8797 16.3776C16.8797 17.6895 15.8139 18.7552 14.5021 18.7552H5.49791C4.18605 18.7552 3.1203 17.6895 3.1203 16.3776ZM6.99999 11.2532C4.17077 11.2532 1.87549 13.5484 1.87549 16.3776C1.87549 18.377 3.49857 20 5.49791 20H14.5021C16.5014 20 18.1245 18.377 18.1245 16.3776C18.1245 13.5484 15.8292 11.2532 13 11.2532H6.99999Z"
        fill="#436AF3"
      />
    </svg>
  );
};
