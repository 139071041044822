import SvgIcon from '@mui/material/SvgIcon';

export default function SignalCellularIcon(props) {
  return (
    <svg width="24" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.523 0.146021C12.657 -0.3074 13.9325 0.335515 14.2465 1.51615L14.2469 1.51761L15.0875 4.70802H15.4503C15.8064 4.70802 16.0951 4.9967 16.0951 5.3528C16.0951 5.7089 15.8064 5.99758 15.4503 5.99758H4.54496C4.18886 5.99758 3.90018 5.7089 3.90018 5.3528C3.90018 4.9967 4.18886 4.70802 4.54496 4.70802H4.91204L5.75218 1.51926L5.75241 1.51836C6.06202 0.332603 7.34076 -0.305798 8.47573 0.14568L9.99979 0.75362L11.523 0.146021ZM13.0003 1.8476L13.7539 4.70802H6.24561L6.99961 1.84615L7.00004 1.8445C7.11207 1.41467 7.57833 1.17672 7.99908 1.34392L9.54872 1.96206C9.83674 2.07841 10.1607 2.08184 10.4535 1.96099L12.0016 1.34346C12.4148 1.17821 12.884 1.41184 13.0001 1.84688L13.0003 1.8476ZM10.7104 8.54324C10.9505 9.48183 11.8018 10.1757 12.8152 10.1757C14.0149 10.1757 14.9875 9.20313 14.9875 8.0034C14.9875 7.60021 14.8776 7.22267 14.6862 6.89907L13.7896 7.26989C13.9435 7.47402 14.0347 7.72805 14.0347 8.0034C14.0347 8.67695 13.4887 9.22295 12.8152 9.22295C12.199 9.22295 11.6895 8.76594 11.6072 8.17238L10.7104 8.54324ZM5.28166 6.94617C5.10704 7.25905 5.00754 7.61957 5.00754 8.00332C5.00754 9.20305 5.98008 10.1756 7.17983 10.1756C8.19092 10.1756 9.0407 9.48488 9.28294 8.54952L8.38524 8.18981C8.29547 8.77482 7.78997 9.22287 7.17983 9.22287C6.5063 9.22287 5.96031 8.67687 5.96031 8.00332C5.96031 7.74396 6.04127 7.50351 6.1793 7.30586L5.28166 6.94617ZM4.69972 12.0692C3.46492 12.0692 2.54075 13.1983 2.78207 14.4054L3.6888 18.9469H0.285156V20H19.7145V18.9469H16.2979L17.2047 14.4056C17.4461 13.1984 16.5218 12.0692 15.287 12.0692H4.69972ZM15.224 18.9469L16.1719 14.1992C16.283 13.644 15.8575 13.1224 15.287 13.1224H4.69972C4.12919 13.1224 3.70374 13.6438 3.81479 14.1991L4.76272 18.9469H15.224Z"
        fill="white"
      />
    </svg>
  );
}
export function SignalCellularIconBlue(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.523 0.146021C12.657 -0.3074 13.9325 0.335515 14.2465 1.51615L14.2469 1.51761L15.0875 4.70802H15.4503C15.8064 4.70802 16.0951 4.9967 16.0951 5.3528C16.0951 5.7089 15.8064 5.99758 15.4503 5.99758H4.54496C4.18886 5.99758 3.90018 5.7089 3.90018 5.3528C3.90018 4.9967 4.18886 4.70802 4.54496 4.70802H4.91204L5.75218 1.51926L5.75241 1.51836C6.06202 0.332603 7.34076 -0.305798 8.47573 0.14568L9.99979 0.75362L11.523 0.146021ZM13.0003 1.8476L13.7539 4.70802H6.24561L6.99961 1.84615L7.00004 1.8445C7.11207 1.41467 7.57833 1.17672 7.99908 1.34392L9.54872 1.96206C9.83674 2.07841 10.1607 2.08184 10.4535 1.96099L12.0016 1.34346C12.4148 1.17821 12.884 1.41184 13.0001 1.84688L13.0003 1.8476ZM10.7104 8.54324C10.9505 9.48183 11.8018 10.1757 12.8152 10.1757C14.0149 10.1757 14.9875 9.20313 14.9875 8.0034C14.9875 7.60021 14.8776 7.22267 14.6862 6.89907L13.7896 7.26989C13.9435 7.47402 14.0347 7.72805 14.0347 8.0034C14.0347 8.67695 13.4887 9.22295 12.8152 9.22295C12.199 9.22295 11.6895 8.76594 11.6072 8.17238L10.7104 8.54324ZM5.28166 6.94617C5.10704 7.25905 5.00754 7.61957 5.00754 8.00332C5.00754 9.20305 5.98008 10.1756 7.17983 10.1756C8.19092 10.1756 9.0407 9.48488 9.28294 8.54952L8.38524 8.18981C8.29547 8.77482 7.78997 9.22287 7.17983 9.22287C6.5063 9.22287 5.96031 8.67687 5.96031 8.00332C5.96031 7.74396 6.04127 7.50351 6.1793 7.30586L5.28166 6.94617ZM4.69972 12.0692C3.46492 12.0692 2.54075 13.1983 2.78207 14.4054L3.6888 18.9469H0.285156V20H19.7145V18.9469H16.2979L17.2047 14.4056C17.4461 13.1984 16.5218 12.0692 15.287 12.0692H4.69972ZM15.224 18.9469L16.1719 14.1992C16.283 13.644 15.8575 13.1224 15.287 13.1224H4.69972C4.12919 13.1224 3.70374 13.6438 3.81479 14.1991L4.76272 18.9469H15.224Z"
        fill="#436AF3"
      />
    </svg>
  );
}
