import { Box, Typography } from "@mui/material"
import Person from '@jibin/common/icons/Person/Person';
import Style from '@jibin/common/style/Styles';

const Index = () => {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                <Typography
                    sx={{
                        ...Style.versionsCard.infoRound,
                        width: '32px',
                        height: '32px',
                    }}><Person fontSize="small" /></Typography>
                <Typography variant="caption" sx={{ fontWeight: 600 }}>
                    Unassigned
                </Typography></Box>
        </>
    )
}

export default Index