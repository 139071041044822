import Style from '@jibin/common/style/Styles';
import { Button, Link, List, ListItem, Menu, Skeleton, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import SearchIcon from '@jibin/common/icons/Search/Search';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FC, useState, useContext, useCallback, useEffect, useMemo, createContext } from 'react';

import DropDown from '@jibin/common/icons/Dropdown/Dropdown';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';

import { shallowEqual, useSelector } from 'react-redux';
import ArrowBackIcon from '@jibin/common/icons/ArrowBack/ArrowBack';
import { CompanyManagementApi } from '../CompanyManagement.Api';
import { Service } from 'src/Pages/Common/Sidebar';
export const CompnayManageMentContex = createContext(null);
export const ComapnyManagementTemplate: FC<{ children: React.ReactNode | React.ReactNode[] }> = (
  props
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const nevigate = useNavigate();
  const [useParams, setUseParam] = useSearchParams();
  const id = useParams.get('q');
  const company_uuid = useParams.get('company_uuid');
  const [values, setValue] = useState<any>({ Framework: [], Service: [] });
  const [companyData, setCompanyData] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isRefresh, setIsRefresh] = useState(false);
  const [listOfServices, setListOfServices] = useState([]);
  const [listOfFramework, setListOfFramework] = useState([]);
  const [filterData, setFilterData] = useState({
    Service: [''],
    status: ['not_started', 'in_progress', 'reopened'],
    Framework: [''],
    tag_uuids: ['']
  });
  const [onBoardingSteps, setOnBoardingStep] = useState([]);
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const MenuItems = [
    {
      name: 'Company Account',
      class: '',
      route: `${PageRouteConstants.CompanyManagementAccount}?q=${id}&company_uuid=${company_uuid}`
    },
    {
      name: 'Contacts',
      class: '',
      route: `${PageRouteConstants.CompanyManagementContact}?q=${id}&company_uuid=${company_uuid}`
    },
    {
      name: 'Integrations',
      class: '',
      route: `${PageRouteConstants.CompanyManagementIntegrations}?q=${id}&company_uuid=${company_uuid}`
    }
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (commonDetails?.data?.company?.type != 'engineering') {
      nevigate(PageRouteConstants.DashBoard);
    }
  }, [commonDetails]);
  const GetCompanyDetails = useCallback(async () => {
    try {
      const res = await CompanyManagementApi.GetComapnyDetails(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        searchParams.get('q')
      );

      const res2 = await CompanyManagementApi.GetCompanyFrameworks(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid
      );

      const allFrameworks = [];

      res2?.data?.data?.forEach((element) => {
        const enabled = res?.data?.data?.frameworks?.find((el) => el.name === element.name);

        if (enabled?.enabled) {
          allFrameworks.push({
            name: element.name,
            enabled: true
          });
        } else {
          allFrameworks.push({
            name: element.name,
            enabled: false
          });
        }
      });

      setCompanyData(res?.data?.data);
      setOnBoardingStep(res?.data?.data?.settings?.onboarding);
      const services =
        res?.data?.data?.status?.toUpperCase() == 'ACTIVE' ||
        res?.data?.data?.status?.toUpperCase() == 'PENDING'
          ? res?.data?.data?.services || []
          : Service;
      const enabledServiceData = [];
      const servicesKeyValue = services?.map((el: any) => {
        if (el?.enabled) {
          enabledServiceData.push(el.name);
        }
        return {
          key: el?.name,
          value: el?.name,
          enabled: el?.enabled,
          name: el?.name
        };
      });
      const enabledFrameworkData = [];
      const frameworksKeyValue: any = allFrameworks?.map((el: any) => {
        if (el?.enabled) {
          enabledFrameworkData.push(el.name);
        }
        return {
          key: el?.name,
          value: el?.name,
          enabled: el?.enabled,
          name: el?.name
        };
      });
      servicesKeyValue.push({ key: 'Select', value: '', enabled: false, name: 'Select' });
      frameworksKeyValue.push({ key: 'Select', value: '', enabled: false, name: 'Select' });

      setFilterData({
        ...filterData,
        Service: enabledServiceData,
        Framework: enabledFrameworkData
      });
      setListOfFramework(frameworksKeyValue);
      setListOfServices(servicesKeyValue);

      setValue({
        ...values,
        Framework: allFrameworks,
        Service: services
      });
      searchParams.set('company_uuid', res2?.data?.data?.company_uuid);
    } catch (error) {
      console.log('error ::', error);
    }
  }, [commonDetails, isRefresh]);
  useEffect(() => {
    GetCompanyDetails();
  }, [GetCompanyDetails]);
  return (
    <CompnayManageMentContex.Provider
      value={{
        companyData,
        setCompanyData,
        setOnBoardingStep,
        setValue,
        filterData,
        setFilterData,
        listOfFramework,
        setListOfFramework,
        onBoardingSteps,
        listOfServices,
        setListOfServices,
        values
      }}>
      <Box sx={Style.PoliciesAndProcedures.PageHeadTitle}>
        <Typography
          variant="caption"
          sx={{ color: 'primary.main', display: 'flex', alignItem: 'center', mb: 1 }}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            nevigate(PageRouteConstants.CompanyManagement);
          }}>
          <ArrowBackIcon fontSize="small" sx={{ mr: 0.2 }} />
          Back
        </Typography>
        <Typography variant="h2" sx={{ mb: { xs: 1, lg: 2 } }}>
          Company Management
        </Typography>
        <Typography variant="subtitle2">Manage easily your companies in one place</Typography>
      </Box>

      <Box>
        <>
          <List sx={Style.CompanySetting.MenuList}>
            {MenuItems.map((currenElement, index) => (
              <ListItem disablePadding key={index} sx={{ width: 'auto' }}>
                <Link
                  onClick={() => {
                    navigate(currenElement.route);
                  }}
                  style={{ cursor: 'pointer' }}
                  className={
                    `${window.location.pathname}?q=${id}&company_uuid=${company_uuid}` ==
                    currenElement.route
                      ? 'active'
                      : ''
                  }
                  sx={Style.CompanySetting.MenuListItem}>
                  {currenElement.name}
                </Link>
              </ListItem>
            ))}
          </List>
          <Box sx={{ px: 4 }}>{props.children}</Box>
        </>
      </Box>
    </CompnayManageMentContex.Provider>
  );
};

export default ComapnyManagementTemplate;
