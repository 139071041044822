import HttpService from '@jibin/common/services/http/HttpService';

export const EndpointProtectionApi = {
  // /companies/{company_id}/users/{user_id}/multi-edr/devices
  GetAllEndPoint(userId: string, companyId: string, params: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/multi-edr/devices?` + params);
  },
  GetAllProviderOption(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    // /companies/{company_id}/users/{user_id}/multi-edr/filters
    return HttpService.getAll(`companies/${companyId}/users/${userId}/multi-edr/filters`);
  },
  GetAllDeviceDetails(userId: string, companyId: string, edr_key: string) {
    //return HttpService.getAll("todos/1");

    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/multi-edr/devices/${edr_key}`
    );
  },
  PostRemediationData(userId: string, companyId: string, edr_key: string, data: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.post(
      `companies/${companyId}/users/${userId}/multi-edr/devices/${edr_key}/remediations`,
      data
    );
  }
};
