import BorderLinearProgressBlue from '@jibin/common/components/LinearLoading/BorderLinearProgressBlue';
import WarningRed from '@jibin/common/icons/WarningRed/WarningRed';
import CheckEmptyCircle from '@jibin/common/icons/CheckEmptyCircle/CheckEmptyCircle';
import {
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  SwipeableDrawer,
  TextareaAutosize,
  TextField,
  Typography
} from '@mui/material';

import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { FC, useState, useEffect, useCallback, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import './index.css';
import { GapAnalysisApi } from '../GapAnalysis.Api';
import GapAnalysisQ from './GapAnalysisQ/GapAnalysisQ';
import commonDetailSlice from 'src/store/reducers/commonReducer';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ArrowForward } from '@mui/icons-material';
import GapAnlysisSubmited from './GapAnalysisQ/GapAnlysisSubmited';
import LinkIcon from '@jibin/common/icons/LinkIcon';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import RemidiationPopUpList from '@jibin/common/components/RemidiationPopUp/RemidiationPopupList';
import { RemediationApi } from 'src/Pages/Remediation/Remediation.Api';
import UploadIconMin from '@jibin/common/icons/UploadIconMin/UploadIconMin';

import PdfIcon from '@jibin/common/icons/PdfIcon/PdfIcon';
import GapAnalysisSideBarUploadDoc from './GapAnalysisSideBarUploadDoc/GapAnalysisSideBarUploadDoc';
import CreateRemediation from 'src/Pages/Remediation/CreateRemediation';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import ControlHistory from './ControlHistory';
import { LoginContext } from '@jibin/common/Context/LoginContext';
import { CompanyUserRoles } from '@jibin/common/utils/constants/ConstantName';
var debounce = require('lodash.debounce');

const GapAnalysisForm: FC<{
  el: any;
  setValue: any;
  filteredCategories: any;
  selectCategory: any;
  index: any;
  length: any;
  value: any;
  GetCategories: any;
  isAllComplete: boolean;
  isEngineer: boolean;
}> = (prop) => {
  const { engineer } = useContext(LoginContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isRefresh, setIsRefresh] = useState(false);
  const dispatch = useDispatch();

  type Anchor = 'top' | 'left' | 'bottom' | 'right';
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [state, setState] = useState({
    right: false
  });
  const [stateDoc, setStateDoc] = useState({
    right: false
  });
  const toggleDrawer = (anchor: Anchor, open: boolean) => {
    if (!open) {
      prop.GetCategories();
    } else {
      if (el?.remediation?.remediation_uuid) {
        GetRemidiationDetails(el?.remediation?.remediation_uuid);
      }
    }

    setState({ ...state, [anchor]: open });
  };
  const toggleDrawerDoc = (anchor: Anchor, open: boolean, el: any) => {
    if (!open) {
      prop.GetCategories();
    }
    setDocData(el);
    setStateDoc({ ...stateDoc, [anchor]: open });
  };

  const [questions, setQuestions] = useState([] as any);
  const [completeScore, setCompleteScore] = useState(0);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [isGapButtonLoading, setIsGapButtonLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selected, setSelected] = useState('');
  const [isSubmited, setIsSubmited] = useState(false);
  const location = useLocation();
  const [rowData, setRowData] = useState({});
  const [docData, setDocData] = useState({} as any);
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const { el } = prop;

  const getQuestions = () => {
    setIsSkeleton(true);

    const params = {
      control_uuid: el?.control_uuid
    };
    GapAnalysisApi.GetQuestionnaires(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      new URLSearchParams(params).toString()
    ).then(
      (q: any) => {
        setQuestions(q.data.data);
        const filterData = q?.data?.data?.question_answers?.filter(
          (el: any) => !el?.answer?.answer_uuid && el
        );

        if (filterData?.length != 0) {
          setIsSubmited(true);
        } else {
          setIsSubmited(false);
        }
        setIsSkeleton(false);

        // let answered = q.data?.data.filter((q) => q.answer?.questionnaire_answers_uuid);
        // setCompleteScore((answered.length / q.data?.data.length) * 100);
      },
      (err: any) => {}
    );
  };
  const renderstatusColor = (status) => {
    var color = '#F87171';

    if (status == 'in_progress') {
      color = '#FEF3C7';
    } else if (status == 'not_started') {
      color = '#FFE6E2';
    } else if (status == 'reopened') {
      color = '#FDE68A';
    } else {
      color = '#A7F3D0';
    }
    return color;
  };
  async function getEntries() {
    GapAnalysisApi.GetUserData().then(
      async (t: any) => {
        await dispatch(commonDetailSlice.actions.setcommonDetails(t));
        setIsSkeleton(false);
      },
      async (err: any) => {}
    );
  }
  const renderBgColor = (status: any) => {
    let color = '#EF4444';
    if (status == 'Partially Meets') {
      color = '#F59E0B';
    } else if (status == 'Pending') {
      color = '#787878';
    } else if (status == 'Fully Meets') {
      color = '#27AE60';
    } else if (status == 'Not Applicable') {
      color = '#D9E1FD';
    } else if (status == 'Does Not Meet') {
      color = '#EF4444';
    }
    return color;
  };

  const handleSubmitControle = async () => {
    setIsloading(true);
    try {
      const res = await GapAnalysisApi.SubmitControle(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        el?.control_uuid
      );
      prop.GetCategories();
      setIsEdit(false);
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
    }
  };
  const GetRemidiationDetails = async (remediation_uuid: any) => {
    setIsSkeleton(true);
    try {
      const res = await RemediationApi.GetRemediatios(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        remediation_uuid
      );

      setRowData(res.data?.data);
      setIsSkeleton(false);
    } catch (err) {
      setIsSkeleton(false);
      console.log(err);
    }
  };

  const postAnswers = useCallback(
    debounce(
      () => {
        try {
          setIsGapButtonLoading(true);
          let data = {
            status: 'submitted'
          };
          GapAnalysisApi.Submit(
            commonDetails.data.user_uuid,
            commonDetails.data.company.company_uuid,
            data
          ).then(
            async (q: any) => {
              await getEntries();
            },
            (err: any) => {}
          );
        } catch (error) {
        } finally {
          setTimeout(() => {
            setIsGapButtonLoading(false);
          }, 1000);
        }
      },
      500,
      true
    ),
    [commonDetails]
  );

  useEffect(() => {
    getQuestions();

    return () => {
      setQuestions([]);
    };
  }, [commonDetails, location.search, isRefresh]);

  return (
    <Box sx={{ position: 'relative', pb: 4 }}>
      <Box
        sx={{
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '0.2em'
          },
          '&::-webkit-scrollbar-track': {
            borderRadius: '10px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',

            borderRadius: '10px'
          }
        }}>
        <Box sx={{ mb: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle2" style={{ fontWeight: 600, color: '#696969' }}>
                {' '}
                <span style={{ fontWeight: 600, color: '#696969' }}> {el?.control_id}:</span>
                {el?.control_title}{' '}
              </Typography>
            </Box>
            {el?.control_status != 'Not Submitted' &&
            el?.control_status != 'Fully Meets' &&
            !isEdit ? (
              <Box sx={{ ml: 2 }}>
                {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
                  <CreateRemediation
                    engineer={engineer}
                    remediationData={{
                      issueName: el?.control_title,
                      recommendation: el?.implementation_guidelines,
                      description: el?.specification,
                      remediationCreated: el?.remediation?.remediation_uuid ? true : false,
                      control_status: el?.control_status
                    }}
                    controle_id={el?.control_uuid}
                    isGap={true}
                    text={'Create Remediation'}
                    GetRemediations={() => prop.GetCategories()}
                    GetTagsForList={() => {}}
                  />
                )}
              </Box>
            ) : el?.control_status == 'Fully Meets' && !isEdit ? (
              <></>
            ) : (
              commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
                <LoadingButton
                  sx={{ ml: 2 }}
                  loading={isLoading}
                  variant="contained"
                  disabled={isSubmited}
                  onClick={() => handleSubmitControle()}>
                  Submit Control
                </LoadingButton>
              )
            )}
          </Box>

          <Box>
            <Box sx={{ py: 1 }}>
              <Typography variant="caption" sx={{ color: '#9D9D9D' }}>
                This control maps to the following frameworks:
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {questions?.frameworks?.map((el: any) => (
                <Chip
                  variant="outlined"
                  sx={{ bgcolor: '#D9EDFF', border: 'none', color: '#787878', fontSize: '12px' }}
                  label={el?.name}
                />
              ))}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1 }}>
          <Box>
            <Button
              onClick={() => {
                prop.selectCategory(prop.index - 1, prop.filteredCategories[prop.index - 1]);
                prop.setValue(prop.value - 1);
              }}
              sx={{ fontSize: '16px' }}
              disabled={prop.value == 0}
              variant="outlined">
              <ArrowBackIcon sx={{ fontSize: '16px' }} /> Previous
            </Button>
          </Box>
          <Box>
            <Button
              onClick={() => {
                prop.selectCategory(prop.index + 1, prop.filteredCategories[prop.index + 1]);
                prop.setValue(prop.value + 1);
              }}
              sx={{ fontSize: '16px' }}
              disabled={prop.value == prop.length - 1}
              variant="outlined">
              Next <ArrowForward sx={{ fontSize: '16px' }} />
            </Button>
          </Box>
        </Box>
        {(el?.remediation || el?.control_status != 'Not Submitted') && !isEdit ? (
          <Box
            sx={{
              border: '1px solid #D5D5D5',
              borderRadius: 2,
              py: 2,
              mb: 2,
              backgroundColor: '#FFFFFF'
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 2
              }}>
              <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                Control Status:
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '100px',
                  border: '1px solid #DADADA',
                  px: 2,
                  py: 0.5
                }}>
                <Box
                  sx={{
                    height: '12px',
                    width: '12px',
                    backgroundColor: renderBgColor(el?.control_status),
                    borderRadius: '50%',
                    mr: 1
                  }}></Box>
                <Typography variant="body2">{el?.control_status}</Typography>
              </Box>
            </Box>
            <Divider sx={{ my: 1 }} />
            {questions?.question_answers?.map((data, index: string) => (
              <GapAnlysisSubmited
                data={data}
                setIsEdit={setIsEdit}
                setSelected={setSelected}
                index={index}
                getQuestions={() => getQuestions()}
                GetCategories={() => prop.GetCategories()}
                isAllComplete={prop.isAllComplete}
                isEngineer={prop.isEngineer}
              />
            ))}
            {el?.remediation &&
              commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
                <Divider sx={{ width: 1, my: 2 }} />
              )}

            {el?.remediation &&
              commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 2
                  }}>
                  <Typography
                    variant="body2"
                    onClick={() => toggleDrawer('right', true)}
                    sx={{
                      fontWeight: 600,
                      color: '#696969',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}>
                    Track Remediation:{' '}
                    <span style={{ cursor: 'pointer' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="body2" sx={{ color: '#436AF3' }}>
                          Remediation ID-{el?.remediation?.remediation_uuid}
                        </Typography>

                        <LinkIcon />
                      </Box>
                    </span>
                  </Typography>
                  <SwipeableDrawer
                    BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
                    sx={{
                      '.MuiDrawer-paper': {
                        boxShadow: 'none'
                      }
                    }}
                    anchor={'right'}
                    open={state['right']}
                    onClose={() => toggleDrawer('right', false)}
                    onOpen={() => toggleDrawer('right', true)}>
                    {!isSkeleton && (
                      <RemidiationPopUpList
                        el={rowData}
                        isGap={true}
                        remediation_uuid={el?.remediation?.remediation_uuid}
                        closePopup={() => toggleDrawer('right', false)}
                        isEngineer={false}
                        setIsRefresh={setIsRefresh}
                        isRefresh={isRefresh}
                      />
                    )}
                  </SwipeableDrawer>
                  <Typography variant="body2" sx={{ color: '#696969' }}>
                    {' '}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          backgroundColor: renderstatusColor(el?.remediation?.status),
                          py: '6px',
                          px: '30px',
                          fontSize: '12px',
                          borderRadius: '100px'
                        }}>
                        <Typography variant="caption">
                          {' '}
                          {el?.remediation?.status == 'in_progress' ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              {/* <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: '#FBBF24',
            
                                    borderRadius: '50%'
                                  }}></Box> */}
                              <Typography variant="caption">In Progress</Typography>
                            </Box>
                          ) : el?.remediation?.status == 'not_started' ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              {/* <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: '#EF4444',
                                    borderRadius: '50%'
                                  }}></Box> */}
                              <Typography variant="caption">Not Started</Typography>
                            </Box>
                          ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              {/* <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: '#10B981',
                                    borderRadius: '50%'
                                  }}></Box> */}
                              <Typography variant="caption">
                                {el?.remediation?.status.charAt(0).toUpperCase() +
                                  '' +
                                  el?.remediation?.status.slice(1)}
                              </Typography>
                            </Box>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Typography>
                </Box>
              )}
          </Box>
        ) : (
          questions.question_answers?.map((data, index: string) => (
            <GapAnalysisQ
              data={data}
              isEdit={isEdit}
              dataForSideBar={{ controle_id: el?.control_id, controle_title: el?.control_title }}
              index={index}
              getQuestions={() => getQuestions()}
              GetCategories={() => prop.GetCategories()}
              isAllComplete={prop.isAllComplete}
              isEngineer={prop.isEngineer}
            />
          ))
        )}
      </Box>
      <SwipeableDrawer
        anchor={'right'}
        open={stateDoc['right']}
        onClose={() => {
          // getQuestions();
          toggleDrawerDoc('right', false, {});
        }}
        onOpen={() => {
          toggleDrawerDoc('right', true, {});
        }}>
        <GapAnalysisSideBarUploadDoc
          rowData={docData}
          dataForSideBar={{
            controle_id: el?.control_id,

            controle_title: el?.control_title,
            control_uuid: el?.control_uuid
          }}
          closePopup={(e, toggle = false) => {
            // getQuestions();
            toggleDrawerDoc('right', toggle, {});
          }}
        />
        {/* )} */}
      </SwipeableDrawer>
      {commonDetails?.data?.company?.user_role === CompanyUserRoles.AUDITOR &&
      el?.evidence_groups?.length === 0 ? (
        <></>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: 2,
            border: '1px solid #CCCCCC',
            bgcolor: '#F2F9FF',
            p: 2,
            borderRadius: '8px'
          }}>
          <Box sx={{ visibility: el?.evidence_groups?.length > 0 ? 'none' : 'hidden' }}>
            {el?.evidence_groups?.length > 0 &&
              (el?.evidence_groups ? (
                <Box sx={{ display: 'block' }}>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 1.5 }}>
                    {el?.evidence_groups?.map((el, index) => (
                      <Box
                        onClick={() => {
                          toggleDrawerDoc('right', true, el);
                        }}
                        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        key={index}>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center',

                            py: '5px',

                            cursor: 'pointer',
                            height: 1
                          }}>
                          <PdfIcon sx={{ color: '#436AF3' }} />
                          <Typography
                            variant="body1"
                            sx={{
                              color: '#436AF3',
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5
                            }}>
                            <EllipsisContent
                              variant="body2"
                              text={el?.description}
                              maxTextWidth={50}
                              maxWidth={50}
                            />
                            {el?.evidences?.length >= 2 && (
                              <Typography
                                sx={{
                                  color: '#787878',
                                  bgcolor: '#ffffff',

                                  px: 1,
                                  display: 'flex',
                                  lineHeight: 1,
                                  borderRadius: '100px',
                                  fontSize: '12px',
                                  alignItems: 'center',
                                  justifyContent: 'center',

                                  py: '5px'
                                }}
                                variant="caption">
                                +{el?.evidences?.length - 1}
                              </Typography>
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Box></Box>
              ))}
          </Box>
          {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'column',
                alignItems: 'flex-end'
              }}>
              <Box>
                <Button
                  sx={{ bgcolor: '#FFFFFF', fontSize: '16px' }}
                  onClick={() => toggleDrawerDoc('right', true, {})}
                  variant="outlined">
                  Upload Evidence
                  <Box sx={{ ml: 1 }}>
                    <UploadIconMin />
                  </Box>
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )}

      {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
        <ControlHistory control_uuid={el?.control_uuid} />
      )}
    </Box>
  );
};
export default GapAnalysisForm;
