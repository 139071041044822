import React, { useEffect, useState } from 'react'
import { Box, List, Typography, FormControl, Autocomplete, TextField, Chip, Select, MenuItem, IconButton, Button } from "@mui/material";
import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import AnnocementIcon from '@jibin/common/icons/AnnocementIcon';
import HelpSquareIcon from '@jibin/common/icons/HelpSquareIcon';
import ArrowBackIcon from '@jibin/common/icons/ArrowBack/ArrowBack';
import UploadIcon from '@jibin/common/icons/UploadIcon/UploadIcon';
import { toast } from 'react-toastify';
import GallaryIcon from '@jibin/common/icons/GallaryIcon/GallaryIcon';
import VideoPlay from '@jibin/common/icons/VideoPlay/VideoPlay';
import FileIcon from '@jibin/common/icons/FileIcon/FileIcon';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import { LoadingButton } from '@mui/lab';
import { HeaderApi } from '../Header.Api';
import { shallowEqual, useSelector } from 'react-redux';
import { ServiceReviewApi } from '@jibin/app/src/Pages/CustomerSuccess/ServicesReview/ServiceReview.Api'







const ResourceCenter = (props) => {

    const { closePopup, state } = props;
    const [contactUs, setContactUs] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [fileData, setFileData] = useState([] as any);
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);

    const commonDetails = useSelector(
        ({ commonDetails }: any) => commonDetails.commonDetails?.data,
        shallowEqual
    );

    const stopReload = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragEvent = (e: any) => {
        handleImageUpload(e, e.dataTransfer.files);
    };

    const handleImageUpload = (e, files) => {
        stopReload(e);

        if (!e) return;
        const selectedFiles = Array.from(files);
        const newFileCount = selectedFiles.length + fileData.length;

        if (newFileCount > 10) {
            toast.error("Maximum 10 files can be uploaded");
            return;
        }

        setFileData([...fileData, ...selectedFiles]);
    };

    const handleBrowseEvent = (e: any) => {
        const selectedFiles = Array.from(e.target.files);
        const newFileCount = selectedFiles.length + fileData.length;

        if (newFileCount > 10) {
            toast.error("Maximum 10 files can be uploaded");
            return;
        }

        setFileData([...fileData, ...selectedFiles]);
    };

    const handleRemoveFile = (i) => {
        const upadatedFileData = fileData?.filter((el, index) => index != i);
        setFileData(upadatedFileData);
    };

    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const niceBytes = (x) => {
        let l = 0,
            n = parseInt(x, 10) || 0;

        while (n >= 1024 && ++l) {
            n = n / 1024;
        }

        return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
    }

    const handleConfirm = async () => {
        if (!isComplete) return;

        const obj: any = {
            feedback: feedback,
            files: []
        }

        for (let i = 0; i < fileData.length; i++) {
            obj.files.push({
                file_name: fileData[i].name,
                file_size: fileData[i].size,
                file_type: fileData[i].type
            });
        }

        try {
            setIsLoading(true)
            const res = await HeaderApi.HelpCenter(
                commonDetails?.data?.company.company_uuid,
                commonDetails?.data?.user_uuid,
                obj
            )
            await ServiceReviewApi.UploadDocuments(res, fileData);
            toast.success("Feedback sent successfully!")
            closePopup()
            setContactUs(false)
            setIsLoading(false)
        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    const validate = () => {
        let isValid = false;
        if (feedback) {
            isValid = true;
        }
        setIsComplete(isValid)
    }

    useEffect(() => {
        validate()
    }, [feedback])

    useEffect(() => {
        if (!state.right) {
            setContactUs(false)
            setFeedback('')
            setFileData([])
        }
    }, [state.right])

    return (
        <>
            <Box
                sx={{
                    width: { md: 520, xs: 300 },
                    height: '100vh',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column'
                }}
                role="presentation">


                <List sx={{ p: 0 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 1,
                            justifyContent: 'space-between',
                            backgroundColor: '#003260',
                            px: 2.5,
                            alignItems: 'center',
                            py: 1
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: 1,
                                alignItems: 'center'
                            }}>
                            <Typography
                                variant="body1"
                                sx={{ fontSize: '16px', fontWeight: 600, color: '#ffffff', my: 1.25 }}>
                                {'Resource Center'}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box
                                    onClick={(event) => {
                                        closePopup(event);
                                    }}>
                                    <CrossCancle
                                        sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </List>


                <Box sx={{ padding: '24px 20px' }}>
                    {contactUs ?
                        <Box sx={{ display: 'flex', cursor: 'pointer', mb: '30px' }} onClick={() => setContactUs(false)}>
                            <ArrowBackIcon fontSize="small" sx={{ color: '#436AF3' }} />
                            <Typography variant='body2' sx={{ color: '#436AF3', ml: 0.5 }}>Back</Typography>
                        </Box> :
                        <Box sx={{ p: 3, background: '#F2F9FF', cursor: 'pointer', display: 'flex', borderRadius: 1, mb: 2 }} onClick={() => window.open('https://www.redesign-group.com/trust/trust-portal/releasenotes', '_blank')}>
                            <Box sx={{ width: '40px', height: '43px', borderRadius: '4px', mr: 2, backgroundColor: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <AnnocementIcon color={'#436AF3'} />
                            </Box>
                            <Box>
                                <Typography variant='body1' sx={{ fontSize: '19px', fontWeight: 600, mb: '4px' }}>Release Notes</Typography>
                                <Typography variant='body2'>WHAT IS NEW</Typography>
                            </Box>
                        </Box>}
                    <Box onClick={() => setContactUs(true)} sx={{ p: 3, background: '#F2F9FF', display: 'flex', borderRadius: 1, cursor: contactUs ? 'auto' : 'pointer', mb: 2 }}>
                        <Box sx={{ width: '40px', height: '43px', borderRadius: '4px', mr: 2, backgroundColor: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <HelpSquareIcon color={'#436AF3'} />
                        </Box>
                        <Box>
                            <Typography variant='body1' sx={{ fontSize: '19px', fontWeight: 600, mb: '4px' }}>Contact Support</Typography>
                            <Typography variant='body2'>CONTACT SUPPORT</Typography>
                        </Box>
                    </Box>


                    {contactUs && <>
                        <List sx={{ mb: 2 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    gap: 2
                                }}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: '14px', fontWeight: 600, color: '#696969' }}>
                                    Please provide your feedback
                                </Typography>
                                <Box sx={{ width: 1, position: 'relative' }}>
                                    <TextField
                                        size="small"
                                        placeholder="Type here..."
                                        name="feedback"
                                        fullWidth
                                        onChange={(e) => {
                                            if (e.target.value.length <= 999) {
                                                setFeedback(e.target.value);
                                            }
                                        }}
                                        value={feedback}
                                        inputProps={{ sx: { fontSize: '14px', paddingBottom: '20px' } }}
                                        variant="outlined"
                                        rows={8}
                                        multiline
                                        sx={{ fontSize: '14px' }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            bottom: 8,  // Adjust to fine-tune the position
                                            right: 16,  // Adjust to fine-tune the position
                                            pointerEvents: 'none',
                                        }}
                                    >
                                        <Typography variant="caption" sx={{ fontSize: '14px', color: '#B7B7B7' }}>
                                            {`${feedback?.length}/999`}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </List>



                        <List sx={{ mb: 3 }}>
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body2" sx={{ fontWeight: '600', color: '#696969' }}>Upload File</Typography>
                            </Box>
                            <Box
                                onDragOver={(e) => stopReload(e)}
                                onDrop={(e) => handleDragEvent(e)}
                                sx={{
                                    width: '100%',
                                    backgroundColor: '#FBFBFF',
                                    py: 4,
                                    display: 'flex',
                                    border: '1px dashed #CCCCCC',
                                    borderRadius: '8px',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 2.5
                                }}>
                                <UploadIcon />
                                <Typography variant="body1" sx={{ fontWeight: 600, color: '#464646' }}>
                                    Drag & Drop or{' '}
                                    <label style={{ color: '#436AF3', cursor: 'pointer' }}>
                                        <input
                                            multiple
                                            hidden
                                            onChange={(e) => handleBrowseEvent(e)}
                                            style={{
                                                backgroundColor: 'transparent',
                                                color: 'transparent'
                                            }}
                                            type={'file'}
                                        />{' '}
                                        Choose File{' '}
                                    </label>
                                    to upload
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1.5,
                                        width: '100%',
                                        alignItem: 'center',
                                        m: 'auto'
                                    }}>
                                    {fileData.length == 0 ? (
                                        <Typography textAlign={'center'} variant="body2" sx={{ color: '#787878' }}>
                                            Document, Image, or Video
                                        </Typography>
                                    ) : (
                                        fileData?.map((el, index) => (
                                            <Box
                                                key={index + 1}
                                                sx={{
                                                    display: 'flex',
                                                    width: '80%',
                                                    m: 'auto',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    p: 1.5,
                                                    borderRadius: '8px',
                                                    backgroundColor: '#FFFFFF',
                                                    border: '1px solid #EBEBEB'
                                                }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    {el.type.split('/')[0] == 'image' ? (
                                                        <GallaryIcon />
                                                    ) : el.type.split('/')[0] == 'video' ? (
                                                        <VideoPlay />
                                                    ) : (
                                                        <FileIcon />
                                                    )}

                                                    <EllipsisContent
                                                        sx={{
                                                            fontSize: 14,
                                                            color: '#2D2D2D',
                                                            width: 'max-content',
                                                            maxWidth: {
                                                                xxs: 150,
                                                                md: 150
                                                            }
                                                        }}
                                                        text={el.name}
                                                    />

                                                    <Typography variant="body2" sx={{ color: '#787878' }}>
                                                        {niceBytes(el.size)}
                                                    </Typography>
                                                </Box>
                                                <Box onClick={() => handleRemoveFile(index)}>
                                                    <CrossCancle
                                                        sx={{
                                                            background: 'rgba(120, 120, 120,0.1)',
                                                            width: '20px',
                                                            height: '20px',
                                                            color: '#787878',
                                                            p: '6px',
                                                            borderRadius: '50%',
                                                            cursor: 'pointer'
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        ))
                                    )}
                                </Box>
                            </Box>
                        </List>




                        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                            <LoadingButton
                                loading={isLoading}
                                disabled={!isComplete}
                                variant="contained"
                                sx={{ minWidth: 100 }}
                                onClick={handleConfirm}>
                                {' '}
                                Submit
                            </LoadingButton>
                        </Box>




                    </>




                    }

                </Box>





            </Box>
        </>
    )
}

export default ResourceCenter