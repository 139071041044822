import ConformationModel from '@jibin/common/components/ConformationModel';
import CloseIcon from '@jibin/common/icons/Close/Close';
import EditIcon from '@jibin/common/icons/Edit/Edit';
import SearchIcon from '@jibin/common/icons/Search/Search';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import Style from '@jibin/common/style/Styles';
import Countries from '@jibin/common/utils/Statics/Countries.json';
import States from '@jibin/common/utils/Statics/States.json';
import { FormHelperText, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { grey } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import React, { FC, useCallback, useEffect } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import './index.css';
import { shallowEqual, useSelector } from 'react-redux';
import { CompanySettingsService } from '../../CompanySettings.Api';
import { Location } from '../Models/CompanyProfile.Model';

import { CompanyWebsiteProp } from '../Models/CompanyWebsiteProp';
import { LoadingButton } from '@mui/lab';
var debounce = require('lodash.debounce');

export const AddLocationModel: FC<CompanyWebsiteProp> = (companyWebsiteProp) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const locations = [
    { name: 'Data Center', id: 1 },
    { name: 'Corporate Office', id: 2 },
    { name: 'Studio', id: 3 },
    { name: 'Manufacturing Facility', id: 4 },
    { name: 'Warehouse', id: 5 }
  ];

  const [open, setOpen] = React.useState(false);
  const [deleteOption, setDeleteOption] = React.useState('');
  const [value, setValue] = React.useState<any>([]);
  const [country, setCountry] = React.useState('US');
  const [address, setAddress] = React.useState({ address1: '', address2: '' });
  const [adressError, setAdressError] = React.useState({ address1: false, address2: false });
  const handleChangeCountry = (event: any) => {
    setCountry(event.target.value);
  };
  const [openDelete, setOpenDelete] = React.useState(false);
  const [autocompleteInputRef, setautocompleteInputRef] = React.useState({} as any);
  const [isAddressButtonLoading, setIsAddressButtonLoading] = React.useState(false);

  const handleClickOpenDelete = (option, value) => {
    setDeleteOption(option);
    setValue(value);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleChangeAddress = (event: any) => {
    setAddress({ ...address, [event.target.name]: event.target.value });
    if (
      (event.target.name == 'address1' && event.target.value.length > 100) ||
      (event.target.name == 'address2' && event.target.value.length > 100)
    ) {
      setAdressError({ ...adressError, [event.target.name]: true });
    } else {
      setAdressError({ ...adressError, [event.target.name]: false });
    }
  };
  const sortedStates = [...States].sort((a, b) => a.name.localeCompare(b.name));

  const [facilities, setFacilities] = React.useState([]);

  const [setState, setSetState] = React.useState('');
  const handleChangesetSetState = (event: any) => {
    setSetState(event.target.value);
  };
  const handleConfirmDelete = (deleteFacility) => {
    const updatedFacility = value.filter((el, index) => el !== deleteFacility);
    setValue(updatedFacility);
    maketags(updatedFacility);
    setOpenDelete(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    formik.resetForm();
    setAdressError({ address1: false, address2: false });
    setOpen(false);
    setValue([]);
    companyWebsiteProp.handleClose();
  };

  useEffect(() => {
    let data = [];
    if (companyWebsiteProp.LocationProp?.facilities) {
      companyWebsiteProp.LocationProp.facilities.forEach((element) => {
        data.push(element.name);
      });

      maketags(data);
      setValue(data);
    }
  }, [companyWebsiteProp]);

  const maketags = (data: any) => {
    var facilities = [];

    data.forEach((element) => {
      let facilitytype = locations.filter((q) => q.name == element);

      if (facilitytype.length > 0) {
        facilities.push({ type: facilitytype[0].name, status: 'Active', name: element });
      } else {
        facilities.push({ type: 'Others', status: 'Active', name: element });
      }
    });

    setFacilities(facilities);
  };

  const validate = (values: Location) => {
    const errors = {};

    // if (!values.address) {
    //   errors['address'] = 'This field is Required';
    // }

    if (!values.country) {
      errors['country'] = 'This field is Required';
    }
    // if (!values.zip) {
    //   errors['zip'] = 'This field is Required';
    // } else if (values.zip.length > 10) {
    //   errors['zip'] = 'Length can not be more than 10 characters';
    // }

    // if (!values.city) {
    //   errors['city'] = 'This field is Required';
    // } else if (values.city.length > 20) {
    //   errors['city'] = 'Length can not be more than 20 characters';
    // }

    // if (!values.address1) {
    //   errors['address1'] = 'This field is Required';
    // }

    // if (!values.state) {
    //   errors['state'] = 'This field is Required';
    // }

    return errors;
  };

  const formik = useFormik({
    initialValues: new Location(companyWebsiteProp.LocationProp),

    validateOnChange: false,
    validate,
    onSubmit: useCallback(
      debounce(
        (values) => {
          try {
            setIsAddressButtonLoading(true);
            // if (values.address1.length > 100 || values.address2.length > 100) {
            //   return;
            // }
            values['facilities'] = facilities;

            values['status'] = 'Active';
            delete values.company_address_uuid;
            delete values.company_uuid;

            if (companyWebsiteProp.LocationProp.company_address_uuid) {
              //values['company_address_uuid'] = companyWebsiteProp.LocationProp.company_address_uuid;

              //values['company_uuid'] = companyWebsiteProp.LocationProp.company_uuid;
              CompanySettingsService.putLocation(
                commonDetails.data.user_uuid,
                commonDetails.data.company.company_uuid,
                companyWebsiteProp.LocationProp.company_address_uuid,
                values
              ).then(
                (q: any) => {
                  handleClose();
                  companyWebsiteProp.handleSubmit();
                  formik.resetForm();
                },
                (err) => {}
              );
            } else {
              CompanySettingsService.postLocation(
                commonDetails.data.user_uuid,
                commonDetails.data.company.company_uuid,
                values
              ).then(
                (q: any) => {
                  handleClose();
                  companyWebsiteProp.handleSubmit();
                  formik.resetForm();
                },
                (err) => {}
              );
            }
          } catch (error) {
          } finally {
            setTimeout(() => {
              setIsAddressButtonLoading(false);
            }, 1000);
          }
        },
        500,
        true
      ),
      [value, open, commonDetails]
    )
  });
  const [defaultvalueforadd, setDefaultvalueforadd] = React.useState('Mahesana, Gujarat, India');

  const toAddressString = (address: any) => {
    if (!address) {
      return '';
    }

    if(address.address){
      return address.address;
    }

    const arr = [];
    if (address.address1) {
      arr.push(address.address1);
    }

    if (address.address2) {
      arr.push(` ${address.address2}`);
    }

    if (address.city) {
      arr.push(` ${address.city}`);
    }

    if (address.state) {
      arr.push(` ${address.state}`);
    }

    if (address.country) {
      arr.push(` ${address.country}`);
    }

    return arr.join(',');
  };

  const toAddress = (place: any) => {
    const { lat, lng } = place.geometry.location;
    const location = { lat: lat(), lon: lng() };

    return extractAddress(place.address_components, location);
  };

  const extractAddress = (addresses: any[], location: any) => {
    const address = {
      location,
      country: extractAddressData('country', addresses),
      street1: extractAddressData('route', addresses),
      street2: extractAddressData('neighborhood', addresses),
      city: extractAddressData('locality', addresses),
      state: extractAddressData('administrative_area_level_1', addresses),
      zip: extractAddressData('postal_code', addresses)
    };

    return address;
  };

  const extractAddressData = (name: string, addresses: any[]) =>
    addresses.find((c) => c.types.includes(name))?.short_name || '';

  const extractAddressStreetData = (addresses: any[]) => {
    const street = extractAddressData('street_number', addresses);
    if (street) {
      const route = extractAddressData('route', addresses);

      if (route) {
        return `${street} ${route}`;
      }
    }
    return '';
  };

  const AutocompleteAdd = () => {
    const refs = usePlacesWidget<HTMLDivElement>({
      apiKey: process.env.REACT_APP_MAP_KEY,
      libraries: ['places'],
      options: { types: ['address'] },
      onPlaceSelected: (place: any) => {
        //set state
        if (place.address_components) {
          let data = toAddress(place);
          formik.setFieldValue('address', place.formatted_address);
          formik.setFieldValue('address1', data.street1);
          formik.setFieldValue('country', data.country);
          formik.setFieldValue('city', data.city);
          formik.setFieldValue('address2', data.street2);
          formik.setFieldValue('state', data.state);
          formik.setFieldValue('zip', data.zip);
        }
      }
    });
    return (
      <TextField
        inputRef={refs.ref}
        sx={{ zIndex: 10 }}
        size="small"
        autoComplete={'off'}
        id="address"
        name="address"
        variant="outlined"
        placeholder="Address"
        defaultValue={toAddressString(formik.values)}
        fullWidth
      />
    );
  };

  return (
    <>
      {companyWebsiteProp.IsEdit ? (
        <Box>
          <MenuItem onClick={handleClickOpen} sx={{ fontSize: 12 }}>
            <EditIcon sx={{ mr: 2, color: 'primary.main' }} /> Edit
          </MenuItem>
        </Box>
      ) : (
        <Box sx={{}}>
          <Button variant="contained" onClick={handleClickOpen} sx={{ minWidth: 160 }}>
            Add Address
          </Button>
        </Box>
      )}

      {/* <Button variant="contained" onClick={handleClickOpen} sx={{ minWidth: 160 }}>Add Site</Button> */}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="subtitle2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'text.primary',
                fontWeight: '600',
                maxWidth: 'calc(100% - 40px)',
                p: 1
              }}>
              Address
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} sx={{ color: 'text.primary' }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ px: { xs: 2, md: 3 }, pt: 0 }}>
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Email">Address</label>
                    </Box>
                    <AutocompleteAdd />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="FacilityType">Facility Type (select multiple)</label>
                    </Box>
                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={locations
                        .sort((a: any, b: any) => {
                          return a?.name > b?.name ? 1 : -1;
                        })
                        .map((option) => option.name)}
                      freeSolo
                      value={value}
                      renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                            onDelete={() => handleClickOpenDelete(option, value)}
                          />
                        ));
                      }}
                      onChange={(event, value) => {
                        setValue(value);
                        maketags(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          placeholder={value.length > 0 ? '' : 'Head Office, Data Center...'}
                          // InputProps={{
                          //   startAdornment: (
                          //     <InputAdornment position="start">
                          //       <SearchIcon sx={{ color: "grey.500" }} />
                          //     </InputAdornment>
                          //   ),
                          // }}
                        />
                      )}
                      sx={{
                        '&.MuiAutocomplete-root': {
                          '.MuiOutlinedInput-root': {
                            p: 0,
                            pl: 1
                          },
                          '.MuiAutocomplete-tag': {
                            m: 1,
                            bgcolor: 'common.lightBlue',
                            border: 'none'
                          }
                        }
                      }}
                    />
                    <Dialog
                      fullWidth
                      maxWidth="sm"
                      open={openDelete}
                      onClose={handleCloseDelete}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description">
                      <DialogTitle
                        id="alert-dialog-title"
                        sx={{
                          px: { xs: 1, md: 2 },
                          py: { xs: 0.5, md: 1 },
                          bgcolor: 'error.red100',
                          mb: { xs: 2, md: 3 }
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}>
                          <Typography
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              color: 'error.main',
                              fontWeight: '600',
                              maxWidth: 'calc(100% - 40px)',
                              p: 1
                            }}>
                            <WarningIcon sx={{ mr: 1 }} />
                            Confirm Deletion
                          </Typography>
                          <IconButton
                            sx={{ color: 'text.primary' }}
                            aria-label="close"
                            onClick={handleCloseDelete}>
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </DialogTitle>
                      <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
                        <DialogContentText
                          id="alert-dialog-description"
                          sx={{ color: 'text.primary', fontWeight: 700, mb: 1 }}>
                          Are you sure do you want to delete this Facility?
                        </DialogContentText>

                        <DialogContentText
                          id="alert-dialog-description"
                          sx={{ color: 'text.primary' }}>
                          {deleteOption}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0 }}>
                        <Button
                          variant="outlined"
                          sx={{ minWidth: 100 }}
                          onClick={handleCloseDelete}>
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ minWidth: 100 }}
                          color="error"
                          onClick={() => handleConfirmDelete(deleteOption)}>
                          {' '}
                          Confirm
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: 3, pt: 0 }}>
            <Button variant="outlined" sx={{ minWidth: 100 }} color="error" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              loading={isAddressButtonLoading}
              variant="contained"
              sx={{ minWidth: 100 }}
              type="submit">
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default AddLocationModel;
