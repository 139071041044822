import Style from '@jibin/common/style/Styles';
import { Box, Button, Container, FormControl, Grid, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-number-input';
import { Amplify, Auth } from 'aws-amplify';
import { CommonFunctions } from '@jibin/common/utils/Common';
import { useNavigate, useLocation } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';



export const MfaSMS: FC = () => {

    Amplify.configure({
        Auth: {
            userPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID),
            userPoolWebClientId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_CLIENT_ID)
        }
    });

    const navigate = useNavigate();
    const locaton = useLocation();
    const isAllowed = locaton?.state && locaton?.state?.allow;
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneErrorMsg, setPhoneErrorMsg] = useState('');
    const [userDetails, setUserDetails] = useState({} as any);

    const handleChangePhone = (value) => {
        if (/^\+\d{10,15}$/.test(value)) {
            setPhoneErrorMsg("");
        }
        if (phoneErrorMsg == "Please enter phone number" && value) {
            setPhoneErrorMsg("");
        }
        setPhoneNumber(value);
    };

    async function getUserInfo() {
        const user = await Auth.currentAuthenticatedUser();
        setUserDetails(user);
    }

    const sendCode = async () => {
        const phoneNumber_update = await Auth.updateUserAttributes(userDetails, {
            phone_number: phoneNumber
        });
        try {
            const data = await Auth.verifyCurrentUserAttribute('phone_number');
            navigate(PageRouteConstants.Mfa, { state: { mfa_status: "MFA_SMS" } })
        } catch (err) {

        }
    }

    useEffect(() => {
        if (!isAllowed) {
            navigate(PageRouteConstants.Login);
        } else {
            getUserInfo()
        }
    }, [])

    return (
        <Box sx={Style.SignUp.SignUpWrapper}>
            <Container maxWidth="xl">
                <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
                        <Box sx={Style.Auth.AuthLeftPart}>
                            <Box sx={Style.Auth.TrustLogo}>
                                <img alt="" src="/images/trust-logo-black.svg" />
                            </Box>
                            <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                                <Box sx={{ mb: 1 }}>
                                    <label htmlFor="password">Phone Number for Multi-Factor Authentication</label>
                                </Box>
                                <Box
                                    sx={{
                                        border: '1px solid #EBEBEB',
                                        borderRadius: '4px',
                                        px: '14px',
                                        '&:hover': {
                                            outline: '0.8px solid black'
                                        },
                                        '&:focus-within': {
                                            outline: '1.9px solid #436af3 !important'
                                        }
                                    }}>
                                    <PhoneInput
                                        international
                                        autoFormat={false}
                                        defaultCountry="US"
                                        onChange={handleChangePhone}
                                        //@ts-ignore
                                        value={phoneNumber}
                                    />
                                </Box>
                                {phoneErrorMsg != "" && (
                                    <Typography variant="caption" sx={{ color: '#EF4444' }}>
                                        {phoneErrorMsg}
                                    </Typography>
                                )}
                            </FormControl>
                            <Button
                                variant="contained"
                                sx={{ mt: { xs: 3, md: 4, xl: 5 } }}
                                fullWidth
                                onClick={() => {
                                    if (phoneNumber) {
                                        if (/^\+\d{10,15}$/.test(phoneNumber)) {
                                            sendCode();
                                        } else {
                                            setPhoneErrorMsg("Please enter a valid phone number");
                                        }
                                    } else {
                                        setPhoneErrorMsg("Please enter phone number");
                                    }
                                }}
                            >
                                Send Verification Code
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
                        <Box sx={Style.Auth.AuthInfographics}>
                            <img alt="" src="/images/elephant-with-men.png" />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
