import CopyUrl from '@jibin/common/components/ConformationModel/CopyUrl';
import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import Shareicon from '@jibin/common/icons/Shareicon/Shareicon';
import { notifySuccess } from '@jibin/common/services/http/http-common';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { Box, List, Typography } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { GapAnalysisApi } from 'src/Pages/GapAnalysisNew/GapAnalysis.Api';
import NoHistoryAvailable from './NoHistoryAvailable';

const ControlHistoryPopup = ({ closePopup, control_uuid, isOpen }) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [controlHistory, setControlHistory] = useState([]);
  const GetControlHistor = useCallback(async () => {
    try {
      if (isOpen) {
        const res = await GapAnalysisApi.GetControlHistory(
          commonDetails.data.user_uuid,
          commonDetails.data.company.company_uuid,
          control_uuid
        );
        setControlHistory(res?.data?.data);
      }
    } catch (error) {}
  }, [commonDetails, control_uuid, isOpen]);
  useEffect(() => {
    GetControlHistor();
  }, [GetControlHistor]);
  return (
    <Box
      sx={{
        width: { md: 520, xs: 300 },
        height: '100vh',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
      }}
      role="presentation">
      <List sx={{ p: 0 }}>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 1,
            justifyContent: 'space-between',
            backgroundColor: '#003260',
            px: 3,
            py: 2
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',

              width: 1,
              alignItems: 'center'
            }}>
            <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', my: 1 }}>
              Control History
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box
                onClick={(event) => {
                  closePopup(event);
                }}>
                <CrossCancle
                  sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2" sx={{ color: '#FFFFFF' }}>
              Last updated on:{' '}
              {moment(controlHistory[0]?.created_at).format('DD MMM YYYY').toUpperCase()}
            </Typography>
          </Box>
        </List>
      </List>
      <List sx={{ p: 3 }}>
        {controlHistory.length ? 
          <>
            {controlHistory?.map((el: any) => {
              return (
                <List>
                  <Typography variant="body2">
                    <span>
                      {' '}
                      On {moment(el?.created_at).format('DD MMM YYYY [at] hh:mm a').toUpperCase()}{' '}
                    </span>
                    <span
                      style={{ wordBreak: 'break-word' }}
                      dangerouslySetInnerHTML={{ __html: el?.action }}></span>
                  </Typography>
                </List>
              );
            })}
          </> : 
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 136px)' }}>
          <NoHistoryAvailable />
        </Box>}
      </List>
    </Box>
  );
};
export default ControlHistoryPopup;
