import Box from '@mui/material/Box';
import React from 'react';
const ColorDot = (props: any) => {
  return (
    <Box
      sx={{
        minWidth: '14px',
        height: '14px',
        width: '14px',
        backgroundColor: props.color,

        borderRadius: '50%'
      }}></Box>
  );
};
export default ColorDot;
