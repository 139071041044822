import { FC, useCallback, useEffect, useState } from 'react';
import { Button, Grid, Skeleton, Typography } from '@mui/material';

import CalenderIcon from '@jibin/common/icons/CalenderIcon/CalenderIcon';
import Box from '@mui/material/Box';
import { PanetrationApi } from './Panetration.Api';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TopRemediations } from '../Common/TopRemediations/TopRemediations';
import DownloadIcon from '@jibin/common/icons/DownloadIcon/DownloadIcon';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import SimpleBarChart from './BarChart';
import { BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar, Tooltip, LabelList } from 'recharts';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import { NoDataAvailable } from './../Common/NoDataAvailable';
import { TopRiskWidget } from '../Common/TopRiskWidget/TopRiskWidget';
import HeadingHeader from '../Common/HeadingHeader';

export const Panetration: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const navigate = useNavigate();
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [data, setData] = useState({
    critical: [],
    high: [],
    medium: []
  });
  const [penetrationTests, setPenetrationTests] = useState({} as any);
  const [penetrationStats, setPenetrationStats] = useState({} as any);
  const [ptCriticalityCount, setPTCriticalityCount] = useState({
    criticalTotal: 0,
    highTotal: 0,
    mediumTotal: 0
  });
  const [error, setError] = useState(false);
  const renderColorBackGround = (severity: any = 'critical') => {
    var color = '#FFE6E2';
    if (severity == 'critical') {
      color = '#FFE6E2';
    } else if (severity == 'high') {
      color = '#FDE68A';
    } else if (severity == 'medium') {
      color = '#FEF3C7';
    } else if (severity == 'low') {
      color = '#D0DAFC';
    }
    return color;
  };
  const renderColor = (severity: any) => {
    var color = '#F87171';
    if (severity == 'critical') {
      color = '#F87171';
    } else if (severity == 'high') {
      color = '#F59E0B';
    } else if (severity == 'medium') {
      color = '#FBBF24';
    } else if (severity == 'low') {
      color = '#436AF3';
    }
    return color;
  };
  // const GetPenetrationTests = useCallback(() => {
  //   PanetrationApi.PenetrationTests(
  //     commonDetails.data.user_uuid,
  //     commonDetails.data.company.company_uuid
  //   ).then(
  //     (q: any) => {
  //       setPenetrationTests(q.data);
  //     },
  //     (err: any) => { }
  //   );
  // }, [commonDetails]);

  const GetPenetrationStats = useCallback(() => {
    setIsSkeleton(true);
    PanetrationApi.PenetrationStats(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setPenetrationStats(q.data);
        setIsSkeleton(false);
      },
      (err: any) => {
        if (err.response.status == 500) {
          setError(true);
        }
        setIsSkeleton(false);
      }
    );
  }, [commonDetails]);
  const GetRemediatedData = useCallback(() => {
    setIsSkeleton(true);
    PanetrationApi.PenetrationStatsData(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        const critical = [
          { name: 'Open', pv: q.data.data?.critical.open },
          { name: 'Remediated', pv: q.data.data?.critical?.remediated }
        ];
        const high = [
          { name: 'Open', pv: q.data.data?.high.open },
          { name: 'Remediated', pv: q.data.data?.high?.remediated }
        ];
        const medium = [
          { name: 'Open', pv: q.data.data?.medium.open },
          { name: 'Remediated', pv: q.data.data?.medium?.remediated }
        ];
        setData({
          ...data,
          critical: critical,
          high: high,
          medium: medium
        });
        setPTCriticalityCount({
          criticalTotal: q.data.data?.critical.total,
          highTotal: q.data.data?.high?.total,
          mediumTotal: q.data.data?.medium?.total
        });
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  const momentTimezone = require('moment-timezone');

  useEffect(() => {
    GetPenetrationStats();
    GetRemediatedData();
  }, [GetPenetrationStats, GetRemediatedData]);

  return (
    <Box>
      <HeadingHeader
        subtitle1={'Penetration Testing'}
        subtitle2={'Simulated cyberattack to proactively test for vulnerabilities'}
      />
      {isSkeleton ? (
        <Box sx={{ px: 4, py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : error ? (
        <NoDataAvailable
          title="No Data Available"
          message="Go to Technical Information and Setup your Environment"
        />
      ) : (
        <Box sx={{ px: 4, py: 3, width: 1 }}>
          <Box sx={{ color: 'rgba(70, 70, 70, 1)', width: 1 }}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderRadius: '8px',

                  gap: 1,
                  flexWrap: 'wrap'
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1
                  }}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    Testing Results
                  </Typography>
                  {momentTimezone(penetrationStats.data?.scan.current).isValid() && (
                    <Typography variant="caption">
                      Last Test:{' '}
                      {momentTimezone(penetrationStats.data?.scan.current).isValid()
                        ? momentTimezone(penetrationStats.data?.scan.current)
                            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                            .format('DD MMM YYYY')
                            .toUpperCase() +
                          ' at ' +
                          momentTimezone(penetrationStats.data?.scan.current)
                            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                            .format('hh:mm A zz')
                        : 'Not set'}{' '}
                    </Typography>
                  )}
                </Box>

                {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' && (
                  <Box>
                    <Button variant="outlined">
                      Download report <DownloadIcon sx={{ fontSize: '18px', ml: 0.5 }} />
                    </Button>
                  </Box>
                )}
              </Box>
              <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={12} sm={6} md={4} sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      border: '1px solid #CCCCCC',
                      width: 1,
                      height: 1,

                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px'
                    }}>
                    <Box sx={{ p: 3 }}>
                      <Box sx={{ mb: 4 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 1
                          }}>
                          <Typography sx={{ fontWeight: 600 }}>Findings by Criticality</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                          {momentTimezone(penetrationStats.data?.scan.current).isValid() && (
                            <Typography variant="caption">
                              Last Penetration Test :{' '}
                              {momentTimezone(penetrationStats.data?.scan.current).isValid()
                                ? momentTimezone(penetrationStats.data?.scan.current)
                                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                                    .format('DD MMM YYYY')
                                    .toUpperCase()
                                : 'Not set'}{' '}
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            border: '1px solid #EAECF0',
                            borderRadius: '8px',
                            p: '14px'
                          }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Box
                                sx={{
                                  width: '24px',
                                  height: '24px',
                                  backgroundColor: renderColorBackGround('critical'),
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  borderRadius: '50%'
                                }}>
                                <FlagIcon
                                  sx={{
                                    color: renderColor('critical'),
                                    width: '12px'
                                  }}
                                />
                              </Box>
                            </Box>
                            <Typography
                              variant={'body1'}
                              sx={{ fontWeight: 600, color: '#696969' }}>
                              Critical
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                              {ptCriticalityCount?.criticalTotal
                                ? ptCriticalityCount.criticalTotal
                                : 0}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            border: '1px solid #EAECF0',
                            borderRadius: '8px',
                            p: '14px'
                          }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box
                              sx={{
                                width: '24px',
                                height: '24px',
                                backgroundColor: renderColorBackGround('high'),
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '50%'
                              }}>
                              <FlagIcon
                                sx={{
                                  color: renderColor('high'),
                                  width: '12px'
                                }}
                              />
                            </Box>
                            <Typography
                              variant={'body1'}
                              sx={{ fontWeight: 600, color: '#696969' }}>
                              High
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                              {ptCriticalityCount?.highTotal ? ptCriticalityCount?.highTotal : 0}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            border: '1px solid #EAECF0',
                            borderRadius: '8px',
                            p: '14px'
                          }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box
                              sx={{
                                width: '24px',
                                height: '24px',
                                backgroundColor: renderColorBackGround('medium'),
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '50%'
                              }}>
                              <FlagIcon
                                sx={{
                                  color: renderColor('medium'),
                                  width: '12px'
                                }}
                              />
                            </Box>
                            <Typography
                              variant={'body1'}
                              sx={{ fontWeight: 600, color: '#696969' }}>
                              Medium
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                              {ptCriticalityCount?.mediumTotal
                                ? ptCriticalityCount?.mediumTotal
                                : 0}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      border: '1px solid #CCCCCC',
                      width: 1,
                      height: 1,
                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px'
                    }}>
                    <Box sx={{ p: 3 }}>
                      <Box sx={{ mb: 4 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 1
                          }}>
                          <Typography sx={{ fontWeight: 600 }}>Penetration Testing</Typography>
                          <Typography
                            variant="body2"
                            onClick={() => navigate(`${PageRouteConstants.Remediation}?source=pen`)}
                            sx={{ fontWeight: 600, color: ' #436AF3', cursor: 'pointer' }}>
                            See All
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                          <Typography variant="caption">
                            Open vs. Remediated Status by severity{' '}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',

                            borderRadius: '8px',
                            px: '14px'
                          }}>
                          <SimpleBarChart
                            data={data.critical}
                            xKey="name"
                            yKey="pv"
                            color={'#F87171'}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',

                            borderRadius: '8px',
                            px: '14px'
                          }}>
                          <SimpleBarChart
                            data={data.high}
                            xKey="name"
                            yKey="pv"
                            color={'#F59E0B'}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',

                            borderRadius: '8px',
                            px: '14px'
                          }}>
                          <SimpleBarChart
                            data={data.medium}
                            xKey="name"
                            yKey="pv"
                            color={'#FDE68A'}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: 0.9,
                            pt: 4,

                            m: 'auto'
                          }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box
                              sx={{
                                width: '14px',
                                height: '14px',
                                backgroundColor: 'rgba(249, 99, 99, 1)',
                                borderRadius: '50%'
                              }}></Box>
                            <Typography sx={{ fontSize: '14px' }}>Critical</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box
                              sx={{
                                width: '14px',
                                height: '14px',
                                backgroundColor: 'rgba(245, 158, 11, 1)',
                                borderRadius: '50%'
                              }}></Box>
                            <Typography sx={{ fontSize: '14px' }}>High</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box
                              sx={{
                                width: '14px',
                                height: '14px',
                                backgroundColor: 'rgba(253, 230, 138, 1)',
                                borderRadius: '50%'
                              }}></Box>
                            <Typography sx={{ fontSize: '14px' }}>Medium</Typography>
                          </Box>
                          {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '14px',
                          height: '14px',
                          backgroundColor: 'rgba(75, 83, 227,1)',
                          borderRadius: '50%'
                        }}></Box>
                      <Typography sx={{ fontSize: '14px' }}>Passed</Typography>
                    </Box> */}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      border: '1px solid #CCCCCC',
                      height: 1,
                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px'
                    }}>
                    <TopRiskWidget size={10} source="pen" code="PT" column={4} title="Top Risks" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
