import HttpService from '@jibin/common/services/http/HttpService';

export const UpGuardApi = {
  UpGuardApiStats(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/attack_surface_management/stats`
    );
  },
  UpGuardApiRatings(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/attack_surface_management/rating`
    );
  },
  UpGuardTrending(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/attack_surface_management/trend`
    );
  }
  // Remediations(userId: string, companyId: string) {
  //   //return HttpService.getAll("todos/1");
  //   return HttpService.getAll(`companies/${companyId}/users/${userId}/remediations`);
  // },
};
