import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FC } from 'react';
import Box from '@mui/material/Box';
import Settings, { SettingsIconDropDown } from '@jibin/common/icons/Settings/Settings';
import Person from '@jibin/common/icons/Person/Person';
import Logout from '@jibin/common/icons/Logout/Logout';
import ExpandMore from '@jibin/common/icons/ExpandMore/ExpandMore';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Style from '@jibin/common/style/Styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { NotificationSourceTypeConstants } from '@jibin/common/utils/constants/notificationSourceTypeConstants';
import NotificationIcon from '@jibin/common/icons/NotificationIcon';
import AnnocementIcon from '@jibin/common/icons/AnnocementIcon';
import UserPool from '@jibin/common/utils/UserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { IconButton, Link, List, ListItem, Select, Tooltip } from '@mui/material';
import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import moment from 'moment';
import { HeaderApi } from './Header.Api';
import commonDetailSlice from 'src/store/reducers/commonReducer';
import { LoginService } from 'src/Pages/Login/index.api';
import ReleaseNoteDialog from '@jibin/common/components/Header/ReleaseNoteDialog';
import { LoginContext } from '@jibin/common/Context/LoginContext';
import { CompanyUserRoles, UserRoles } from '@jibin/common/utils/constants/ConstantName';
import HelpCenter from './HelpCenter';

interface HeaderProperties {
  showMenu: boolean;
}

export const Header: FC<HeaderProperties> = ({ showMenu }) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails?.commonDetails?.data,
    shallowEqual
  );
  const [socket, setSocket] = useState({} as any);
  const [versionData, setVersionData] = useState<any>({});

  const navigate = useNavigate();
  const [anchorElN, setAnchorElN] = React.useState<null | HTMLElement>(null);
  const openN = Boolean(anchorElN);
  const [currentElement, setCurrentElement] = useState('Unread');
  const [notifications, setNotifications] = useState<any[]>([]);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [versionDataByID, setVersionDataByID] = useState<any>({});
  const handleClickN = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElN(event.currentTarget);
  };
  const handleCloseN = () => {
    setAnchorElN(null);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [waitingToReconnect, setWaitingToReconnect] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const clientRef = useRef(null);
  const { engineer, handleChangeView } = useContext(LoginContext);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const navigateTo = (path: any) => {
    handleClose();
    navigate(path);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = () => {
    localStorage.clear();

    const user = new CognitoUser({
      Username: commonDetails?.data.email,
      Pool: UserPool
    });
    user.signOut();

    handleClose();
    window.location.href = '/';
  };

  const GetNotifications = useCallback(async () => {
    try {
      const res = await HeaderApi.GetNotifications(
        commonDetails?.data?.user_uuid,
        commonDetails?.data?.company.company_uuid
      );
      setNotifications(res.data.data);
      setIsSkeleton(false);
    } catch (error) {}
  }, [commonDetails]);
  const dispatch = useDispatch();
  async function getEntries() {
    LoginService.GetUserData().then(
      async (t: any) => {
        console.log(t);
        dispatch(commonDetailSlice.actions.setcommonDetails(t));
      },

      async (err: any) => {
        console.log(err);
      }
    );
  }
  const GetNewVersion = useCallback(async () => {
    try {
      const res = await HeaderApi.GetNewVersion();
      setVersionData(res?.data?.data);
      GetVersionByUserID(res?.data?.data?.version);
    } catch (error) {}
  }, []);
  const GetVersionByUserID = async (version: string) => {
    try {
      const res = await HeaderApi.GetNewVersionID(commonDetails?.data?.user_uuid, version);
      setVersionDataByID(res?.data?.data);
    } catch (error) {}
  };
  const handleSubmitVersionRead = async (version: string) => {
    try {
      if (versionDataByID?.is_read) {
        return;
      }
      if (version) {
        const res = await HeaderApi.ReadVersionByID(commonDetails?.data?.user_uuid, version);
        GetVersionByUserID(version);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (commonDetails) {
      GetNewVersion();
    }
    if (
      commonDetails?.data?.group != 'superadmin' &&
      commonDetails?.data?.group != UserRoles.SERVICE_DELIVERY_COORDINATOR
    ) {
      if (engineer == null) {
        if (commonDetails?.data?.group == UserRoles.CYBER_SECURITY_ENGINEER) {
          handleChangeView(true);
        } else {
          handleChangeView(false);
        }
      }
    }
  }, [GetNewVersion]);
  useEffect(() => {
    if (commonDetails && commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR) {
      GetNotifications();
      if (waitingToReconnect) {
        return;
      }

      try {
        // Only set up the websocket once
        if (!clientRef.current) {
          const client = new WebSocket(
            `${process.env.REACT_APP_WEBSOCKET_HOST}ws/users/${commonDetails?.data.user_uuid}`
          );
          clientRef.current = client;
          //window.client = client;

          client.onerror = (e) => console.error(e);

          client.onopen = () => {
            setIsOpen(true);
            // console.log('ws opened');
            //client.send('ping');
          };

          client.onmessage = (event) => {
            // Company Deactivated
            if (JSON.parse(event.data).data.type == 'notification') {
              if (JSON.parse(event.data).data.data?.subject == 'Company Deactivated') {
                getEntries();
              } else {
                setNotifications((notifications) => [
                  ...notifications,
                  JSON.parse(event.data).data.data
                ]);
              }
            }
          };

          client.onclose = () => {
            if (clientRef.current) {
              // Connection failed
              // console.log('ws closed by server');
            } else {
              // Cleanup initiated from app side, can return here, to not attempt a reconnect
              // console.log('ws closed by app component unmount');
              return;
            }

            if (waitingToReconnect) {
              return;
            }

            // Parse event code and log
            setIsOpen(false);
            // console.log('ws closed');

            // Setting this will trigger a re-run of the effect,
            // cleaning up the current websocket, but not setting
            // up a new one right away
            setWaitingToReconnect(true);

            // This will trigger another re-run, and because it is false,
            // the socket will be set up again
            setTimeout(() => setWaitingToReconnect(null), 5000);
          };

          return () => {
            // console.log('Cleanup');
            // Dereference, so it will set up next time
            clientRef.current = null;

            client.close();
          };
        }
      } catch (error) {}
    }
  }, [waitingToReconnect, GetNotifications]);

  const handleUpdateStaus = (el: any, isAll: boolean) => {
    let status = currentElement.toLowerCase() == 'read' ? 'unread' : 'read';

    let data = { status: status, notification_uuids: [] };
    if (isAll) {
      let currNotification = notifications.filter(
        (q) => q.status.toLowerCase() == currentElement.toLowerCase()
      );
      currNotification.forEach((element) => {
        data.notification_uuids.push(element.notification_uuid);
      });
    } else {
      data.notification_uuids.push(el.notification_uuid);
    }

    HeaderApi.PatchNotifications(
      commonDetails?.data.user_uuid,
      commonDetails?.data.company.company_uuid,
      data
    ).then((q) => {
      data.notification_uuids.forEach((element) => {
        updateStatus(data.notification_uuids, status);
      });
    });
  };

  const updateStatus = (idsToUpdate: any[], status: string) => {
    let notify = [...notifications];
    idsToUpdate.forEach((element) => {
      let index = notify.findIndex((q) => q.notification_uuid == element);
      notify[index].status = status;
    });
    setNotifications(notify);
  };

  const renderName = (el) => {
    if (el.created_by?.first_name) {
      return el.created_by?.first_name?.charAt(0) + el.created_by?.last_name?.charAt(0);
    } else {
      return el.source?.charAt(0);
    }
  };

  const redirectToPage = (el) => {
    let queryParam = new URLSearchParams(el?.meta).toString();
    switch (el.source) {
      case NotificationSourceTypeConstants.Authorizations:
        navigate(PageRouteConstants.Authorizations + '?' + queryParam);
        break;
      case NotificationSourceTypeConstants.CompanyInformation:
        navigate(PageRouteConstants.CompanyProfile + '?' + queryParam);
        break;
      case NotificationSourceTypeConstants.CustomerSuccess:
        navigate(PageRouteConstants.ServicesReview + '?' + queryParam);
        break;
      case NotificationSourceTypeConstants.Endpoint:
        navigate(PageRouteConstants.EndpointProtection + '?' + queryParam);
        break;
      case NotificationSourceTypeConstants.GapAnalysis:
        navigate(PageRouteConstants.GapAnalysis + '?' + queryParam);
        break;
      case NotificationSourceTypeConstants.Locations:
        navigate(PageRouteConstants.CompanyProfile + '?' + queryParam);
        break;
      case NotificationSourceTypeConstants.ManageUsers:
        navigate(PageRouteConstants.ManageUsers + '?' + queryParam);
        break;
      case NotificationSourceTypeConstants.Meetings:
        navigate(PageRouteConstants.Meetings + '?' + queryParam);
        break;
      case NotificationSourceTypeConstants.MyAccount:
        navigate(PageRouteConstants.MyAccount + '?' + queryParam);
        break;
      case NotificationSourceTypeConstants.Penetration:
        navigate(PageRouteConstants.Penetration + '?' + queryParam);
        break;
      case NotificationSourceTypeConstants.PlanandBilling:
        navigate(PageRouteConstants.PlanAndBilling + '?' + queryParam);
        break;
      case NotificationSourceTypeConstants.Remediation:
      case NotificationSourceTypeConstants.RemediationTracker:
        navigate(PageRouteConstants.Remediation + '?' + queryParam);
        break;
      case NotificationSourceTypeConstants.TechnicalInformationApplications:
      case NotificationSourceTypeConstants.TechnicalInformation:
        navigate(PageRouteConstants.Applications + '?' + queryParam);
        break;
      case NotificationSourceTypeConstants.TechnicalInformationIPRanges:
        navigate(PageRouteConstants.ExternalInfrastructureAssessment + '?' + queryParam);
        break;
      case NotificationSourceTypeConstants.TechnicalInformationWireless:
        navigate(PageRouteConstants.WirelessAssessment + '?' + queryParam);
        break;
      case NotificationSourceTypeConstants.Vulnerability:
        navigate(PageRouteConstants.Vulnerability + '?' + queryParam);
        break;
    }
    handleCloseN();
  };
  const sortedNotifications = (notify: any) => {
    return notify.sort((a: any, b: any) => {
      return a?.created_at < b?.created_at ? 1 : -1;
    });
  };

  return (
    <Box component="header" sx={Style.Header.HeaderWrapper}>
      <Box sx={Style.Header.HeaderLogo}>
        <a href="http://www.redesign-group.com/" target="_blank">
          <img alt="" src="/images/trust-logo-black.svg" />
        </a>
      </Box>
      {showMenu && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {commonDetails?.data?.group == UserRoles?.SUPER_ADMIN && (
            <Select
              sx={{ fontSize: '14px' }}
              size="small"
              value={engineer ? 'engineer' : 'user'}
              onChange={(e: any) => {
                if (e.target.value == 'engineer') {
                  handleChangeView(true);
                } else {
                  handleChangeView(false);
                }
              }}>
              <MenuItem sx={{ fontSize: '14px' }} value={'user'}>
                {' '}
                User View
              </MenuItem>
              <MenuItem sx={{ fontSize: '14px' }} value={'engineer'}>
                Engineer View
              </MenuItem>
            </Select>
          )}

          <IconButton
            onClick={() => handleSubmitVersionRead(versionDataByID?.version)}
            sx={{
              ' path': {
                stroke: versionDataByID?.is_read ? '#B7B7B7' : '#436AF3'
              }
            }}
            href={versionData?.release_notes_url}
            target="_blank">
            <AnnocementIcon />
          </IconButton>
          {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
            <IconButton
              onClick={handleClickN}
              aria-controls={openN ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openN ? 'true' : undefined}
              sx={{ display: 'flex', alignItems: 'center', gap: 0.5, borderRadius: '4px' }}>
              <NotificationIcon />
              {notifications &&
                sortedNotifications(notifications)?.filter((q) => q.status != 'read').length >
                  0 && (
                  <Box sx={Style.Header.NotificationNumber}>
                    <Typography
                      variant="caption"
                      sx={{ fontSize: '10px', textAlign: 'center', color: '#FBFBFF' }}>
                      {(() => {
                        const unreadCount = notifications?.filter(
                          (q) => q.status !== 'read'
                        ).length;
                        return unreadCount > 99 ? '99+' : unreadCount;
                      })()}
                    </Typography>
                  </Box>
                )}
            </IconButton>
          )}

          <Menu
            anchorEl={anchorElN}
            id="account-menu"
            open={openN}
            onClose={handleCloseN}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                width: 370,
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuItem disableTouchRipple sx={{ justifyContent: 'space-between' }}>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                Notifications
              </Typography>
              <IconButton onClick={handleCloseN}>
                {' '}
                <CrossCancle sx={{ color: '#9D9D9D', width: '14px', height: '14px' }} />
              </IconButton>
            </MenuItem>
            <List sx={{ ...Style.CompanySetting.MenuList, px: 2, justifyContent: 'space-between' }}>
              <ListItem disablePadding sx={{ width: 'auto' }}>
                <Link
                  onClick={() => {
                    setCurrentElement('Unread');
                  }}
                  style={{ cursor: 'pointer', fontSize: '14px' }}
                  className={currentElement === 'Unread' ? 'active' : ''}
                  sx={Style.CompanySetting.MenuListItem}>
                  Unread
                </Link>
                <Link
                  onClick={() => {
                    setCurrentElement('Read');
                  }}
                  style={{ cursor: 'pointer', fontSize: '14px' }}
                  className={currentElement === 'Read' ? 'active' : ''}
                  sx={Style.CompanySetting.MenuListItem}>
                  Read
                </Link>
              </ListItem>
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  handleUpdateStaus({}, true);
                }}>
                <Typography variant="caption" sx={{ fontWeight: 600, color: '#436AF3' }}>
                  Mark All as {currentElement == 'Read' ? 'Unread' : 'Read'}
                </Typography>
              </Box>
            </List>
            <Box sx={{ px: 2, maxHeight: '400px', overflowY: 'auto' }}>
              {notifications
                ?.filter((q) => q.status?.toUpperCase() == currentElement?.toUpperCase())
                .map((el, index) => (
                  <Box
                    key={el.notification_uuid}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      justifyContent: 'space-between',
                      mb: 2
                    }}>
                    <Box
                      onClick={() => {
                        redirectToPage(el);
                      }}
                      sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box>
                          {el.created_by.ico ? (
                            <Box
                              sx={{
                                ...Style.UserNameRound
                              }}>
                              <img
                                width={'100%'}
                                height="100%"
                                style={{ objectFit: 'cover', borderRadius: '50%' }}
                                src={`${process.env.REACT_APP_API_HOST}/${el.created_by.ico}`}
                                alt=""
                              />
                            </Box>
                          ) : (
                            <Typography variant="caption" sx={Style.UserNameRound}>
                              {renderName(el)}
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      <Box>
                        <Typography variant="body2">
                          <span
                            style={{ wordBreak: 'break-word' }}
                            dangerouslySetInnerHTML={{ __html: el.body_html }}></span>
                          <span style={{ color: 'rgba(183, 183, 183, 1)' }}>
                            {' '}
                            {moment(el.created_at).fromNow()}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          cursor: 'pointer',
                          height: '14px',
                          p: 0.25,
                          width: '14px',
                          border: '1px solid #DADADA',
                          borderRadius: '50%'
                        }}>
                        <Tooltip title={`Mark as ${el.status == 'read' ? 'Unread' : 'Read'}`}>
                          <Box
                            onClick={() => {
                              handleUpdateStaus(el, false);
                            }}
                            sx={{
                              height: 1,
                              width: 1,
                              borderRadius: '50%',
                              backgroundColor: el.status != 'read' ? '#436AF3' : 'transparent'
                            }}></Box>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>

            <Box sx={{ textAlign: 'center' }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, cursor: 'pointer', color: '#B7B7B7' }}>
                Notifications from the last 30 days.
              </Typography>
            </Box>
          </Menu>

          <HelpCenter />

          <Box sx={Style.Header.UserProfile}>
            {commonDetails?.data?.ico ? (
              <Box sx={Style.UserNameRound}>
                <img
                  width={'100%'}
                  height="100%"
                  style={{ objectFit: 'cover', borderRadius: '50%' }}
                  src={`${process.env.REACT_APP_API_HOST}/${commonDetails?.data.ico}`}
                  alt=""
                />
              </Box>
            ) : (
              <Typography variant="caption" sx={Style.UserNameRound}>
                {commonDetails?.data?.first_name?.charAt(0) +
                  commonDetails?.data?.last_name?.charAt(0)}
              </Typography>
            )}

            <Box>
              <Button
                disableRipple
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                endIcon={<ExpandMore />}
                sx={Style.Header.ProfileButton}>
                {commonDetails?.data.first_name + ' ' + commonDetails?.data.last_name}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}>
                <MenuItem
                  onClick={() => {
                    navigateTo(PageRouteConstants.MyAccount);
                  }}>
                  <Person fontSize="small" sx={Style.MenuIcon} /> My Account
                </MenuItem>
                {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
                  <MenuItem
                    onClick={() => {
                      navigateTo(PageRouteConstants.General);
                    }}
                    sx={{ display: 'flex', alignItems: 'center' }}>
                    <SettingsIconDropDown
                      fontSize="small"
                      sx={{ ...Style.MenuIcon, path: { fill: '#464646' } }}
                    />
                    {commonDetails?.data?.company?.type == 'engineering'
                      ? 'Manage User'
                      : 'Company Account'}
                  </MenuItem>
                )}

                <ReleaseNoteDialog versionData={versionData} />
                <MenuItem onClick={handleSignout}>
                  <Logout fontSize="small" sx={Style.MenuIcon} /> Sign out
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Header;
