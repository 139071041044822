import CloseIcon from '@jibin/common/icons/Close/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Style from '@jibin/common/style/Styles';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { MenuItem } from '@mui/material';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { PoliciesAndProceduresApi } from '../Policys.Api';

// export interface DialogTitleProps {
//   id: string;
//   children?: React.ReactNode;
//   onClose: () => void;
// }

export default function CreateTemplateModel() {
  const [templateData, setTemplateData] = React.useState({} as any);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const nevigate = useNavigate();

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const GetUserDetails = useCallback(() => {
    PoliciesAndProceduresApi.GetTemplates(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setTemplateData(q.data);
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  useEffect(() => {
    GetUserDetails();
  }, [GetUserDetails]);

  const newPolicy = (templateId: any) => {
    nevigate(`${PageRouteConstants.PoliciesAndProceduresTemplate + templateId}?isTemplate=true`);

    // PoliciesAndProceduresApi.CreateNewPolicyFromTemplate(
    //   commonDetails.data.user_uuid,
    //   commonDetails.data.company.company_uuid,
    //   templateId
    // ).then(
    //   (q: any) => {
    //     nevigate(
    //       `${
    //         PageRouteConstants.PoliciesAndProceduresTemplate + q.data.data.policy_uuid
    //       }?isTemplate=true`
    //     );
    //   },
    //   (err: any) => {}
    // );
  };

  return (
    <>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Create from Template
      </Button> */}

      <MenuItem onClick={handleClickOpen} sx={{ fontSize: '14px' }}>
        View from Template
      </MenuItem>
      {templateData?.data && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle
            id="alert-dialog-title"
            sx={{ px: { xs: 1, md: 2 }, py: { xs: 0.5, md: 1 }, bgcolor: 'error.red100', mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  flex: '1 1 auto',
                  color: 'text.primary',
                  fontWeight: '600',
                  p: 1,
                  textAlign: 'center'
                }}>
                Select a Template
                <Typography variant="body2" component="span" sx={{ display: 'block', mt: 1 }}>
                  Save time and gain agility selecting the templates you need.{' '}
                </Typography>
              </Typography>
              <IconButton sx={{ color: 'text.primary' }} aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ px: { xs: 2, md: 3 } }}>
            <Grid
              container
              spacing={2}
              sx={{
                display: { md: 'flex', xs: 'none' },
                borderBottom: '1px solid',
                borderColor: 'common.borderColor',
                pb: 1
              }}>
              <Grid item xs={12} md={3}>
                <Typography sx={{ width: '20%', pr: 2 }}>Template</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography>Description</Typography>
              </Grid>
            </Grid>
            {templateData?.data.map((currentElement, index) => (
              <Grid container spacing={{ xs: 1, sm: 2 }} key={index} sx={{ pt: 3 }}>
                <Grid item xs={12} md={3}>
                  <Typography variant="caption">{currentElement.name}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="caption">{currentElement.description} </Typography>
                </Grid>
                <Grid item xs={12} md={3} sx={{ textAlign: { md: 'right' } }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      newPolicy(currentElement.policy_template_uuid);
                    }}
                    sx={{ minWidth: 150 }}>
                    {' '}
                    View{' '}
                  </Button>
                </Grid>
              </Grid>
            ))}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
