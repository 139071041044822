import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  TextField
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import CheckCircle from '@jibin/common/icons/CheckCircle/CheckCircle';
import ListItem from '@mui/material/ListItem';
import Style from '@jibin/common/style/Styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import commonDetailSlice from 'src/store/reducers/commonReducer';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { OnboardingApi } from './index.api';
import CloseIcon from '@jibin/common/icons/Close/Close';
import DownloadIcon from '@jibin/common/icons/DownloadIcon/DownloadIcon';
import { notifyError } from '@jibin/common/services/http/http-common';
import HeadingHeader from '../Common/HeadingHeader';
import AWS, { CognitoIdentityServiceProvider } from 'aws-sdk';
import UserPool from '@jibin/common/utils/UserPool';
import axios from 'axios';
import { Amplify, Auth } from 'aws-amplify';
import { CommonFunctions } from '@jibin/common/utils/Common';
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});
AWS.config.logger = console;
// Create an instance of CognitoIdentityServiceProvider

// Define the parameters for the adminSetUserPassword function

// Call adminSetUserPassword to set the password for the user

const Onboarding: FC = () => {
  const dispatch = useDispatch();
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [commonDetailsOn, setCommonDetails] = useState({} as any);
  const [open, setOpen] = useState(false);
  const [isAllStepComplete, setIsAllStepComplete] = useState(false);
  const [fileValue, setFileValue] = useState(null);
  const [fileName, setFileName] = useState('');
  const [url, setUrl] = useState('');
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setFileValue(null);
    setFileName('');
    setOpen(false);
  };
  Amplify.configure({
    Auth: {
      userPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID),
      userPoolWebClientId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_CLIENT_ID)
    }
  });
  const setPassword = async () => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    const accessToken = session.getAccessToken().getJwtToken();
    try {
      const data = {
        Password: 'Admin@123', // Replace with your desired password
        Permanent: true,
        Username: 'komal.padaria@nurdsoft.co',
        UserPoolId: UserPool.getUserPoolId() // Replace with the username of the user you want to update
      };
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://cognito-idp.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/x-amz-json-1.1'
        },
        data: JSON.stringify(data)
      };

      const data2 = await axios.request(config);
      console.log(data2);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (commonDetails?.data) {
      getEntries();
      getSecurityData();
    }
  }, [commonDetails]);

  async function getEntries() {
    OnboardingApi.GetUserData().then(
      async (t: any) => {
        if (
          t.data?.data?.company?.onboarding?.filter((q) => q.status == 'DRAFT').length == 0 ||
          t.data?.data?.company?.onboarding?.length == 0 ||
          !t.data?.data?.company?.onboarding
        ) {
          if (!isAllStepComplete) {
            setIsAllStepComplete(true);
            await dispatch(commonDetailSlice.actions.setcommonDetails(t));
          }
          navigate(PageRouteConstants.DashBoard);
        }
        setCommonDetails(t.data);
      },
      async (err: any) => {}
    );
  }

  const getSecurityData = async () => {
    try {
      const res = await OnboardingApi.GetSecurityAwareness(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid
      );

      const blob = new Blob([res.data], { type: 'text/csv' });

      // Creating an object for downloading url
      const url = window.URL.createObjectURL(blob);

      // Creating an anchor(a) tag of HTML

      setUrl(url);
    } catch (e) {
      console.log(e);
    }
  };
  const submitDocument = async (event) => {
    if (!fileValue) {
      notifyError('Please upload file');
      return;
    }
    try {
      const res = await OnboardingApi.PostDocument(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        fileValue
      );
      handleClose();
      getEntries();
    } catch (e) {
      console.log(e);
    }
  };

  const navigate = useNavigate();
  return (
    <>
      <HeadingHeader
        subtitle1={'Onboarding'}
        subtitle2={'Take the first step to rapidly improve your cybersecurity program'}
      />
      {/* <Button onClick={setPassword}>Set Password</Button> */}
      {commonDetailsOn?.data && (
        <Box sx={{ pt: '12px', ...Style.OnBoarding.ListWrapper }}>
          {commonDetailsOn?.data?.company?.onboarding?.map((ob: any, index: any) => (
            <List disablePadding key={ob.name}>
              <ListItem disablePadding sx={Style.OnBoarding.ListItem}>
                <Box sx={Style.OnBoarding.ListContent}>
                  {ob.status !== 'DRAFT' ? (
                    <CheckCircle sx={{ color: 'common.green', mr: 1.5 }} />
                  ) : (
                    <CheckCircle sx={{ color: 'common.grey', mr: 1.5 }} />
                  )}
                  <Typography variant="subtitle2">{ob.name}</Typography>
                </Box>
                {ob.status !== 'DRAFT' ? (
                  <Button
                    onClick={() => {
                      navigate(ob.url);
                    }}
                    sx={Style.OnBoarding.CompleteTaskButton}
                    disableElevation
                    disableRipple
                    variant="text">
                    Manage
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      ob.url == '/security-awareness' ? handleOpen() : navigate(ob.url);
                    }}
                    sx={Style.OnBoarding.TaskButton}
                    disableElevation
                    disableRipple
                    color="primary"
                    variant="contained">
                    Start
                  </Button>
                )}
              </ListItem>
            </List>
          ))}

          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'text.primary',
                    fontWeight: '600',
                    maxWidth: 'calc(100% - 40px)',
                    p: 1
                  }}>
                  Setup File Repository
                </Typography>
                <IconButton aria-label="close" onClick={handleClose} sx={{ color: 'text.primary' }}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ px: { xs: 2, md: 3 }, pt: 0 }}>
              <DialogContentText id="alert-dialog-description">
                <Box sx={{ m: 'auto', width: 1, display: 'flex', justifyContent: 'center', mb: 7 }}>
                  <img src={'/images/KB4-logo.png'} />
                </Box>
                <Box>
                  <Typography variant="body1" sx={{ textAlign: 'center', mb: 7 }}>
                    Initiate a customised Security Awareness Program and improve your company's
                    security awareness.
                  </Typography>
                </Box>
                <Box sx={{ width: 1 }}>
                  <Box sx={{ width: 0.5, m: 'auto' }}>
                    <Box sx={{ mb: 1 }}>
                      <Typography sx={{ color: '#464646' }} variant="caption">
                        Upload your team mailing list (.csv format)
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',

                        gap: 1,
                        m: 'auto',
                        mb: 1
                      }}>
                      <Box
                        sx={{
                          border: '1px solid #9D9D9D',
                          padding: 1,
                          height: '36px',

                          borderRadius: '4px',
                          width: 0.8,
                          whiteSpace: 'nowrap',

                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>
                        {fileName?.split('\\').pop()}
                      </Box>
                      <Button variant="contained" component="label">
                        Upload
                        <input
                          type="file"
                          hidden
                          accept=".csv"
                          onChange={(e) => {
                            setFileValue(e.target.files[0]);
                            setFileName(e.target.value);
                          }}
                        />
                      </Button>
                    </Box>
                    <a
                      href={url}
                      download={'sample_campaign_users.csv'}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        textDecoration: 'none',
                        color: '#464646'
                      }}>
                      <Typography variant="body2">Download CSV template</Typography>
                      <DownloadIcon />
                    </a>
                  </Box>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: 3, pt: 0, mt: 4 }}>
              <Button variant="outlined" sx={{ minWidth: 130 }} onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" sx={{ minWidth: 130 }} onClick={submitDocument}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
};

export default Onboarding;
