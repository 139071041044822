import { ConstantName } from '../constants/ConstantName';

export const CommonFunctions = {
  getDefaultString: (value: any) => {
    return value ? value : '';
  }
};

export const GetAssignImage = (companyName, imagePath) => {
  const path =
    (companyName?.toUpperCase() === ConstantName.REDESIGN_TRUST_GROUP ||
      companyName?.toUpperCase() === ConstantName.REDESIGN_TRUST_PORTAL ||
      companyName?.toUpperCase() === ConstantName.REDESIGN_TRUST_TEAM_THE ||
      companyName?.toUpperCase() === ConstantName.REDESIGN_TRUST_TEAM ||
      companyName?.toUpperCase() === ConstantName.REDESIGN_TRUST_PORTAL_ENGG ||
      companyName?.toUpperCase() === ConstantName.REDESIGN_TRUST_GROUP_ENGG) &&
    imagePath.includes('https://')
      ? imagePath
      : `${process.env.REACT_APP_API_HOST}/${imagePath}`;
  return path;
};
