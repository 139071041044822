import { Avatar, Button, IconButton, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useCallback, useEffect, useState } from 'react';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import EditProfilePopUp from './EditProfilePopup';
import ChangePasswordPopup from './ChangePasswordPopup';
import { MyAccountApi } from './MyAccount.Api';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { commonDetailSlice } from '../../store/reducers/commonReducer';
import MFAPopupApp from './MFAPopupApp';
import MFAPopupPhone from './MFAPopupPhone';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import HeadingHeader from '../Common/HeadingHeader';

const MyAccount: FC = () => {
  const [skeleton, setSkeleton] = useState(false);
  const dispatch = useDispatch();

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const handleChange = async (e: any) => {
    const file = {
      file_name: e.target.files[0].name,
      file_size: e.target.files[0].size,
      file_type: e.target.files[0].type
    };

    try {
      const res = await MyAccountApi.PutProfilePhoto(commonDetails.data.user_uuid, { file });
      await MyAccountApi.UploadProfilePhoto(res, e.target.files[0]);

      refreshDetails();
    } catch (error) {}
  };
  const userDetails = commonDetails.data;
  const GetUserDetails = useCallback(async () => {
    setSkeleton(true);
    MyAccountApi.GetUserData().then(
      async (q: any) => {
        dispatch(commonDetailSlice.actions.removecommonDetails());

        dispatch(commonDetailSlice.actions.setcommonDetails(q));
        setSkeleton(false);
      },
      (err: any) => {}
    );
  }, []);

  useEffect(() => {
    GetUserDetails();
  }, []);
  const refreshDetails = () => {
    GetUserDetails();
  };

  return (
    <Box>
      <HeadingHeader subtitle1={'My Account'} />
      {userDetails && (
        <Box sx={{ px: 4, py: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 2 }}>
            <Box>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                {' '}
                Profile Information
              </Typography>
            </Box>
            <EditProfilePopUp
              userDetails={userDetails}
              refreshDetails={() => {
                refreshDetails();
              }}
            />
          </Box>
          <Box sx={{ p: 3, backgroundColor: '#FFFFFF', borderRadius: '8px' }}>
            <Box sx={{ mb: 2 }}>
              <IconButton
                sx={{ width: '64px', height: '64px', backgroundColor: '#D9D9D9', p: 0 }}
                color="primary"
                aria-label="upload picture"
                component="label">
                <input hidden onChange={(e) => handleChange(e)} accept="image/*" type="file" />
                {commonDetails.data.ico ? (
                  <img
                    width={'100%'}
                    height="100%"
                    style={{ objectFit: 'cover', borderRadius: '50%' }}
                    src={`${process.env.REACT_APP_API_HOST}/${commonDetails.data.ico}`}
                    alt=""
                  />
                ) : (
                  <PhotoCamera sx={{ color: 'grey' }} />
                )}
              </IconButton>
            </Box>

            <Box
              sx={{
                display: 'flex',
                width: 1,
                justifyContent: 'space-between',
                gap: 2,
                flexDirection: 'column'
              }}>
              <Box
                sx={{
                  display: 'flex',
                  width: 1,

                  flexDirection: 'row',
                  minWidth: '200px'
                }}>
                <Box sx={{ minWidth: '200px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    Name:
                  </Typography>
                </Box>
                <Box>
                  {' '}
                  <Typography sx={{ color: '#696969' }}>
                    {userDetails.first_name} {userDetails.last_name}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  width: 1,

                  flexDirection: 'row',
                  minWidth: '200px'
                }}>
                <Box sx={{ minWidth: '200px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    Phone:
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  {' '}
                  <Typography
                    sx={{
                      color: '#696969',
                      ' .PhoneInputCountry': {
                        display: 'none'
                      },
                      ' .PhoneInputInput': {
                        padding: 0,
                        color: '#696969'
                      },
                      ' input:disabled': {
                        bgcolor: 'transparent'
                      }
                    }}>
                    <PhoneInputWithCountrySelect
                      onChange={() => {}}
                      international
                      disabled
                      autoFormat={false}
                      defaultCountry="US"
                      value={userDetails.phone}
                    />
                  </Typography>

                  <MFAPopupPhone isEdit={true} isAuth={false} userDetails={userDetails} />

                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  width: 1,

                  flexDirection: 'row'
                }}>
                <Box sx={{ minWidth: '200px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    Email:
                  </Typography>
                </Box>
                <Box>
                  {' '}
                  <Typography sx={{ color: '#696969' }}>{userDetails.email}</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  width: 1,

                  flexDirection: 'row',
                  minWidth: '200px'
                }}>
                <Box sx={{ minWidth: '200px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    Company:
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  {' '}
                  <Typography sx={{ color: '#696969' }}>{userDetails.company?.name}</Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  width: 1,

                  flexDirection: 'row',
                  minWidth: '200px'
                }}>
                <Box sx={{ minWidth: '200px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    Job Title:
                  </Typography>
                </Box>
                <Box>
                  {' '}
                  <Typography sx={{ color: '#696969' }}>{userDetails?.job}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ py: 2 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  {' '}
                  Account Security
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: 1,
                justifyContent: 'space-between',
                gap: 2,
                p: 3,
                flexDirection: 'column',
                backgroundColor: '#FFFFFF',
                borderRadius: '8px'
              }}>
              <Box
                sx={{
                  display: 'flex',
                  width: 1,

                  flexDirection: 'row'
                }}>
                <Box sx={{ minWidth: '200px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    Email:
                  </Typography>
                </Box>
                <Box>
                  {' '}
                  <Typography sx={{ color: '#696969' }}>{userDetails.email}</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  width: 1
                }}>
                <Box sx={{ minWidth: '200px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    Password:
                  </Typography>
                </Box>
                <Box>
                  <ChangePasswordPopup />
                </Box>
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ py: 2 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    {' '}
                    Multi-Factor Authentication
                  </Typography>
                </Box>
              </Box>
              {skeleton ? (
                <Skeleton sx={{ height: '100px' }} />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    width: 1,
                    justifyContent: 'space-between',
                    gap: 2,
                    p: 3,

                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px'
                  }}>
                  <Box>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      Verify login via SMS
                    </Typography>
                    <Typography variant="caption">
                      This is the phone number you have registered as MFA method {userDetails.phone}
                    </Typography>
                  </Box>
                  <Box>

                    <MFAPopupPhone isEdit={false} isAuth={true} userDetails={userDetails} />

                  </Box>
                </Box>
              )}
              {skeleton ? (
                <Skeleton sx={{ height: '100px' }} />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    width: 1,
                    justifyContent: 'space-between',
                    gap: 2,
                    p: 3,
                    mt: 1,

                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px'
                  }}>
                  <Box>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      Verify login with an authentication app
                    </Typography>
                    <Typography variant="caption">
                      Make sure you have an authenticator app installed on your mobile before you
                      start.
                    </Typography>
                  </Box>
                  <Box>
                    {' '}
                    <MFAPopupApp userDetails={userDetails} />{' '}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default MyAccount;
