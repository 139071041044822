import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
import PdfIcon from '@jibin/common/icons/PdfIcon/PdfIcon';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import momentTimezone from 'moment-timezone';
import { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Style from '@jibin/common/style/Styles';
import DeleteIcon from '@jibin/common/icons/Delete/Delete';
import EditIcon from '@jibin/common/icons/EditIcon/EditIcon';
import DownloadIcon from '@jibin/common/icons/DownloadIcon/DownloadIcon';
import { UserRoles } from '@jibin/common/utils/constants/ConstantName';
import UnAssigned from '../Common/UnAssigned';
import CloseIcon from '@jibin/common/icons/Close/Close';
import { ServiceReportsApi } from './ServiceReports.Api';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import "@cyntler/react-doc-viewer/dist/index.css";
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';

const ServiceReportRow = ({
  el,
  assignees,
  engineer,
  providers,
  onClick,
  deleteReport,
  getAllFiles
}) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [rowData, setRowData] = useState({} as any);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElFiles, setAnchorElFiles] = useState(null);
  const [opneDialog, setOpenDialog] = useState(false);
  const [docs, setDocs] = useState<any>(null);

  const open = Boolean(anchorEl);
  const openFiles = Boolean(anchorElFiles);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickFiles = (event: any) => {
    setAnchorElFiles(event.currentTarget);
    setDocs(null);
  };
  const handleClose = () => {
    setRowData(null);
    setAnchorEl(null);
    setAnchorElFiles(null);
  };
  const handleOpenDialog = async (file) => {
    try {
      setOpenDialog(true);
      const res = await ServiceReportsApi.GetReportFile(
        el.service.services_uuid,
        commonDetails.data.company.company_uuid,
        el.reports_uuid,
        file.file_name
      );
      setDocs([
        {
          uri: res?.data?.data,
          fileType:
            file?.file_ext.split('.')[1] === 'mp4' ? 'video/mp4' : file?.file_ext.split('.')[1],
          fileName: file?.file_name
        }
      ]);
    } catch (error) {
      console.log('ERROR ::', error);
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setAnchorElFiles(null);
  };
  const downloadFile = async () => {
    try {
      const response = await fetch(docs[0]?.uri);
      const blob = await response.blob();
      const href = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = href;
      a.download = docs[0]?.fileName;
      a.click();
      window.URL.revokeObjectURL(href);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <>
      <TableRow
        id={el.device_id}
        sx={{
          '.MuiTableCell-root': {
            //backgroundColor: '#FFFFFF ',
            px: '20px',

            borderBottom: '1px solid  #CCCCCC',
            borderTopRadius: '4px',
            borderTop: '1px solid  #CCCCCC',
            '&:last-child': {
              borderRight: '1px solid  #CCCCCC',
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px'
            },
            '&:first-child': {
              borderLeft: '1px solid  #CCCCCC',
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px'
            }
          }
        }}>
        <TableCell>
          <Box
            onClick={handleClickFiles}
            id="basic-button"
            aria-controls={openFiles ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openFiles ? 'true' : undefined}
            sx={{
              fontWeight: 500,
              color: '#436AF3',
              display: 'flex',

              alignItems: 'center',
              gap: 0.5,
              cursor: 'pointer'
            }}>
            <PdfIcon />
            {el?.name}
          </Box>
          <Menu
            anchorEl={anchorElFiles}
            id={'account-menu'}
            open={openFiles}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                ml: 3
              }
            }}>
            <MenuItem
              onClick={() => {
                getAllFiles(el);
                handleClose();
              }}>
              <DownloadIcon />
              <Typography sx={{ ml: 1 }} variant="caption">
                {' '}
                Download All Files
              </Typography>
            </MenuItem>
            {el?.files?.map((file) => {
              return (
                <MenuItem
                  onClick={() => {
                    handleOpenDialog(file);
                  }}>
                  <FilePresentOutlinedIcon />
                  <EllipsisContent
                    sx={{ ml: 1 }}
                    maxWidth={150}
                    variant="caption"
                    text={file?.file_name}
                  />
                </MenuItem>
              );
            })}
          </Menu>
        </TableCell>
        <TableCell
          onClick={() => {
            handleClose();
          }}
          align="left">
          <Typography variant="caption">{el?.service?.name} </Typography>
        </TableCell>
        <TableCell
          onClick={() => {
            // onClick(false);
            handleClose();
          }}
          component="th"
          scope="row">
          <Typography variant="caption">
            {el?.start_date
              ? momentTimezone(el?.start_date).tz('utc').format('DD MMM YYYY').toUpperCase()
              : '-'}
          </Typography>
        </TableCell>
        <TableCell
          onClick={() => {
            // onClick(false);
            handleClose();
          }}
          component="th"
          scope="row">
          <Typography variant="caption">
            {el?.end_date
              ? momentTimezone(el?.end_date).tz('utc').format('DD MMM YYYY').toUpperCase()
              : '-'}
          </Typography>
        </TableCell>

        <TableCell
          onClick={() => {
            // onClick(false);
            handleClose();
          }}
          align="left">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              {el.uploaded_by?.ico ? (
                <Box
                  sx={{
                    ...Style.versionsCard.infoRound,
                    width: '32px',
                    height: '32px'
                  }}>
                  <img
                    width={'100%'}
                    height="100%"
                    style={{ objectFit: 'cover', borderRadius: '50%' }}
                    src={`${process.env.REACT_APP_API_HOST}/${el.uploaded_by?.ico}`}
                    alt=""
                  />
                </Box>
              ) : el?.uploaded_by?.first_name ? (
                <Typography
                  sx={{
                    ...Style.versionsCard.infoRound,
                    width: '32px',
                    height: '32px'
                  }}>
                  {' '}
                  {el.uploaded_by.first_name.charAt(0) + '' + el.uploaded_by.last_name.charAt(0)}
                </Typography>
              ) : (
                <UnAssigned />
              )}
            </Box>
            {el.uploaded_by?.first_name && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                <Typography variant="caption" sx={{ fontWeight: 600 }}>
                  {el.uploaded_by.first_name + ' ' + el.uploaded_by.last_name}
                </Typography>
                <Typography variant="caption">{el.uploaded_by?.company.name}</Typography>
              </Box>
            )}
          </Box>
        </TableCell>

        <TableCell sx={{ maxWidth: 250 }} align="left">
          <IconButton
            onClick={handleClick}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            <MoreHorizIcon color="primary" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id={'account-menu'}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuItem
              onClick={() => {
                getAllFiles(el);
                handleClose();
              }}>
              <DownloadIcon />
              <Typography sx={{ ml: 1 }} variant="caption">
                {' '}
                Download
              </Typography>
            </MenuItem>

            {(commonDetails.data.user_uuid === el.uploaded_by?.user_uuid ||
              (commonDetails?.data?.group.toLowerCase() == UserRoles.SUPER_ADMIN && engineer) ||
              commonDetails?.data?.group.toLowerCase() == UserRoles.CUSTOMER_ADMIN ||
              commonDetails?.data?.group.toLowerCase() == UserRoles.CYBER_SECURITY_ENGINEER ||
              commonDetails?.data?.group.toLowerCase() == UserRoles.SERVICE_DELIVERY_COORDINATOR ||
              commonDetails?.data?.group.toLowerCase() == UserRoles.SERVICE_DELIVERY_MANAGER) && (
              <>
                {' '}
                <MenuItem
                  onClick={() => {
                    onClick(true);
                    handleClose();
                  }}>
                  <EditIcon />
                  <Typography sx={{ ml: 1 }} variant="caption">
                    Edit
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    deleteReport(el);
                    handleClose();
                  }}>
                  <DeleteIcon sx={{ color: '#fff' }} />
                  <Typography sx={{ ml: 1 }} variant="caption">
                    {' '}
                    Delete
                  </Typography>
                </MenuItem>
              </>
            )}
          </Menu>
        </TableCell>
      </TableRow>

      <Dialog
        fullWidth
        maxWidth="lg"
        open={opneDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            px: { xs: 1, md: 2 },
            py: { xs: 0.5, md: 1 },
            bgcolor: 'error.red100',
            mb: { xs: 1, md: 1 }
          }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: '600',
                maxWidth: 'calc(100% - 40px)',
                p: 1
              }}>
              {'Preview File'}
            </Typography>
            <IconButton
              sx={{ color: 'text.primary' }}
              aria-label="close"
              onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          {docs ? (
            <Box sx={{ height: '500px' }}>
              <DocViewer
                documents={docs}
                pluginRenderers={DocViewerRenderers}
                theme={{
                  primary: '#5296d8',
                  tertiary: '#5296d899',
                  textPrimary: '#ffffff'
                }}
              />
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0 }}>
          <Button variant="outlined" sx={{ minWidth: 100 }} onClick={handleCloseDialog}>
            {'Close'}
          </Button>
          <Button variant="contained" sx={{ minWidth: 100 }} color="error" onClick={downloadFile}>
            {' '}
            {'Download File'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ServiceReportRow;
