import { FC, ReactNode } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export interface NotFoundBoxProps {
  children?: ReactNode;
  title?: string;
  message?: string;
  src?: string;
}

export const NoDataAvailable: FC<NotFoundBoxProps> = (props) => {
  const {
    title = 'No Data Available',
    src,
    children = '',
    message = 'Please click the button below'
  } = props;
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', width: '100%', m: '35px auto auto auto' }}>
      <Box sx={{ textAlign: 'center' }}>
        <img width={src ? '100%' : '50%'} src={src ? src : '/images/NoDataFound.png'} />
        <Typography variant="h2" sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <Typography sx={{ mt: '10px', textAlign: 'center', color: '#696969' }} variant="body2">
          {message}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};
export const NoSearchDataAvailable: FC<NotFoundBoxProps> = (props) => {
  const {
    title = 'No Search Results',
    children = '',
    message = 'Try adjusting your search or filter to find what you are looking for'
  } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        m: '35px auto auto auto',
        my: 5
      }}>
      <Box sx={{ textAlign: 'center' }}>
        <img width={'50%'} src="/images/NoSearchFound.png" />
        <Typography variant="h2" sx={{ fontWeight: 600, m: '20px 0 0 0' }}>
          {title}
        </Typography>
        <Typography sx={{ mt: '10px', textAlign: 'center', color: '#696969' }} variant="body2">
          {message}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};
export const NoHistoryFound: FC<NotFoundBoxProps> = (props) => {
  const { title = 'No Data Available', src } = props;
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', width: '100%', m: '35px auto auto auto' }}>
      <Box sx={{ textAlign: 'center' }}>
        <img width={src ? '100%' : '100%'} src={src ? src : '/images/NoHistory.png'} />
        <Typography variant="h2" sx={{ fontWeight: 600, color: '#9D9D9D' }}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
