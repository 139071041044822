

export function CustomIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2373 6.66651H3.48394C2.49727 6.66651 1.69727 5.85985 1.69727 4.87985V3.12652C1.69727 2.13985 2.50394 1.33984 3.48394 1.33984H13.2373C14.2239 1.33984 15.0239 2.14652 15.0239 3.12652V4.87985C15.0239 5.85985 14.2173 6.66651 13.2373 6.66651Z"
        stroke="#436AF3"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2373 14.6665H3.48394C2.49727 14.6665 1.69727 13.8599 1.69727 12.8799V11.1265C1.69727 10.1398 2.50394 9.33984 3.48394 9.33984H13.2373C14.2239 9.33984 15.0239 10.1465 15.0239 11.1265V12.8799C15.0239 13.8599 14.2173 14.6665 13.2373 14.6665Z"
        stroke="#436AF3"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.35718 3.33301V4.66634"
        stroke="#436AF3"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.02393 3.33301V4.66634"
        stroke="#436AF3"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.35718 11.333V12.6663"
        stroke="#436AF3"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.02393 11.333V12.6663"
        stroke="#436AF3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.69043 4H12.3571"
        stroke="#436AF3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.69043 12H12.3571"
        stroke="#436AF3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
