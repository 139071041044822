import { createSlice } from '@reduxjs/toolkit';

const initialStateObj = {
  roleDetails: {}
};

export const roleReducerSlice = createSlice({
  name: 'roleDetails',
  initialState: initialStateObj,
  reducers: {
    setRoleReducer: (state, { payload }) => {
      state.roleDetails = payload;
    },
    removeRoleReducer: (state) => {
      state.roleDetails = {};
    }
  }
});

export const { setRoleReducer, removeRoleReducer } = roleReducerSlice.actions;

export default roleReducerSlice.reducer;
