import SvgIcon from '@mui/material/SvgIcon';
export default function GlobalIcon(prop) {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...prop}>
      <path
        d="M8.00001 14.6666C11.6819 14.6666 14.6667 11.6819 14.6667 7.99998C14.6667 4.31808 11.6819 1.33331 8.00001 1.33331C4.31811 1.33331 1.33334 4.31808 1.33334 7.99998C1.33334 11.6819 4.31811 14.6666 8.00001 14.6666Z"
        stroke="#464646"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.33333 2H5.99999C4.69999 5.89333 4.69999 10.1067 5.99999 14H5.33333"
        stroke="#464646"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 2C11.3 5.89333 11.3 10.1067 10 14"
        stroke="#464646"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 10.6667V10C5.89333 11.3 10.1067 11.3 14 10V10.6667"
        stroke="#464646"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 6.00002C5.89333 4.70002 10.1067 4.70002 14 6.00002"
        stroke="#464646"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
}
