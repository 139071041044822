import HttpService from '@jibin/common/services/http/HttpService';
import axios from 'axios';

export const RemediationApi = {
  Remediations(userId: string, companyId: string, params: any) {
    //return HttpService.getAll("todos/1");
    // /companies/9940c9d2-8ef0-42b9-8626-5be545d1e63d/users/64d1802e-1fa4-4732-b182-0719199108a8/remediation/list
    return HttpService.getAll(`companies/${companyId}/users/${userId}/remediation/list?` + params);
  },
  CreateRemediation(userId: string, companyId: string, data: any) {
    return HttpService.post(`companies/${companyId}/users/${userId}/remediation`, data);
  },
  DeletAllRemediation(userId: string, companyId: string, data: any) {
    return HttpService.post(`companies/${companyId}/users/${userId}/remediation/bulk-action`, data);
  },
  // /companies/{company_id}/users/{user_id}/remediation/{remediation_id}/create-jira-issue
  CreateRemediationJiraTicket(
    userId: string,
    companyId: string,
    remediation_id: string,
    data: any
  ) {
    return HttpService.post(
      `companies/${companyId}/users/${userId}/remediation/${remediation_id}/create-jira-issue`,
      data
    );
  },
  // /companies/{company_id}/users/{user_id}/gav2/controls/{control_id}/remediations
  // /companies/{company_id}/users/{user_id}/gav2/controls/{control_id}/remediations
  CreateRemediationFromGap(userId: string, companyId: string, control_id: any, data: any) {
    return HttpService.post(
      `companies/${companyId}/users/${userId}/gav2/controls/${control_id}/remediations`,
      data
    );
  },

  TagsList(userId: string, companyId: string, query: any) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/remediation/tags?${query}`);
  },
  UploadDocuments(response: any, files: any) {
    const {
      data: { data: urls }
    } = response;

    return Promise.all(
      urls.map(({ upload_url, callback_url, callback_data }, i) => {
        const file = files[i];
        return axios
          .put(upload_url, file, {
            headers: {
              'Content-Type': file.type
            }
          })
          .then(() => HttpService.post(callback_url, { data: callback_data }));
      })
    );
  },
  GetRemediatios(userId: string, companyId: string, remediation_id: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/remediation/${remediation_id}`
    );
  },
  GetRemediatiosHistory(userId: string, companyId: string, remediation_id: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/remediation/${remediation_id}/history`
    );
  },
  PutRemediationData(userId: string, companyId: string, remediation_id: string, data: any) {
    return HttpService.putAll(
      `companies/${companyId}/users/${userId}/remediation/${remediation_id}`,
      data
    );
  },
  ReopenRemediation(userId: string, companyId: string, remediation_id: string, data: any) {
    return HttpService.patchAll(
      `companies/${companyId}/users/${userId}/remediation/${remediation_id}/status`,
      data
    );
  },
  GetAssignees(userId: string, companyId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/assignees`);
  },
  deleteRemediationActionFile(
    userId: string,
    companyId: string,
    remediation_id: string,
    action_taken_id: string,
    file_id: string
  ) {
    return HttpService.remove(
      `companies/${companyId}/users/${userId}/remediation/${remediation_id}/actions-taken/${action_taken_id}/files`,
      file_id
    );
  },

  patchAssignee(userId: string, companyId: string, remediation_id: string, data) {
    return HttpService.patchAll(
      `companies/${companyId}/users/${userId}/remediation/${remediation_id}/assign`,
      data
    );
  },
  async GetFiles(originalUrl: string) {
    const response = await HttpService.getAll(originalUrl);

    return response;
  },
  PostActionData(userId: string, companyId: string, remediation_id: string, data: any) {
    return HttpService.post(
      `companies/${companyId}/users/${userId}/remediation/${remediation_id}/actions-taken`,
      data
    );
  },
  GetRemediationSummary(companyId: string, userId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/remediation-summary`);
  },
};
