import HttpService from '@jibin/common/services/http/HttpService';

export const PoliciesAndProceduresApi = {
  // User section
  GetPolicyList(userId: string, companyId: string, q: string = '') {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/policies?` + q);
  },

  GetPolicyById(userId: string, companyId: string, policyId: string, version: number = 0) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/settings/policy/${policyId}?version=${version}`
    );
  },
  DownloadPolicy(userId: string, companyId: string, policyId: string, version: number = 0) {
    //return HttpService.getAll("todos/1");
    return HttpService.getDocument(
      `companies/${companyId}/users/${userId}/settings/policy/${policyId}/document?version=${version}`
    );
  },
  GetPolicyHistoryById(userId: string, companyId: string, policyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/settings/policy/${policyId}/history`
    );
  },

  CreatePolicy(userId: string, companyId: string, data: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.post(`companies/${companyId}/users/${userId}/settings/policy`, data);
  },
  UploadPolicy(userId: string, companyId: string, data: any) {
    //return HttpService.getAll("todos/1");
    // /companies/{company_id}/users/{user_id}/settings/policies/upload
    return HttpService.postDocument(`companies/${companyId}/users/${userId}/settings/policies/upload`, data);
  },

  CreatePolicyDocument(userId: string, companyId: string, policyId: string, data: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.post(
      `companies/${companyId}/users/${userId}/settings/policy/${policyId}/document`,
      data
    );
  },
  SubmitPolicyDocument(userId: string, companyId: string, policyId: string, data: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.patch(
      `companies/${companyId}/users/${userId}/settings/policy`,
      policyId,
      data
    );
  },

  GetTemplates(userId: string, companyId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/policies/templates`);
  },
  GetTemplateById(userId: string, companyId: string, policyId: string, version: number = 0) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/policies/template/${policyId}`
    );
  },
  DownloadBulkFiles(userId: string, companyId: string, data: any) {
    return HttpService.postDownload(
      `companies/${companyId}/users/${userId}/settings/policies/bulkdownload`,
      data
    );
  },
  CreateNewPolicyFromTemplate(userId: string, companyId: string, templateId: any, data: any) {
    return HttpService.post(
      `companies/${companyId}/users/${userId}/settings/policy/${templateId}`,
      data
    );
  }
};
