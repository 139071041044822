export class UserModel {
  constructor(userModel?: UserModel) {
    let properties = ['email', 'job_title', 'user_uuid', 'first_name', 'last_name', 'status'];
    for (let property of properties)
      if (userModel && (userModel[property] || userModel[property] === 0))
        this[property] = userModel[property];
  }
  email: string;
  job_title: string;
  user_uuid: string;
  first_name: string;
  last_name: string;
}
