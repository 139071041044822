import HttpService from '@jibin/common/services/http/HttpService';

export const TopRiskWidgetApi = {
  Remediations(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/remediations?top=10`);
  },
  RemediationsList(userId: string, companyId: string, params: any) {
    //return HttpService.getAll("todos/1");
    // /companies/9940c9d2-8ef0-42b9-8626-5be545d1e63d/users/64d1802e-1fa4-4732-b182-0719199108a8/remediation/list
    return HttpService.getAll(`companies/${companyId}/users/${userId}/remediation/list?` + params);
  },
  RiskList(userId: string, companyId: string, params?: any) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/risks?` + (params || ''));
  },
  GetServicesList() {
    return HttpService.getAll(`services`);
  }
};
