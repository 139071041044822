import SvgIcon from '@mui/material/SvgIcon';

export default function CrossCancle(props) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1.6L14.4 0L8 6.4L1.6 0L0 1.6L6.4 8L0 14.4L1.6 16L8 9.6L14.4 16L16 14.4L9.6 8L16 1.6Z"
      />
    </SvgIcon>
  );
}
export function CrossCancleRounded() {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_22834_410)">
        <path
          d="M10.625 6.87502L6.37502 11.125M6.37502 6.87502L10.625 11.125M15.5834 9.00002C15.5834 12.912 12.412 16.0834 8.50002 16.0834C4.588 16.0834 1.41669 12.912 1.41669 9.00002C1.41669 5.088 4.588 1.91669 8.50002 1.91669C12.412 1.91669 15.5834 5.088 15.5834 9.00002Z"
          stroke="#F87171"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22834_410">
          <rect width="17" height="17" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
