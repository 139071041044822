import SvgIcon from '@mui/material/SvgIcon';

export default function CheckBoxOutLine(props) {
  return (
    <SvgIcon
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect x="1.16663" y="0.5" width="19" height="19" rx="5.5" fill="white" />
      <rect x="1.16663" y="0.5" width="19" height="19" rx="5.5" stroke="#B7B7B7" />
    </SvgIcon>
  );
}
