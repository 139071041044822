import Style from '@jibin/common/style/Styles';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ResponsiveContainer,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

const ConsultHourChart = ({
  data,
  upGuardStats,
  isDragable,
  customIcon,
  currentGrid,
  dropdownMenu
}: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        border: '1px solid #CCCCCC',
        width: 1,
        p: 3,
        backgroundColor: '#FFFFFF',
        borderRadius: '8px'
      }}>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              Consulting Hours Utilization
            </Typography>
            <Typography variant="caption">Last 12 months</Typography>
          </Box>
          {location.pathname == PageRouteConstants.DashBoard && (
            <>
              {isDragable && (
                <>
                  {customIcon}{' '}
                  {currentGrid?.i === 'dashboard-attack-surface-management' ? dropdownMenu : null}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box sx={{ mt: 2, height: '207px', width: 1, ml: '-20px' }}>
        {' '}
        <ResponsiveContainer height="100%" width="100%">
          <LineChart
            data={data}
            margin={{
              top: 5,
              bottom: 5
            }}>
            <CartesianGrid color="#EEEEEE" />
            <XAxis fontSize={'10px'} padding={{ right: 30 }} color={'#9E9E9E'} dataKey="month" />
            <YAxis domain={[0, 100]} fontSize={'10px'} color={'#9E9E9E'} />
            <Tooltip
              labelFormatter={(label) => {
                const selectedData = data.find((entry) => entry.month == label);
                return selectedData ? `` : '';
              }}
            />
            <Legend
              formatter={(value, entry, index) => (
                <span style={{ color: '#464646', fontSize: '12px' }}>{value}</span>
              )}
              payload={[
                {
                  id: 'value',
                  value: 'Total Purchased Monthly Hours',
                  type: 'circle',
                  color: '#94ACFF'
                },
                {
                  id: 'value',
                  value: 'Total Monthly Hours Consumed',
                  type: 'circle',
                  color: '#F87171'
                }
              ]}
            />
            <Line
              type="monotone"
              dataKey="value"
              name="Total Purchased Monthly Hours"
              stroke="#94ACFF"
              strokeDasharray="3 3"
              fontSize={'12px'}
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="value2"
              name="Total Monthly Hours Consumed"
              stroke="#F87171"
              strokeDasharray="3 3"
              fontSize={'12px'}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};
export default ConsultHourChart;
