import { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  List,
  TextField,
  Autocomplete,
  InputAdornment,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Checkbox,
  Tooltip
} from '@mui/material';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import { useFormik } from 'formik';
import { UserRoles } from '@jibin/common/utils/constants/ConstantName';
import Style from '@jibin/common/style/Styles';
import { GetAssignImage } from '@jibin/common/utils/Common';
import CloseIcon from '@jibin/common/icons/Close/Close';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import SearchIcon from '@jibin/common/icons/Search/Search';
import CheckBoxOutLine from '@jibin/common/icons/CheckBoxOutLine/CheckBoxOutLine';
import CheckBoxLightBlue from '@jibin/common/icons/CheckBoxLightBlue/CheckBoxLightBlue';
import { ASSETSOURCE } from '@jibin/common/utils/enums/SourceTypeEnums';
import { AssetManagementApi } from '../AssetManagement.Api';
import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import { RemediationApi } from 'src/Pages/Remediation/Remediation.Api';

const CreateRemediation = ({
  closePopup,
  commonDetails,
  assignees,
  rowData,
  assetsList,
  selectedAssetList,
  associatedAssets,
  setAssociatedAssets,
  GetAssets
}) => {
  const [isRemediationButtonLoading, setIsRemediationButtonLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(true);
  const [option, setOption] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteOption, setDeleteOption] = useState('');
  const [tagOptions, setTagOptions] = useState([]);
  const [tagErrorMessage, setTagErrorMessage] = useState({ maxLimit: '', tagLimit: '' });
  const [facilities, setFacilities] = useState([]);
  const [sourceTags, setSourceTags] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [limitMsg, setLimitMsg] = useState(false);
  const [searchAssets, setSearchAssets] = useState([]);
  const [selectedUUID, setSelectedUUID] = useState([]);
  const [isBoxVisible, setIsBoxVisible] = useState(true);
  const box1Ref = useRef(null);
  const box2Ref = useRef(null);
  const box3Ref = useRef(null);

  const handleClickOutside = (event) => {
    if (
      box1Ref.current &&
      !box1Ref.current.contains(event.target) &&
      box2Ref.current &&
      !box2Ref.current.contains(event.target) &&
      box3Ref.current &&
      !box3Ref.current.contains(event.target)
    ) {
      setIsBoxVisible(false);
    }
  };

  const validate = (value: any) => {
    if (!value.description || !value.issue_name || !value.recommendation || !value.source) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      severity: 'medium',
      issue_name: '',
      source: [],
      description: '',
      recommendation: '',
      assign: null,
      tags: []
    },
    validate,
    onSubmit: async () => {
      try {
        setIsRemediationButtonLoading(true);
        const sValues = [];

        formik?.values?.source?.forEach((value) => {
          let element = ASSETSOURCE.filter((q) => q?.key === value);
          if (element) {
            sValues.push(element[0]?.value);
          }
        });

        const associatedAssetUUIDs = associatedAssets.map((asset) => asset?.asset_uuid);
        const filteredIds = assetsList
          .filter((asset) => associatedAssetUUIDs.includes(asset?.asset_uuid))
          .map((asset) => asset?.asset_uuid);

        const data = {
          asset_uuids: filteredIds,
          remediation: {
            issue_name: formik.values.issue_name,
            description: formik.values.description,
            sources: sValues,
            severity: formik.values.severity,
            recommendation: formik.values.recommendation,
            tags: facilities,
            owner_uuid: formik.values?.assign?.user_uuid
          }
        };

        await AssetManagementApi.CreateRemediation(
          commonDetails.data.company.company_uuid,
          commonDetails.data.user_uuid,
          data
        );

        GetAssets();
        closePopup();
      } catch (error) {
      } finally {
        setTimeout(() => {
          setIsRemediationButtonLoading(false);
        }, 1000);
      }
    }
  });

  const handleClickOpenDelete = (option, value) => {
    setDeleteOption(option);

    formik.setFieldValue('tags', value);
    setOpenDelete(true);
  };

  const maketags = (data: any) => {
    const facility = [];

    data.forEach((element) => {
      let facilitytype = tagOptions.filter((q) => q.tag_name == element);
      if (facilitytype?.length > 0) {
        facility.push({ tag_name: element, tag_uuid: facilitytype[0].tag_uuid });
      } else {
        facility.push({ tag_name: element });
      }
    });
    setFacilities([...facility]);
  };

  const handleTagChange = (event, value) => {
    var newArray = value.filter(function (elem, pos) {
      return value.indexOf(elem) == pos;
    });
    if (tagErrorMessage.maxLimit) {
      setTagErrorMessage({
        ...tagErrorMessage,
        maxLimit: ''
      });
      return;
    }
    formik.setFieldValue('tags', newArray);
    maketags(newArray);
  };
  const GetTags = useCallback(async () => {
    const query: any = {
      include_non_associated_tags: true
    };
    try {
      const res = await RemediationApi.TagsList(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        new URLSearchParams(query).toString()
      );

      if (res.data.data) {
        setTagOptions(res.data.data);
      }
    } catch (error) {}
  }, [commonDetails]);
  useEffect(() => {
    GetTags();
  }, [GetTags]);
  useEffect(() => {
    formik.setFieldValue('issue_name', '');
    formik.setFieldValue('source', []);
    formik.setFieldValue('description', '');
    formik.setFieldValue('recommendation', '');

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  function validateString(inputString) {
    if (!/^[a-zA-Z_\s]+$/.test(inputString)) {
      return false;
    }
    if (/^\d+$/.test(inputString)) {
      return false;
    }
    return true;
  }

  const handleConfirmDelete = (deleteFacility) => {
    const updatedFacility = formik.values.tags.filter((el, index) => el !== deleteFacility);
    formik.setFieldValue('tags', updatedFacility);

    maketags(updatedFacility);
    setOpenDelete(false);
  };

  const renderOption = (assignees) => {
    const options = assignees.data.map((option) => {
      const firstLetter = option.company_name.toUpperCase();
      const company_uuid = option.company_uuid;

      const data = option?.users.map((el: any) => {
        return { firstLetter: firstLetter, company_uuid: company_uuid, ...el };
      });
      return data;
    });

    const optionarray = options.concat.apply([], options).flat();
    setOption(optionarray);
  };

  const renderColorBackGround = (severity: any = 'critical') => {
    var color = '#FFE6E2';
    if (severity == 'high') {
      color = '#FCD34D';
    } else if (severity == 'critical') {
      color = '#FFE6E2';
    } else if (severity == 'medium') {
      color = '#FEF3C7';
    } else if (severity == 'low') {
      color = '#D0DAFC';
    } else if (severity == 'status') {
      color = '#EBEBEB';
    }
    return color;
  };

  const assetColorBackGround = (status: string) => {
    let color = '';
    if (status === 'Healthy') {
      color = '#97e9d2';
    } else if (status === 'Unhealthy') {
      color = '#FFE6E2';
    } else if (status === 'Unknown') {
      color = '#e5e5e7';
    } else if (status === 'Alert') {
      color = '#FEF3C7';
    }
    return color;
  };

  const assetColorIcon = (status: string) => {
    let color = '';
    if (status === 'Healthy') {
      color = '#10B981';
    } else if (status === 'Unhealthy') {
      color = '#F73939';
    } else if (status === 'Unknown') {
      color = '#B7B7B7';
    } else if (status === 'Alert') {
      color = '#FBBF24';
    }
    return color;
  };

  const bgStatusColor = (status: string) => {
    let color = '';
    if (status === 'Healthy') {
      color = '#10B981';
    } else if (status === 'Unhealthy') {
      color = '#F87171';
    } else if (status === 'Unknown') {
      color = '#B7B7B7';
    } else if (status === 'Alert') {
      color = '#FCD34D';
    }
    return color;
  };

  const [value, setValue] = useState<any>([]);

  const maketagsSource = (data: any) => {
    var source = [];

    data.forEach((element) => {
      let sourceType = ASSETSOURCE.filter((q) => q.key === element);

      if (sourceType.length > 0) {
        source.push({ type: sourceType[0].value, status: 'Active', name: element });
      } else {
        source.push({ type: 'Others', status: 'Active', name: element });
      }
    });

    setSourceTags(source);
  };

  const addAssets = () => {
    if (selectedUUID?.length > 10) {
      setLimitMsg(true);
    }
    const filteredData = searchAssets
      ?.filter((item) => selectedUUID.includes(item?.asset_uuid) && item?.remediation_uuid === '')
      ?.slice(0, 10);
    setAssociatedAssets([...associatedAssets, ...filteredData]);
    GetSearchAssets(selectedUUID);

    // setSearchText('');
    // setSearchAssets([])
  };

  const handleConfirmDeleteSource = (option, value) => {
    const updatedFacility = value.filter((el, index) => el !== option);
    setValue(updatedFacility);
    formik.setFieldValue('source', updatedFacility);
    maketags(updatedFacility);
    setOpenDelete(false);
  };

  const removeAsset = (assetUUIDToRemove) => {
    if (selectedUUID?.length < 10) {
      setLimitMsg(false);
    }
    // if (associatedAssets.length > 1) {
    const updatedAssetsList = associatedAssets.filter(
      (asset) => asset.asset_uuid !== assetUUIDToRemove
    );
    const updatedAssetUUIDs = selectedUUID.filter((uuid) => uuid !== assetUUIDToRemove);
    setSelectedUUID(updatedAssetUUIDs);
    setAssociatedAssets(updatedAssetsList);
    GetSearchAssets(updatedAssetUUIDs);
    // }
  };

  const isSelected = (name: string) => selectedUUID?.indexOf(name) !== -1;
  const handleClickCheck = (event: React.MouseEvent<unknown>, name: string) => {
    event.preventDefault();
    const selectedIndex = selectedUUID.indexOf(name);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUUID, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUUID.slice(1));
    } else if (selectedIndex === selectedUUID.length - 1) {
      newSelected = newSelected.concat(selectedUUID.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUUID.slice(0, selectedIndex),
        selectedUUID.slice(selectedIndex + 1)
      );
    }
    setSelectedUUID(newSelected);
  };

  const GetSearchAssets = useCallback(
    async (selectedUUID?: any) => {
      try {
        const queryParams = new URLSearchParams({
          keyword: searchText
        }).toString();
        const res = await AssetManagementApi.AssetList(
          commonDetails.data.company.company_uuid,
          commonDetails.data.user_uuid,
          queryParams
        );
        const filteredData = res?.data?.data?.data?.filter(
          (item) => item?.remediation_uuid === '' && !selectedUUID.includes(item?.asset_uuid)
        );
        setSearchAssets(filteredData);
      } catch (error) {}
    },
    [commonDetails, searchText]
  );

  useEffect(() => {
    if (searchText) {
      GetSearchAssets(selectedUUID);
    } else {
      setSearchAssets([]);
    }
  }, [GetSearchAssets, searchText]);

  useEffect(() => {
    if (Object.keys(rowData).length) {
      setAssociatedAssets([rowData]);
      setSelectedUUID([rowData?.asset_uuid]);
    } else if (selectedAssetList?.length) {
      const filteredData = assetsList?.filter(
        (item) => selectedAssetList.includes(item?.asset_uuid) && item?.remediation_uuid === ''
      );
      const filteredIds = filteredData.map((asset) => asset?.asset_uuid);
      setSelectedUUID(filteredIds);
      setAssociatedAssets(filteredData);
    }
    if (assignees) {
      renderOption(assignees);
    }
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ p: 2.5 }}>
            <Box sx={{ width: '100%' }}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                  <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                    Criticality Level:
                  </Typography>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      value={formik.values.severity || 'medium'}
                      name="severity"
                      onChange={formik.handleChange}
                      displayEmpty
                      sx={{
                        '.MuiSelect-select': {
                          py: 1,
                          display: 'flex',
                          alignItems: 'center'
                        },
                        '&.MuiInputBase-root': {
                          backgroundColor: renderColorBackGround(formik.values.severity)
                        },
                        borderRadius: '100px'
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}>
                      <MenuItem sx={{ backgroundColor: '#FFE6E2' }} value="critical">
                        <Box>
                          <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F73939' }} />
                        </Box>
                        <Typography variant="body2">Critical</Typography>
                      </MenuItem>
                      <MenuItem sx={{ backgroundColor: '#FCD34D' }} value="high">
                        <Box>
                          <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F59E0B' }} />
                        </Box>
                        <Typography variant="body2">High</Typography>
                      </MenuItem>
                      <MenuItem sx={{ backgroundColor: '#FEF3C7' }} value={'medium'}>
                        <Box>
                          <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#FBBF24' }} />
                        </Box>
                        <Typography variant="body2">Medium</Typography>
                      </MenuItem>
                      <MenuItem sx={{ backgroundColor: '#D0DAFC' }} value={'low'}>
                        {' '}
                        <Box>
                          <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#436AF3' }} />
                        </Box>
                        <Typography variant="body2">Low</Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={{ my: 1 }}>
                  <Box>
                    <List sx={{ py: 1.5 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          gap: 2
                        }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                          Remediation Name
                        </Typography>
                        <Box sx={{ width: 1 }}>
                          <TextField
                            size="small"
                            placeholder="Type here"
                            onChange={formik.handleChange}
                            fullWidth
                            name="issue_name"
                            inputProps={{ sx: { fontSize: '14px' } }}
                            variant="outlined"
                            sx={{ fontSize: '14px' }}
                            value={formik.values?.issue_name}
                          />
                        </Box>
                      </Box>
                    </List>

                    <List sx={{ py: 1.5 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          gap: 2
                        }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                          Add Associated Assets
                        </Typography>
                        <Box ref={box1Ref} sx={{ width: 1 }}>
                          <TextField
                            placeholder="Search by Asset Name"
                            hiddenLabel
                            fullWidth
                            size="small"
                            name="searchText"
                            value={searchText}
                            onClick={() => setIsBoxVisible(true)}
                            onChange={(e) => {
                              setSearchText(e.target.value);
                              setIsBoxVisible(true);
                            }}
                            sx={{
                              input: { backgroundColor: '#FFFFFF' },
                              '.MuiOutlinedInput-root': {
                                bgcolor: '#FFFFFF'
                              }
                            }}
                            inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment sx={{ bgcolor: '#FFFFFF' }} position="end">
                                  <SearchIcon />
                                </InputAdornment>
                              )
                            }}
                          />
                        </Box>
                        {isBoxVisible && searchAssets.length ? (
                          <Box sx={{ width: 1 }}>
                            <Box
                              ref={box2Ref}
                              sx={{
                                border: '1px solid #CCCCCC',
                                padding: '8px',
                                borderRadius: '4px',
                                maxHeight: '170px',
                                overflowX: 'auto'
                              }}>
                              {searchAssets?.map((asset) => {
                                const isItemSelected = isSelected(asset?.asset_uuid);
                                return (
                                  <Tooltip
                                    placement="top"
                                    arrow
                                    title={
                                      <>
                                        <span style={{ fontWeight: 600 }}>{asset?.type}:</span>{' '}
                                        {asset?.name} <span style={{ fontWeight: 600 }}>IP:</span>{' '}
                                        {asset?.ip_address[0]}{' '}
                                        <span style={{ fontWeight: 600 }}>MAC:</span>{' '}
                                        {asset?.mac_address[0]}
                                      </>
                                    }>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        overflow: 'hidden',
                                        mb: 1
                                      }}>
                                      <Checkbox
                                        sx={{ padding: 0, width: '16px', height: '16px', mr: 0.5 }}
                                        onClick={(event) => {
                                          handleClickCheck(event, asset?.asset_uuid);
                                          event.stopPropagation();
                                          event.preventDefault();
                                        }}
                                        checked={isItemSelected}
                                        checkedIcon={<CheckBoxLightBlue />}
                                        inputProps={
                                          {
                                            // 'aria-labelledby': labelId
                                          }
                                        }
                                        icon={
                                          <CheckBoxOutLine
                                            sx={{
                                              fill: 'none',
                                              width: '16px',
                                              height: '16px'
                                            }}
                                          />
                                        }
                                      />
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          background: assetColorBackGround(asset?.status),
                                          borderRadius: '50%',
                                          width: '24px',
                                          height: '24px',
                                          mr: '6px',
                                          flex: ' 0 0 24px'
                                        }}>
                                        <FlagIcon
                                          sx={{
                                            fontSize: '12px',
                                            color: assetColorIcon(asset?.status)
                                          }}
                                        />
                                      </Box>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{
                                          fontSize: '14px',
                                          color: '#464646',
                                          fontWeight: 400,
                                          whiteSpace: 'nowrap'
                                        }}>
                                        <span style={{ fontWeight: 600 }}>{asset?.type}:</span>{' '}
                                        {asset?.name} <span style={{ fontWeight: 600 }}>IP:</span>{' '}
                                        {asset?.ip_address[0]}{' '}
                                        <span style={{ fontWeight: 600 }}>MAC:</span>{' '}
                                        {asset?.mac_address[0]}
                                      </Typography>
                                    </Box>
                                  </Tooltip>
                                );
                              })}
                            </Box>
                          </Box>
                        ) : null}
                        <Box ref={box3Ref} sx={{ textAlign: 'end', width: 1 }}>
                          <Button variant="outlined" onClick={addAssets}>
                            Add Assets
                          </Button>
                        </Box>
                      </Box>
                    </List>

                    <List sx={{ py: 1.5 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          gap: 2
                        }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                          Associated Assets
                        </Typography>
                        {associatedAssets?.map((asset) => {
                          return (
                            <Tooltip
                              placement="top"
                              arrow
                              title={
                                <>
                                  <span style={{ fontWeight: 600 }}>{asset?.type}:</span>{' '}
                                  {asset?.name} <span style={{ fontWeight: 600 }}>IP:</span>{' '}
                                  {asset?.ip_address[0]}{' '}
                                  <span style={{ fontWeight: 600 }}>MAC:</span>{' '}
                                  {asset?.mac_address[0]}
                                </>
                              }>
                              <Box
                                className="as-asset-wrapper"
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: 1,
                                  border: '1px solid #CCCCCC',
                                  borderRadius: '4px',
                                  overflow: 'hidden',
                                  padding: '8px',
                                  position: 'relative',
                                  cursor: 'pointer'
                                }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: assetColorBackGround(asset?.status),
                                    borderRadius: '50%',
                                    width: '24px',
                                    flex: ' 0 0 24px',
                                    height: '24px',
                                    mr: '6px'
                                  }}>
                                  <FlagIcon
                                    sx={{ fontSize: '12px', color: assetColorIcon(asset?.status) }}
                                  />
                                </Box>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    fontSize: '14px',
                                    color: '#464646',
                                    fontWeight: 400,
                                    whiteSpace: 'nowrap'
                                  }}>
                                  <span style={{ fontWeight: 600 }}>{asset?.type}:</span>{' '}
                                  {asset?.name} <span style={{ fontWeight: 600 }}>IP:</span>{' '}
                                  {asset?.ip_address[0]}{' '}
                                  <span style={{ fontWeight: 600 }}>MAC:</span>{' '}
                                  {asset?.mac_address[0]}
                                </Typography>
                                <Box
                                  className="hover-status"
                                  sx={{ display: 'none', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      minWidth: '14px',
                                      height: '14px',
                                      width: '14px',
                                      backgroundColor: bgStatusColor(asset?.status),
                                      borderRadius: '50%'
                                    }}></Box>
                                  <Typography variant="caption">{asset?.status}</Typography>
                                  <CrossCancle
                                    onClick={() => removeAsset(asset?.asset_uuid)}
                                    sx={{
                                      color: '#000',
                                      height: '8px',
                                      width: '8px',
                                      cursor: 'pointer'
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Tooltip>
                          );
                        })}
                        {limitMsg && (
                          <Box sx={{ background: '#FECACA4D', p: 1.5, borderRadius: 1, width: 1 }}>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontSize: '14px', color: '#464646', fontWeight: 400 }}>
                              You have achieve the limit of Assets for this Remediation.
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </List>

                    <List sx={{ py: 1.5 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          gap: 2
                        }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                          Source
                        </Typography>
                        <Autocomplete
                          fullWidth
                          multiple
                          id="tags-filled"
                          options={ASSETSOURCE.sort((a: any, b: any) =>
                            a?.key > b?.key ? 1 : -1
                          ).map((option) => option.key)}
                          freeSolo
                          value={formik.values.source}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                                onDelete={() => handleConfirmDeleteSource(option, value)}
                                sx={{ fontSize: '14px' }}
                              />
                            ))
                          }
                          onChange={(event, value) => {
                            // setValue(value);
                            formik.setFieldValue('source', value);
                            maketagsSource(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              placeholder={'Select Source'}
                              sx={{ fontSize: '14px' }}
                            />
                          )}
                          sx={{
                            '&.MuiAutocomplete-root': {
                              '.MuiOutlinedInput-root': {
                                p: 0,
                                pl: 1,
                                fontSize: '14px'
                              },
                              '.MuiAutocomplete-tag': {
                                m: 1,
                                bgcolor: 'common.lightBlue',
                                border: 'none',
                                fontSize: '14px'
                              }
                            },
                            '& .MuiInputBase-input': {
                              fontSize: '14px'
                            },
                            '& .MuiChip-root': {
                              fontSize: '14px'
                            }
                          }}
                        />
                      </Box>
                    </List>

                    <List sx={{ py: 1.5 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          gap: 2
                        }}>
                        <Typography variant="subtitle2" sx={{ fontSize: '14px', fontWeight: 600 }}>
                          Remediation Description
                        </Typography>
                        <Box sx={{ width: 1 }}>
                          <TextField
                            size="small"
                            placeholder="Type your answer here..."
                            name="description"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.description}
                            inputProps={{ sx: { fontSize: '14px' } }}
                            variant="outlined"
                            rows={7}
                            multiline
                            sx={{ fontSize: '14px' }}
                          />
                        </Box>
                      </Box>
                    </List>

                    <List sx={{ py: 1.5 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          gap: 2
                        }}>
                        <Typography variant="subtitle2" sx={{ fontSize: '14px', fontWeight: 600 }}>
                          Recommendation
                        </Typography>
                        <Box sx={{ width: 1 }}>
                          <TextField
                            size="small"
                            placeholder="Type your answer here..."
                            name="recommendation"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.recommendation}
                            inputProps={{ sx: { fontSize: '14px' } }}
                            variant="outlined"
                            rows={7}
                            multiline
                            sx={{ fontSize: '14px' }}
                          />
                        </Box>
                      </Box>
                    </List>

                    <List sx={{ py: 1.5 }}>
                      <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
                            Remediation Owner
                          </Typography>
                          <Typography
                            variant="body2"
                            onClick={() =>
                              formik.setFieldValue('assign', {
                                first_name: commonDetails.data.first_name,
                                last_name: commonDetails.data.last_name,
                                user_uuid: commonDetails.data.user_uuid,
                                ico: commonDetails.data.ico,
                                company_uuid: commonDetails.data.company.company_uuid
                              })
                            }
                            sx={{
                              fontWeight: 600,
                              color: '#436AF3',
                              mb: 2,
                              cursor: 'pointer'
                            }}>
                            {commonDetails?.data?.group != UserRoles.SERVICE_DELIVERY_COORDINATOR &&
                              'Assign to me'}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1.5 }}>
                          <FormControl sx={{ width: '100%' }}>
                            <Autocomplete
                              id="grouped-demo"
                              size="small"
                              fullWidth={true}
                              options={option}
                              value={formik.values.assign}
                              onChange={(e, value) => formik.setFieldValue('assign', value)}
                              groupBy={(option: any) => option.firstLetter}
                              getOptionLabel={(option: any) => {
                                return `${option?.first_name} ${option?.last_name}`;
                              }}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ fontSize: '14px' }}
                                  {...params}
                                  placeholder="Assign to"
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: formik.values.assign ? (
                                      <InputAdornment position="start">
                                        <Box>
                                          {formik.values.assign?.ico ? (
                                            <Box
                                              sx={{
                                                ...Style.versionsCard.infoRound,
                                                width: '24px',
                                                height: '24px',
                                                fontSize: '14px'
                                              }}>
                                              <img
                                                width={'100%'}
                                                height="100%"
                                                style={{
                                                  objectFit: 'cover',
                                                  borderRadius: '50%'
                                                }}
                                                src={GetAssignImage(
                                                  formik.values.assign?.firstLetter,
                                                  formik.values.assign?.ico
                                                )}
                                                alt=""
                                              />
                                            </Box>
                                          ) : (
                                            <Typography
                                              sx={{
                                                ...Style.versionsCard.infoRound,
                                                width: '24px',
                                                height: '24px',
                                                fontSize: '14px'
                                              }}>
                                              {formik.values.assign?.first_name?.charAt(0) +
                                                '' +
                                                formik.values.assign?.last_name?.charAt(0)}
                                            </Typography>
                                          )}
                                        </Box>
                                      </InputAdornment>
                                    ) : null
                                  }}
                                />
                              )}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                  key={option?.user_uuid}>
                                  {option.ico ? (
                                    <Box
                                      sx={{
                                        ...Style.versionsCard.infoRound,
                                        width: '24px',
                                        height: '24px',
                                        fontSize: '14px'
                                      }}>
                                      <img
                                        width={'100%'}
                                        height="100%"
                                        style={{
                                          objectFit: 'cover',
                                          borderRadius: '50%'
                                        }}
                                        src={GetAssignImage(option?.firstLetter, option?.ico)}
                                        alt=""
                                      />
                                    </Box>
                                  ) : (
                                    <Typography
                                      sx={{
                                        ...Style.versionsCard.infoRound,
                                        width: '24px',
                                        height: '24px',
                                        fontSize: '14px'
                                      }}>
                                      {option?.first_name?.charAt(0) +
                                        '' +
                                        option?.last_name?.charAt(0)}
                                    </Typography>
                                  )}

                                  <Typography variant="body2">
                                    {' '}
                                    {option?.first_name} {option?.last_name}
                                  </Typography>
                                </Box>
                              )}
                              renderGroup={(params) => (
                                <Box key={params.key}>
                                  <Typography
                                    sx={{
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      padding: '10px 10px'
                                    }}>
                                    {params.group}
                                  </Typography>
                                  {params.children}
                                </Box>
                              )}
                            />
                          </FormControl>
                        </Box>
                      </Box>
                    </List>

                    <List sx={{ py: 2 }}>
                      <FormControl variant="outlined" fullWidth>
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
                          Tag
                        </Typography>
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          freeSolo
                          options={tagOptions.map((el) => el.tag_name)}
                          value={formik.values.tags}
                          renderTags={(value, getTagProps) => {
                            return value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                                onDelete={() => handleClickOpenDelete(option, value)}
                              />
                            ));
                          }}
                          selectOnFocus
                          onChange={(event: any, newValue, reason) => {
                            if (
                              reason == 'selectOption' ||
                              reason == 'removeOption' ||
                              reason == 'clear'
                            ) {
                              handleTagChange(event, newValue);
                            } else {
                              const optionToSelect = tagOptions?.find((option) => {
                                if (
                                  option?.tag_name?.toLowerCase() ===
                                  event.target.value.toLowerCase()
                                ) {
                                  return option;
                                }
                              });
                              if (optionToSelect) {
                                handleTagChange(event, [
                                  ...formik.values.tags,
                                  optionToSelect.tag_name
                                ]);
                              } else {
                                handleTagChange(event, newValue);
                              }
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={
                                tagErrorMessage.maxLimit || tagErrorMessage.tagLimit ? true : false
                              }
                              helperText={
                                tagErrorMessage.maxLimit
                                  ? tagErrorMessage.maxLimit
                                  : tagErrorMessage.tagLimit
                              }
                              {...params}
                              onChange={(e: any) => {
                                if (e.target.value) {
                                  if (e.target.value.startsWith('_')) {
                                    setTagErrorMessage({
                                      ...tagErrorMessage,
                                      maxLimit: `Please Don't Start the tag with (_)`
                                    });
                                  } else if (!validateString(e.target.value)) {
                                    setTagErrorMessage({
                                      ...tagErrorMessage,
                                      maxLimit: `Please don't add specific characters or numbers just Alphapetical Letters ONLY`
                                    });
                                  } else if (e.target.value.length > 50) {
                                    setTagErrorMessage({
                                      ...tagErrorMessage,
                                      maxLimit:
                                        'You have reached the maximum limit of characters per Tag'
                                    });
                                  } else {
                                    setTagErrorMessage({
                                      ...tagErrorMessage,
                                      maxLimit: ''
                                    });
                                  }
                                } else {
                                  setTagErrorMessage({
                                    ...tagErrorMessage,
                                    maxLimit: ''
                                  });
                                }
                              }}
                              placeholder={formik?.values?.tags.length > 0 ? '' : 'Add Tags'}
                              // InputProps={{
                              //   startAdornment: (
                              //     <InputAdornment position="start">
                              //       <SearchIcon sx={{ color: "grey.500" }} />
                              //     </InputAdornment>
                              //   ),
                              // }}
                            />
                          )}
                          sx={{
                            '&.MuiAutocomplete-root': {
                              '.MuiAutocomplete-input': {
                                minWidth: '70px'
                              },
                              '.MuiOutlinedInput-root': {
                                p: 0,
                                pl: 1,
                                pr: 1,
                                '.MuiAutocomplete-clearIndicator': {
                                  margin: '0px -6px 0px 0px'
                                }
                              },
                              '.MuiAutocomplete-tag': {
                                m: '7px',
                                bgcolor: 'common.lightBlue',
                                border: 'none'
                              },
                              '.MuiAutocomplete-endAdornment': {
                                right: '5px'
                              }
                            }
                          }}
                        />
                        <Dialog
                          fullWidth
                          maxWidth="sm"
                          open={openDelete}
                          onClose={handleCloseDelete}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description">
                          <DialogTitle
                            id="alert-dialog-title"
                            sx={{
                              px: { xs: 1, md: 2 },
                              py: { xs: 0.5, md: 1 },
                              bgcolor: 'error.red100',
                              mb: { xs: 2, md: 3 }
                            }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}>
                              <Typography
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  color: 'error.main',
                                  fontWeight: '600',
                                  maxWidth: 'calc(100% - 40px)',
                                  p: 1
                                }}>
                                <WarningIcon sx={{ mr: 1 }} />
                                Confirm Deletion
                              </Typography>
                              <IconButton
                                sx={{ color: 'text.primary' }}
                                aria-label="close"
                                onClick={handleCloseDelete}>
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          </DialogTitle>
                          <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
                            <DialogContentText
                              id="alert-dialog-description"
                              sx={{ color: 'text.primary', fontWeight: 700, mb: 1 }}>
                              Are you sure do you want to delete this Tag?
                            </DialogContentText>

                            <DialogContentText
                              id="alert-dialog-description"
                              sx={{ color: 'text.primary' }}>
                              {deleteOption}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0 }}>
                            <Button
                              variant="outlined"
                              sx={{ minWidth: 100 }}
                              onClick={handleCloseDelete}>
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              sx={{ minWidth: 100 }}
                              color="error"
                              onClick={() => handleConfirmDelete(deleteOption)}>
                              {' '}
                              Confirm
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </FormControl>
                    </List>
                  </Box>
                </Box>

                <Box sx={{ textAlign: 'end' }}>
                  <Button variant="outlined" onClick={closePopup} sx={{ minWidth: 137, mr: 2 }}>
                    Cancel
                  </Button>
                  <Button
                    disabled={isComplete}
                    variant="contained"
                    type="submit"
                    sx={{ minWidth: 137 }}>
                    {' '}
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default CreateRemediation;
