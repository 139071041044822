import BorderLinearProgress from '@jibin/common/components/LinearLoading/BorderLinearGreen';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { Box, Grid, linearProgressClasses, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ResponsiveContainer } from 'recharts';
import { TopRemediations } from 'src/Pages/Common/TopRemediations/TopRemediations';
import { GapAnalysisApi } from '../GapAnalysis.Api';
import FrameWorkPopup from 'src/Pages/DashBoard/DashboardFrameWorkPopup/FrameWorkPopup';
import { TopRiskWidget } from 'src/Pages/Common/TopRiskWidget/TopRiskWidget';
import GapWidget from '../GapWidget';
import GapFrameWorkWidget from '../GapFrameWorkWidget';
import { CompanyUserRoles } from '@jibin/common/utils/constants/ConstantName';

const OverViewGap = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [gapFilterData, setGapFilterData] = useState({
    framework: '',
    control_status: '',
    keyword: ''
  });
  const navigate = useNavigate();
  const [frameworkCompliance, setFrameworkCompliance] = useState([] as any);
  const [completedScore, setCompletedScore] = useState(0);
  const GetCategories = useCallback(async () => {
    try {
      const res = await GapAnalysisApi.GetGapStats(
        commonDetails.data.user_uuid,

        commonDetails.data.company.company_uuid
      );
      const dataArray = [];
      for (const key in res?.data?.data?.frameworks) {
        const obje = {
          name: key,
          completed: res?.data?.data?.frameworks[key]?.compliance_controls,
          total: res?.data?.data?.frameworks[key]?.total_controls
        };
        dataArray.push(obje);
      }
      setFrameworkCompliance(dataArray);
    } catch (error) {}
  }, [commonDetails]);

  useEffect(() => {
    GetCategories();
  }, [GetCategories]);
  return (
    <Box sx={{ p: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6} md={6}>
          <GapWidget
            isDragable={undefined}
            currentGrid={undefined}
            setAnchorEl={undefined}
            anchorEl={undefined}
            setWidgetSize={undefined}
          />
        </Grid>

        <Grid item xs={12} lg={6} md={6}>
          <GapFrameWorkWidget
            isDragable={undefined}
            currentGrid={undefined}
            setAnchorEl={undefined}
            anchorEl={undefined}
            setWidgetSize={undefined}
          />
        </Grid>
        {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
          <Grid item xs={12}>
            <Box
              sx={{
                border: '1px solid #CCCCCC',
                width: 1,
                backgroundColor: '#FFFFFF',
                borderRadius: '8px'
              }}>
              <TopRiskWidget size={10} source="gap" code="CE" column={12} title="Top Risks" />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default OverViewGap;
