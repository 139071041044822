import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React, { FC } from 'react';
import CloseIcon from '../../icons/Close/Close';
import WarningIcon from '../../icons/Warning/Warning';
import DeleteIcon from '@jibin/common/icons/Delete/Delete';
import { DeleteTypesEnum } from '../../utils/enums/deleteTypeEnums';
import { MenuItem, Switch } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import theme from '@jibin/common/style/core/config/theme';

export interface ConformationModel {
  title?: string;
  description?: string;
  asking?: string;
  cancelButtonText?: string;
  conformButtonText?: string;
  type?: number;
  label?: any;
  handleClose(): void;
  data?: any;
  handleConfirm(): void;
  onKeyDown?(e: any): any;
}

export const ConformationModel: FC<ConformationModel> = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };
  const handleConfirm = () => {
    setOpen(false);
    props.handleConfirm();
  };
  const handleChange = (e: any) => {
    handleClickOpen();
  };
  const renderSwitch = (type: any) => {
    switch (type) {
      case DeleteTypesEnum.Icon:
        return (
          <MenuItem onClick={handleClickOpen}>
            <DeleteIcon sx={{ mr: 4 }} />
            Delete
          </MenuItem>
        );
      case DeleteTypesEnum.Button:
        return (
          <Button
            variant="outlined"
            onClick={handleClickOpen}
            sx={{ minWidth: 120, mr: 2 }}
            color="error">
            Delete
          </Button>
        );
      case DeleteTypesEnum.Switch:
        return (
          <Switch
            onClick={(e) => {
              handleChange(e);
            }}
            checked={
              props?.data?.status.toUpperCase() == 'ACTIVE' ||
              props?.data?.status.toUpperCase() == 'PENDING'
            }
            {...props.label}
            sx={{
              width: 30,
              height: 16,
              mr: 1.2,

              //   '	.MuiSwitch-switchBase': { color: 'rgba(0, 0, 0, 0.25)', m: '2px' },
              //   '	.MuiSwitch-track': {
              //     backgroundColor: 'transparent',
              //     border: '1px solid #ADB5BD',
              //     borderRadius: '45px'
              //   },
              //   '.MuiSwitch-thumb': {
              //     boxShadow: 'none',
              //     width: '12px',
              //     height: '12px'
              //   },
              //   '	.Mui-disabled': {
              //     cursor: 'not-allowed'
              //   },

              padding: 0,

              '.MuiSwitch-switchBase': {
                padding: 0,
                m: '4px',
                mt: '2px',

                transitionDuration: '300ms',
                '&.Mui-checked': {
                  transform: 'translateX(20px)',
                  mt: '2px',

                  ml: '-4px',
                  color: '#fff',
                  '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.primary.main,
                    opacity: 1,
                    border: 0
                  },
                  '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5
                  }
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                  color: '#33cf4d',
                  border: '6px solid #fff'
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                  color:
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[600]
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
                }
              },
              '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 12,
                height: 12
              },
              '& .MuiSwitch-track': {
                borderRadius: 26 / 2,
                backgroundColor: theme.palette.grey[300],
                opacity: 1,
                transition: theme.transitions.create(['background-color'], {
                  duration: 500
                })
              }
            }}
          />
        );
      case DeleteTypesEnum.SwitchAndLabel:
        return (
          // <Box>
          <MenuItem
            onClick={(e) => {
              handleChange(e);
            }}
            sx={{ fontSize: '12px' }}>
            <Switch
              checked={
                props?.data?.status.toUpperCase() == 'ACTIVE' ||
                props?.data?.status.toUpperCase() == 'PENDING'
              }
              {...props.label}
              sx={{
                width: 30,
                height: 16,
                mr: 1.2,

                //   '	.MuiSwitch-switchBase': { color: 'rgba(0, 0, 0, 0.25)', m: '2px' },
                //   '	.MuiSwitch-track': {
                //     backgroundColor: 'transparent',
                //     border: '1px solid #ADB5BD',
                //     borderRadius: '45px'
                //   },
                //   '.MuiSwitch-thumb': {
                //     boxShadow: 'none',
                //     width: '12px',
                //     height: '12px'
                //   },
                //   '	.Mui-disabled': {
                //     cursor: 'not-allowed'
                //   },

                padding: 0,

                '.MuiSwitch-switchBase': {
                  padding: 0,
                  m: '4px',
                  mt: '2px',

                  transitionDuration: '300ms',
                  '&.Mui-checked': {
                    transform: 'translateX(20px)',
                    mt: '2px',

                    ml: '-4px',
                    color: '#fff',
                    '& + .MuiSwitch-track': {
                      backgroundColor: theme.palette.primary.main,
                      opacity: 1,
                      border: 0
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                      opacity: 0.5
                    }
                  },
                  '&.Mui-focusVisible .MuiSwitch-thumb': {
                    color: '#33cf4d',
                    border: '6px solid #fff'
                  },
                  '&.Mui-disabled .MuiSwitch-thumb': {
                    color:
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600]
                  },
                  '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
                  }
                },
                '& .MuiSwitch-thumb': {
                  boxSizing: 'border-box',
                  width: 12,
                  height: 12
                },
                '& .MuiSwitch-track': {
                  borderRadius: 26 / 2,
                  backgroundColor: theme.palette.grey[300],
                  opacity: 1,
                  transition: theme.transitions.create(['background-color'], {
                    duration: 500
                  })
                }
              }}
            />
            {props?.data?.status.toUpperCase() == 'ACTIVE' ||
            props?.data?.status.toUpperCase() == 'PENDING'
              ? ' Deactivate'
              : 'Activate'}
          </MenuItem>
          // </Box>
        );
      default:
        return (
          <Button
            variant="outlined"
            onClick={handleClickOpen}
            sx={{ minWidth: 120, mr: 2 }}
            color="error">
            Delete
          </Button>
        );
    }
  };
  return (
    <>
      {renderSwitch(props.type)}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            px: { xs: 1, md: 2 },
            py: { xs: 0.5, md: 1 },
            bgcolor: 'error.red100',
            mb: { xs: 2, md: 3 }
          }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'error.main',
                fontWeight: '600',
                maxWidth: 'calc(100% - 40px)',
                p: 1
              }}>
              <WarningIcon sx={{ mr: 1 }} />
              {props.title}
            </Typography>
            <IconButton sx={{ color: 'text.primary' }} aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          {props.asking && (
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: 'text.primary', fontWeight: 700, mb: 1 }}>
              {props.asking}
            </DialogContentText>
          )}
          <DialogContentText id="alert-dialog-description" sx={{ color: 'text.primary' }}>
            {props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0 }}>
          <Button variant="outlined" sx={{ minWidth: 100 }} onClick={handleClose}>
            {props.cancelButtonText}
          </Button>
          <Button variant="contained" sx={{ minWidth: 100 }} color="error" onClick={handleConfirm}>
            {' '}
            {props.conformButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConformationModel;
