import React from 'react';
import ReactGA from 'react-ga4';

import { useLocation } from 'react-router-dom';

const RouteChangeTracker = () => {
  const location = useLocation();
  ReactGA.set({ page: location.pathname });
  // ReactGA.pageview(location.pathname);
  ReactGA.send({ hitType: 'pageview', page: location.pathname, title: location.pathname });

  return <div></div>;
};

export default RouteChangeTracker;
