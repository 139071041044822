import { SvgIcon } from '@mui/material';

export default function PrintIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.8335 4.66634H11.1668V3.33301C11.1668 1.99967 10.6668 1.33301 9.16683 1.33301H6.8335C5.3335 1.33301 4.8335 1.99967 4.8335 3.33301V4.66634Z"
        stroke="#464646"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6668 10V12.6667C10.6668 14 10.0002 14.6667 8.66683 14.6667H7.3335C6.00016 14.6667 5.3335 14 5.3335 12.6667V10H10.6668Z"
        stroke="#464646"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 6.66699V10.0003C14 11.3337 13.3333 12.0003 12 12.0003H10.6667V10.0003H5.33333V12.0003H4C2.66667 12.0003 2 11.3337 2 10.0003V6.66699C2 5.33366 2.66667 4.66699 4 4.66699H12C13.3333 4.66699 14 5.33366 14 6.66699Z"
        stroke="#464646"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3332 10H10.5265H4.6665"
        stroke="#464646"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.6665 7.33301H6.6665"
        stroke="#464646"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
