import { SvgIcon } from '@mui/material';

const ArchiveIcon = (props) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 14H3V5.8209H4V12.9776H11.9999V5.8209H12.9999L13 14ZM2 1H14V5.08955H2V1ZM3 2.02239V4.06716H12.9999L13 2.02239H3Z"
        fill="#696969"
      />
      <path
        d="M7.79775 10.9466C7.82429 10.9635 7.85581 10.977 7.89051 10.9861C7.92522 10.9953 7.96243 11 8 11C8.03757 11 8.07478 10.9953 8.10949 10.9861C8.14419 10.977 8.17571 10.9635 8.20225 10.9466L9.91623 9.85577C9.96987 9.82163 10 9.77533 10 9.72705C10 9.67877 9.96987 9.63247 9.91623 9.59833C9.86259 9.5642 9.78983 9.54502 9.71398 9.54502C9.63812 9.54502 9.56537 9.5642 9.51173 9.59833L8.28566 10.379V7.1818C8.28566 7.13359 8.25557 7.08734 8.20199 7.05325C8.14842 7.01915 8.07576 7 8 7C7.92424 7 7.85158 7.01915 7.79801 7.05325C7.74443 7.08734 7.71434 7.13359 7.71434 7.1818V10.379L6.48827 9.59833C6.43463 9.5642 6.36188 9.54502 6.28602 9.54502C6.21017 9.54502 6.13741 9.5642 6.08377 9.59833C6.03013 9.63247 6 9.67877 6 9.72705C6 9.77533 6.03013 9.82163 6.08377 9.85577L7.79775 10.9466Z"
        fill="#464646"
      />
    </SvgIcon>
  );
};
export default ArchiveIcon;
