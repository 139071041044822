import CheckEmptyCircle from '@jibin/common/icons/CheckEmptyCircle/CheckEmptyCircle';
import PinIcon from '@jibin/common/icons/PinIcon/PinIcon';
import WarningRed from '@jibin/common/icons/WarningRed/WarningRed';
import { CheckCircle } from '@mui/icons-material';
import {
  Typography,
  TextareaAutosize,
  TextField,
  SwipeableDrawer,
  Select,
  MenuItem
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import GapAnalysisSideBarEngineer from '../GapAnalysisSideBar/GapAnalysisSideBarEngineer';
import GapAnalysisSideBar from '../GapAnalysisSideBar/GapAnalysisSideBar';
import { useSearchParams } from 'react-router-dom';
import { CompanyUserRoles } from '@jibin/common/utils/constants/ConstantName';
type Anchor = 'top' | 'left' | 'bottom' | 'right';
const GapAnalysisQ: FC<{
  data: any;
  getQuestions: any;
  index: any;
  GetCategories: any;
  isAllComplete: boolean;
  isEngineer: boolean;
}> = (props) => {
  const { data, index, isAllComplete, GetCategories, getQuestions, isEngineer } = props;
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [rowData, setRowData] = useState({} as any);
  const [state, setState] = useState({
    right: false
  });

  const toggleDrawer = (anchor: Anchor, open: boolean, row: any) => {
    setState({ ...state, [anchor]: open });
    setRowData(row);
  };
  const setParams = (index) => {};

  return (
    <Box
      key={index}
      sx={{
        border: '1px solid #D5D5D5',
        borderRadius: 2,
        p: 2,
        mb: 2,
        backgroundColor: '#FFFFFF'
      }}>
      <Box>
        <Typography
          variant="body1"
          sx={{ fontWeight: 500, mb: 1.25, color: '#464646', whiteSpace: 'pre-line' }}>
          <span>{index + 1}.</span> {data.question}
        </Typography>
        <Box onClick={() => toggleDrawer('right', true, data)}>
          <TextField
            disabled={commonDetails?.data?.company?.user_role === CompanyUserRoles.AUDITOR}
            maxRows={1}
            minRows={1}
            defaultValue={data?.answer?.comment}
            value={data?.answer?.comment}
            sx={{
              input: { cursor: 'pointer' },
              width: 1,
              '.MuiInputBase-input': {
                py: 1
              },
              '.MuiInputBase-root': {
                py: 0
              }
            }}
            InputProps={{
              readOnly: true
            }}
            // sx={{ width: 1, mb: 1 }}
            placeholder={'Type your answer here...'}
          />
        </Box>

        <SwipeableDrawer
          anchor={'right'}
          open={state['right']}
          onClose={() => {
            GetCategories();
            getQuestions();
            toggleDrawer('right', false, {});
          }}
          onOpen={() => {
            toggleDrawer('right', true, data);
          }}>
          {isEngineer ? (
            <GapAnalysisSideBarEngineer
              rowData={rowData}
              isEngineer={isEngineer}
              index={index}
              closePopup={(e, toggle = false) => {
                GetCategories();
                getQuestions();
                toggleDrawer('right', toggle, toggle ? data : {});
              }}
            />
          ) : (
            <GapAnalysisSideBar
              rowData={rowData}
              isEngineer={isEngineer}
              index={index}
              closePopup={(e, toggle = false) => {
                GetCategories();
                getQuestions();
                toggleDrawer('right', toggle, toggle ? data : {});
              }}
            />
          )}
        </SwipeableDrawer>

        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1.5 }}>
          {data?.answer?.questionnaire_answers_uuid && (
            <Box>
              {data?.answer?.files ? (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,

                    alignItems: 'center',
                    backgroundColor: '#D9EDFF',
                    py: '5px',
                    px: 1.5,
                    borderRadius: '100px'
                  }}>
                  <PinIcon />
                  <Typography variant="body1" sx={{ color: '#436AF3' }}>
                    {data?.answer?.files?.length}
                  </Typography>
                </Box>
              ) : (
                <Box></Box>
              )}
            </Box>
          )}
          {isAllComplete ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {data?.answer?.questionnaire_answers_uuid && (
                <>
                  {commonDetails.data.company.gap_status == 'draft' ? (
                    <>
                      <CheckCircle sx={{ color: '#27AE60' }} />
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Saved
                      </Typography>{' '}
                    </>
                  ) : (
                    <Select
                      value={data?.answer.feedback.status || 'pending'}
                      name="status"
                      displayEmpty
                      disabled={true}
                      sx={{
                        '.MuiSelect-select': {
                          py: 0.5,
                          px: '12px',
                          display: 'flex',
                          alignItems: 'center'
                        },
                        borderRadius: '100px'
                      }}
                      inputProps={{ 'aria-label': 'Without label', IconComponent: () => null }}>
                      <MenuItem value="does_not_meet">
                        <Box
                          sx={{
                            height: '12px',
                            width: '12px',
                            backgroundColor: '#EF4444',
                            borderRadius: '50%',
                            mr: 1
                          }}></Box>
                        <Typography variant="body2">Does Not Meet</Typography>
                      </MenuItem>
                      <MenuItem value={'pending'}>
                        {' '}
                        <Box
                          sx={{
                            height: '12px',
                            width: '12px',
                            backgroundColor: '#787878',
                            borderRadius: '50%',
                            mr: 1
                          }}></Box>
                        <Typography variant="body2">Pending</Typography>
                      </MenuItem>
                      <MenuItem value={'partially_meets'}>
                        {' '}
                        <Box
                          sx={{
                            height: '12px',
                            width: '12px',
                            backgroundColor: '#FBBF24',
                            borderRadius: '50%',
                            mr: 1
                          }}></Box>
                        <Typography variant="body2">Partially Meets</Typography>
                      </MenuItem>
                      <MenuItem value={'fully_meets'}>
                        {' '}
                        <Box
                          sx={{
                            height: '12px',
                            width: '12px',
                            backgroundColor: '#27AE60',
                            borderRadius: '50%',
                            mr: 1
                          }}></Box>
                        <Typography variant="body2">Fully Meets</Typography>
                      </MenuItem>
                      <MenuItem value={'not_applicable'}>
                        {' '}
                        <Box
                          sx={{
                            height: '12px',
                            width: '12px',
                            backgroundColor: '#D9E1FD',
                            borderRadius: '50%',
                            mr: 1
                          }}></Box>
                        <Typography variant="body2">Not Applicable</Typography>
                      </MenuItem>
                    </Select>
                  )}
                </>
              )}
            </Box>
          ) : isEngineer ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                border: '1px solid #EBEBEB',
                borderRadius: '100px',
                width: '150px',
                py: '8px',
                px: '12px'
              }}>
              <Box
                sx={{
                  height: '12px',
                  width: '12px',
                  backgroundColor: '#787878',
                  borderRadius: '50%',
                  mr: 1
                }}></Box>
              <Typography variant="body2">Pending</Typography>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {data?.answer?.questionnaire_answers_uuid && (
                <>
                  <CheckCircle sx={{ color: '#27AE60' }} />
                  <Typography variant="body2" sx={{ color: '#696969' }}>
                    Saved
                  </Typography>{' '}
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default GapAnalysisQ;
