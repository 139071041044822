import { combineReducers } from '@reduxjs/toolkit';
import authDetailReducer from './authReducer';
import commonDetailReducer from './commonReducer';
import subscriptionPlanReducer from './subscriptionPlanReducer';
import servicesReducer from './servicesReducer';
import loaderReducer from './loaderReducer';
import roleReducer from './roleReducer';

const RootReducer = combineReducers({
  authData: authDetailReducer,
  commonDetails: commonDetailReducer.reducer,
  subscriptionPlan: subscriptionPlanReducer.reducer,
  servicesDetials: servicesReducer.reducer,
  skeleton: loaderReducer,
  roleDetails: roleReducer
});

export default RootReducer;
