import SvgIcon from '@mui/material/SvgIcon';

export default function TickMark(props) {
  return (
    <SvgIcon
      width="24"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.1802 1.44231C8.04463 1.44231 7.92507 1.50737 7.85086 1.60819L7.80295 1.79983C7.79425 1.83465 7.78512 1.86575 7.77971 1.88421L7.77969 1.88425L7.77968 1.88429L7.77823 1.88925C7.79739 2.09783 7.97134 2.25954 8.18506 2.25954H11.8053L11.8093 2.25952C12.0215 2.25505 12.1925 2.09109 12.208 1.88196L12.2065 1.87684C12.1987 1.84924 12.1875 1.80734 12.1782 1.75782L12.1586 1.65313C12.0892 1.52715 11.9552 1.44231 11.8004 1.44231H8.1802ZM11.8004 0C12.4774 0 13.0688 0.362257 13.3915 0.90377H14.5264C16.5496 0.90377 18.1899 2.54393 18.1899 4.56723V16.3365C18.1899 18.3598 16.5496 19.9999 14.5264 19.9999H5.47358C3.45031 19.9999 1.81006 18.3598 1.81006 16.3365V4.56723C1.81006 2.54393 3.45031 0.90377 5.47358 0.90377H6.58917C6.91187 0.36228 7.50319 0 8.1802 0H11.8004ZM8.1802 3.70192C7.32841 3.70192 6.61226 3.12844 6.3961 2.34608H5.47358C4.24684 2.34608 3.25237 3.34053 3.25237 4.56723V16.3365C3.25237 17.5632 4.24684 18.5576 5.47358 18.5576H14.5264C15.7531 18.5576 16.7476 17.5632 16.7476 16.3365V4.56723C16.7476 3.34053 15.7531 2.34608 14.5264 2.34608H13.5895C13.3755 3.12031 12.6718 3.68989 11.8318 3.70166L11.8004 3.70192H8.1802ZM14.5747 9.15407C14.8564 8.87245 14.8564 8.41584 14.5747 8.13421C14.2931 7.85258 13.8365 7.85258 13.5549 8.13421L9.21834 12.4707L9.21832 12.4707L9.21488 12.4742C9.14823 12.5418 9.03548 12.5468 8.95941 12.4708L8.9594 12.4707L7.33446 10.8458C7.05284 10.5641 6.59623 10.5641 6.3146 10.8457C6.03296 11.1274 6.03296 11.584 6.31458 11.8656L7.93952 13.4906L7.93953 13.4906C8.57433 14.1254 9.60424 14.1311 10.2397 13.4891L14.5747 9.15407Z"
        fill="white"
      />
    </SvgIcon>
  );
}
export function TickMarkBlue(props) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.1802 1.44231C8.04463 1.44231 7.92507 1.50737 7.85086 1.60819L7.80295 1.79983C7.79425 1.83465 7.78512 1.86575 7.77971 1.88421L7.77969 1.88425L7.77968 1.88429L7.77823 1.88925C7.79739 2.09783 7.97134 2.25954 8.18506 2.25954H11.8053L11.8093 2.25952C12.0215 2.25505 12.1925 2.09109 12.208 1.88196L12.2065 1.87684C12.1987 1.84924 12.1875 1.80734 12.1782 1.75782L12.1586 1.65313C12.0892 1.52715 11.9552 1.44231 11.8004 1.44231H8.1802ZM11.8004 0C12.4774 0 13.0688 0.362257 13.3915 0.90377H14.5264C16.5496 0.90377 18.1899 2.54393 18.1899 4.56723V16.3365C18.1899 18.3598 16.5496 19.9999 14.5264 19.9999H5.47358C3.45031 19.9999 1.81006 18.3598 1.81006 16.3365V4.56723C1.81006 2.54393 3.45031 0.90377 5.47358 0.90377H6.58917C6.91187 0.36228 7.50319 0 8.1802 0H11.8004ZM8.1802 3.70192C7.32841 3.70192 6.61226 3.12844 6.3961 2.34608H5.47358C4.24684 2.34608 3.25237 3.34053 3.25237 4.56723V16.3365C3.25237 17.5632 4.24684 18.5576 5.47358 18.5576H14.5264C15.7531 18.5576 16.7476 17.5632 16.7476 16.3365V4.56723C16.7476 3.34053 15.7531 2.34608 14.5264 2.34608H13.5895C13.3755 3.12031 12.6718 3.68989 11.8318 3.70166L11.8004 3.70192H8.1802ZM14.5747 9.15407C14.8564 8.87245 14.8564 8.41584 14.5747 8.13421C14.2931 7.85258 13.8365 7.85258 13.5549 8.13421L9.21834 12.4707L9.21832 12.4707L9.21488 12.4742C9.14823 12.5418 9.03548 12.5468 8.95941 12.4708L8.9594 12.4707L7.33446 10.8458C7.05284 10.5641 6.59623 10.5641 6.3146 10.8457C6.03296 11.1274 6.03296 11.584 6.31458 11.8656L7.93952 13.4906L7.93953 13.4906C8.57433 14.1254 9.60424 14.1311 10.2397 13.4891L14.5747 9.15407Z"
        fill="#436AF3"
      />
    </SvgIcon>
  );
}
