import HttpService from '@jibin/common/services/http/HttpService';
import axios from 'axios';

export const ConatinerAPI = {
  GetActiveSession(userId: string, companyId: string, device_id: any) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/users_activities/devices/${device_id}/sessions`
    );
  }
};
