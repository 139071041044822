export class Ips {
  constructor(ips?: Ips) {
    let properties = [
      'tech_info_ip_range_uuid',
      'company_uuid',
      'ip_address',
      'prefix',
      'is_external',
      'facility',
      'company_facility_uuid',
      'data_center_search'
    ];
    for (let property of properties)
      if (ips && (ips[property] || ips[property] === 0)) this[property] = ips[property];
  }
  tech_info_ip_range_uuid: string;
  company_uuid: string;
  ip_address: string;
  prefix: number;
  is_external: boolean = false;
  facility: Facility = new Facility();
  company_facility_uuid: string;
  data_center_search:string;
}

export class Facility {
  constructor(facility?: Facility) {
    let properties = ['company_facility_uuid', 'name', 'ip_address', 'address'];
    for (let property of properties)
      if (facility && (facility[property] || facility[property] === 0))
        this[property] = facility[property];
  }
  company_facility_uuid: string;
  name: string;
  address: string;
}
