import CloseIcon from '@jibin/common/icons/Close/Close';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import PhoneInput from 'react-phone-number-input';
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useState, useRef, useEffect, useCallback } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import DeletaionBox from '../DeletaionBox';
import { Amplify, Auth } from 'aws-amplify';
import { CommonFunctions } from '@jibin/common/utils/Common';
import { MyAccountApi } from '../MyAccount.Api';
import commonDetailSlice from 'src/store/reducers/commonReducer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

var debounce = require('lodash.debounce');
const notifyError = (message: any) => toast.error(message, { position: toast.POSITION.TOP_RIGHT });

const MFAPopupPhone: FC<{ isEdit: boolean; isAuth?: boolean; userDetails: any }> = (prop) => {
  const [open, setOpen] = useState(false);
  const [displayPhone, setDisplayPhone] = useState('none');
  const [displayAuth, setDisplayAuth] = useState('none');
  const [codeSent, setCodeSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneErrorMsg, setPhoneErrorMsg] = useState('');
  const [otpCode, setOtpCode] = useState('');
  const [otpErrorMsg, setOtpErrorMsg] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const [userDetails, setUserDetails] = useState({} as any);
  const [openDeletaion, setOpenDeletaion] = useState(false);
  const [isSendCodeButtonLoading, setIsSendCodeButtonLoading] = useState(false);
  const [isVerifyButtonLoading, setIsVerifyButtonLoading] = useState(false);
  const dispatch = useDispatch();
  Amplify.configure({
    Auth: {
      userPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID),
      userPoolWebClientId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_CLIENT_ID)
    }
  });
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const handleOpen = () => {
    if (prop.userDetails.isMfaSmsEnabled && !prop.isEdit) {
      if (prop?.userDetails?.mfa_status === 'disabled') {
        setOpenDeletaion(true);
      }
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    if (prop.userDetails.isMfaSmsEnabled && !prop.isEdit) {
      setOpenDeletaion(false);
    } else {
      setOpen(false);
      setDisplayAuth('none');
      setDisplayPhone('none');
      setCodeSent(false);
    }
  };
  const handleChangePhone = (value) => {
    if (/^\+\d{10,15}$/.test(value)) {
      setPhoneErrorMsg('');
    }
    if (phoneErrorMsg == 'Please enter phone number' && value) {
      setPhoneErrorMsg('');
    }

    setPhoneNumber(value);
  };
  const handleOtp = (value) => {
    if (/^\d{6}$/.test(value)) {
      setOtpErrorMsg('');
    }
    if (otpErrorMsg == 'Please enter otp code' && value) {
      setOtpErrorMsg('');
    }

    setOtpCode(value.target.value);
  };
  async function getUserInfo() {
    const user = await Auth.currentAuthenticatedUser();
    setUserDetails(user);
  }

  const handleSetMfa = useCallback(
    debounce(
      async () => {
        try {
          setIsVerifyButtonLoading(true);
          const data1 = await Auth.verifyCurrentUserAttributeSubmit('phone_number', otpCode);
          if (!prop.isEdit) {
            const noMFA = await Auth.setPreferredMFA(userDetails, 'NOMFA');
            const res = await Auth.setPreferredMFA(userDetails, 'SMS_MFA');
            const data = { ...prop.userDetails, isMfaSmsEnabled: true };
            data.phone = phoneNumber;
            data.isMfaAppEnabled = false;
            const updateUserdetals = await MyAccountApi.PutUserData(data);
            const user = await MyAccountApi.GetUserData();
            dispatch(commonDetailSlice.actions.setcommonDetails(user));
          } else {
            const data = { ...prop.userDetails };
            data.phone = phoneNumber;

            const updateUserdetals = await MyAccountApi.PutUserData(data);
            const user = await MyAccountApi.GetUserData();
            dispatch(commonDetailSlice.actions.setcommonDetails(user));
          }

          setIsChecked(true);
          handleClose();
        } catch (ex) {
          if (ex?.message === 'Attempt limit exceeded, please try after some time.') {
            notifyError('Attempt limit exceeded, please try after 30 mins.');
          } else {
            notifyError(ex.message);
          }
          const phoneNumber_update = await Auth.updateUserAttributes(userDetails, {
            phone_number: prop.userDetails.phone
          });
          handleClose();
        } finally {
          setTimeout(() => {
            setIsVerifyButtonLoading(false);
          }, 1000);
        }
      },
      500,
      true
    ),
    [open, otpCode]
  );

  const updateUserData = useCallback(
    debounce(
      async () => {
        console.log(prop?.isAuth);
        try {
          setIsSendCodeButtonLoading(true);
          setCodeSent(true);
          let userData: any;
          if (prop?.isAuth) {
            const user = await Auth.currentAuthenticatedUser();
            userData = user;
          }
          const phoneNumber_update = await Auth.updateUserAttributes(
            prop?.isAuth ? userData : userDetails,
            {
              phone_number: phoneNumber
            }
          );
          try {
            setIsSendCodeButtonLoading(true);
            const data = await Auth.verifyCurrentUserAttribute('phone_number');
            if (prop?.isAuth) {
              handleOpen();
            }
          } catch (ex) {
            if (ex?.message === 'Attempt limit exceeded, please try after some time.') {
              notifyError('Attempt limit exceeded, please try after 30 mins.');
            } else {
              notifyError(ex.message);
            }
            const phoneNumber_update = await Auth.updateUserAttributes(
              prop?.isAuth ? userData : userDetails,
              {
                phone_number: prop.userDetails.phone
              }
            );
            handleClose();
          } finally {
            setTimeout(() => {
              setIsSendCodeButtonLoading(false);
            }, 1000);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setTimeout(() => {
            setIsSendCodeButtonLoading(false);
          }, 1000);
        }
      },
      500,
      true
    ),
    [phoneNumber, open]
  );

  useEffect(() => {
    setIsChecked(prop.userDetails.isMfaSmsEnabled);
    getUserInfo();
    //phone_number: prop.userDetails.phone
    setPhoneNumber(prop.userDetails.phone);
  }, [commonDetails, prop]);
  console.log(prop?.userDetails);
  return (
    <Box>
      {prop.isEdit ? (
        <Box sx={{ color: '#436AF3', cursor: 'pointer' }}>
          <Button onClick={handleOpen} sx={{ fontWeight: 600, p: 0, minWidth: 0, minHeight: 0 }}>
            Edit
          </Button>
        </Box>
      ) : (
        <Box
          onClick={() => {
            console.log(/^\+\d{10,15}$/.test(phoneNumber));
            if (phoneNumber) {
              if (/^\+\d{10,15}$/.test(phoneNumber)) {
                setIsSendCodeButtonLoading(true);
                updateUserData();
              } else {
                setPhoneErrorMsg('Please enter a valid phone number');
              }
            } else {
              setPhoneErrorMsg('Please enter phone number');
            }
          }}>
          <Switch
            checked={isChecked}
            sx={{
              ml: 2,

              '	.MuiSwitch-switchBase': { color: 'rgba(0, 0, 0, 0.25)' },
              '	.MuiSwitch-track': {
                backgroundColor: 'transparent',
                border: '1px solid #ADB5BD',
                borderRadius: '45px'
              },
              '.css-jsexje-MuiSwitch-thumb': {
                boxShadow: 'none'
              },
              '	.Mui-disabled': {
                cursor: 'not-allowed'
              }
            }}
          />
        </Box>
      )}

      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '50%'
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="subtitle2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'text.primary',
                fontWeight: '600',
                maxWidth: 'calc(100% - 40px)',

                py: 1,
                px: 2.5
              }}>
              {codeSent ? 'Verification code' : 'Edit phone number'}
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} sx={{ color: 'text.primary' }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <form>
          <DialogContent sx={{ px: { xs: 2, md: 3 }, pt: 0 }}>
            <DialogContentText id="alert-dialog-description">
              <Box>
                <Typography sx={{ p: 2.5 }} variant="body1">
                  {codeSent
                    ? 'Secure your account by adding an extra layer of verification.'
                    : 'To confirm it’s your number, we’ll send you a code'}
                </Typography>
              </Box>
              {codeSent && (
                <Box>
                  <Typography sx={{ p: 2.5 }} variant="body1">
                    Please enter the 6-digit code you received on your phone {phoneNumber}
                  </Typography>
                </Box>
              )}

              <Box>
                <Box sx={{ px: 2.5, py: 1 }}>
                  {!codeSent && (
                    <Typography variant="body1">Enter your new phone number</Typography>
                  )}
                </Box>
                <Box sx={{ px: 2.5, py: 1 }}>
                  {codeSent ? (
                    <>
                      <TextField
                        sx={{
                          py: 0,
                          width: 0.6,
                          '.MuiInputBase-input': {
                            py: 1
                          }
                        }}
                        onChange={handleOtp}
                        placeholder="Please enter the 6-digit verification code"
                      />
                      <Button
                        sx={{ ml: 1 }}
                        onClick={async () => {
                          setIsSendCodeButtonLoading(true);
                          await updateUserData();
                          toast.success('OTP Sent successfully');
                        }}>
                        Resend OTP
                      </Button>
                      {otpErrorMsg != '' && (
                        <Box>
                          <Typography variant="caption" sx={{ color: '#EF4444' }}>
                            {otpErrorMsg}
                          </Typography>
                        </Box>
                      )}
                    </>
                  ) : (
                    <Box>
                      <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                        <Box
                          sx={{
                            border: '1px solid #EBEBEB',
                            borderRadius: '4px',
                            px: '14px',
                            '&:hover': {
                              outline: '0.8px solid black'
                            },
                            '&:focus-within': {
                              outline: '1.9px solid #436af3 !important'
                            }
                          }}>
                          <PhoneInput
                            international
                            autoFormat={false}
                            defaultCountry="US"
                            onChange={handleChangePhone}
                            //@ts-ignore
                            value={phoneNumber}
                          />
                        </Box>
                        {phoneErrorMsg != '' && (
                          <Typography variant="caption" sx={{ color: '#EF4444' }}>
                            {phoneErrorMsg}
                          </Typography>
                        )}
                      </FormControl>
                    </Box>
                  )}
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
          {/* {prop.isEdit ? (
            <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: 3, pt: 0 }}>
              <Button
                variant="contained"
                onClick={() => {
                  updateUserData();
                }}
                sx={{ minWidth: 100 }}>
                Verify
              </Button>
            </DialogActions>
          ) : (
            <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: 3, pt: 0 }}>
              <Button
                onClick={() => {
                  setCodeSent(true);
                }}
                variant="contained"
                sx={{ minWidth: 100, display: codeSent ? 'none' : 'flex' }}>
                Send Code
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleSetMfa();
                }}
                sx={{ minWidth: 100, display: codeSent ? 'flex' : 'none' }}>
                Verify
              </Button>
            </DialogActions>
          )} */}
          <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: 3, pt: 0 }}>
            <LoadingButton
              loading={isSendCodeButtonLoading}
              onClick={() => {
                if (phoneNumber) {
                  if (/^\+\d{10,15}$/.test(phoneNumber)) {
                    setIsSendCodeButtonLoading(true);
                    updateUserData();
                  } else {
                    setPhoneErrorMsg('Please enter a valid phone number');
                  }
                } else {
                  setPhoneErrorMsg('Please enter phone number');
                }
              }}
              variant="contained"
              sx={{ minWidth: 100, display: codeSent ? 'none' : 'flex' }}>
              Send Code
            </LoadingButton>
            <LoadingButton
              loading={isVerifyButtonLoading}
              variant="contained"
              onClick={() => {
                if (otpCode) {
                  if (/^\d{6}$/.test(otpCode)) {
                    handleSetMfa();
                  } else {
                    setOtpErrorMsg('Please enter 6-digit code');
                  }
                } else {
                  setOtpErrorMsg('Please enter otp code');
                }
              }}
              sx={{ minWidth: 100, display: codeSent ? 'flex' : 'none' }}>
              Verify
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <DeletaionBox
        openDeletaion={openDeletaion}
        handleOpen={handleOpen}
        handleClose={handleClose}
        flag={'SMS'}
        userDetails={prop.userDetails}
        titleText={'Disable Phone Verificantion'}
        discription={'A verification code won’t be sent to your phone when you log in. '}
      />
    </Box>
  );
};
export default MFAPopupPhone;
