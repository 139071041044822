import HttpService from '@jibin/common/services/http/HttpService';
export const HeaderApi = {
  GetNotifications(userId: string, companyId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/notifications`);
  },
  PatchNotifications(userId: string, companyId: string, data) {
    return HttpService.patchAll(`companies/${companyId}/users/${userId}/notifications`, data);
  },
  GetNewVersion() {
    return HttpService.getAll('version');
  },
  GetNewVersionID(user_id: string, version: string) {
    return HttpService.getAll(`users/${user_id}/version/${version}`);
  },
  ReadVersionByID(user_id: string, version: string) {
    return HttpService.post(`users/${user_id}/version/${version}`, {});
  },
  HelpCenter(companyId: string, userId: string, data: any) {
    return HttpService.post(`companies/${companyId}/users/${userId}/feedbacks`, data);
  },
};
