import HttpService from '@jibin/common/services/http/HttpService';

export const DashboardApi = {
  // Remediations(userId: string, companyId: string) {
  //   //return HttpService.getAll("todos/1");
  //   return HttpService.getAll(`companies/${companyId}/users/${userId}/remediations?top=10`);
  // },
  VulnerabilityStats(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/settings/vulnerabilities/stats`
    );
  },
  training(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/training`);
  },
  phishing(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/phishing`);
  },
  policystats(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/policies/stats`);
  },
  frameworkStats(userId: string, companyId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/frameworks/stats`);
  },
  emailProtectionStats(userId: string, companyId: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/email-protections/stats?period=30`
    );
  },
  endpointProtectionStats(userId: string, companyId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/multi-edr/stats`);
  },
  // /companies/{company_id}/users/{user_id}/assets/stats
  GetAssetsStats(userId: string, companyId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/assets/stats`);
  },
  GetRisksStats(userId: string, companyId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/risks/stats`);
  },
  GetGapStats(userId: string, companyId: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/gav2/frameworks/compliance-metrics`
    );
  },
  GetNDRDStats(userId: string, companyId: string, params: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/network_protection/stats?` + params
    );
  },
  savedCompanyLayout(companyId: string, data: any) {
    return HttpService.post(`companies/${companyId}/settings/dashboard`, data);
  },
  getCompanyLayout(companyId: string) {
    return HttpService.getAll(`companies/${companyId}/settings/dashboard`);
  },
  GetEventDetails(companyId: string, userId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/event/widget-summary`);
  }
};
