import HttpService from '@jibin/common/services/http/HttpService';
import axios from 'axios';

export const GapAnalysisApi = {
  GetCategories(userId: string, companyId: string, q: any) {
    // /companies/{company_id}/users/{user_id}/gav2/domains
    return HttpService.getAll(`companies/${companyId}/users/${userId}/gav2/domains?` + q);
  },
  GetFrameWorks(userId: string, companyId: string) {
    // /companies/{company_id}/users/{user_id}/gav2/domains
    return HttpService.getAll(`companies/${companyId}/users/${userId}/gav2/frameworks`);
  },
  GetQuestionnaires(userId: string, companyId: string, query: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/gav2/questions?` + query);
  },
  GetExportData(userId: string, companyId: string, query: string) {
    //return HttpService.getAll("todos/1");
    // /companies/{company_id}/users/{user_id}/gav2/export
    return HttpService.getDocument(`companies/${companyId}/users/${userId}/gav2/export?` + query);
  },
  UploadDocuments(response: any, files: any) {
    const {
      data: { data: urls }
    } = response;

    return Promise.all(
      urls.map(({ upload_url, callback_url, callback_data }, i) => {
        const file = files[i];
        return axios
          .put(upload_url, file, {
            headers: {
              'Content-Type': file.type
            }
          })
          .then(() => HttpService.post(callback_url.substr(1), { data: callback_data }));
      })
    );
  },
  // /companies/{company_id}/users/{user_id}/gav2/questions/{question_id}/answers
  PostAnswers(userId: string, companyId: string, questionnaire_id: string, data: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.post(
      `companies/${companyId}/users/${userId}/gav2/questions/${questionnaire_id}/answers`,
      data
    );
  },
  PostAnswersEngineer(
    userId: string,
    companyId: string,
    questionnaire_id: string,
    answer_id: string,
    data: any
  ) {
    //return HttpService.getAll("todos/1");
    return HttpService.post(
      `companies/${companyId}/users/${userId}/questionnaires/${questionnaire_id}/answers/${answer_id}/feedback`,
      data
    );
  },
  Submit(userId: string, companyId: string, data: any) {
    return HttpService.post(`companies/${companyId}/users/${userId}/questionnaires/submit`, data);
  },
  // /companies/{company_id}/users/{user_id}/gav2/controls/{control_id}/submit
  SubmitControle(userId: string, companyId: string, controleId: string) {
    return HttpService.post(
      `companies/${companyId}/users/${userId}/gav2/controls/${controleId}/submit`,
      {}
    );
  },
  // /companies/{company_id}/users/{user_id}/gav2/controls/{control_id}/evidence-groups/evidences
  UploadGroupDocuments(userId: string, companyId: string, controleId: string, data: any) {
    return HttpService.putAll(
      `companies/${companyId}/users/${userId}/gav2/controls/${controleId}/evidences`,
      data
    );
  },
  PutAnswers(
    userId: string,
    companyId: string,
    questionnaire_id: string,
    answer_id: string,
    data: any
  ) {
    //return HttpService.getAll("todos/1");
    return HttpService.putAll(
      `companies/${companyId}/users/${userId}/gav2/questions/${questionnaire_id}/answers/${answer_id}`,
      data
    );
  },
  // /companies/{company_id}/users/{user_id}/gav2/controls/{control_id}/evidence-groups/{evidence_group_id}/evidences/{evidence_id}
  async GetFile(
    userId: string,
    companyId: string,
    control_id: string,
    evidence_group_id: string,
    evidence_id: any
  ) {
    const response = await HttpService.getAll(
      `companies/${companyId}/users/${userId}/gav2/controls/${control_id}/evidence-groups/${evidence_group_id}/evidences/${evidence_id}`
    );

    return response;
  },
  // /companies/{company_id}/users/{user_id}/gav2/controls/{control_id}/history
  async GetControlHistory(userId: string, companyId: string, control_id: string) {
    const response = await HttpService.getAll(
      `companies/${companyId}/users/${userId}/gav2/controls/${control_id}/history`
    );

    return response;
  },
  // /companies/{company_id}/users/{user_id}/gav2/controls/{control_id}/evidence-groups/{evidence_group_id}/evidences/{evidence_id}
  async RemoveFile(
    companyId: string,
    userId: string,
    control_id: string,
    evidence_group_id: string,
    evidence_id: string
  ) {
    return await HttpService.deleteFiles(
      `companies/${companyId}/users/${userId}/gav2/controls/${control_id}/evidence-groups/${evidence_group_id}/evidences/${evidence_id}`
    );
  },
  GetUserData() {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll('users/me');
  },
  GetGapStats(userId: string, companyId: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/gav2/frameworks/compliance-metrics`
    );
  }
};
