import React from 'react'

function DomainIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.3571 14.6663C12.039 14.6663 15.0238 11.6816 15.0238 7.99967C15.0238 4.31778 12.039 1.33301 8.3571 1.33301C4.6752 1.33301 1.69043 4.31778 1.69043 7.99967C1.69043 11.6816 4.6752 14.6663 8.3571 14.6663Z"
        stroke="#436AF3"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.69041 2H6.35708C5.05708 5.89333 5.05708 10.1067 6.35708 14H5.69041"
        stroke="#436AF3"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3572 2C11.6572 5.89333 11.6572 10.1067 10.3572 14"
        stroke="#436AF3"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.35718 10.6667V10C6.25051 11.3 10.4638 11.3 14.3572 10V10.6667"
        stroke="#436AF3"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.35718 6.00039C6.25051 4.70039 10.4638 4.70039 14.3572 6.00039"
        stroke="#436AF3"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DomainIcon