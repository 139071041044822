import HttpService from '@jibin/common/services/http/HttpService';

export const ServiceReportsApi = {
  // /companies/{company_id}/users/{user_id}/multi-edr/devices
  GetAllEndPoint(userId: string, companyId: string, params: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/multi-edr/devices?` + params);
  },
  GetAllReports(companyId: string, params: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/reports?` + params);
  },
  GetAllServices() {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`services`);
  },
  GetAllProviderOption(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/multi-edr/providers?`);
  },
  GetAllDeviceDetails(userId: string, companyId: string, edr_key: string) {
    //return HttpService.getAll("todos/1");

    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/multi-edr/devices/${edr_key}`
    );
  },
  PostRemediationData(userId: string, companyId: string, edr_key: string, data: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.post(
      `companies/${companyId}/users/${userId}/multi-edr/devices/${edr_key}/remediations`,
      data
    );
  },
  PostReportData(serviceId: string, companyId: string, data: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.post(`companies/${companyId}/services/${serviceId}/reports`, data);
  },
  EditReportData(serviceId: string, companyId: string, reportId: string, data: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.put(`companies/${companyId}/services/${serviceId}/reports`, reportId, data);
  },
  GetReportDataById(serviceId: string, companyId: string, reportId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.get(`companies/${companyId}/services/${serviceId}/reports`, reportId);
  },
  DeleteReportData(serviceId: string, companyId: string, reportId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.remove(`companies/${companyId}/services/${serviceId}/reports`, reportId);
  },
  CallBackReportData(serviceId: string, companyId: string, reportId: string, data: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.post(
      `companies/${companyId}/services/${serviceId}/reports/${reportId}`,
      data
    );
  },
  GetReportFile(serviceId: string, companyId: string, reportId: string, fileId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.get(
      `companies/${companyId}/services/${serviceId}/reports/${reportId}/files`,
      fileId
    );
  }
};
