import HttpService from '@jibin/common/services/http/HttpService';
import axios from 'axios';

export const MyAccountApi = {
  GetUserData() {
    return HttpService.getAll(`users/me`);
  },
  PutUserData(data: any) {
    return HttpService.putAll(`users/me`, data);
  },
  PutProfilePhoto(user_id: any, data: any) {
    return HttpService.putAll(`users/${user_id}/profile-pic`, data);
  },
  async UploadProfilePhoto(response: any, file: any) {
    const {
      data: {
        data: {
          upload_url,
          callback_url,
          callback_data,
        }
      }
    } = response;
 

    return axios.put(upload_url, file, {
      headers: {
        'Content-Type': file.type
      }
    }).then(() => HttpService.post(callback_url, { data: callback_data }));
  }
};
