import SvgIcon from '@mui/material/SvgIcon';

export default function CheckBoxLightBlue(props) {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.16663" y="0.5" width="19" height="19" rx="5.5" fill="#F2F9FF" />
      <path
        d="M15.3333 6.5L8.91667 12.9167L6 10"
        stroke="#436AF3"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect x="1.16663" y="0.5" width="19" height="19" rx="5.5" stroke="#436AF3" />
    </svg>
  );
}
