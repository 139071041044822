import BorderLinearProgress from '@jibin/common/components/LinearLoading/BorderLinearGreen';
import AlertIcon from '@jibin/common/icons/AlertIcon/AlertIcon';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import Style from '@jibin/common/style/Styles';
import ConsultHourChart from '@jibin/common/components/ConsultHourChart';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { Typography, linearProgressClasses, Grid, Button, Skeleton } from '@mui/material';
import { Box } from '@mui/system';

import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TopRemediations } from '../Common/TopRemediations/TopRemediations';
import { NoDataAvailable } from '../Common/NoDataAvailable';
import { useSelector, shallowEqual } from 'react-redux';
import { CompanyDashboardApi } from './CompanyDashboard.Api';
import moment from 'moment';
import './dashboard.css';
import { CompanyTopRemediationsByFlag } from '../Common/CompanyTopRemediationsByFlag/CompanyTopRemediationsByFlag';
import HeadingHeader from '../Common/HeadingHeader';
const CompanyDashboard: FC = () => {
  const navigate = useNavigate();

  const [isSkeleton, setIsSkeleton] = useState(true);
  const [data, setData] = useState([]);
  const [trendingData, setTrendingData] = useState({} as any);
  const [flag, setFlag] = useState(true);

  async function aggregateDataByMonth(dailyData) {
    const currentDate = moment();
    const twelveMonthsAgo = currentDate.subtract(12, 'months');

    const filterData = dailyData
      ?.filter((el) => {
        const objDate = moment(el.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS Z'); // Adjust the format based on your date string

        return objDate.isAfter(twelveMonthsAgo);
      })
      ?.reduce((result, data) => {
        const month = moment(data?.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS Z').format('MMM');
        const year = moment(data?.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS Z').format('YYYY');
        const key = `${month} ${year}`;

        if (!result[key]) {
          result[key] = { month: key, sum1: 0, sum2: 0, count: 0 };
        }

        result[key].sum1 += data.total_hours;
        result[key].sum2 += data.consulting_hours;
        result[key].count++;

        return result;
      }, {});
    for (const month in filterData) {
      const average1 = filterData[month].sum1 / filterData[month].count;
      const average2 = filterData[month].sum2 / filterData[month].count;
      filterData[month].value = Math.round(average1);
      filterData[month].value2 = Math.round(average2);
    }
    return filterData;
  }
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const GetUpGuardTrending = useCallback(async () => {
    try {
      const res = await CompanyDashboardApi.ConsultingHourUtilization(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );

      // setTrendingData(res?.data?.data);

      const monthlyUpdatedData = Object.values(await aggregateDataByMonth(res?.data?.data));
      setData(monthlyUpdatedData);
    } catch (error) {}
  }, [commonDetails]);
  useEffect(() => {
    setIsSkeleton(true);
    if (commonDetails?.data?.company?.type != 'engineering') {
      navigate(PageRouteConstants.DashBoard);
    } else {
      Promise.all([GetUpGuardTrending()]).finally(() => {
        setIsSkeleton(false);
      });
    }
  }, [GetUpGuardTrending, commonDetails, navigate]);
  if (isSkeleton) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton variant="rectangular" height={'50px'} />
      </Box>
    );
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <HeadingHeader subtitle1={'Dashboard'} subtitle2={'Manage clients in one place'} />

      <>
        <Box sx={{ px: 4, py: 3, backgroundColor: '#FBFBFF', width: 1 }}>
          <Box sx={{ color: 'rgba(70, 70, 70, 1)', width: 1 }}>
            <Box>
              <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
                <Grid xs={12} item lg={6}>
                  <Box
                    sx={{
                      border: '1px solid #CCCCCC',
                      width: 1,
                      height: 1,
                      minHeight: '406px',
                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px'
                    }}>
                    <CompanyTopRemediationsByFlag
                      column={8}
                      size={5}
                      source="security_incidents"
                      title="Open Security Events"
                    />
                  </Box>
                </Grid>
                <Grid xs={12} item lg={6}>
                  <Box
                    sx={{
                      border: '1px solid #CCCCCC',
                      width: 1,
                      height: 1,
                      minHeight: '406px',
                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px'
                    }}>
                    <CompanyTopRemediationsByFlag
                      column={8}
                      size={5}
                      source="open_risk"
                      title="Open Risks"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
                <Grid xs={12} item lg={6}>
                  <Box
                    sx={{
                      border: '1px solid #CCCCCC',
                      width: 1,
                      height: 1,
                      minHeight: '406px',
                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px'
                    }}>
                    <CompanyTopRemediationsByFlag
                      column={8}
                      size={5}
                      source="rem"
                      title="Open Remediations"
                    />
                  </Box>
                </Grid>
                <Grid xs={12} item lg={6}>
                  <Box
                    sx={{
                      border: '1px solid #CCCCCC',
                      width: 1,
                      height: 1,
                      minHeight: '406px',
                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px'
                    }}>
                    <CompanyTopRemediationsByFlag
                      column={8}
                      size={5}
                      source="ooart"
                      title="Oldest Opened and Assigned Remediation Tasks"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
                <Grid xs={12} item lg={6}>
                  <Box
                    sx={{
                      border: '1px solid #CCCCCC',
                      width: 1,
                      height: 1,
                      minHeight: '406px',
                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px'
                    }}>
                    <CompanyTopRemediationsByFlag
                      column={8}
                      size={5}
                      source="vul"
                      title="Open Vulnerabilities"
                    />
                  </Box>
                </Grid>
                <Grid xs={12} item lg={6}>
                  <Box
                    sx={{
                      border: '1px solid #CCCCCC',
                      width: 1,
                      height: 1,
                      minHeight: '406px',
                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px'
                    }}>
                    <CompanyTopRemediationsByFlag
                      column={8}
                      size={5}
                      source="attack_surface"
                      title="Attack Surface Risk Rating"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
                <Grid xs={12} item lg={6}>
                  <Box
                    sx={{
                      border: '1px solid #CCCCCC',
                      width: 1,
                      height: 1,
                      minHeight: '406px',
                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px'
                    }}>
                    <CompanyTopRemediationsByFlag
                      column={8}
                      size={5}
                      source="sat"
                      title="Security Awareness Training Completion"
                    />
                  </Box>
                </Grid>
                <Grid xs={12} item lg={6}>
                  <Box
                    sx={{
                      border: '1px solid #CCCCCC',
                      width: 1,
                      height: 1,
                      minHeight: '406px',
                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px'
                    }}>
                    <CompanyTopRemediationsByFlag
                      column={8}
                      size={5}
                      source="pen"
                      title="Open Penetration Testing Findings"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ mt: 4 }}>
              <Grid container>
                <Grid item xs={12}>
                  <ConsultHourChart data={data} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </>
    </Box>
  );
};
export default CompanyDashboard;
