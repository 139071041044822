const MacAddressIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_20499_2491)">
        <path
          d="M15.6905 7.33301H12.3572C12.1804 7.33301 12.0108 7.40325 11.8858 7.52827C11.7607 7.65329 11.6905 7.82286 11.6905 7.99967V13.9997C11.6905 14.1765 11.7607 14.3461 11.8858 14.4711C12.0108 14.5961 12.1804 14.6663 12.3572 14.6663H15.6905C15.8673 14.6663 16.0369 14.5961 16.1619 14.4711C16.2869 14.3461 16.3572 14.1765 16.3572 13.9997V7.99967C16.3572 7.82286 16.2869 7.65329 16.1619 7.52827C16.0369 7.40325 15.8673 7.33301 15.6905 7.33301ZM15.6905 13.333H12.3572V8.66634H15.6905V13.333ZM13.6905 1.33301H1.69051C0.950511 1.33301 0.357178 1.92634 0.357178 2.66634V10.6663C0.357178 11.02 0.497654 11.3591 0.747702 11.6091C0.997751 11.8592 1.33689 11.9997 1.69051 11.9997H6.35718V13.333H5.02384V14.6663H10.3572V13.333H9.02384V11.9997H10.3572V10.6663H1.69051V2.66634H13.6905V5.99967H15.0238V2.66634C15.0238 1.92634 14.4238 1.33301 13.6905 1.33301Z"
          fill="#436AF3"
        />
      </g>
      <defs>
        <clipPath id="clip0_20499_2491">
          <rect width="16" height="16" fill="white" transform="translate(0.357178)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default MacAddressIcon;
