const FilterCancelIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17848_12160)">
        <path
          d="M9.19002 12.937L6.00002 14.0003V8.33366L3.01335 5.04833C2.79032 4.80294 2.66672 4.48326 2.66669 4.15166V2.66699H13.3334V4.11499C13.3333 4.46859 13.1928 4.80767 12.9427 5.05766L10 8.00033"
          stroke="#436AF3"
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6666 14.6663L11.3333 11.333"
          stroke="#436AF3"
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3333 14.6663L14.6666 11.333"
          stroke="#436AF3"
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17848_12160">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default FilterCancelIcon;
