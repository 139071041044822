import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import FileIcon from '@jibin/common/icons/FileIcon/FileIcon';
import GallaryIcon from '@jibin/common/icons/GallaryIcon/GallaryIcon';
import PinIcon from '@jibin/common/icons/PinIcon/PinIcon';
import UploadIcon from '@jibin/common/icons/UploadIcon/UploadIcon';
import VideoPlay from '@jibin/common/icons/VideoPlay/VideoPlay';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import Shareicon from '@jibin/common/icons/Shareicon/Shareicon';
import {
  Box,
  List,
  Typography,
  Button,
  TextField,
  ListItem,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  IconButton
} from '@mui/material';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { GapAnalysisApi } from '../../GapAnalysis.Api';
import { notifySuccess } from '@jibin/common/services/http/http-common';
import { useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';
import { LoginContext } from '@jibin/common/Context/LoginContext';
var debounce = require('lodash.debounce');
export interface GapAnalysisSideBarProp {
  rowData: any;
  index: any;
  answerOption: any;
  dataForSideBar: any;
  isEngineer: boolean;
  closePopup(event: React.KeyboardEvent | React.MouseEvent, toggled?: boolean): void;
}
const GapAnalysisSideBar: FC<GapAnalysisSideBarProp> = (prop) => {
  const [fileData, setFileData] = useState([] as any);

  const [error, setError] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([{ name: 'xyz' }]);
  const { rowData, closePopup, index, dataForSideBar, isEngineer, answerOption } = prop;
  const [postData, setPostData] = useState({ answer: '' });
  const [isUploade, setIsUpload] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [postDataEngineer, setPostDataEngineer] = useState({
    status: 'pending',
    severity: 'high',
    title: '',
    description: '',
    recommendation: ''
  });
  const [values, setValues] = useState('high');
  const [isAnswerButtonLoading, setIsAnswerButtonLoading] = useState(false);
  const [isFileRemoveButtonLoading, setIsFileRemoveButtonLoading] = useState(false);
  const [stateRowData, setStateRowData] = useState(rowData);
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  useEffect(() => {
    setStateRowData(rowData);
  }, [rowData]);

  function niceBytes(x) {
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }
  const handleChangeEngineer = (e: any) => {
    if (e.target.name == 'recommendation') {
      if (e.target.value.length < 1000) {
        setPostDataEngineer({ ...postDataEngineer, [e.target.name]: e.target.value });
      } else {
        setPostDataEngineer({
          ...postDataEngineer,
          [e.target.name]: postDataEngineer.recommendation
        });
      }
    } else {
      setPostDataEngineer({ ...postDataEngineer, [e.target.name]: e.target.value });
    }
  };

  const handleChange = (event: any) => {
    setError(false);
    if (event.target.value.length < 1000) {
      setPostData({ ...postData, answer: event.target.value });
    } else {
      setPostData({ ...postData, answer: postData.answer });
    }
  };
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const { engineer } = useContext(LoginContext);
  const handleChangeDropdown = (e: any) => {
    setValues(e.target.value);
  };

  const handleRemoveFile = (i) => {
    const upadatedFileData = fileData.filter((el, index) => index != i);
    setFileData(upadatedFileData);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const files = [];
      if (rowData?.answer?.answer_uuid) {
        setIsAnswerButtonLoading(true);
        let data = { answer: { comment: postData.answer, answer: answerOption } };
        const res = await GapAnalysisApi.PutAnswers(
          commonDetails.data.user_uuid,
          commonDetails.data.company.company_uuid,
          rowData.question_uuid,
          rowData.answer?.answer_uuid,
          data
        );

        closePopup(e);
      }
      setIsAnswerButtonLoading(false);
    } catch (err) {
      setIsAnswerButtonLoading(false);
    } finally {
      setTimeout(() => {
        setIsAnswerButtonLoading(false);
      }, 1000);
    }
  };

  const handleImageUpload = (e, files) => {
    stopReload(e);

    if (!e) return;

    setFileData([...fileData, ...files]);
  };

  const stopReload = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleBrowseEvent = (e: any) => {
    setFileData([...fileData, ...e.target.files]);
  };

  const handleDragEvent = (e: any) => {
    handleImageUpload(e, e.dataTransfer.files);
  };

  const getFile = async (el) => {
    const res = await GapAnalysisApi.GetFile(
      commonDetails.data.company.company_uuid,
      commonDetails.data.user_uuid,
      rowData.question_uuid,
      rowData.answer?.answer_uuid,
      el.id
    );

    // commonDetails.data.company.company_uuid,
    // commonDetails.data.user_uuid,
    // rowData.question_uuid,
    // rowData.answer?.answer_uuid,

    // el.id
    const a = document.createElement('a');
    a.href = res?.data.data;
    a.target = '_blank';
    a.download = `${el.file_name}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const removeFile = async (e, el) => {
    try {
      setIsFileRemoveButtonLoading(true);
      stopReload(e);
      const res = await GapAnalysisApi.RemoveFile(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        rowData.questionnaires_uuid,
        rowData.answer?.questionnaire_answers_uuid,
        el.id
      );

      const filterFile = stateRowData?.answer?.files?.filter((element) => element.id !== el.id);

      setStateRowData({
        ...stateRowData,
        answer: {
          ...stateRowData?.answer,
          files: filterFile
        }
      });
    } catch (error) {
    } finally {
      setTimeout(() => {
        setIsFileRemoveButtonLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    setPostData({ ...postData, answer: rowData?.answer?.comment });

    // setPostDataEngineer({ ...postDataEngineer, ...rowData?.answer?.feedback });
    if (rowData?.answer?.feedback?.status) {
      setPostDataEngineer({ ...postDataEngineer, ...rowData?.answer?.feedback });
    }

    setUploadedFiles([]);
    setIsUpload(false);
    return () => {
      setPostDataEngineer({
        status: 'pending',
        severity: 'high',
        description: '',
        title: '',
        recommendation: ''
      });
      setFileData([]);
    };
  }, [commonDetails, prop]);
  return (
    <>
      <Box
        sx={{
          width: { md: 520, xs: 300 },

          position: 'relative',
          minHeight: '100vh',
          height: '100%'
        }}
        role="presentation">
        <List sx={{ p: 0 }}>
          <Box sx={{ backgroundColor: '#003260', px: 2.5, py: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 1,
                alignItems: 'center'
              }}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 600, fontSize: '19px', color: 'white' }}>
                  {dataForSideBar?.controle_id}: {dataForSideBar?.controle_title}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  onClick={(event) => {
                    closePopup(event);
                  }}>
                  <CrossCancle
                    sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </List>
        <List sx={{ p: 3, height: 'calc(100% - 145px)' }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, mb: 2 }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 600,
                mb: 1.25,
                color: '#2D2D2D',
                fontSize: '19px',
                display: 'flex',
                gap: 1
              }}>
              {rowData?.question_id} - {rowData.question}
            </Typography>
          </Box>

          <Box sx={{ position: 'relative' }}>
            <Box>
              {' '}
              <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
                Describe your answer
              </Typography>
            </Box>
            <TextField
              autoComplete={'none'}
              error={error}
              inputRef={(input) => {
                if (input != null) {
                  input.focus();
                }
              }}
              helperText={error && 'This field is Required'}
              value={postData.answer}
              // defaultValue={rowData?.answer?.comment}
              onChange={handleChange}
              multiline
              rows={7}
              sx={{ fontSize: '14px', textAlign: 'justify', width: 1 }}
              placeholder="Type your answer here..."
            />

            <Typography
              variant="body2"
              sx={{
                color: '#B7B7B7',
                textAlign: 'end',
                position: 'absolute',
                right: 8,
                bottom: 6
              }}>
              {`${postData?.answer?.length ? postData?.answer?.length : 0}/999`}
            </Typography>
          </Box>

          <Box></Box>
        </List>

        <Box
          sx={{
            textAlign: 'end',

            mx: 3,
            py: 1,
            right: 0
          }}>
          <LoadingButton
            loading={isAnswerButtonLoading}
            onClick={handleSubmit}
            disabled={!postData.answer}
            sx={{
              justifySelf: 'flex-end',
              minWidth: 163,
              backgroundColor: '#436AF3',
              color: 'white',
              '&:hover': {
                backgroundColor: '#436AF3'
              },
              '&.Mui-disabled': {
                backgroundColor: '#EBEBEB',
                color: isAnswerButtonLoading ? '#EBEBEB' : '#9D9D9D'
              }
            }}>
            Save
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};
export default GapAnalysisSideBar;
