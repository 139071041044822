import HttpService from '@jibin/common/services/http/HttpService';

export const NetwrokDetectionApi = {
  GetAllEvent(userId: string, companyId: string, params: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/network_protection/events?` + params
    );
  },

  GetAllDeviceDetails(userId: string, companyId: string, device_id: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/devices/${device_id}`);
  },
  PostRemediationData(userId: string, companyId: string, event_id: string, data: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.post(
      `companies/${companyId}/users/${userId}/network_protection/${event_id}/remediations`,
      data
    );
  }
};
