import HttpService from '@jibin/common/services/http/HttpService';

export const TopRemediationsApi = {
  OpenRiskList(userId: string, companyId: string, param: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/engineering-dashboard/risk-summary?service=` + param
    );
  },
  OpenRemediationList(userId: string, companyId: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/engineering-dashboard/remediation-summary/open`
    );
  },
  OpenOldestAssignedRemediationList(userId: string, companyId: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/engineering-dashboard/remediation-summary/oldest-and-assigned`
    );
  },
  AttackSurfaceRatingList(userId: string, companyId: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/engineering-dashboard/attack-surface/rating-summary`
    );
  },
  SecurityAwarenessTrainingList(userId: string, companyId: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/engineering-dashboard/security-awareness-training-summary`
    );
  },
  EventsList(userId: string, companyId: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/engineering-dashboard/event-summary`
    );
  }
};
