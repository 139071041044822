import { Box, Typography, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import { CompanyUserRoles } from '@jibin/common/utils/constants/ConstantName';

const HeadingHeader = (props: any) => {
  return (
    <>
      {props?.component === 'dashboard' ? (
        <>
          <Box
            sx={{
              px: 4,
              py: 2,
              backgroundColor: '#FFFFFF',
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <Box>
              <Typography variant={'subtitle1'} sx={{ mb: '7px' }}>
                Dashboard
              </Typography>
              <Typography variant={'subtitle2'}>
                Real-time view of your cybersecurity program
              </Typography>
            </Box>
            <Box sx={{ pt: 2, mr: 3, textAlign: 'right' }}>
              {(props?.commonDetails?.data?.group == props?.UserRoles.SUPER_ADMIN || props?.commonDetails?.data?.company?.user_role == CompanyUserRoles.ADMIN) && (
                <>
                  {props?.isDragable ? (
                    <>
                      <Button variant="outlined" sx={{ mr: 2 }} onClick={props?.resetLayout}>
                        Cancel
                      </Button>
                      <Button variant="contained" onClick={props?.saveLayout}>
                        Save
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => props?.setIsDragable(true)}
                      variant="contained"
                      startIcon={props?.SettingsIcon}>
                      Customize
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              backgroundColor: '#FFFFFF',
              p: '16px 32px',
              gap: props?.subtitle2 ? '7px' : '0px'
            }}>
            <Typography variant={'subtitle1'}>{props?.subtitle1}</Typography>
            <Typography variant={'subtitle2'}>{props?.subtitle2}</Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default HeadingHeader;
