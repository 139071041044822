import { useState } from 'react';
import {
  Button,
  Checkbox,
  Typography,
  Menu,
  MenuItem,
  TextField,
  Box,
  InputAdornment
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import SearchIcon from '@jibin/common/icons/Search/Search';

// deviceName = { deviceName };
const FilterDropdown = (props) => {
  const { setFilterData, filterData, filterAssets, userNameList } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [subMenuAnchorElManufacture, setSubMenuAnchorElManufacture] = useState(null);
  const [subMenuAnchorElAssetType, setSubMenuAnchorElAssetType] = useState(null);
  const [userNameListSeach, setUserNameListSeach] = useState('');

  const handleMainMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubMenuClick = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };
  const handleSubMenuClickManufacture = (event) => {
    setSubMenuAnchorElManufacture(event.currentTarget);
  };

  const handleClose = () => {
    setSubMenuAnchorEl(null);
    setSubMenuAnchorElManufacture(null);
    setSubMenuAnchorElAssetType(null);
  };
  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const handleCloseMain = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (type, item) => {
    setFilterData((prevState) => {
      const updatedArray = [...prevState[type]];
      const itemIndex = updatedArray.indexOf(item);
      if (itemIndex !== -1) {
        updatedArray.splice(itemIndex, 1);
      } else {
        updatedArray.push(item);
      }
      return {
        ...prevState,
        [type]: updatedArray
      };
    });
  };

  return (
    <div className="multi-dropdown">
      <Button aria-controls="main-menu" aria-haspopup="true" onClick={handleMainMenuClick}>
        <FilterAltOutlinedIcon /> +
      </Button>
      <Menu id="main-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMain}>
        <MenuItem sx={{ fontWeight: 600 }} onClick={handleSubMenuClick}>
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Assets
        </MenuItem>
        <MenuItem sx={{ fontWeight: 600 }} onClick={handleSubMenuClickManufacture}>
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Username
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={subMenuAnchorEl}
        open={Boolean(subMenuAnchorEl)}
        onClose={handleClose}
        sx={{ height: '350px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, padding: '8px' }}
          variant="body1">
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Assets
        </Typography>
        {filterAssets?.map((el: any, index) => (
          <MenuItem
            onClick={() => handleMenuItemClick('assets', el)}
            sx={{ fontSize: '14px' }}
            value={el}
            autoFocus={index === 0}>
            <Checkbox checked={filterData.assets.includes(el)} /> {el}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={subMenuAnchorElManufacture}
        open={Boolean(subMenuAnchorElManufacture)}
        onClose={handleClose}
        sx={{ height: '350px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, padding: '8px' }}
          variant="body1">
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Username
        </Typography>
        <Box
          sx={{
            fontSize: '14px',
            py: 0,
            px: 2,
            '.MuiMenuItem-root': {
              py: '0px'
            }
          }}>
          <TextField
            onClickCapture={stopImmediatePropagation}
            onKeyDown={(e) => e.stopPropagation()}
            placeholder="Search byUsername"
            hiddenLabel
            fullWidth
            size="small"
            name="keyword"
            value={userNameListSeach}
            onChange={(e) => setUserNameListSeach(e.target.value)}
            sx={{ backgroundColor: '#FFFFFF' }}
            inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Box>
        {userNameList
          ?.filter((el) => {
            if (userNameListSeach) {
              return el?.toLowerCase()?.includes(userNameListSeach?.toLowerCase());
            } else {
              return el;
            }
          })
          ?.map((el: any, index) => (
            <MenuItem
              onClick={() => handleMenuItemClick('username', el)}
              sx={{ fontSize: '14px' }}
              value={el}
              autoFocus={index === 0}>
              <Checkbox checked={filterData.username.includes(el)} /> {el}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export default FilterDropdown;
