import { useSelector, shallowEqual } from 'react-redux';
import { UserRoles } from '../utils/constants/ConstantName';
const checkUseHasWhichUseHasPermission = (roles) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const role = commonDetails?.data?.group.toLowerCase();
  if (roles.includes(role)) {
    return true;
  } else {
    return false;
  }
};
export default checkUseHasWhichUseHasPermission;
