const LockIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0833 6.41669H2.91667C2.27233 6.41669 1.75 6.93902 1.75 7.58335V11.6667C1.75 12.311 2.27233 12.8334 2.91667 12.8334H11.0833C11.7277 12.8334 12.25 12.311 12.25 11.6667V7.58335C12.25 6.93902 11.7277 6.41669 11.0833 6.41669Z"
        stroke="#464646"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.08301 6.41669V4.08335C4.08301 3.30981 4.3903 2.56794 4.93728 2.02096C5.48426 1.47398 6.22613 1.16669 6.99967 1.16669C7.77322 1.16669 8.51509 1.47398 9.06207 2.02096C9.60905 2.56794 9.91634 3.30981 9.91634 4.08335V6.41669"
        stroke="#464646"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default LockIcon;
