import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


const DropdownMenu = ({ setAnchorEl, anchorEl, setWidgetSize }: any) => {
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (size: any) => {
        setWidgetSize(size)
        handleClose();
    };
    return (
        <>
            <Menu
                id="vertical-menu"
                className='edit-width-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={(e) => handleMenuItemClick(1)} className='menu-items-li'>
                    <div style={{ width: '50px', marginRight: '8px' }}>1</div>
                    <div style={{ width: '50px', marginRight: '8px' }}>1</div>
                    <div style={{ width: '50px' }}>1</div>
                </MenuItem>
                <MenuItem onClick={(e) => handleMenuItemClick(2)} className='menu-items-li'>
                    <div style={{ width: '108px', marginRight: '8px' }}>2</div>
                    <div style={{ width: '50px' }}>1</div>
                </MenuItem>
                <MenuItem onClick={(e) => handleMenuItemClick(3)} className='menu-items-li'>
                    <div style={{ width: '166px' }}>3</div>
                </MenuItem>
            </Menu>
        </>
    );
};

export default DropdownMenu;
