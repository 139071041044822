import Style from '@jibin/common/style/Styles';
import { Box, Typography, Button } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { FC, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { QRCodeCanvas } from 'qrcode.react';
import { Amplify, Auth } from 'aws-amplify';
import { CommonFunctions } from '@jibin/common/utils/Common';



export const MfaAuthenticator: FC = () => {

    Amplify.configure({
        Auth: {
            userPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID),
            userPoolWebClientId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_CLIENT_ID)
        }
    });

    const [qrCode, setQrcode] = useState('');
    const [userDetails, setUserDetails] = useState({} as any);
    const navigate = useNavigate();
    const locaton = useLocation();
    const isAllowed = locaton?.state && locaton?.state?.allow;

    async function getUserInfo() {
        const user = await Auth.currentAuthenticatedUser();

        const code = await Auth.setupTOTP(user);
        setUserDetails(user);
        const str =
            'otpauth://totp/Redesign%20Trust%20Portal:' +
            user?.attributes?.email +
            '?secret=' +
            code +
            '&issuer=Redesign%20Trust%20Portal';
        setQrcode(str);
    }

    const handleClick = () => {
        navigate(PageRouteConstants.Mfa, { state: { mfa_status: "MFA_AUTHENTICATOR" } }) // Adjust the URL as needed
    };

    useEffect(() => {
        if (!isAllowed) {
            navigate(PageRouteConstants.Login);
        } else {
            getUserInfo()
        }
    }, [])

    return (
        <Box sx={Style.SignUp.SignUpWrapper}>
            <Container maxWidth="xl">
                <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
                        <Box>
                            <Box sx={Style.Auth.TrustLogo}>
                                <img alt="" src="/images/trust-logo-black.svg" />
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Typography sx={{ fontWeight: 600, textAlign: 'justify', mb: 3 }} variant="subtitle2">
                                    {' '}
                                    Set up Authentication App
                                </Typography>
                                <Typography sx={{ py: 1 }} variant="body1">
                                    <span style={{ fontWeight: 600 }}>Step 1: </span>
                                    Open your Authentication App
                                </Typography>
                                <Typography sx={{ py: 1, mb: 3 }} variant="body1">
                                    <span style={{ fontWeight: 600 }}>Step 2: </span>
                                    Add an account within the app and scan the QR below
                                </Typography>
                                <QRCodeCanvas value={qrCode} />
                            </Box>
                            <Box>
                                <Button variant="contained" onClick={handleClick}>Next</Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
                        <Box sx={Style.Auth.AuthInfographics}>
                            <img alt="" src="/images/elephant-with-men.png" />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
