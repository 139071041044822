
import {
  Dialog, 
  Button, 
  Box,
  Typography,
  Slide
} from '@mui/material';

import { TransitionProps } from '@mui/material/transitions';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';


interface MaintainenceProps {
  flags?: any;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Pass the flags prop and return an element based on the state of the feature flag key
const Maintenance = ({ flags }: MaintainenceProps) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    // window.location.reload();
  };
  useEffect(() => {
    flags.maintenanceFlag && setOpen(true);
  }, [flags.maintenanceFlag]);

  return (
    <Box>
      <Dialog
        sx={{ zIndex: '10000' }}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 8,flexGrow:1 }}>
          <Box
            sx={{
              textAlign: 'center',
              maxWidth: '650px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1
            }}>
            <img width={'50%'} style={{ margin: 'auto' }} src="/images/trust-logo-black.svg" />
            <img width={'50%'} style={{ margin: 'auto' }} src="/images/NoFound.png" />
            <Typography variant="body2" sx={{ fontWeight: 600, color: '#436AF3', mt: '10px' }}>
              New Features coming soon!
            </Typography>
            <Typography variant="h1" sx={{ fontSize: '40px', color: '#787878', mt: '12px' }}>
              We are under Maintenance
            </Typography>
            <Typography
              sx={{ mt: '12px', textAlign: 'center', fontWeight: 600, color: '#9D9D9D' }}
              variant="subtitle2">
              The site you are looking for is currently under maintenance and we will be back soon.
            </Typography>
            {!flags.maintenanceFlag && (
              <Button onClick={() => window.location.reload()} variant="contained">
                {' '}
                Refresh
              </Button>
            )}
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

const MaintenanceWithFlag = withLDConsumer()(Maintenance);
export default MaintenanceWithFlag;
