export class Env {
  constructor(env?: Env) {
    let properties = [
      'name',
      'application_env_uuid',
      'type',
      'description',
      'is_external',
      'url',
      'hosting_provider_type',
      'hosting_provider',
      'mfa_type',
      'mfa',

      'ipRange',
      'ids_ips_type',
      'ip_address',
      'ids_ips',
      'status',
      'ip_size'
    ];
    for (let property of properties)
      if (env && (env[property] || env[property] === 0)) this[property] = env[property];
  }
  application_env_uuid: string;
  name: string;
  ipRange: string;
  type: string = '-1';
  is_external: string;
  description: string;
  ip_address: string;
  url: string;
  hosting_provider_type: string = '-1';
  hosting_provider: string;
  mfa_type: string = '';
  mfa: string;
  ids_ips_type: string = '';
  ids_ips: string;
  status: string;
  ip_size: 0;
}
