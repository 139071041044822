import { useEffect, useState } from 'react';
import { NoDataAvailable, NoSearchDataAvailable } from './../../Common/NoDataAvailable';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  linearProgressClasses
} from '@mui/material';
import Style from '@jibin/common/style/Styles';
import TablePagination from '../../Common/TablePagination';
import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderLinearProgress from '@jibin/common/components/LinearLoading/BorderLinearGreen';
import DeleteDialog from './Models/DeleteDialog';
import moment from 'moment';

const RowUser = ({
  row,
  index,
  GetLicenses,
  commonDetails,
  setRowData,
  globalClose,
  setEditLicense,
  toggleDrawer
}: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setRowData(null);
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const handleClick = (event: any, row: any) => {
    setRowData(row);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setRowData(null);
  };
  const handleRow = (e: any, row: any) => {
    const element = e?.target?.closest('.icon-button')
    if (!element) {
      setRowData(row);
      toggleDrawer('right', true)
      setEditLicense(true);
    }
  }
  useEffect(() => {
    if (globalClose) {
      handleClose();
    }
  }, [globalClose]);
  return (
    <>
      <TableRow
        id={row.license_utilization_uuid}
        key={row?.license_utilization_uuid + index}
        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
        onClick={(e) => {
          handleRow(e, row)
        }}
      >
        <TableCell>{row?.service?.name}</TableCell>
        <TableCell>{row?.vendor?.name}</TableCell>
        <TableCell>{row?.tool?.name}</TableCell>
        <TableCell sx={{ width: '250px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1.5,
              justifyContent: 'space-between'
            }}>
            <Box
              sx={{
                width: '100px',
                border: '1px solid #B3DAFF',
                borderRadius: '10px',
                p: 0.4,
                backgroundColor: '#F2F9FF'
              }}>
              <BorderLinearProgress
                variant="determinate"
                value={(row?.licenses_used / row?.total_licenses) * 100}
                sx={{
                  [`.${linearProgressClasses.bar}`]: {
                    borderRadius: 5,
                    backgroundColor:
                      (row?.licenses_used / row?.total_licenses) * 100 <= 30
                        ? '#10B981'
                        : (row?.licenses_used / row?.total_licenses) * 100 >= 31 &&
                          (row?.licenses_used / row?.total_licenses) * 100 <= 70
                          ? '#FCD34D'
                          : '#EF4444'
                  }
                }}
              />
            </Box>

            <Typography variant="body2" sx={{ color: '#696969', whiteSpace: 'nowrap' }}>
              {`${Math.round((row?.licenses_used / row?.total_licenses) * 100).toFixed(0)}% (${row?.licenses_used
                }/${row?.total_licenses})`}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>{moment(row?.start_date).format('DD MMM YYYY').toUpperCase()}</TableCell>
        <TableCell>{moment(row?.end_date).format('DD MMM YYYY').toUpperCase()}</TableCell>
        <TableCell>
          <Box>
            {row?.status === 'Active' ? (
              <Box
                sx={{
                  ...Style.SiteListTable.StatusActive,
                  bgcolor: 'common.green'
                }}></Box>
            ) : row?.status === 'Expired' ? (
              <Box
                sx={{
                  ...Style.SiteListTable.StatusActive,
                  bgcolor: '#EF4444'
                }}></Box>
            ) : row?.status === 'Inactive' ? (
              <Box
                sx={{
                  ...Style.SiteListTable.StatusActive,
                  bgcolor: '#B7B7B7'
                }}></Box>
            ) : null}
            <Typography variant="caption">
              {row.status.charAt(0).toUpperCase() + row.status.substr(1).toLowerCase()}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <IconButton
            onClick={(e) => {
              handleClick(e, row);
            }}
            className='icon-button'
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            <MoreHorizIcon color="primary" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id={'account-menu'}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuItem
              onClick={() => {
                // handleOpenEdit();
                // handleClose();
                toggleDrawer('right', true)
                setEditLicense(true);
                setAnchorEl(null);
              }}>
              <EditOutlinedIcon sx={{ color: '#436AF3' }} />
              <Typography sx={{ ml: 1 }} variant="caption">
                {' '}
                Edit
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDelete(true);
              }}>
              <DeleteOutlineOutlinedIcon sx={{ color: '#EF4444' }} />
              <Typography sx={{ ml: 1 }} variant="caption">
                {' '}
                Delete License
              </Typography>
            </MenuItem>
          </Menu>
        </TableCell>

        <DeleteDialog
          openDelete={openDelete}
          handleCloseDelete={handleCloseDelete}
          rowData={row}
          setAnchorEl={setAnchorEl}
          GetLicenses={GetLicenses}
          commonDetails={commonDetails}
        />
      </TableRow>
    </>
  );
};

const LicenseListTable = (props) => {
  let {
    licenseListData,
    totalCount,
    page,
    setPage,
    rowsPerPage,
    globalClose,
    setRowsPerPage,
    GetLicenses,
    commonDetails,
    setEditLicense,
    setRowData,
    toggleDrawer
  } = props;

  return (
    <>
      {!licenseListData || licenseListData?.length == 0 ? (
        <NoSearchDataAvailable />
      ) : (
        <>
          {' '}
          <Box className="material-ui-table">
            {' '}
            <Table sx={{ minWidth: 650, marginBottom: '12px' }} aria-label="site list table">
              <TableHead>
                <TableRow>
                  <TableCell>Service</TableCell>
                  <TableCell>Vendor Name</TableCell>
                  <TableCell>Tool</TableCell>
                  <TableCell>License Utilization</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {licenseListData?.map((row, index) => (
                  <>
                    <Box key={row?.first_name + index + index} sx={Style.Common.Divider}></Box>
                    <RowUser
                      row={row}
                      index={index}
                      globalClose={globalClose}
                      GetLicenses={GetLicenses}
                      commonDetails={commonDetails}
                      setRowData={setRowData}
                      setEditLicense={setEditLicense}
                      toggleDrawer={toggleDrawer}
                    />
                  </>
                ))}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            totalCount={totalCount}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </>
      )}
    </>
  );
};

export default LicenseListTable;
