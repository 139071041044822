import { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Typography,
  SwipeableDrawer,
  List,
  MenuItem,
  FormControl,
  Select,
  TextField,
  ListItemText,
  Checkbox,
  OutlinedInput
} from '@mui/material';
import { useFormik } from 'formik';
import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import CalenderIconFilter from '@jibin/common/icons/CalenderIconFilter/CalenderIconFilter';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { LoadingButton } from '@mui/lab';
import { useSearchParams } from 'react-router-dom';
import { SIEMApi } from './SIEM.Api';
import { useNavigate } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import LinkIcon from '@jibin/common/icons/LinkIcon';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type Anchor = 'top' | 'left' | 'bottom' | 'right';

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Event = (prop: any) => {
  const {
    text,
    isEdit,
    isView,
    rowData,
    setIsView,
    servicesOption,
    setRowData,
    setIsEdit,
    setAnchorEl,
    commonDetails,
    GetEndPoint,
    isWidget,
    isViewGrid,
    setIsViewGrid,
    GetEventSummary
  } = prop;
  const [assetWithValue, setAssetWithValue] = useState({});
  const [assetsTagList, setAssetTagList] = useState([]);
  const [useParams, setUseParam] = useSearchParams();
  const [tools, setToolsOption] = useState([]);
  const navigate = useNavigate();
  const [state, setState] = useState({
    right: false
  });
  const [isComplete, setIsComplete] = useState(true);

  const [remediationFilterform, setRemediationFilterform] = useState({
    keyword: '',
    Assets: '',
    status: '',
    source: '',
    tag_uuids: ''
  });

  const setFormikValue = (val: any) => {
    if (val.includes('ip')) {
      formik.setFieldValue('ip', formik.values.ip);
      setIsComplete(true);
    } else {
      formik.setFieldValue('ip', '');
    }

    if (val.includes('mac')) {
      formik.setFieldValue('mac', formik.values.mac);
      setIsComplete(true);
    } else {
      formik.setFieldValue('mac', '');
    }
    if (val.includes('custom')) {
      formik.setFieldValue('custom', formik.values.custom);
      setIsComplete(true);
    } else {
      formik.setFieldValue('custom', '');
    }
    if (val.includes('machine')) {
      formik.setFieldValue('machine', formik.values.machine);
      setIsComplete(true);
    } else {
      formik.setFieldValue('machine', '');
    }
    if (val.includes('hostnames')) {
      formik.setFieldValue('hostnames', formik.values.hostnames);
      setIsComplete(true);
    } else {
      formik.setFieldValue('hostnames', '');
    }
    validate(formik.values);
  };

  const handleChangeAsset = (event: any) => {
    let {
      target: { value, name }
    } = event;
    const filterValue = value?.filter((el: any) => el != '');
    setFormikValue(filterValue);
    let string;
    if (filterValue.length == 0) {
      string = '';
      const dataForApi = { ...remediationFilterform };

      dataForApi[name] = string;

      setRemediationFilterform(dataForApi);

      const item = typeof value === 'string' ? filterValue.split(',') : [''];

      formik.setFieldValue('Assets', item);
    } else {
      string = filterValue.join(',');
      const dataForApi = { ...remediationFilterform };

      dataForApi[name] = string;

      setRemediationFilterform(dataForApi);

      const item = typeof value === 'string' ? filterValue.split(',') : filterValue;
      formik.setFieldValue('Assets', item);
    }
    // if (value.length > formik?.values?.Assets?.length) {
    //   setIsComplete(true);
    // }
  };

  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected?.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });

    return selectedLabels?.join(', ');
  };

  const validate = (value: any) => {
    const errors: any = {};
    const isEmpty = value?.Assets?.every((item) => item.trim() === '');
    const requiredFields = ['hostnames', 'ip', 'mac', 'machine', 'custom'];
    for (const field of requiredFields) {
      if (value?.Assets?.includes(field) && !value[field]) {
        setIsComplete(true);
        return;
      }
    }
    if (!value.description || !value.name || !value.services_uuid) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      criticality: 'Medium',
      Assets: [''],
      services_uuid: '',
      integration_tools_uuid: '',
      discovered_at: new Date()
      // recommendation: remediationData?.recommendation
    },
    validate,
    onSubmit: async () => {
      try {
        let originalDate = new Date(formik?.values?.discovered_at || '');
        const adjustedISOString =
          originalDate.getFullYear() +
          '-' +
          String(originalDate.getMonth() + 1).padStart(2, '0') +
          '-' +
          String(originalDate.getDate()).padStart(2, '0') +
          'T' +
          String(originalDate.getHours()).padStart(2, '0') +
          ':' +
          String(originalDate.getMinutes()).padStart(2, '0') +
          ':' +
          String(originalDate.getSeconds()).padStart(2, '0') +
          '.' +
          String(originalDate.getMilliseconds()).padStart(3, '0') +
          'Z';

        const objService = servicesOption?.filter(
          (item) => item?.value === formik.values.services_uuid
        );
        const objTool = tools?.filter(
          (item) => item?.value === formik.values.integration_tools_uuid
        );

        const data: any = {
          name: formik.values.name,
          description: formik.values.description,
          criticality: formik.values.criticality,
          recommendation: formik.values.recommendation,
          service: {
            name: objService?.[0]?.name,
            services_uuid: formik.values.services_uuid
          },
          tool: {},
          assets: {
            ip: formik.values.ip,
            mac: formik.values.mac,
            custom: formik.values.custom,
            machine: formik.values.machine,
            hostnames: formik.values.hostnames ? formik.values.hostnames.split(',') : []
          },
          status: 'Not Started',
          discovered_at: adjustedISOString
        };

        if (formik.values.integration_tools_uuid && objTool?.[0]?.name) {
          data.tool = {
            name: objTool?.[0]?.name,
            integration_tools_uuid: formik.values.integration_tools_uuid
          };
        }

        if (isEdit) {
          const res = await SIEMApi.PutEvent(
            commonDetails?.data?.company.company_uuid,
            commonDetails?.data?.user_uuid,
            rowData?.event_uuid,
            data
          );
        } else {
          const res = await SIEMApi.CreateEvent(
            commonDetails?.data?.company.company_uuid,
            commonDetails?.data?.user_uuid,
            data
          );
        }
        GetEndPoint();
        GetEventSummary();
        toggleDrawer('right', false);
      } catch (error) {
      } finally {
        setTimeout(() => {}, 1000);
      }
    }
  });
  useEffect(() => {
    if (formik.values.services_uuid) {
      const service = servicesOption?.filter((item) => item?.value === formik.values.services_uuid);
      if (!service?.[0]?.tools?.length) {
        formik.setFieldValue('integration_tools_uuid', '');
      }
    }
    return () => {
      setToolsOption([]);
    };
  }, [formik.values.services_uuid]);
  const AssetList = [
    { key: 'Select Asset', value: '', placeholder: '' },

    { key: 'Domain/URL', value: 'hostnames', placeholder: 'Eg: www.redesign-group.com' },
    { key: 'IP Address', value: 'ip', placeholder: 'Eg: 192.158.1.38' },
    { key: 'MAC Address', value: 'mac', placeholder: 'Eg: 00-B0-D0-63-C2-26' },
    {
      key: 'Machine/Server Name',
      value: 'machine',
      placeholder: 'Eg: Teletraan'
    },
    { key: 'Other', value: 'custom', placeholder: 'Type here' }
  ];
  // const areTagsEqual = (tag1, tag2) => tag1.trim().toLowerCase() === tag2.trim().toLowerCase();
  const renderColorBackGround = (criticality: any) => {
    var color = '#FFE6E2';
    if (criticality == 'High') {
      color = '#FCD34D';
    } else if (criticality == 'Critical') {
      color = '#FFE6E2';
    } else if (criticality == 'Medium') {
      color = '#FEF3C7';
    } else if (criticality == 'Low') {
      color = '#D0DAFC';
    } else if (criticality == 'Status') {
      color = '#EBEBEB';
    }
    return color;
  };
  const renderColor = (criticality: any) => {
    var color = '#F87171';
    if (criticality == 'Hritical') {
      color = '#F87171';
    } else if (criticality == 'High') {
      color = '#F59E0B';
    } else if (criticality == 'Medium') {
      color = '#FBBF24';
    } else if (criticality == 'Low') {
      color = '#436AF3';
    }
    return color;
  };
  const RenderAssetList = (assest: any) => {
    let data = [];
    if (assest?.length > 0) {
      data = AssetList?.filter((el: any) => {
        return el?.value && assest?.includes(el.value) && el;
      });
    } else {
      data = [];
    }

    return data;
  };
  const toggleDrawer = (anchor: Anchor, open: boolean) => {
    setState({ ...state, [anchor]: open });
    setAnchorEl && setAnchorEl(null);
    if (!open) {
      formik.setFieldValue('Assets', ['']);
      formik.setFieldValue('name', '');
      formik.setFieldValue('description', '');
      formik.setFieldValue('criticality', 'Medium');
      formik.setFieldValue('recommendation', '');
      formik.setFieldValue('services_uuid', '');
      formik.setFieldValue('integration_tools_uuid', '');
      formik.setFieldValue('ip', '');
      formik.setFieldValue('mac', '');
      formik.setFieldValue('custom', '');
      formik.setFieldValue('machine', '');
      formik.setFieldValue('hostnames', '');
      formik.setFieldValue('discovered_at', new Date());

      setIsView && setIsView(false);
      setIsEdit && setIsEdit(false);
      setRowData && setRowData(null);
      setIsViewGrid && setIsViewGrid(false);
    }

    if (rowData) {
      if (!open) {
        useParams.delete('event_uuid');
        setUseParam(useParams);
      } else {
        useParams.set('event_uuid', rowData?.event_uuid);
        setUseParam(useParams);
      }
    }
  };

  const capitalizeFirstLetter = (text) => {
    return text?.charAt(0)?.toUpperCase() + text?.slice(1);
  };

  useEffect(() => {
    if ((isView || isEdit) && rowData) {
      toggleDrawer('right', true);
      const asset: any = [];
      const assetsTag: any = [];

      if (rowData?.asset?.custom) {
        asset.push(rowData?.asset?.custom);
      }
      if (rowData?.asset?.ip) asset.push(rowData?.asset?.ip);
      if (rowData?.asset?.mac) asset.push(rowData?.asset?.mac);
      if (rowData?.asset?.machine) asset.push(rowData?.asset?.machine);
      if (rowData?.asset?.hostnames?.length > 0) asset.push([...rowData?.asset?.hostnames]);

      if (rowData?.asset?.hostnames?.length > 0) asset.push([...rowData?.asset?.hostnames]);
      for (const key in rowData?.asset) {
        assetsTag.push(key);
      }
      setAssetWithValue(rowData?.asset);
      setAssetTagList(assetsTag);
    }
    // if(prop?.isView)
  }, [prop]);

  useEffect(() => {
    if (isEdit) {
      const date = new Date(rowData?.discovered_at);
      formik.setFieldValue('name', rowData?.name);
      formik.setFieldValue('description', rowData?.description);
      formik.setFieldValue('services_uuid', rowData?.service?.services_uuid);
      formik.setFieldValue('integration_tools_uuid', rowData?.tool?.tool_uuid);
      formik.setFieldValue('discovered_at', date);
      formik.setFieldValue('criticality', rowData?.criticality);

      const assets: any = [];
      const assetsLabel: any = [];
      const assetsTag: any = [];

      if (rowData?.asset?.custom) {
        assets.push(rowData?.asset?.custom);
      }
      if (rowData?.asset?.ip) {
        assets.push(rowData?.asset?.ip);
      }
      if (rowData?.asset?.mac) assets.push(rowData?.asset?.mac);
      if (rowData?.asset?.machine) assets.push(rowData?.asset?.machine);
      if (rowData?.asset?.hostnames?.length > 0) assets.push([...rowData?.asset?.hostnames]);
      for (const key in rowData?.asset) {
        assetsTag.push(key);
      }
      if (rowData?.asset?.['ip']) {
        formik.setFieldValue('ip', rowData?.asset?.ip);
        assetsLabel.push('ip');
      } else {
        formik.setFieldValue('ip', '');
      }

      if (rowData?.asset?.['mac']) {
        formik.setFieldValue('mac', rowData?.asset?.mac);
        assetsLabel.push('mac');
      } else {
        formik.setFieldValue('mac', '');
      }
      if (rowData?.asset?.['custom']) {
        formik.setFieldValue('custom', rowData?.asset?.custom);
        assetsLabel.push('custom');
      } else {
        formik.setFieldValue('custom', '');
      }
      if (rowData?.asset?.['machine']) {
        formik.setFieldValue('machine', rowData?.asset?.machine);
        assetsLabel.push('machine');
      } else {
        formik.setFieldValue('machine', '');
      }
      if (rowData?.asset?.['hostnames']) {
        formik.setFieldValue('hostnames', rowData?.asset?.hostnames?.join(','));
        assetsLabel.push('hostnames');
      } else {
        formik.setFieldValue('hostnames', '');
      }

      formik.setFieldValue('Assets', assetsLabel?.length > 0 ? assetsLabel : ['']);
      setIsComplete(false);
    }
  }, [isEdit, rowData]);

  const GetToolsByService = useCallback(async () => {
    try {
      if (formik.values.services_uuid) {
        const res = await SIEMApi.GetToolsByServiceV2(
          commonDetails?.data?.company.company_uuid,
          commonDetails?.data?.user_uuid,
          formik.values.services_uuid
        );
        const ProviderListForFilter = res?.data?.data?.map((el) => {
          return {
            value: el.integration_tools_uuid,
            name: el.vendor_tool_name,
            tooltip: el.vendor_tool_name
          };
        });
        setToolsOption(ProviderListForFilter);
      }
    } catch (error) {}
  }, [commonDetails, formik.values.services_uuid]);

  useEffect(() => {
    GetToolsByService();
  }, [GetToolsByService]);
  useEffect(() => {
    if (!isViewGrid) {
      toggleDrawer('right', false);
      useParams.delete('event_uuid');
      setUseParam(useParams);
    }
  }, [isViewGrid]);

  const isValidDate = (dateString) => {
    let date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  return (
    <>
      {!isWidget ? (
        <Button variant="contained" onClick={() => toggleDrawer('right', true)}>
          {text}
        </Button>
      ) : null}

      <SwipeableDrawer
        BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
        sx={{
          '.MuiDrawer-paper': {
            boxShadow: 'none'
          }
        }}
        anchor={'right'}
        open={state['right']}
        onClose={() => toggleDrawer('right', false)}
        onOpen={() => toggleDrawer('right', true)}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              width: { md: 520, xs: 300 },
              position: 'relative',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column'
            }}
            role="presentation">
            <List sx={{ p: 0 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 1,
                  justifyContent: 'space-between',
                  backgroundColor: '#003260',
                  px: 2.5,
                  alignItems: 'center',
                  py: 1
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',

                    width: 1,
                    alignItems: 'center'
                  }}>
                  <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', my: 1.25 }}>
                    {isEdit ? 'Edit an Event' : isView ? 'Event Details' : 'Create an Event'}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box onClick={() => toggleDrawer('right', false)}>
                      <CrossCancle
                        sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </List>

            <Box sx={{ flex: 1 }}>
              <Box sx={{ p: 3 }}>
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderColor: 'divider' }}></Box>
                  <TabPanel value={0} index={0}>
                    {isView ? (
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}>
                          <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                            Criticality Level:
                          </Typography>
                          <FormControl sx={{ m: 1 }}>
                            <Box
                              sx={{
                                py: 1,
                                px: 2,

                                backgroundColor: renderColorBackGround(rowData?.criticality),
                                borderRadius: '100px'
                              }}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <FlagIcon
                                  sx={{
                                    fontSize: '12px',
                                    mr: 1,
                                    color: renderColor(rowData?.criticality)
                                  }}
                                />

                                <Typography variant="body2">
                                  {' '}
                                  {capitalizeFirstLetter(rowData?.criticality)}
                                </Typography>
                              </Box>
                            </Box>
                          </FormControl>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: '#F2F9FF',
                            padding: 2.5,
                            borderRadius: '8px',
                            my: 1
                          }}>
                          <Box>
                            <List>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  gap: 1
                                }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: '19px', fontWeight: 600 }}>
                                  {rowData?.name}
                                </Typography>
                              </Box>
                            </List>
                            <List>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  gap: 1
                                }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: '16px', fontWeight: 600 }}>
                                  Service Name
                                </Typography>
                                <Box>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: ' #696969',
                                      wordBreak: 'break-word',
                                      wordWrap: 'break-word'
                                    }}>
                                    {rowData?.service?.name}
                                  </Typography>
                                </Box>
                              </Box>
                            </List>
                            <List>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  gap: 1
                                }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: '16px', fontWeight: 600 }}>
                                  Tool Name
                                </Typography>
                                <Box>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: ' #696969',
                                      wordBreak: 'break-word',
                                      wordWrap: 'break-word'
                                    }}>
                                    {rowData?.tool?.name}
                                  </Typography>
                                </Box>
                              </Box>
                            </List>
                            <List>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  gap: 1
                                }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: '16px', color: '#696969', fontWeight: 600 }}>
                                  Asset
                                </Typography>
                                <Box>
                                  {RenderAssetList(assetsTagList)?.map((el: any) =>
                                    Array.isArray(assetWithValue[el?.value]) ? (
                                      assetWithValue[el?.value].map((item) => (
                                        <Box sx={{ display: 'flex' }}>
                                          <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                            {el?.key}:
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              color: ' #696969',
                                              wordBreak: 'break-word',
                                              wordWrap: 'break-word'
                                            }}>
                                            &nbsp;{item}
                                          </Typography>
                                        </Box>
                                      ))
                                    ) : assetWithValue[el?.value].includes(',') ? (
                                      assetWithValue[el?.value].split(',').map((item) => {
                                        return (
                                          <Box sx={{ display: 'flex' }}>
                                            <Typography
                                              variant="body2"
                                              sx={{ whiteSpace: 'nowrap' }}>
                                              {el?.key}:
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              sx={{
                                                color: ' #696969',
                                                wordBreak: 'break-word',
                                                wordWrap: 'break-word'
                                              }}>
                                              &nbsp;{item}
                                            </Typography>
                                          </Box>
                                        );
                                      })
                                    ) : (
                                      <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                          {el?.key}:
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            color: ' #696969',
                                            wordBreak: 'break-word',
                                            wordWrap: 'break-word'
                                          }}>
                                          &nbsp;{assetWithValue[el?.value]}
                                        </Typography>
                                      </Box>
                                    )
                                  )}
                                </Box>
                              </Box>
                            </List>
                            <List>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  gap: 1
                                }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: '16px', fontWeight: 600 }}>
                                  Description
                                </Typography>
                                <Box>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: ' #696969',
                                      wordBreak: 'break-word',
                                      wordWrap: 'break-word'
                                    }}>
                                    {rowData?.description}
                                  </Typography>
                                </Box>
                              </Box>
                            </List>
                          </Box>
                        </Box>
                        {rowData?.remediation_uuid && (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 1,
                              mt: 3
                            }}>
                            <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                              Track Remediation{' '}
                            </Typography>
                            <Box
                              onClick={() =>
                                navigate(
                                  `${PageRouteConstants.Remediation}?remediation_uuids=${rowData?.remediation_uuid}`
                                )
                              }>
                              <Typography
                                variant="body2"
                                sx={{
                                  gap: 0.5,
                                  fontWeight: 600,
                                  color: '#436AF3',
                                  cursor: 'pointer',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}>
                                {rowData?.remediation_uuid} <LinkIcon />
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        {isValidDate(rowData?.discovered_at) ? (
                          <List>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',

                                alignItems: 'flex-start',
                                gap: 2
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '14px', fontWeight: 600 }}>
                                Discovered Date
                              </Typography>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: '132px' }}>
                                  <label>
                                    <DatePicker
                                      showIcon
                                      disabled
                                      className="calendar-filter"
                                      icon={<CalenderIconFilter />}
                                      selected={new Date(rowData?.discovered_at)}
                                      dateFormat="dd MMM yyyy"
                                      placeholderText="DD MMM YYYY"
                                    />
                                  </label>
                                </Box>
                                <Box className="time-filter" sx={{ width: '132px', ml: 2 }}>
                                  <label>
                                    <DatePicker
                                      showIcon
                                      disabled
                                      className="calendar-filter"
                                      icon={<AccessTimeOutlinedIcon />}
                                      selected={new Date(rowData?.discovered_at)}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={1}
                                      timeCaption="Time"
                                      dateFormat="h:mm aa"
                                    />
                                  </label>
                                </Box>
                              </Box>
                            </Box>
                          </List>
                        ) : null}
                      </Box>
                    ) : (
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}>
                          <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                            Criticality Level:
                          </Typography>
                          <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Select
                              value={formik.values.criticality}
                              name="criticality"
                              onChange={formik.handleChange}
                              displayEmpty
                              sx={{
                                '.MuiSelect-select': {
                                  py: 1,
                                  display: 'flex',
                                  alignItems: 'center'
                                },
                                '&.MuiInputBase-root': {
                                  backgroundColor: renderColorBackGround(formik.values.criticality)
                                },
                                borderRadius: '100px'
                              }}
                              inputProps={{ 'aria-label': 'Without label' }}>
                              <MenuItem sx={{ backgroundColor: '#FFE6E2' }} value="Critical">
                                <Box>
                                  <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F73939' }} />
                                </Box>
                                <Typography variant="body2">Critical</Typography>
                              </MenuItem>
                              <MenuItem sx={{ backgroundColor: '#FCD34D' }} value="High">
                                <Box>
                                  <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F59E0B' }} />
                                </Box>
                                <Typography variant="body2">High</Typography>
                              </MenuItem>

                              <MenuItem sx={{ backgroundColor: '#FEF3C7' }} value={'Medium'}>
                                <Box>
                                  <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#FBBF24' }} />
                                </Box>
                                <Typography variant="body2">Medium</Typography>
                              </MenuItem>

                              <MenuItem sx={{ backgroundColor: '#D0DAFC' }} value={'Low'}>
                                {' '}
                                <Box>
                                  <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#436AF3' }} />
                                </Box>
                                <Typography variant="body2">Low</Typography>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        <Box sx={{ my: 1 }}>
                          <Box>
                            <List sx={{ py: 1.5 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  gap: 2
                                }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: '14px', fontWeight: 600, color: '#696969' }}>
                                  Event Name
                                </Typography>
                                <Box sx={{ width: 1 }}>
                                  <TextField
                                    size="small"
                                    // defaultValue={remediationData?.name}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    name="name"
                                    placeholder="Type here"
                                    value={formik.values.name}
                                    inputProps={{ sx: { fontSize: '14px' } }}
                                    variant="outlined"
                                    sx={{ fontSize: '14px' }}
                                  />
                                </Box>
                              </Box>
                            </List>
                            <List sx={{ py: 1.5 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  gap: 2
                                }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: '14px', fontWeight: 600, color: '#696969' }}>
                                  Service Name
                                </Typography>
                                <Box sx={{ width: 1 }}>
                                  <Select
                                    size="small"
                                    displayEmpty
                                    fullWidth
                                    id="type"
                                    sx={{
                                      fontSize: '14px',
                                      color: '#696969',
                                      minWidth: { xxs: 0, md: '188px' },
                                      backgroundColor: '#FFFFFF'
                                    }}
                                    defaultValue=""
                                    name="services_uuid"
                                    value={formik.values.services_uuid}
                                    onChange={formik.handleChange}
                                    inputProps={{ 'aria-label': 'Without label' }}>
                                    <MenuItem value="" sx={{ display: 'none' }}>
                                      Select Service
                                    </MenuItem>
                                    {servicesOption?.map((el: any, index) => (
                                      <MenuItem
                                        sx={{ fontSize: '14px' }}
                                        value={el?.value}
                                        autoFocus={index === 0}>
                                        {el?.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </Box>
                              </Box>
                            </List>
                            {tools?.length > 0 && (
                              <List sx={{ py: 1.5 }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    gap: 2
                                  }}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: '14px', fontWeight: 600 }}>
                                    Tool Name
                                  </Typography>
                                  <Box sx={{ width: 1 }}>
                                    <Select
                                      size="small"
                                      displayEmpty
                                      fullWidth
                                      id="integration_tools_uuid"
                                      sx={{
                                        fontSize: '14px',
                                        color: '#696969',
                                        minWidth: { xxs: 0, md: '188px' },
                                        backgroundColor: '#FFFFFF'
                                      }}
                                      name="integration_tools_uuid"
                                      value={formik.values.integration_tools_uuid}
                                      onChange={formik.handleChange}
                                      inputProps={{ 'aria-label': 'Without label' }}>
                                      <MenuItem value="" sx={{ display: 'none' }}>
                                        Select Tool
                                      </MenuItem>
                                      {tools?.map((tool: any, index) => (
                                        <MenuItem
                                          sx={{ fontSize: '14px' }}
                                          value={tool.value}
                                          key={index}>
                                          {tool?.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </Box>
                                </Box>
                              </List>
                            )}

                            <List sx={{ pt: 1.5, pb: 0 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  gap: 2
                                }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: '14px', fontWeight: 600, color: '#696969' }}>
                                  Asset Type
                                </Typography>
                                <Box sx={{ width: 1 }}>
                                  <FormControl sx={{ width: 1 }}>
                                    <Select
                                      size="small"
                                      displayEmpty
                                      multiple
                                      fullWidth
                                      placeholder="Select Asset"
                                      name="Assets"
                                      labelId="demo-multiple-checkbox-label"
                                      id="demo-multiple-checkbox"
                                      value={formik.values.Assets}
                                      onChange={handleChangeAsset}
                                      input={
                                        <OutlinedInput
                                          sx={{
                                            py: '5.5px'
                                          }}
                                        />
                                      }
                                      renderValue={(selected) => renderValue(selected, AssetList)}
                                      sx={{
                                        py: 0.35,
                                        width: 1,
                                        fontSize: '14px',
                                        color: '#696969',
                                        '.MuiSelect-select': {
                                          py: '5.5px'
                                        },
                                        minWidth: { xxs: 0, md: '150px' },
                                        backgroundColor: '#FFFFFF'
                                      }}
                                      inputProps={{ 'aria-label': 'Without label' }}>
                                      {AssetList.map((name, index) => (
                                        <MenuItem
                                          sx={{
                                            fontSize: '14px',
                                            display: !name.value ? 'none' : 'flex',

                                            py: 0,
                                            '.MuiMenuItem-root': {
                                              py: '0px'
                                            }
                                          }}
                                          key={name.key}
                                          autoFocus={index === 1}
                                          value={name.value}>
                                          <Checkbox
                                            checked={
                                              formik?.values?.Assets?.indexOf(name.value) > -1
                                            }
                                          />
                                          <ListItemText
                                            sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                                            primary={name.key}
                                          />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Box>
                            </List>
                            <List sx={{ py: 0 }}>
                              {RenderAssetList(formik?.values?.Assets)?.map((el) => {
                                return (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      my: 3,
                                      alignItems: 'flex-start',
                                      gap: 2
                                    }}>
                                    <Typography
                                      variant="subtitle2"
                                      sx={{ fontSize: '14px', fontWeight: 600, color: '#696969' }}>
                                      {el?.key}
                                    </Typography>
                                    <Box sx={{ width: 1 }}>
                                      <TextField
                                        size="small"
                                        name="asset"
                                        fullWidth
                                        onChange={(e: any) =>
                                          formik.setFieldValue(el?.value, e.target.value)
                                        }
                                        value={formik.values[el.value]}
                                        inputProps={{ sx: { fontSize: '14px' } }}
                                        variant="outlined"
                                        placeholder={el?.placeholder}
                                        sx={{ fontSize: '14px' }}
                                        // defaultValue={rowData?.recommendation}
                                      />
                                    </Box>
                                  </Box>
                                );
                              })}
                            </List>
                            <List sx={{ pt: formik?.values?.Assets?.[0] ? 0 : 3, pb: 1.5 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',

                                  alignItems: 'flex-start',
                                  gap: 2
                                }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: '14px', fontWeight: 600, color: '#696969' }}>
                                  Event Description
                                </Typography>
                                <Box sx={{ width: 1 }}>
                                  <TextField
                                    size="small"
                                    name="description"
                                    placeholder="Enter Description"
                                    fullWidth
                                    // defaultValue={remediationData?.description}
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                    inputProps={{ sx: { fontSize: '14px' } }}
                                    variant="outlined"
                                    rows={7}
                                    multiline
                                    sx={{ fontSize: '14px' }}
                                  />
                                </Box>
                              </Box>
                            </List>
                            <List>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',

                                  alignItems: 'flex-start',
                                  gap: 2
                                }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: '14px', fontWeight: 600, color: '#696969' }}>
                                  Discovered Date
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Box sx={{ width: '132px' }}>
                                    <label>
                                      <DatePicker
                                        showIcon
                                        className="calendar-filter"
                                        icon={<CalenderIconFilter />}
                                        selected={formik.values.discovered_at}
                                        dateFormat="dd MMM yyyy"
                                        placeholderText="DD MMM YYYY"
                                        onChange={(date: any, event: any) => {
                                          formik.setFieldValue('discovered_at', date);
                                        }}
                                        maxDate={new Date()}
                                      />
                                    </label>
                                  </Box>
                                  <Box className="time-filter" sx={{ width: '132px', ml: 2 }}>
                                    <label>
                                      <DatePicker
                                        showIcon
                                        className="calendar-filter"
                                        icon={<AccessTimeOutlinedIcon />}
                                        selected={formik.values.discovered_at}
                                        onChange={(date) =>
                                          formik.setFieldValue('discovered_at', date)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                      />
                                    </label>
                                  </Box>
                                </Box>
                              </Box>
                            </List>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </TabPanel>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                px: 2,
                py: 2,
                textAlign: 'end',
                borderTop: '1px solid #0000000D'
                // bottom: 0,
                // right: 0,
                // position: !create ? 'absolute' : 'relative'
              }}>
              <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => toggleDrawer('right', false)}
                  sx={{ minWidth: 150 }}
                  variant="outlined">
                  Cancel
                </Button>
                {isView ? (
                  <Button
                    onClick={() => {
                      setIsEdit(true);
                      setIsView(false);
                    }}
                    variant="contained"
                    sx={{
                      minWidth: 150,
                      border: '1px solid #436AF3',
                      '&.Mui-disabled': {
                        border: '1px solid #E0E0E0'
                      }
                    }}>
                    Edit
                  </Button>
                ) : (
                  <LoadingButton
                    // loading={isRemediationButtonLoading}
                    variant="contained"
                    disabled={isComplete}
                    sx={{
                      minWidth: 150,
                      border: '1px solid #436AF3',
                      '&.Mui-disabled': {
                        border: '1px solid #E0E0E0'
                      }
                    }}
                    type="submit">
                    Save
                  </LoadingButton>
                )}
              </Box>
            </Box>
          </Box>
        </form>
      </SwipeableDrawer>
    </>
  );
};

export default Event;
