import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import Style from '@jibin/common/style/Styles';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';

import { FormControlLabel, Radio, RadioGroup, Skeleton } from '@mui/material';
import { useSelector, shallowEqual } from 'react-redux';
import FrameworkControl from '../FrameWorks/FrameworkControl';
import { FrameworksApi } from '../FrameWorks/Frameworks.Api';
import { CompanyUserRoles, UserRoles } from '@jibin/common/utils/constants/ConstantName';
import HeadingHeader from '../Common/HeadingHeader';
import { LoginContext } from '@jibin/common/Context/LoginContext';

const MenuItems = [
  { name: 'Overview', class: '', route: PageRouteConstants.GapAnalysisV2 },
  { name: 'Questionnaire', class: '', route: PageRouteConstants.GapAnalysisV2Questionnaire }
];
export const gapContex = createContext(null);

export const GapAnalysisTemplate = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const { engineer } = useContext(LoginContext);

  return (
    <gapContex.Provider value={{ engineer }}>
      <HeadingHeader
        subtitle1={'Compliance'}
        subtitle2={'Assessment of your cybersecurity program against a leading framework'}
        engineer={engineer}
        commonDetails={commonDetails}
        UserRoles={UserRoles}
        component={'Gap Analysis'}
      />
      {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
        <List sx={Style.CompanySetting.MenuList}>
          {MenuItems?.map((currenElement, index) => (
            <ListItem disablePadding key={index} sx={{ width: 'auto' }}>
              <Link
                onClick={() => {
                  navigate(currenElement?.route);
                }}
                style={{ cursor: 'pointer' }}
                className={location.pathname === currenElement?.route ? 'active' : ''}
                sx={Style.CompanySetting.MenuListItem}>
                {currenElement.name}
              </Link>
            </ListItem>
          ))}
        </List>
      )}

      {props.children}
    </gapContex.Provider>
  );
};

export default GapAnalysisTemplate;
