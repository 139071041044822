import SvgIcon from '@mui/material/SvgIcon';

export default function CheckBoxLight(props) {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
      <rect x="1.16663" y="0.5" width="19" height="19" rx="5.5" fill="#FEF0E5" />
      <path
        d="M15.3333 6.5L8.91667 12.9167L6 10"
        stroke="#F9A866"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect x="1.16663" y="0.5" width="19" height="19" rx="5.5" stroke="#F9A866" />
    </svg>
  );
}
