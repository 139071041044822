import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import FileIcon from '@jibin/common/icons/FileIcon/FileIcon';
import GallaryIcon from '@jibin/common/icons/GallaryIcon/GallaryIcon';
import PinIcon from '@jibin/common/icons/PinIcon/PinIcon';
import UploadIcon from '@jibin/common/icons/UploadIcon/UploadIcon';
import VideoPlay from '@jibin/common/icons/VideoPlay/VideoPlay';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import Shareicon from '@jibin/common/icons/Shareicon/Shareicon';
import {
  Box,
  List,
  Typography,
  Button,
  TextField,
  ListItem,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  Select
} from '@mui/material';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { GapAnalysisApi } from '../../GapAnalysis.Api';
import { notifySuccess } from '@jibin/common/services/http/http-common';
import { LoadingButton } from '@mui/lab';
import CopyUrl from '@jibin/common/components/ConformationModel/CopyUrl';
import { LoginContext } from '@jibin/common/Context/LoginContext';
export interface GapAnalysisSideBarEngineerProp {
  rowData: any;
  index: any;

  isEngineer: boolean;
  closePopup(event: React.KeyboardEvent | React.MouseEvent): void;
}
var debounce = require('lodash.debounce');

const GapAnalysisSideBarEngineer: FC<GapAnalysisSideBarEngineerProp> = (prop) => {
  const [fileData, setFileData] = useState([] as any);

  const [error, setError] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([{ name: 'xyz' }]);
  const { rowData, closePopup, index, isEngineer } = prop;
  const [postData, setPostData] = useState({ answer: '' });
  const [isUploade, setIsUpload] = useState(false);
  const [postDataEngineer, setPostDataEngineer] = useState({
    status: 'pending',
    severity: 'high',
    recommendation: '',
    title: '',
    description: ''
  });
  const [values, setValues] = useState('high');
  const [isAnswerButtonLoading, setIsAnswerButtonLoading] = useState(false);
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  function niceBytes(x) {
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }

  const renderColorBackGround = (severity: any = 'critical') => {
    var color = '#FFE6E2';
    if (severity == 'high') {
      color = '#FCD34D';
    } else if (severity == 'critical') {
      color = '#FFE6E2';
    } else if (severity == 'medium') {
      color = '#FEF3C7';
    } else if (severity == 'low') {
      color = '#D0DAFC';
    } else if (severity == 'status') {
      color = '#EBEBEB';
    }
    return color;
  };

  const handleChangeEngineer = (e: any) => {
    if (e.target.name == 'description') {
      if (e.target.value.length < 1000) {
        setPostDataEngineer({ ...postDataEngineer, [e.target.name]: e.target.value });
      } else {
        setPostDataEngineer({
          ...postDataEngineer,
          [e.target.name]: postDataEngineer.description
        });
      }
    } else if (e.target.name == 'recommendation') {
      if (e.target.value.length < 1000) {
        setPostDataEngineer({ ...postDataEngineer, [e.target.name]: e.target.value });
      } else {
        setPostDataEngineer({
          ...postDataEngineer,
          [e.target.name]: postDataEngineer.recommendation
        });
      }
    } else {
      setPostDataEngineer({ ...postDataEngineer, [e.target.name]: e.target.value });
    }
  };
  const handleChange = (event: any) => {
    setError(false);
    if (event.target.value.length < 1000) {
      setPostData({ ...postData, answer: event.target.value });
    } else {
      setPostData({ ...postData, answer: postData.answer });
    }
  };
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const { engineer } = useContext(LoginContext);

  const handleSubmit = useCallback(
    debounce(
      async (e: any) => {
        e.preventDefault();
        if (postData.answer == '') {
          setError(true);
          return;
        }
        try {
          setIsAnswerButtonLoading(true);
          if (engineer) {
            const res = await GapAnalysisApi.PostAnswersEngineer(
              commonDetails.data.user_uuid,
              commonDetails.data.company.company_uuid,
              rowData.questionnaires_uuid,
              rowData.answer?.questionnaire_answers_uuid,
              postDataEngineer
            );
            closePopup(e);
          } else if (rowData?.answer?.questionnaire_answers_uuid) {
            const formData = new FormData();

            for (let i = 0; i < fileData.length; i++) {
              formData.append('files', fileData[i]);
            }
            let data = { comment: postData.answer };
            formData.append('data', JSON.stringify(data));
            const res = await GapAnalysisApi.PutAnswers(
              commonDetails.data.user_uuid,
              commonDetails.data.company.company_uuid,
              rowData.questionnaires_uuid,
              rowData.answer?.questionnaire_answers_uuid,
              formData
            );
            closePopup(e);
          } else {
            const formData = new FormData();
            for (let i = 0; i < fileData.length; i++) {
              formData.append('files', fileData[i]);
            }
            let data = { comment: postData.answer };
            formData.append('data', JSON.stringify(data));

            const res = await GapAnalysisApi.PostAnswers(
              commonDetails.data.user_uuid,
              commonDetails.data.company.company_uuid,
              rowData.questionnaires_uuid,
              formData
            );
            closePopup(e);
          }
        } catch (err) {
        } finally {
          setTimeout(() => {
            setIsAnswerButtonLoading(false);
          }, 1000);
        }
      },
      500,
      true
    ),
    [postData, postDataEngineer, fileData]
  );

  const handleImageUpload = (e, files) => {
    stopReload(e);

    if (!e) return;

    setFileData([...fileData, ...files]);
  };

  const stopReload = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleBrowseEvent = (e: any) => {
    setFileData([...fileData, ...e.target.files]);
  };

  const handleDragEvent = (e: any) => {
    handleImageUpload(e, e.dataTransfer.files);
  };
  const getFile = async (el) => {
    const res = await GapAnalysisApi.GetFile(
      commonDetails.data.company.company_uuid,
      commonDetails.data.user_uuid,
      rowData.questionnaires_uuid,
      rowData.answer?.questionnaire_answers_uuid,
      el.id
    );
    const a = document.createElement('a');
    a.href = res?.data.data;
    a.target = '_blank';
    a.download = `${el.file_name}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    setPostData({ ...postData, answer: rowData?.answer?.comment });
    if (rowData?.answer?.feedback?.status) {
      setPostDataEngineer({ ...postDataEngineer, ...rowData?.answer?.feedback });
    }

    setUploadedFiles([]);
    setIsUpload(false);
    return () =>
      setPostDataEngineer({
        status: 'pending',
        severity: 'high',
        title: '',
        recommendation: '',
        description: ''
      });
  }, [commonDetails, prop]);

  return (
    <>
      <Box
        sx={{
          width: { md: 520, xs: 300 },

          position: 'relative',
          minHeight: '100vh'
        }}
        role="presentation">
        <List sx={{ p: 0 }}>
          <Box sx={{ backgroundColor: '#003260', px: 2.5, py: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 1,
                alignItems: 'center'
              }}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 600, fontSize: '19px', color: 'white' }}>
                  Question
                  {/* {engineer ? 'Source: Gap Analysis' : 'Question Category'} */}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CopyUrl
                  title="Copy and share this page"
                  handleClose={() => {}}
                  icon={<Shareicon />}
                  mainBtnTitle={''}
                  url={window.location.href}
                  handleConfirm={() => {
                    navigator.clipboard.writeText(window.location.href);
                    notifySuccess('Copied');
                  }}
                />

                <Box
                  onClick={(event) => {
                    closePopup(event);
                  }}>
                  <CrossCancle
                    sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                  />
                </Box>
              </Box>
            </Box>
            {/* {engineer && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2" sx={{ color: '#FFFFFF' }}>
                  Discovered: 12 JAN 2023
                </Typography>
                <Box
                  sx={{
                    backgroundColor: '#FEF3C7',
                    py: '6px',
                    px: '30px',
                    fontSize: '12px',
                    borderRadius: '100px'
                  }}>
                  In Progress
                </Box>
              </Box>
            )} */}
          </Box>
        </List>
        <List sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, mb: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 600, mb: 1.25, color: '#2D2D2D', fontSize: '19px' }}>
              <span>{index + 1}.</span>
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 600,
                mb: 1.25,
                color: '#2D2D2D',
                fontSize: '19px',
                textAlign: 'justify'
              }}>
              {rowData.question}
            </Typography>
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Box sx={{ backgroundColor: '#F2F9FF', padding: 2.5, borderRadius: '8px', my: 1 }}>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: ' #696969',
                    wordBreak: 'break-word',
                    wordWrap: 'break-word'
                  }}>
                  {postData.answer}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box></Box>
        </List>
        <List sx={{ p: 3 }}>
          {rowData?.answer?.files?.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
                Files:
              </Typography>

              <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 1.5 }}>
                {rowData?.answer?.files?.map((el, index) => (
                  <Box
                    onClick={() => getFile(el)}
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      backgroundColor: '#D9EDFF',
                      py: '5px',
                      px: 1.5,
                      borderRadius: '100px',
                      cursor: 'pointer'
                    }}>
                    <PinIcon />
                    <EllipsisContent text={el.file_name} maxWidth={377} />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </List>
        {commonDetails.data.company.gap_status == 'draft' ? (
          <Box></Box>
        ) : (
          <Box>
            <Box sx={{ mb: 8 }}>
              <Divider sx={{ mx: 3 }} />
              <Box sx={{ p: 3 }}>
                <Typography variant="body1" sx={{ fontWeight: 600, mb: 1, color: '#2D2D2D' }}>
                  Does evidence meet the control?
                </Typography>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                    Control Status:
                  </Typography>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      value={postDataEngineer.status || 'pending'}
                      name="status"
                      disabled={rowData?.answer?.feedback?.status ? true : false}
                      onChange={handleChangeEngineer}
                      displayEmpty
                      sx={{
                        '.MuiSelect-select': {
                          py: 1,
                          display: 'flex',
                          alignItems: 'center'
                        },
                        borderRadius: '100px'
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}>
                      <MenuItem value="does_not_meet">
                        <Box
                          sx={{
                            height: '12px',
                            width: '12px',
                            backgroundColor: '#EF4444',
                            borderRadius: '50%',
                            mr: 1
                          }}></Box>
                        <Typography variant="body2">Does Not Meet</Typography>
                      </MenuItem>
                      <MenuItem value={'pending'}>
                        {' '}
                        <Box
                          sx={{
                            height: '12px',
                            width: '12px',
                            backgroundColor: '#787878',
                            borderRadius: '50%',
                            mr: 1
                          }}></Box>
                        <Typography variant="body2">Pending</Typography>
                      </MenuItem>
                      <MenuItem value={'partially_meets'}>
                        {' '}
                        <Box
                          sx={{
                            height: '12px',
                            width: '12px',
                            backgroundColor: '#FBBF24',
                            borderRadius: '50%',
                            mr: 1
                          }}></Box>
                        <Typography variant="body2">Partially Meets</Typography>
                      </MenuItem>
                      <MenuItem value={'fully_meets'}>
                        {' '}
                        <Box
                          sx={{
                            height: '12px',
                            width: '12px',
                            backgroundColor: '#27AE60',
                            borderRadius: '50%',
                            mr: 1
                          }}></Box>
                        <Typography variant="body2">Fully Meets</Typography>
                      </MenuItem>
                      <MenuItem value={'not_applicable'}>
                        {' '}
                        <Box
                          sx={{
                            height: '12px',
                            width: '12px',
                            backgroundColor: '#D9E1FD',
                            borderRadius: '50%',
                            mr: 1
                          }}></Box>
                        <Typography variant="body2">Not Applicable</Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Divider sx={{ mx: 3 }} />
              {postDataEngineer.status == 'fully_meets' ||
              postDataEngineer.status == 'not_applicable' ? (
                <Box></Box>
              ) : (
                <Box>
                  <Box sx={{ p: 3 }}>
                    <Typography variant="body1" sx={{ fontWeight: 600, mb: 1, color: '#2D2D2D' }}>
                      Create a Remediation Action
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}>
                      <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                        Criticality Level:
                      </Typography>
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          value={postDataEngineer.severity || 'high'}
                          name="severity"
                          disabled={rowData?.answer?.feedback?.status ? true : false}
                          onChange={handleChangeEngineer}
                          displayEmpty
                          sx={{
                            '.MuiSelect-select': {
                              py: 1,
                              display: 'flex',
                              alignItems: 'center'
                            },
                            '&.MuiInputBase-root': {
                              backgroundColor: renderColorBackGround(postDataEngineer.severity)
                            },
                            borderRadius: '100px'
                          }}
                          inputProps={{ 'aria-label': 'Without label' }}>
                          <MenuItem sx={{ backgroundColor: '#FFE6E2' }} value="critical">
                            <Box>
                              <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F73939' }} />
                            </Box>
                            <Typography variant="body2">Critical</Typography>
                          </MenuItem>
                          <MenuItem sx={{ backgroundColor: '#FCD34D' }} value="high">
                            <Box>
                              <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F59E0B' }} />
                            </Box>
                            <Typography variant="body2">High</Typography>
                          </MenuItem>

                          <MenuItem sx={{ backgroundColor: '#FEF3C7' }} value={'medium'}>
                            <Box>
                              <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#FBBF24' }} />
                            </Box>
                            <Typography variant="body2">Medium</Typography>
                          </MenuItem>

                          <MenuItem sx={{ backgroundColor: '#D0DAFC' }} value={'low'}>
                            {' '}
                            <Box>
                              <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#436AF3' }} />
                            </Box>
                            <Typography variant="body2">Low</Typography>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box sx={{ px: 3 }}>
                    {rowData?.answer?.feedback?.status ? (
                      <Box
                        sx={{
                          backgroundColor: '#F2F9FF',
                          padding: 2.5,
                          borderRadius: '8px',
                          my: 1
                        }}>
                        <Box>
                          <List>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 1
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '19px', fontWeight: 600 }}>
                                {postDataEngineer.title}
                              </Typography>
                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: '#9D9D9D',
                                    fontWeight: 600,
                                    wordBreak: 'break-all',
                                    wordWrap: 'break-word'
                                  }}>
                                  {postDataEngineer.description}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: '#9D9D9D',
                                    fontWeight: 600,
                                    wordBreak: 'break-all',
                                    wordWrap: 'break-word'
                                  }}>
                                  {postDataEngineer.recommendation}
                                </Typography>
                              </Box>
                            </Box>
                          </List>
                        </Box>
                      </Box>
                    ) : (
                      <Box sx={{ p: 1, background: '#F2F9FF', borderRadius: '8px' }}>
                        <TextField
                          autoComplete={'none'}
                          value={postDataEngineer.title}
                          name="title"
                          onChange={handleChangeEngineer}
                          defaultValue={'Website does don implement X-frame Option Best Practices'}
                          sx={{
                            fontSize: '14px',
                            textAlign: 'justify',
                            width: 1,
                            mb: 1.5,
                            backgroundColor: 'white',

                            '.MuiInputBase-input': {
                              py: '14.5px'
                            },
                            '.MuiOutlinedInput-notchedOutline': {
                              borderColor: '#CCCCCC'
                            }
                          }}
                          placeholder="Remediation Issue Name"
                        />{' '}
                        <Box sx={{ position: 'relative' }}>
                          <TextField
                            autoComplete={'none'}
                            multiline
                            value={postDataEngineer.description}
                            name="description"
                            onChange={handleChangeEngineer}
                            rows={7}
                            sx={{
                              fontSize: '14px',

                              border: 'none',
                              textAlign: 'justify',
                              width: 1,
                              backgroundColor: 'white',
                              '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#CCCCCC'
                              }
                            }}
                            placeholder="Remediation Description"
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              color: '#B7B7B7',
                              textAlign: 'end',
                              position: 'absolute',
                              right: 8,
                              bottom: 6
                            }}>
                            {`${
                              postDataEngineer?.description?.length
                                ? postDataEngineer?.description?.length
                                : 0
                            }/999`}
                          </Typography>
                        </Box>
                        <Box sx={{ position: 'relative', mt: 2 }}>
                          <TextField
                            autoComplete={'none'}
                            multiline
                            value={postDataEngineer.recommendation}
                            name="recommendation"
                            onChange={handleChangeEngineer}
                            rows={7}
                            sx={{
                              fontSize: '14px',

                              border: 'none',
                              textAlign: 'justify',
                              width: 1,
                              backgroundColor: 'white',
                              '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#CCCCCC'
                              }
                            }}
                            placeholder="Recommendation Description"
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              color: '#B7B7B7',
                              textAlign: 'end',
                              position: 'absolute',
                              right: 8,
                              bottom: 6
                            }}>
                            {`${
                              postDataEngineer?.recommendation?.length
                                ? postDataEngineer?.recommendation?.length
                                : 0
                            }/999`}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
            <Box sx={{ textAlign: 'end', position: 'relative' }}>
              <LoadingButton
                loading={isAnswerButtonLoading}
                onClick={handleSubmit}
                disabled={rowData?.answer?.feedback?.status && true}
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  margin: 3,
                  right: 0,
                  justifySelf: 'flex-end',
                  minWidth: 163,
                  backgroundColor: '#436AF3',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#436AF3'
                  },
                  '&.Mui-disabled': {
                    backgroundColor: '#EBEBEB',
                    color: isAnswerButtonLoading ? '#EBEBEB' : '#9D9D9D'
                  }
                }}>
                Save
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
export default GapAnalysisSideBarEngineer;
