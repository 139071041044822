import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React, { FC, ReactElement } from 'react';
import CloseIcon from '../../icons/Close/Close';
import WarningIcon from '../../icons/Warning/Warning';
import DeleteIcon from '@jibin/common/icons/Delete/Delete';
import { DeleteTypesEnum } from '../../utils/enums/deleteTypeEnums';
import { Divider, MenuItem, Switch, TextField } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import ArchiveIcon from '@jibin/common/icons/ArchiveIcon/Archive';
import CheckIcon from '@jibin/common/icons/CheckIcon/CheckIcon';
import CheckCircleIcon from '@jibin/common/icons/CheckCircle/CheckCircle';
import theme from '@jibin/common/style/core/config/theme';
import { LoadingButton } from '@mui/lab';

export interface ConformationModel {
  title?: string;
  description?: any;
  asking?: string;
  cancelButtonText?: string;
  conformButtonText?: string;
  type?: string;
  label?: any;
  text?: string | ReactElement;
  company_uuid?: any;
  data?: any;
  mainBtnTitle: any;
  mainBtnType: any;
  checked: any;
  returnData: any;
  iconComponent?: any;
  handleClose: any;
  actionTaken?: any;
  isLoading?: any;
  component?: any;
  handleConfirm(returnData: any): void;
}

export const SuccessConfirmation: FC<ConformationModel> = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };
  const handleConfirm = async () => {
    await props.handleConfirm(props.returnData);
    props.handleClose();
    setOpen(false);
  };
  const handleChange = (e: any) => {
    if (!e.target.checked) {
      handleClickOpen();
    }
  };

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  return (
    <>
      {props?.type == 'icon' ? (
        <MenuItem
          onClick={handleClickOpen}
          sx={{ display: 'flex', gap: props?.iconComponent ? 0 : 1 }}>
          {props?.iconComponent ? (
            props?.iconComponent
          ) : (
            <ArchiveIcon sx={{ mr: 1, fontSize: '24px' }} />
          )}

          <Typography variant={props?.iconComponent ? 'body1' : 'body2'}>
            {props?.mainBtnTitle}
          </Typography>
        </MenuItem>
      ) : props?.type == 'switch' ? (
        <MenuItem
          onClick={() => {
            handleClickOpen();
          }}>
          <Switch
            size="small"
            checked={props.checked}
            sx={{
              width: 30,
              height: 16,
              mr: 1.2,

              //   '	.MuiSwitch-switchBase': { color: 'rgba(0, 0, 0, 0.25)', m: '2px' },
              //   '	.MuiSwitch-track': {
              //     backgroundColor: 'transparent',
              //     border: '1px solid #ADB5BD',
              //     borderRadius: '45px'
              //   },
              //   '.MuiSwitch-thumb': {
              //     boxShadow: 'none',
              //     width: '12px',
              //     height: '12px'
              //   },
              //   '	.Mui-disabled': {
              //     cursor: 'not-allowed'
              //   },

              padding: 0,

              '.MuiSwitch-switchBase': {
                padding: 0,
                m: '4px',
                mt: '2px',

                transitionDuration: '300ms',
                '&.Mui-checked': {
                  transform: 'translateX(20px)',
                  mt: '2px',

                  ml: '-4px',
                  color: '#fff',
                  '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.primary.main,
                    opacity: 1,
                    border: 0
                  },
                  '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5
                  }
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                  color: '#33cf4d',
                  border: '6px solid #fff'
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                  color:
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[600]
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
                }
              },
              '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 12,
                height: 12
              },
              '& .MuiSwitch-track': {
                borderRadius: 26 / 2,
                backgroundColor: theme.palette.grey[300],
                opacity: 1,
                transition: theme.transitions.create(['background-color'], {
                  duration: 500
                })
              }
            }}
          />{' '}
          <Typography variant="body2">{props.checked ? 'Unpublish' : 'Publish'}</Typography>
        </MenuItem>
      ) : props?.type == 'text' ? (
        <Box>
          <MenuItem onClick={handleClickOpen} value={30} sx={{ fontSize: '14px' }}>
            {props?.text}
          </MenuItem>
        </Box>
      ) : (
        <Switch
          onClick={handleClickOpen}
          disabled={!props?.company_uuid}
          checked={props?.data?.is_enabled}
        />
      )}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            px: { xs: 1, md: 2 },
            py: { xs: 0.5, md: 1 },
            bgcolor: 'primary.main100'
          }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {props.mainBtnType == 'error' ? (
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'error.main',
                  fontWeight: '600',
                  maxWidth: 'calc(100% - 40px)',
                  p: 1
                }}>
                <WarningIcon sx={{ mr: 1 }} />
                {props.title}
              </Typography>
            ) : (
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary.main',
                  fontWeight: '600',
                  maxWidth: 'calc(100% - 40px)',
                  p: 1
                }}>
                <CheckCircleIcon sx={{ mr: 1, color: '#436AF3' }} />
                {props.title}
              </Typography>
            )}

            <IconButton sx={{ color: 'text.primary' }} aria-label="close" onClick={handleClose}>
              <CloseIcon sx={{ color: '#9D9D9D' }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          {props.asking && (
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: 'text.primary', fontWeight: 700, mb: 1 }}>
              {props.asking}
            </DialogContentText>
          )}
          <DialogContentText id="alert-dialog-description" sx={{ color: 'text.primary' }}>
            {props.description}
          </DialogContentText>
          {props.actionTaken && props?.component}
        </DialogContent>
        <DialogActions sx={{ px: { xs: 2, md: 3 }, py: { xs: 1, md: 2 } }}>
          <Button
            variant="outlined"
            sx={{
              minWidth: 100,
              color: '#9D9D9D',
              borderColor: '#9D9D9D',
              '&:hover': {
                borderColor: '#9D9D9D'
              }
            }}
            onClick={handleClose}>
            {props.cancelButtonText}
          </Button>
          <LoadingButton
            loading={props?.isLoading}
            variant="contained"
            sx={{ minWidth: 100 }}
            color={props.mainBtnType}
            onClick={handleConfirm}>
            {' '}
            {props.conformButtonText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SuccessConfirmation;
