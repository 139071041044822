import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import AboutIcon from '../../../icons/AboutIcon';
import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import moment from 'moment';
import { HeaderApi } from '../Header.Api';

const ReleaseNoteDialog = ({ versionData }: any) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MenuItem onClick={handleClickOpen}>
        <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
          <AboutIcon fill="none" />
        </Box>
        About
      </MenuItem>
      <Dialog
        maxWidth="md"
        open={open}
        sx={{
          '.MuiPaper-root': {
            width: '600px'
          }
        }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'end' }}>
          <IconButton onClick={handleClose}>
            <CrossCancle sx={{ fontSize: '14px', color: '#9D9D9D' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          <DialogContentText id="alert-dialog-description">
            <img alt="" src="../images/trust-logo-black.svg" width={'250px'} />
          </DialogContentText>
          <Stack direction={'column'} gap={2} sx={{ mt: 3 }}>
            <Typography variant="subtitle2" sx={{ color: '#696969', fontWeight: 600 }}>
              Version {versionData?.version}
            </Typography>
            <Typography variant="body1" sx={{ color: '#696969' }}>
              Last updated on {moment(versionData?.release_date).format('DD MMM YYYY')}
            </Typography>
            <Typography variant="body1" sx={{ color: '#696969' }}>
              © {moment().format('YYYY')} The Redesign Group. All rights reserved.
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ textAlign: 'center', justifyContent: 'center', mb: 3 }}>
          <Button
            href={versionData?.release_notes_url}
            target="_blank"
            variant="contained"
            onClick={handleClose}
            autoFocus>
            Release Notes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ReleaseNoteDialog;
