import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React, { FC, useEffect, useState } from 'react';

import DeleteIcon from '@jibin/common/icons/Delete/Delete';
import {
    FormControl,
    FormHelperText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Switch,
    TextField
} from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import CloseIcon from '@jibin/common/icons/Close/Close';
import { CompanyManagementApi } from 'src/Pages/CompanyManagement/CompanyManagement.Api';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

export interface ConfigureBox {
    title?: string;
    description?: string;
    customerList?: any;
    orgList?: any;
    cancelButtonText?: string;
    conformButtonText?: string;
    access_details: any;
    handleClose(): void;
    data?: any;
    handleConfirm(): void;
    company_uuid: any;
    type;
}

export const ConfigureBox: FC<ConfigureBox> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [useParams, setUseParam] = useSearchParams();
    const id = useParams.get('q');
    const [isTestSuccessFull, setTestSuccessFull] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const [error, setError] = useState(false);
    const [intergrationData, setIntegrationData] = useState({} as any);
    const commonDetails = useSelector(
        ({ commonDetails }: any) => commonDetails.commonDetails?.data,
        shallowEqual
    );
    const company_uuid = commonDetails.data.company.company_uuid;
    const handleConfirm = () => {
        setOpen(false);
        props.handleConfirm();
    };
    const handleClose = () => {
        setOpen(false);
        props.handleClose();
    };

    const handleChange = (e: any) => {
        handleClickOpen();
    };
    const handelTest = async () => {
        const findIndex = intergrationData?.access_details?.findIndex((el) => !el?.value);
        if (findIndex != -1) {
            setError(true);
            return;
        }
        setIsloading(true);
        try {
            const res = await CompanyManagementApi.TestIntigration(
                commonDetails.data.company.company_uuid,
                commonDetails.data.user_uuid,

                intergrationData
            );
            if (res) {
                toast.success('Test successful!');
            }
            setTestSuccessFull(true);
            setIsloading(false);
        } catch (error) {
            setIsloading(false);
        }
    };

    const handelSubmit = async () => {
        const findIndex = intergrationData?.access_details?.findIndex((el) => !el?.value);
        if (findIndex != -1) {
            setError(true);
            return;
        }
        setIsloading(true);
        try {
            const res = await CompanyManagementApi.SubmitIntigration(
                commonDetails.data.company.company_uuid,
                commonDetails.data.user_uuid,
                id,
                { ...intergrationData, company_uuid: company_uuid }
            );
            handleClose();
            setIsloading(false);
        } catch (error) {
            setIsloading(false);
        }
    };
    const handleChangeData = (e: any) => {
        setError(false);
        const findObject = intergrationData?.access_details?.findIndex(
            (el: any) => el?.key == e.target.name
        );
        const data = intergrationData?.access_details;
        const data2 = intergrationData;
        data[findObject] = { ...data[findObject], value: e.target.value };

        data2.access_details = data;
        setIntegrationData({ ...intergrationData, ...data2 });
    };
    useEffect(() => {
        setIntegrationData(props.data);
    }, [props]);
    return (
        <>
            <Box>
                {props?.type == 'toogle' ? (
                    <Switch onClick={handleClickOpen} disabled={!company_uuid} checked={false} />
                ) : (
                    <Button disabled={!props?.company_uuid} onClick={handleClickOpen} variant="text">
                        Configure
                    </Button>
                )}
            </Box>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        px: { xs: 1, md: 2 },
                        py: { xs: 0.5, md: 1 },
                        bgcolor: 'error.red100'
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',

                                fontWeight: '600',
                                maxWidth: 'calc(100% - 40px)',
                                p: 1
                            }}>
                            {props.title}
                        </Typography>
                        <IconButton sx={{ color: 'text.primary' }} aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
                    <DialogContentText id="alert-dialog-description" sx={{ color: 'text.primary' }}>
                        {intergrationData?.access_details?.map((element_access: any, key: any) => (
                            <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                                <Box sx={{ mb: 1 }}>
                                    <label htmlFor="Email" style={{ fontSize: '12px' }}>
                                        {element_access?.name}
                                    </label>
                                </Box>
                                {props?.data?.key_name === 'quickbooks' ||
                                    props?.data?.key_name === 'unified_endpoint_management' ? (
                                    <Stack>
                                        <Select
                                            size="small"
                                            onChange={(e: any) => handleChangeData(e)}
                                            placeholder={element_access?.placeholder}
                                            name={element_access?.key}
                                            value={element_access.value || '-1'}
                                            error={error && !element_access.value ? true : false}>
                                            <MenuItem value={'-1'} sx={{ display: 'none' }}>
                                                Select
                                                {props?.data?.key_name === 'quickbooks'
                                                    ? 'Customer'
                                                    : props?.data?.key_name === 'unified_endpoint_management'
                                                        ? 'Organization'
                                                        : ''}
                                            </MenuItem>
                                            {props?.data?.key_name === 'quickbooks' &&
                                                props.customerList?.map((el: any) => (
                                                    <MenuItem value={el.customer_id}>{el?.customer_name}</MenuItem>
                                                ))}
                                            {props?.data?.key_name === 'unified_endpoint_management' &&
                                                props.orgList?.map((el: any) => (
                                                    <MenuItem value={el.id.toString()}>{el?.name}</MenuItem>
                                                ))}
                                        </Select>
                                        <FormHelperText error>
                                            {error && !element_access?.value ? 'This field is Required' : ''}
                                        </FormHelperText>
                                    </Stack>
                                ) : (
                                    <TextField
                                        size="small"
                                        sx={{ input: { fontSize: '12px' } }}
                                        placeholder={element_access?.placeholder}
                                        name={element_access?.key}
                                        value={element_access.value}
                                        error={error && !element_access.value ? true : false}
                                        onChange={(e: any) => handleChangeData(e)}
                                        // onChange={handleChange('Email')}
                                        helperText={error && !element_access?.value ? 'This field is Required' : ''}
                                        aria-describedby="outlined-weight-helper-text"
                                    />
                                )}
                            </FormControl>
                        ))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0 }}>
                    <Button
                        disabled={isLoading}
                        variant="outlined"
                        sx={{ minWidth: 100 }}
                        onClick={handelTest}>
                        {props.cancelButtonText}
                    </Button>

                    <Button
                        disabled={isLoading || (!props?.data?.company_integrations_uuid && !isTestSuccessFull)}
                        variant="contained"
                        sx={{ minWidth: 100 }}
                        onClick={handelSubmit}>
                        {' '}
                        {props.conformButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConfigureBox;
