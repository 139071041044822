import { Table, TableBody, TableRow, TableCell, styled, Box } from '@mui/material';
import { useCallback, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { SecurityAwarenessApi } from '../../SecurityAwareness.Api';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid #EBEBEB`,
  borderRadius: '4px',

  '&::before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#F2F9FF' : '#F2F9FF',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));
const data = [
  {
    name: 'Patrick Sullivan',
    courses: [
      {
        title: '10 Ways to Avoid Phishing Scam with Quiz',
        enrollmentDate: '10 Jan 20203',
        dueDate: '10 Mar 2023',
        status: 'Completed'
      },
      {
        title: '10 Ways to Avoid Phishing Scam with Quiz',
        enrollmentDate: '10 Jan 20203',
        dueDate: '10 Mar 2023',
        status: 'Completed'
      }
    ]
  }
  // ... other data objects
];
const AccordianComponent = ({ item }: any) => {
  const [expanded, setExpanded] = useState(true);
  const [trainingDataEnrollments, setTrainingDataEnrollments] = useState([] as any);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const ITEMS_PER_PAGE = 2;
  //   const paginatedData = securityData.slice(
  //     (currentPage - 1) * ITEMS_PER_PAGE,
  //     currentPage * ITEMS_PER_PAGE
  //   );
  const renderBgColor = (status: any) => {
    let color = '#EF4444';
    if (status == 'Completed' || status == 'Passed') {
      color = '#10B981';
    } else if (status == 'Past Due') {
      color = '#EF4444';
    } else if (status == 'Not Started') {
      color = '#EF4444';
    } else if (status == 'In Progress') {
      color = '#FCD34D';
    }
    return color;
  };
  return (
    <Accordion key={item.name} expanded={expanded} onChange={handleChange(item.name)}>
      <AccordionSummary
        sx={{
          '.MuiAccordionSummary-content': {
            ml: 0
          },
          borderRadius: '4px'
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={item.name + 'bh-panel'}
        id={item.name + 'bh-header'}>
        <Typography variant="body2">{item.name}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List sx={{ p: 0 }}>
          {item?.modules?.map((el) => (
            <Table className='table-accordion' sx={{ width: '100%' }}>
              <TableBody>
                <TableRow>
                  <TableCell align="left" sx={{ width: '350px' }}>
                    <Typography variant="body2"> {el?.module_name}</Typography>
                  </TableCell>

                  <TableCell align="left" sx={{ width: '200px' }}>
                    <Typography variant="body2">
                      {' '}
                      {el?.enrollment_date
                        ? moment(el?.enrollment_date).format('DD MMM YYYY')
                        : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ width: '200px' }}>
                    <Typography variant="body2">
                      {' '}
                      {el?.due_date ? moment(el?.due_date).format('DD MMM YYYY') : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: '200px' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '100px',
                        py: 0.5,
                        width: 1
                      }}>
                      <Box
                        sx={{
                          height: '12px',
                          width: '12px',
                          backgroundColor: renderBgColor(el?.status),
                          borderRadius: '50%',
                          mr: 1
                        }}></Box>
                      <Typography variant="body2">{el?.status}</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
export default AccordianComponent;
