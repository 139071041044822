import HttpService from '@jibin/common/services/http/HttpService';
import axios from 'axios';

export const ServiceReviewApi = {
  PostDocument(service_review_uuid: string, user_id: string, company_id: string, data: any) {
    return HttpService.post(
      `companies/${company_id}/users/${user_id}/services-review/${service_review_uuid}/evidence/reports`,
      data
    );
  },
  UploadDocuments(response: any, files: any) {
    const {
      data: { data: urls }
    } = response;

    return Promise.all(
      urls.map(({ upload_url, callback_url, callback_data }, i) => {
        const file = files[i];
        return axios.put(upload_url, file, {
          headers: {
            'Content-Type': file.type
          }
        }).then(() => HttpService.post(callback_url, { data: callback_data }));
      })
    );
  },
  PatchApprovel(company_id: string, user_id: string, evidence_id: string, data: any) {
    //return HttpService.getAll("todos/1");

    return HttpService.patchData(
      `companies/${company_id}/users/${user_id}/services-review/${evidence_id}/acknowledge`,
      data
    );
  },
  async GetFile(service_name: string, company_id: string, file_path: string) {
    file_path = file_path.slice(1);
    const response = await HttpService.getAll(`${file_path}`);

    return response;
  },
  GetAllServices(company_id: string, user_id: string) {
    return HttpService.getAll(`companies/${company_id}/users/${user_id}/services-review`);
  }
};
