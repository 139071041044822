import SvgIcon from '@mui/material/SvgIcon';

export default function SpaceDashboardIcon(props) {
  return (
    <svg width="24" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.4351C0 1.53752 1.5375 0 3.43508 0C5.11844 0 6.51845 1.20996 6.8131 2.80789H10.0062V4.054H6.81462C6.5233 5.65604 5.12135 6.8702 3.43508 6.8702C1.53749 6.8702 0 5.33267 0 3.4351ZM3.43508 1.24611C2.22571 1.24611 1.24611 2.22572 1.24611 3.4351C1.24611 4.64448 2.22571 5.62409 3.43508 5.62409C4.64446 5.62409 5.62408 4.64447 5.62408 3.4351C5.62408 2.22572 4.64446 1.24611 3.43508 1.24611ZM13.1298 3.4351C13.1298 1.53751 14.6673 0 16.5649 0C18.4625 0 20 1.53752 20 3.4351C20 5.11846 18.79 6.51847 17.1921 6.81311V9.99792H15.946V6.81462C14.344 6.5233 13.1298 5.12137 13.1298 3.4351ZM16.5649 1.24611C15.3555 1.24611 14.3759 2.22572 14.3759 3.4351C14.3759 4.64447 15.3555 5.62409 16.5649 5.62409C17.7743 5.62409 18.7539 4.64448 18.7539 3.4351C18.7539 2.22572 17.7743 1.24611 16.5649 1.24611ZM2.81619 13.1854V9.99792H4.06229V13.1869C5.66022 13.4815 6.87019 14.8815 6.87019 16.5649C6.87019 18.4625 5.33266 20 3.43508 20C1.53749 20 0 18.4625 0 16.5649C0 14.8786 1.21415 13.4767 2.81619 13.1854ZM3.43508 14.3759C2.22571 14.3759 1.24611 15.3555 1.24611 16.5649C1.24611 17.7743 2.22571 18.7539 3.43508 18.7539C4.64446 18.7539 5.62408 17.7743 5.62408 16.5649C5.62408 15.3555 4.64446 14.3759 3.43508 14.3759ZM13.1861 15.9418C13.4791 14.3419 14.8801 13.1298 16.5649 13.1298C18.4625 13.1298 20 14.6673 20 16.5649C20 18.4625 18.4625 20 16.5649 20C14.8801 20 13.4791 18.7879 13.1861 17.188H10.0021V15.9418H13.1861ZM16.5649 14.3759C15.3555 14.3759 14.3759 15.3555 14.3759 16.5649C14.3759 17.7743 15.3555 18.7539 16.5649 18.7539C17.7743 18.7539 18.7539 17.7743 18.7539 16.5649C18.7539 15.3555 17.7743 14.3759 16.5649 14.3759Z"
        fill="white"
      />
    </svg>
  );
}
export function SpaceDashboardIconBlue(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.4351C0 1.53752 1.5375 0 3.43508 0C5.11844 0 6.51845 1.20996 6.8131 2.80789H10.0062V4.054H6.81462C6.5233 5.65604 5.12135 6.8702 3.43508 6.8702C1.53749 6.8702 0 5.33267 0 3.4351ZM3.43508 1.24611C2.22571 1.24611 1.24611 2.22572 1.24611 3.4351C1.24611 4.64448 2.22571 5.62409 3.43508 5.62409C4.64446 5.62409 5.62408 4.64447 5.62408 3.4351C5.62408 2.22572 4.64446 1.24611 3.43508 1.24611ZM13.1298 3.4351C13.1298 1.53751 14.6673 0 16.5649 0C18.4625 0 20 1.53752 20 3.4351C20 5.11846 18.79 6.51847 17.1921 6.81311V9.99792H15.946V6.81462C14.344 6.5233 13.1298 5.12137 13.1298 3.4351ZM16.5649 1.24611C15.3555 1.24611 14.3759 2.22572 14.3759 3.4351C14.3759 4.64447 15.3555 5.62409 16.5649 5.62409C17.7743 5.62409 18.7539 4.64448 18.7539 3.4351C18.7539 2.22572 17.7743 1.24611 16.5649 1.24611ZM2.81619 13.1854V9.99792H4.06229V13.1869C5.66022 13.4815 6.87019 14.8815 6.87019 16.5649C6.87019 18.4625 5.33266 20 3.43508 20C1.53749 20 0 18.4625 0 16.5649C0 14.8786 1.21415 13.4767 2.81619 13.1854ZM3.43508 14.3759C2.22571 14.3759 1.24611 15.3555 1.24611 16.5649C1.24611 17.7743 2.22571 18.7539 3.43508 18.7539C4.64446 18.7539 5.62408 17.7743 5.62408 16.5649C5.62408 15.3555 4.64446 14.3759 3.43508 14.3759ZM13.1861 15.9418C13.4791 14.3419 14.8801 13.1298 16.5649 13.1298C18.4625 13.1298 20 14.6673 20 16.5649C20 18.4625 18.4625 20 16.5649 20C14.8801 20 13.4791 18.7879 13.1861 17.188H10.0021V15.9418H13.1861ZM16.5649 14.3759C15.3555 14.3759 14.3759 15.3555 14.3759 16.5649C14.3759 17.7743 15.3555 18.7539 16.5649 18.7539C17.7743 18.7539 18.7539 17.7743 18.7539 16.5649C18.7539 15.3555 17.7743 14.3759 16.5649 14.3759Z"
        fill="#436AF3"
      />
    </svg>
  );
}
