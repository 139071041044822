import BorderLinearProgress from '@jibin/common/components/LinearLoading/BorderLinearGreen';
import AlertIcon from '@jibin/common/icons/AlertIcon/AlertIcon';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import Style from '@jibin/common/style/Styles';
import UpGuardChart from '@jibin/common/components/UpGaurdChart';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { Typography, linearProgressClasses, Grid, Button, Skeleton } from '@mui/material';
import { Box } from '@mui/system';

import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TopRemediations } from '../Common/TopRemediations/TopRemediations';
import { NoDataAvailable } from './../Common/NoDataAvailable';
import { useSelector, shallowEqual } from 'react-redux';
import { UpGuardApi } from './UpGuard.Api';
import moment from 'moment';
import './dashboard.css';
import { TopRiskWidget } from '../Common/TopRiskWidget/TopRiskWidget';
import HeadingHeader from '../Common/HeadingHeader';
const UpGuard: FC = () => {
  const navigate = useNavigate();
  const renderColor = (severity: any) => {
    var color = '#F87171';
    if (severity == 'critical') {
      color = '#F87171';
    } else if (severity == 'high') {
      color = '#F59E0B';
    } else if (severity == 'medium') {
      color = '#FBBF24';
    } else if (severity == 'low') {
      color = '#436AF3';
    }
    return color;
  };
  const renderColorBackGround = (severity: any = 'critical') => {
    var color = '#FFE6E2';
    if (severity == 'critical') {
      color = '#FFE6E2';
    } else if (severity == 'high') {
      color = '#FDE68A';
    } else if (severity == 'medium') {
      color = '#FEF3C7';
    } else if (severity == 'low') {
      color = '#D0DAFC';
    }
    return color;
  };
  const [upGuardData, setUpGuardData] = useState({} as any);
  const [upGuardStats, setUpGuardStats] = useState({} as any);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [data, setData] = useState([]);
  const [trendingData, setTrendingData] = useState({} as any);
  const [flag, setFlag] = useState(true);

  async function aggregateDataByMonth(dailyData) {
    const currentDate = moment();
    const twelveMonthsAgo = currentDate.subtract(12, 'months');

    const filterData = dailyData
      ?.filter((el) => {
        const objDate = moment(el.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS Z'); // Adjust the format based on your date string

        return objDate.isAfter(twelveMonthsAgo);
      })
      ?.reduce((result, data) => {
        const month = moment(data?.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS Z').format('MMM');
        // const year = moment(data?.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS Z').format('YYYY');
        const key = `${month}`;
        const cMonth = moment(new Date(), 'YYYY-MM-DD HH:mm:ss.SSSSSS Z').format('MMM');
        // const cYear = moment(new Date(), 'YYYY-MM-DD HH:mm:ss.SSSSSS Z').format('YYYY');
        const cKey = `${cMonth}`;

        if (!result[key]) {
          result[key] = { month: key, sum: 0, count: 0 };
        }

        if (cKey === key) {
          result[key].sum = data.score;
          result[key].count = 1;
        } else {
          result[key].sum += data.score;
          result[key].count++;
        }

        return result;
      }, {});
    for (const month in filterData) {
      const average = filterData[month].sum / filterData[month].count;
      filterData[month].value = Math.round(average);
    }
    return filterData;
  }
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const GetUpGuardDetails = useCallback(async () => {
    try {
      const res = await UpGuardApi.UpGuardApiStats(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );
      setUpGuardData(res?.data?.data);
      if (res?.data?.data) {
        setFlag(true);
      }
    } catch (error) {
      setFlag(false);
      console.log(error);
    }
  }, [commonDetails]);
  const GetUpGuardRatings = useCallback(async () => {
    try {
      const res = await UpGuardApi.UpGuardApiRatings(
        commonDetails.data.user_uuid,

        commonDetails.data.company.company_uuid
      );
      setUpGuardStats(res?.data?.data);
    } catch (error) {}
  }, [commonDetails]);
  const GetUpGuardTrending = useCallback(async () => {
    try {
      const res = await UpGuardApi.UpGuardTrending(
        commonDetails.data.user_uuid,

        commonDetails.data.company.company_uuid
      );

      setTrendingData(res?.data?.data);
      const monthlyData = Object.values(
        await aggregateDataByMonth(res?.data?.data?.trend?.company_trend)
      );

      setData(monthlyData);
    } catch (error) {}
  }, [commonDetails]);
  useEffect(() => {
    //setIsSkeleton(true);
    Promise.all([GetUpGuardDetails(), GetUpGuardRatings(), GetUpGuardTrending()]).finally(() => {
      setIsSkeleton(false);
    });
  }, [GetUpGuardDetails, GetUpGuardRatings]);
  if (isSkeleton) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton variant="rectangular" height={'50px'} />
      </Box>
    );
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <HeadingHeader subtitle1={'Attack Surface Management (ASM)'} subtitle2={'Enhancing security posture by minimizing attack surface'} />

      {!upGuardData?.total && !isSkeleton && (
        <NoDataAvailable
          title="No Data Available"
          message=" We're preparing this service to provide you with insightful data."
        />
      )}

      {upGuardData?.total && (
        <>
          <Box sx={{ p: 3, backgroundColor: '#FBFBFF', width: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                {/* <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  Attack Surface Management Report
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '12px', mt: 1 }}>
                  {moment(upGuardData?.created_at).format('DD MMM YYYY')}
                </Typography> */}
              </Box>
              {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' && (
                <Box>
                  <Button variant="outlined">Download Report</Button>
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={{ p: 4, pt: 0, backgroundColor: '#FBFBFF', width: 1 }}>
            <Box sx={{ color: 'rgba(70, 70, 70, 1)', width: 1 }}>
              <Grid container>
                <Grid item xs={12}>
                  <UpGuardChart data={data} upGuardStats={upGuardStats} />
                </Grid>
              </Grid>
              <Box sx={{ mt: 4 }}>
                <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
                  <Grid item xs={12} md={3.5}>
                    <Box
                      sx={{
                        p: 3,
                        border: '1px solid #CCCCCC',
                        width: 1,
                        height: 1,
                        backgroundColor: '#FFFFFF',
                        borderRadius: '8px'
                      }}>
                      <Box sx={{ mb: 4 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 1
                          }}>
                          <Typography sx={{ fontWeight: 600 }}>Risks by Criticality</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                          <Typography variant="caption">
                            From {upGuardData?.critical + upGuardData?.high + upGuardData?.medium}{' '}
                            findings
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            border: '1px solid #EAECF0',
                            borderRadius: '8px',
                            p: '14px'
                          }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Box
                                sx={{
                                  width: '32px',
                                  height: '32px',
                                  backgroundColor: renderColorBackGround('critical'),
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  borderRadius: '50%'
                                }}>
                                <FlagIcon
                                  sx={{
                                    color: renderColor('critical'),
                                    width: '16px'
                                  }}
                                />
                              </Box>
                            </Box>

                            <Typography
                              variant={'body1'}
                              sx={{ fontWeight: 600, color: '#696969' }}>
                              Critical
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                              {upGuardData?.critical}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            border: '1px solid #EAECF0',
                            borderRadius: '8px',
                            p: '14px'
                          }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Box
                                sx={{
                                  width: '32px',
                                  height: '32px',
                                  backgroundColor: renderColorBackGround('high'),
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  borderRadius: '50%'
                                }}>
                                <FlagIcon
                                  sx={{
                                    color: renderColor('high'),
                                    width: '16px'
                                  }}
                                />
                              </Box>
                            </Box>
                            <Typography
                              variant={'body1'}
                              sx={{ fontWeight: 600, color: '#696969' }}>
                              High
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                              {upGuardData?.high}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            border: '1px solid #EAECF0',
                            borderRadius: '8px',
                            p: '14px'
                          }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Box
                                sx={{
                                  width: '32px',
                                  height: '32px',
                                  backgroundColor: renderColorBackGround('medium'),
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  borderRadius: '50%'
                                }}>
                                <FlagIcon
                                  sx={{
                                    color: renderColor('medium'),
                                    width: '16px'
                                  }}
                                />
                              </Box>
                            </Box>
                            <Typography
                              variant={'body1'}
                              sx={{ fontWeight: 600, color: '#696969' }}>
                              Medium
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                              {upGuardData?.medium}
                            </Typography>
                          </Box>
                        </Box>
                        {/* <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      border: '1px solid #EAECF0',
                      borderRadius: '8px',
                      p: '14px'
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '16px',
                          height: '16px',
                          backgroundColor: '#B3DAFF',
                          borderRadius: '50%'
                        }}></Box>
                      <Typography variant={'body1'} sx={{ fontWeight: 600, color: '#696969' }}>
                        Low
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                        0
                      </Typography>
                    </Box>
                  </Box> */}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid xs={12} item md={8.5}>
                    <Box
                      sx={{
                        border: '1px solid #CCCCCC',
                        width: 1,
                        backgroundColor: '#FFFFFF',
                        borderRadius: '8px'
                      }}>
                      <TopRiskWidget
                        column={8}
                        size={10}
                        source="attack_surface"
                        code="ASM"
                        title="Top Risks"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
export default UpGuard;
