import { createContext, useRef, useState } from 'react';
export const LoginContext = createContext();

export const LoginContextProvider = ({ children }) => {
  const [userData, setUserData] = useState();
  const [engineer, setEngineer] = useState(null);
  const clientRef = useRef(null);

  const [values, setValues] = useState({
    password: '',
    Email: ''
  });
  const handleChangeView = (view) => {
    if (view) {
      setEngineer(true);
    } else {
      setEngineer(null);
    }
  };
  return (
    <LoginContext.Provider
      value={{
        userData,
        values,
        setValues,
        clientRef,
        setUserData,
        engineer,
        setEngineer,
        handleChangeView
      }}>
      {children}
    </LoginContext.Provider>
  );
};
