import CloseIcon from '@jibin/common/icons/Close/Close';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import commonDetailSlice from 'src/store/reducers/commonReducer';
import { UsereDetailsModel } from '../Models/UserDetails.Model';
import { MyAccountApi } from '../MyAccount.Api';
import { LoadingButton } from '@mui/lab';
import { Amplify, Auth } from 'aws-amplify';
import { CommonFunctions } from '@jibin/common/utils/Common';
var debounce = require('lodash.debounce');
Amplify.configure({
  Auth: {
    userPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID),
    userPoolWebClientId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_CLIENT_ID)
  }
});
const EditProfilePopUp: FC<{ userDetails: any; refreshDetails(): void }> = (props) => {
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [userDetails, setUserDetails] = useState(new UsereDetailsModel());
  const [open, setOpen] = useState(false);
  const [isEditSaveButtonLoading, setIsEditSaveButtonLoading] = useState(false);
  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(true);
  };
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const handleClose = () => {
    setOpen(false);
    props.refreshDetails();
    formik.resetForm();
  };

  // const commonDetails = useSelector(
  //   ({ commonDetails }: any) => commonDetails.commonDetails?.data,
  //   shallowEqual
  // );

  // const GetUserDetails = useCallback(() => {
  //   MyAccountApi.GetUserData().then(
  //     (q: any) => {
  //       console.log(q)
  //       let user = new UsereDetailsModel();
  //       user.company = q.company.name;
  //       user.email = q.email;
  //       user.firstName = q.first_name;
  //       user.jobTitle = q.company.user_role;
  //       user.lastName = q.last_name;
  //       user.phone = q.phone;
  //       setUserDetails(user)
  //     },
  //     (err: any) => {}
  //   );
  // }, [commonDetails]);

  useEffect(() => {
    //GetUserDetails()

    if (props.userDetails?.company) {
      let user = new UsereDetailsModel();
      user.company = props.userDetails.company.name;
      user.email = props.userDetails.email;
      user.first_name = props.userDetails.first_name;
      user.job = props.userDetails.job;
      user.isMfaAppEnabled = props.userDetails.isMfaAppEnabled;
      user.isMfaSmsEnabled = props.userDetails.isMfaSmsEnabled;
      user.last_name = props.userDetails.last_name;
      user.company_uuid = props.userDetails.company.company_uuid;
      user.phone = props.userDetails.phone;
      setUserDetails(user);
    }
  }, [
    //GetUserDetails
    props
  ]);
  async function updateUserEmail(newEmail) {
    const user = await Auth.currentAuthenticatedUser();

    await Auth.updateUserAttributes(user, {
      email: newEmail
    });

    console.log('Email updated successfully');
  }

  const validate = (values: UsereDetailsModel) => {
    const errors = {};

    if (!values.first_name.trimStart()) {
      errors['first_name'] = 'This field is Required';
    }
    if (!values.last_name.trimStart()) {
      errors['last_name'] = 'This field is Required';
    }
    if (!values.job.trimStart()) {
      errors['job'] = 'This field is Required';
    }
    // else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#@^*()-_+=$%&?`~<>,./{}"'|])[A-Za-z\d!#@^*()-_+=$%&?`~<>,./{}"'|]{12,}$/.test(values.NewPassword)
    // ) {
    //   errors['NewPassword'] = 'Password must be minimum 12 characters long and contain at least one uppercase letter, one lowercase letter, one digit and one symbol'
    // }
    // if (values.NewPassword != values.ConfirmPassword) {
    //   errors['ConfirmPassword'] = 'Confirm Password does not match New Password';
    // }
    return errors;
  };

  const formik = useFormik({
    initialValues: new UsereDetailsModel(userDetails),
    enableReinitialize: true,
    validateOnChange: validateAfterSubmit,
    validate,
    onSubmit: useCallback(
      debounce(
        async (values) => {
          try {
            setIsEditSaveButtonLoading(true);

            delete values.company;
            delete values.email;
            //delete values.job;
            const res = await MyAccountApi.PutUserData(values);
            const user = await MyAccountApi.GetUserData();

            dispatch(commonDetailSlice.actions.setcommonDetails(user));
            handleClose();
          } catch (error) {
          } finally {
            setTimeout(() => {
              setIsEditSaveButtonLoading(false);
            }, 500);
          }
        },
        500,
        true
      ),
      []
    )
  });

  return (
    <Box>
      <Box sx={{ color: '#436AF3', cursor: 'pointer' }}>
        <Button onClick={handleOpen} sx={{ fontWeight: 600 }}>
          Edit
        </Button>
      </Box>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="subtitle2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'text.primary',
                fontWeight: '600',
                maxWidth: 'calc(100% - 40px)',
                p: 1
              }}>
              Profile Information
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} sx={{ color: 'text.primary' }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ px: { xs: 2, md: 3 }, pt: 0 }}>
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="firstName">First Name </label>
                    </Box>

                    <TextField
                      size="small"
                      id="first_name"
                      name="first_name"
                      value={formik.values.first_name}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onKeyDown={(e) => e.key == 'Enter' && formik.handleSubmit()}
                      error={formik.errors.first_name ? true : false}
                      helperText={formik.errors.first_name}
                      placeholder="First Name"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'firstName'
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="lastName">Last Name</label>
                    </Box>
                    <TextField
                      size="small"
                      id="last_name"
                      name="last_name"
                      value={formik.values.last_name}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onKeyDown={(e) => e.key == 'Enter' && formik.handleSubmit()}
                      error={formik.errors.last_name ? true : false}
                      helperText={formik.errors.last_name}
                      placeholder="Last Name"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'LastName'
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="phoneNumber">Phone</label>
                    </Box>
                    <TextField
                      size="small"
                      disabled
                      id="phone"
                      name="phone"
                      value={formik.values.phone}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      error={formik.errors.phone ? true : false}
                      helperText={formik.errors.phone}
                      placeholder="Phone Number"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'phoneNumber'
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Email"> E-mail</label>
                    </Box>
                    <TextField
                      disabled
                      size="small"
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      error={formik.errors.email ? true : false}
                      helperText={formik.errors.email}
                      placeholder="E-mail"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'Email'
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Company">Company</label>
                    </Box>
                    <TextField
                      disabled
                      size="small"
                      id="company"
                      name="company"
                      value={formik.values.company}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      error={formik.errors.company ? true : false}
                      helperText={formik.errors.company}
                      placeholder="Company"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'Company'
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="jobTitle">Job Title</label>
                    </Box>
                    <TextField
                      size="small"
                      id="job"
                      name="job"
                      value={formik.values.job}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onKeyDown={(e) => e.key == 'Enter' && formik.handleSubmit()}
                      error={formik.errors.job ? true : false}
                      helperText={formik.errors.job}
                      placeholder="Job Title"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'jobTitle'
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: 3, pt: 0 }}>
            <LoadingButton
              loading={isEditSaveButtonLoading}
              variant="contained"
              sx={{ minWidth: 100 }}
              onClick={(e) => {
                setValidateAfterSubmit(true);
                formik.handleSubmit();
              }}
              type="button">
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};
export default EditProfilePopUp;
