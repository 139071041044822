import { Box, Typography,Button } from '@mui/material';
import { FC } from 'react';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import {useNavigate } from 'react-router-dom';

export const NotFound: FC = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 13 }}>
      <Box sx={{ textAlign: 'center' }}>
        <Box sx={{ mb: '30px',textAlign: 'center' }}>
          <img width={'70%'}  alt="" src="/images/trust-logo-black.svg" />
        </Box>
        <img width={'50%'} src="/images/NoFound.png" />
        <Typography variant="body2" sx={{ fontWeight: 600, color: '#436AF3', mt: '10px' }}>
          404 error
        </Typography>
        <Typography variant="h1" sx={{ fontSize: '40px', color: '#787878', mt: '12px' }}>
          Page not found
        </Typography>
        <Typography
          sx={{ mt: '12px', textAlign: 'center', fontWeight: 600, color: '#9D9D9D' }}
          variant="subtitle2">
          Sorry, the page you are looking for doesn't exist.
        </Typography>
        <Button sx={{ mt: '20px'}} onClick={()=>{navigate(PageRouteConstants.DashBoard)}} variant="contained">Go to Dashboard</Button>
      </Box>
    </Box>
  );
};
