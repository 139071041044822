export function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-7")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

export function saveToLS(key, value) {
  let ls = JSON.parse(global.localStorage.getItem("rgl-7")) || {};
  if (global.localStorage) {
    ls[key] = value;
    global.localStorage.setItem(
      "rgl-7",
      JSON.stringify(ls)
    );
  }
}
export function formatLayout(defaultLayout){
  const filteredLayout = defaultLayout.filter(item => item.visibility === true);
  const w4h4Items = filteredLayout.filter(item => item.w === 1 && item.h === 1);
  let xPos = 0;
  let newLayout = [];
  let counter = 0;
  let terminate = '';
  let w8h8Items = 0

  for (let index = 0; index < filteredLayout.length; index++) {
      const row = filteredLayout[index];
      if(row?.w === 3) {
        newLayout.push(row)
        xPos = 0;
      }
      if (row?.w === 1 && row?.h === 2) {
          w8h8Items = 0
          // if next is w 8
          if (filteredLayout[index + 1]?.w === 2) {
              let r = filteredLayout[index + 1]
              terminate = r?.i
              if (xPos === 0) {
                  row.x = xPos
                  newLayout.push(row)
                  xPos += row.w
                  r && (r.x = xPos);
                  r && newLayout.push(r);
                  xPos += r.w
              } else if (xPos === 1) {
                  r && (r.x = xPos);
                  r && newLayout.push(r);
                  xPos += xPos + row.w

                  if (xPos === 3) xPos = 0

                  row.x = xPos
                  newLayout.push(row)
                  xPos += row.w
              } else {
                  row.x = xPos
                  newLayout.push(row)
                  xPos += row.w
                  if (xPos === 3) xPos = 0

                  r && (r.x = xPos);
                  r && newLayout.push(r);
                  xPos += xPos + r.w
              }
              if (xPos === 3) xPos = 0
          } else {
              // w4h4Items if 3 of them are true then if run
              if (xPos === 0) {
                  row.x = xPos
                  newLayout.push(row)
                  xPos += row.w
              } else {
                  row.x = xPos
                  newLayout.push(row)
                  xPos += row.w
              }
          }
          if (xPos === 3) xPos = 0
      } else if (row?.w === 2 && row?.h === 2) {
          w8h8Items++
          if (terminate === row?.i) {
              terminate = ''
          } else {
              if (w8h8Items === 2) {
                  xPos = 0
              }
              if (xPos === 0) {
                  row.x = xPos
                  newLayout.push(row)
                  xPos += row.w
                  if (xPos === 3) xPos = 0
              } else {
                  row.x = xPos
                  newLayout.push(row)
                  xPos += row.w
                  if (xPos === 3) xPos = 0
              }
          }
      } else if (row?.w === 3 && row?.h === 2) {
        newLayout.push(row)
        if (xPos === 3) xPos = 0
      }
  }

  return newLayout;
};
