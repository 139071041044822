import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import { FC, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import InviteUserModel from './InviteUserModel';
import { UserModel } from './Models/User.Model';
import { UserProp } from './Models/UserProp';
import UsersListTable, { SecurityAwarnessManageListTable } from './SecurityAwarnessManageListTable';

export const SecurityAwarnessManageParticipents: FC = (props: any) => {
  const [isRefreshList, setIsRefreshList] = useState(Boolean);

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const data: UserProp = {
    UserModel: new UserModel(),
    IsEdit: false,
    handleSubmit() {}
  };
  const handleRefresh = () => {
    setIsRefreshList(!isRefreshList);
  };

  return (
    <>
      <Box sx={{ ...Style.CompanySettingPage.CompanySettingPageWrapper, py: 0 }}>
        <SecurityAwarnessManageListTable isRefresh={isRefreshList} />
      </Box>
    </>
  );
};
