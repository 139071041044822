import Style from '@jibin/common/style/Styles';
import { Box, Container, Typography, Grid, Radio, FormControlLabel } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';



export const MfaMethod: FC = () => {

    const navigate = useNavigate()
    const locaton = useLocation();
    const [value, setValue] = useState('');
    const isAllowed = locaton?.state && locaton?.state?.allow;

    const StyledRadio = (props) => (
        <Radio
            sx={{
                '&.Mui-checked': {
                    color: '#436AF3',
                },
            }}
            {...props}
        />
    );

    const handleChange = (event) => {
        setValue(event.target.value);
        if (event.target.value === 'sms') {
            navigate('/mfa-sms', { state: { allow: isAllowed } })
        } else if (event.target.value === 'authenticator') {
            navigate('/mfa-authenticator', { state: { allow: isAllowed } })
        }
    };

    useEffect(() => {
        if (!isAllowed) {
            navigate(PageRouteConstants.Login);
        }
    }, []);

    return (
        <Box sx={Style.SignUp.SignUpWrapper}>
            <Container maxWidth="xl">
                <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
                        <Box >
                            <Box sx={Style.Auth.TrustLogo}>
                                <img alt="" src="/images/trust-logo-black.svg" />
                            </Box>
                            <Typography sx={{ fontWeight: 600, textAlign: 'justify', }} variant="subtitle2">
                                {' '}
                                Choose a Multi-Factor Authentication (MFA) method
                            </Typography>
                            <Typography sx={{ textAlign: 'justify', mt: '0.5rem', mb: 2 }} variant="body1">
                                You must configure MFA before you can sign-in.
                            </Typography>

                            <Box sx={{ mb: 2 }}>
                                <FormControlLabel
                                    className='mfa-custom-radio'
                                    control={<StyledRadio />}
                                    label={
                                        <>
                                            <Typography sx={{ fontWeight: 600 }} variant="body1">
                                                SMS Code
                                            </Typography>
                                            <Typography variant="body2" sx={{ maxWidth: '340px', color: '#696969' }}>
                                                Authenticate using a code sent via text message to your phone
                                            </Typography>
                                        </>
                                    }
                                    sx={{
                                        border: '1px solid #9D9D9D',
                                        borderRadius: "4px",
                                        padding: '20px 15px',
                                        alignItems: 'flex-start',
                                        m: 0
                                    }}
                                    value="sms"
                                    onChange={handleChange}
                                    checked={value === 'sms'}
                                />
                            </Box>

                            <Box>
                                <FormControlLabel
                                    className='mfa-custom-radio'
                                    control={<StyledRadio />} // Use the custom styled radio button
                                    label={
                                        <>
                                            <Typography sx={{ fontWeight: 600 }} variant="body1">
                                                Authenticator App
                                            </Typography>
                                            <Typography variant="body2" sx={{ maxWidth: '340px', color: '#696969' }}>
                                                Authenticate using a code generated by an app installed on your mobile device or computer
                                            </Typography>
                                        </>
                                    }
                                    sx={{
                                        border: '1px solid #9D9D9D',
                                        borderRadius: "4px",
                                        padding: '20px 15px',
                                        alignItems: 'flex-start',
                                        m: 0
                                    }}
                                    value="authenticator"
                                    onChange={handleChange}
                                    checked={value === 'authenticator'}
                                />
                            </Box>


                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
                        <Box sx={Style.Auth.AuthInfographics}>
                            <img alt="" src="/images/elephant-with-men.png" />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
