import SvgIcon from '@mui/material/SvgIcon';

export default function VerifiedUserIcon(props) {
  return (
    <svg width="24" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.81335 1.72199C9.1488 1.72199 8.61002 2.26076 8.61002 2.92531V3.40735C8.99929 3.33072 9.40161 3.29055 9.81328 3.29055H10.1867C10.5984 3.29055 11.0008 3.33073 11.3901 3.40737V2.92531C11.3901 2.26074 10.8513 1.72199 10.1868 1.72199H9.81335ZM7.36521 2.92531V3.79304C6.17337 4.30616 5.17439 5.18244 4.50779 6.28237L3.0673 5.16047C1.91914 4.26542 1.24481 2.89018 1.24481 1.43557V0.76337H0V1.43557C0 3.27558 0.852838 5.01254 2.30219 6.14239L3.96275 7.43569C3.73691 8.07857 3.61408 8.76986 3.61408 9.48973V12.4897C3.61408 12.704 3.62464 12.9159 3.64527 13.1247L3.49964 12.9547L2.08054 14.1705L2.07974 14.1712C0.760077 15.3056 0 16.955 0 18.6971V19.5229H1.24481V18.6971C1.24481 17.3191 1.84559 16.0142 2.89085 15.1155L2.89121 15.1152L3.86803 14.2783C4.64167 16.9343 7.09451 18.8756 9.99998 18.8756C12.9055 18.8756 15.3583 16.9343 16.1319 14.2783L17.1095 15.1158C18.1536 16.0097 18.7552 17.3182 18.7552 18.6971V19.5228H20V18.6971C20 16.9558 19.2403 15.3014 17.9193 14.1704L16.5004 12.9547L16.3547 13.1248C16.3753 12.9159 16.3859 12.704 16.3859 12.4897V9.48973C16.3859 8.8746 16.2962 8.28035 16.1292 7.71932L17.9658 6.11574C19.2591 4.9847 20 3.34896 20 1.62648V0.767554H18.7552V1.62648C18.7552 2.99105 18.1683 4.28487 17.1463 5.17872L15.623 6.5088C14.96 5.30278 13.9074 4.34095 12.6349 3.79308V2.92531C12.6349 1.57328 11.5389 0.477173 10.1868 0.477173H9.81335C8.46131 0.477173 7.36521 1.57326 7.36521 2.92531ZM15.1411 9.48973C15.1411 7.5618 14.0384 5.89018 12.4297 5.07144C12.0308 6.01945 11.0935 6.68465 10.0001 6.68465C8.90658 6.68465 7.96923 6.01943 7.57039 5.0714C5.9616 5.89012 4.85889 7.56177 4.85889 9.48973V12.4897C4.85889 15.1179 6.83218 17.2862 9.37765 17.5935V12.4895H10.6225V17.5935C13.1679 17.2861 15.1411 15.1179 15.1411 12.4897V9.48973ZM10.0001 5.43984C10.553 5.43984 11.0301 5.11774 11.2541 4.65075C10.9103 4.57519 10.5531 4.53536 10.1867 4.53536H9.81328C9.44692 4.53536 9.08981 4.57518 8.74605 4.65073C8.97001 5.11774 9.44708 5.43984 10.0001 5.43984Z"
        fill="white"
      />
    </svg>
  );
}
export function VerifiedUserIconBlue(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.81335 1.72199C9.1488 1.72199 8.61002 2.26076 8.61002 2.92531V3.40735C8.99929 3.33072 9.40161 3.29055 9.81328 3.29055H10.1867C10.5984 3.29055 11.0008 3.33073 11.3901 3.40737V2.92531C11.3901 2.26074 10.8513 1.72199 10.1868 1.72199H9.81335ZM7.36521 2.92531V3.79304C6.17337 4.30616 5.17439 5.18244 4.50779 6.28237L3.0673 5.16047C1.91914 4.26542 1.24481 2.89018 1.24481 1.43557V0.76337H0V1.43557C0 3.27558 0.852838 5.01254 2.30219 6.14239L3.96275 7.43569C3.73691 8.07857 3.61408 8.76986 3.61408 9.48973V12.4897C3.61408 12.704 3.62464 12.9159 3.64527 13.1247L3.49964 12.9547L2.08054 14.1705L2.07974 14.1712C0.760077 15.3056 0 16.955 0 18.6971V19.5229H1.24481V18.6971C1.24481 17.3191 1.84559 16.0142 2.89085 15.1155L2.89121 15.1152L3.86803 14.2783C4.64167 16.9343 7.09451 18.8756 9.99998 18.8756C12.9055 18.8756 15.3583 16.9343 16.1319 14.2783L17.1095 15.1158C18.1536 16.0097 18.7552 17.3182 18.7552 18.6971V19.5228H20V18.6971C20 16.9558 19.2403 15.3014 17.9193 14.1704L16.5004 12.9547L16.3547 13.1248C16.3753 12.9159 16.3859 12.704 16.3859 12.4897V9.48973C16.3859 8.8746 16.2962 8.28035 16.1292 7.71932L17.9658 6.11574C19.2591 4.9847 20 3.34896 20 1.62648V0.767554H18.7552V1.62648C18.7552 2.99105 18.1683 4.28487 17.1463 5.17872L15.623 6.5088C14.96 5.30278 13.9074 4.34095 12.6349 3.79308V2.92531C12.6349 1.57328 11.5389 0.477173 10.1868 0.477173H9.81335C8.46131 0.477173 7.36521 1.57326 7.36521 2.92531ZM15.1411 9.48973C15.1411 7.5618 14.0384 5.89018 12.4297 5.07144C12.0308 6.01945 11.0935 6.68465 10.0001 6.68465C8.90658 6.68465 7.96923 6.01943 7.57039 5.0714C5.9616 5.89012 4.85889 7.56177 4.85889 9.48973V12.4897C4.85889 15.1179 6.83218 17.2862 9.37765 17.5935V12.4895H10.6225V17.5935C13.1679 17.2861 15.1411 15.1179 15.1411 12.4897V9.48973ZM10.0001 5.43984C10.553 5.43984 11.0301 5.11774 11.2541 4.65075C10.9103 4.57519 10.5531 4.53536 10.1867 4.53536H9.81328C9.44692 4.53536 9.08981 4.57518 8.74605 4.65073C8.97001 5.11774 9.44708 5.43984 10.0001 5.43984Z"
        fill="#436AF3"
      />
    </svg>
  );
}
