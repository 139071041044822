import React from 'react'

const HelpSquareIcon = (props) => {
    const { openPopup, color } = props;
    return (
        <svg onClick={() => openPopup && openPopup()} style={{ cursor: color ? 'auto' : 'pointer' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.09 7C7.3251 6.33167 7.78915 5.76811 8.39995 5.40913C9.01076 5.05016 9.7289 4.91894 10.4272 5.03871C11.1255 5.15849 11.7588 5.52152 12.2151 6.06353C12.6713 6.60553 12.9211 7.29152 12.92 8C12.92 10 9.92 11 9.92 11M10 15H10.01M5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19Z" stroke={color ? color: '#B7B7B7'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default HelpSquareIcon