import Style from '@jibin/common/style/Styles';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import GoldIcon from '@jibin/common/icons/MedalIcon/GoldIcon.svg';
import SilverIcon from '@jibin/common/icons/MedalIcon/SilverIcon.svg';
import BronzeIcon from '@jibin/common/icons/MedalIcon/BronzeIcon.svg';
import PlatniumIcon from '@jibin/common/icons/MedalIcon/PlatniumIcon.svg';
import HelpIcon from '@jibin/common/icons/HelpIcon/HelpIcon';
import CheckBoxLight from '@jibin/common/icons/CheckBoxLight/CheckBoxLight';
import CheckBoxLightBlue from '@jibin/common/icons/CheckBoxLightBlue/CheckBoxLightBlue';
import CheckBoxOutLine from '@jibin/common/icons/CheckBoxOutLine/CheckBoxOutLine';

import ChipBox from './ChipBox';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CompanyManagementApi } from '../../CompanyManagement.Api';
import { useFormik } from 'formik';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { LoadingButton } from '@mui/lab';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import SearchIcon from '@jibin/common/icons/Search/Search';
import { CompnayManageMentContex } from '..';
import { SidebarService } from 'src/Pages/Common/Sidebar/Sidebar.api';
import commonDetailSlice from 'src/store/reducers/commonReducer';
import { NoDataAvailable } from 'src/Pages/Common/NoDataAvailable';
const CompanyManagementAccount: FC = () => {
  const loadMedal = (type) => {
    switch (type) {
      case 'gold':
      case 'Gold':
        return <img width={'16px'} src={GoldIcon} />;

      case 'silver':
      case 'Silver':
        return <img width={'16px'} src={SilverIcon} />;
      case 'bronze':
      case 'Bronze':
        return <img width={'16px'} src={BronzeIcon} />;
      case 'platinum':
      case 'Platinum':
        return <img width={'16px'} src={PlatniumIcon} />;
    }
  };
  const Framework = [
    { name: 'CIS', id: 1 },
    { name: 'MPA', id: 2 }
  ];

  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState('US');
  const [address, setAddress] = useState({ address1: '', address2: '' });
  const [adressError, setAdressError] = useState({ address1: false, address2: false });
  const [autocompleteInputRef, setautocompleteInputRef] = useState({} as any);
  const [settings, setSettings] = useState({} as any);
  const [setState, setSetState] = useState('');
  const [isRefresh, setIsRefresh] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [isOnBoardButtonLoading, setIsOnBoardButtonLoading] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  const {
    companyData,
    setCompanyData,
    setOnBoardingStep,
    filterData,
    setFilterData,
    setValue,
    onBoardingSteps,
    listOfFramework,
    setListOfFramework,
    listOfServices,
    values
  } = useContext(CompnayManageMentContex);

  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected.map((value) => {
      const selectedOption = options.find((option) => option?.value === value);

      return selectedOption ? selectedOption?.key : '';
    });

    return selectedLabels.join(', ');
  };
  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        fontSize: '14px',
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };
  const handleChange = (event: any) => {
    let {
      target: { value, name }
    } = event;
    const filterValue = value.filter((el: any) => el != '');

    let string;

    if (filterValue.length == 0) {
      string = '';

      const item = { ...filterData };
      item[name] = typeof value === 'string' ? filterValue.split(',') : [''];

      setFilterData(item);
    } else {
      string = filterValue.join(',');

      const item = { ...filterData };

      item[name] = typeof value === 'string' ? filterValue.split(',') : filterValue;

      setFilterData(item);
    }
  };
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const formik = useFormik({
    initialValues: { frameworks: [], services: [], settings: {} },
    onSubmit: () => {}
  });
  const nevigate = useNavigate();
  const handleSubmitPOST = async () => {
    try {
      setIsOnBoardButtonLoading(true);
      const data = { ...companyData };
      data.frameworks = values.Framework;
      data.services = values.Service;

      data.settings = { ...data?.settings, onboarding: onBoardingSteps };
      delete data.plan;
      delete data.company_uuid;
      delete data.external_id;

      delete data.status;
      const res = await CompanyManagementApi.POSTCompanyData(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        searchParams.get('q'),
        data
      );
      searchParams.set('q', searchParams.get('q'));
      searchParams.set('company_uuid', res?.data?.data?.company_uuid);
      setSearchParams(searchParams);
      setIsRefresh(!isRefresh);
      GetUserDetails();
    } catch (error) {
    } finally {
      setTimeout(() => {
        setIsOnBoardButtonLoading(false);
      }, 1000);
    }
  };

  const handleSubmitPATCH = async () => {
    try {
      setIsSubmitButtonLoading(true);
      const data = { ...companyData };
      data.frameworks = values.Framework;
      data.services = values.Service;

      data.settings = { ...data?.settings, onboarding: onBoardingSteps };
      delete data.plan;
      delete data.company_uuid;
      delete data.external_id;
      delete data.status;

      const res = await CompanyManagementApi.UpadteCompanyData(
        companyData.company_uuid,
        commonDetails.data.user_uuid,
        searchParams.get('q'),
        data
      );
      setIsRefresh(!isRefresh);
      GetUserDetails();
    } catch (error) {
    } finally {
      setTimeout(() => {
        setIsSubmitButtonLoading(false);
      }, 1000);
    }
  };

  const GetUserDetails = () => {
    SidebarService.GetUserData().then(
      async (q: any) => {
        await dispatch(commonDetailSlice.actions.setcommonDetails(q));
      },
      (err: any) => {}
    );
  };

  const handleChangeCheckBox = (e, index) => {
    const item = onBoardingSteps;

    const findIndex = onBoardingSteps.findIndex((el) => el.position == index);
    item[findIndex].enabled = e.target.checked;

    setOnBoardingStep(item);
  };

  const handleChangeServiceCheckBox = (e, element, name) => {
    const item = values[name];

    const findIndex = values[name].findIndex((el) => el.name == element.name);

    item[findIndex].enabled = e.target.checked;

    setValue({
      ...values,
      [name]: item
    });
  };

  return (
    <Box>
      {companyData === null ? (
        <NoDataAvailable title="No Data Available" message="This company has not been onboarded" />
      ) : (
        <>
          <Box sx={{ py: 1.5 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#464646', py: 2 }}>
              {companyData?.name}
            </Typography>
          </Box>
          <Box sx={Style.CompanyManagement.CommonBoxes}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                    Location:
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#696969' }}>
                    {companyData?.address?.country}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                    SDC:
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#696969' }}>
                    {companyData?.sdc_user_info?.name}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                    Industry:
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#696969' }}>
                    {companyData?.industry_type?.map((el) => el).join(',')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                    Website:
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#696969' }}>
                    {companyData?.website}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                    Onboarding:
                  </Typography>
                  <Box
                    sx={{
                      px: 1,
                      py: '2px',
                      bgcolor: 'rgba(120, 120, 120, 0.1)',
                      borderRadius: '100px'
                    }}>
                    <Typography variant="caption">{companyData?.status}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography
                    variant="body1"
                    sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontWeight: 600 }}>
                    {loadMedal(companyData?.plan?.plan_type)} Subscription (
                    {companyData?.plan?.plan_type})
                  </Typography>
                  {companyData?.plan?.status == 'active' && (
                    <Box
                      sx={{
                        px: 1,
                        py: '5px',
                        background: 'rgba(16, 185, 129, 0.1)',
                        borderRadius: '100px'
                      }}>
                      <Typography variant="caption" sx={{ fontWeight: 500, color: '#27AE60' }}>
                        Active
                      </Typography>
                    </Box>
                  )}
                  {companyData?.plan?.status == 'expired' && (
                    <Box
                      sx={{
                        px: 1,

                        py: '5px',
                        background: 'rgba(239, 68, 68, 0.1)',
                        borderRadius: '100px'
                      }}>
                      <Typography variant="caption" sx={{ fontWeight: 500, color: '#EF4444' }}>
                        Expired
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <>
            <Box sx={{ py: 1.5 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                Select Services
              </Typography>
            </Box>
            <FormControl sx={{ width: 250, mb: 2 }}>
              <Select
                size="small"
                displayEmpty
                multiple
                name="Service"
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={filterData.Service}
                onChange={(e, newValue: any) => {
                  handleChange(e);

                  const data = values?.Service?.findIndex(
                    (el: any) => el?.name == newValue?.props?.value
                  );

                  const event = {
                    target: {
                      checked: values?.Service[data]?.enabled ? false : true
                    }
                  };
                  handleChangeServiceCheckBox(event, values?.Service[data], 'Service');
                }}
                input={<OutlinedInput />}
                renderValue={(selected) => renderValue(selected, listOfServices)}
                MenuProps={MenuProps}
                sx={{
                  py: 0.35,
                  '.MuiSelect-select': {
                    py: '5.5px'
                  },
                  fontSize: '14px',
                  color: '#696969',
                  minWidth: { xxs: 0, md: '150px' },
                  backgroundColor: '#FFFFFF'
                }}
                inputProps={{ 'aria-label': 'Without label' }}>
                {listOfServices
                  ?.sort((a: any, b: any) => (a?.key > b?.key ? 1 : -1))
                  ?.map((name: any, index: any) => (
                    <MenuItem
                      sx={{
                        fontSize: '14px',
                        display: !name.value ? 'none' : 'flex',
                        py: 0,
                        '.MuiMenuItem-root': {
                          py: '0px'
                        }
                      }}
                      key={name.key}
                      value={name.value}>
                      <Checkbox
                        name={name?.position}
                        onChange={(e) => handleChangeServiceCheckBox(e, name, 'Service')}
                        value={values?.Service[index]?.enabled}
                        defaultChecked={name?.enabled}
                        checkedIcon={<CheckBoxLightBlue />}
                        icon={
                          <CheckBoxOutLine
                            sx={{
                              fill: 'none',
                              width: '21px',
                              height: '20px'
                            }}
                          />
                        }
                        checked={filterData.Service.indexOf(name.value) > -1}
                      />
                      <EllipsisContent
                        maxTextWidth={150}
                        text={name.key}
                        sx={{ fontSize: '14px' }}
                      />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Box sx={Style.CompanyManagement.CommonBoxes}>
              <FormGroup>
                {/* Service?.filter((obj1) =>
          res.data.data
            ?.filter((el: any) => (el.name == obj1.name && el.enabled) || obj1.isStatic)
            .some((obj2) => (obj2.name === obj1.name && obj2.enabled) || obj1.isStatic)
        ); */}
                <Grid container>
                  {values.Service?.filter((el: any) =>
                    filterData.Service?.filter((element: any) => element == el?.name)?.some(
                      (obj2: any) => obj2 === el?.name
                    )
                  )
                    ?.sort?.((a, b) => (a.name > b.name ? 1 : -1))
                    ?.map((el: any, index) => (
                      <Grid key={index + 1} item xs={12} sm={6} lg={4}>
                        <FormControlLabel
                          sx={{ '.MuiFormControlLabel-label': { fontSize: '14px' } }}
                          control={
                            <Checkbox
                              disabled
                              name={el?.position}
                              onChange={(e) => handleChangeServiceCheckBox(e, el, 'Service')}
                              value={el?.enabled}
                              defaultChecked={el?.enabled}
                              checkedIcon={
                                el.is_enabled_on_sf ? <CheckBoxLightBlue /> : <CheckBoxLight />
                              }
                              icon={
                                <CheckBoxOutLine
                                  sx={{
                                    fill: 'none',
                                    width: '21px',
                                    height: '20px'
                                  }}
                                />
                              }
                              color="warning"
                            />
                          }
                          label={
                            <Box
                              sx={{
                                display: 'flex',
                                gap: 0.2,
                                alignItems: 'center'
                              }}>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600, color: '#464646' }}>
                                {el.name}
                              </Typography>
                              {!el.is_enabled_on_sf && el?.enabled && (
                                <Tooltip title="Not updated in SF" placement="top">
                                  <IconButton>
                                    <HelpIcon
                                      sx={{
                                        fill: 'none',
                                        fontSize: '16px'
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          }
                        />
                      </Grid>
                    ))}
                </Grid>
              </FormGroup>
            </Box>
          </>
          <>
            <Box sx={{ py: 1.5 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                Frameworks
              </Typography>
            </Box>
            <FormControl sx={{ width: 250, mb: 2 }}>
              <Select
                size="small"
                displayEmpty
                multiple
                name="Framework"
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={filterData.Framework}
                onChange={(e, newValue: any) => {
                  handleChange(e);
                  const data = values?.Framework?.findIndex(
                    (el: any) => el?.name == newValue?.props?.value
                  );

                  const event = {
                    target: {
                      checked: values?.Framework[data]?.enabled ? false : true
                    }
                  };
                  handleChangeServiceCheckBox(event, values?.Framework[data], 'Framework');
                }}
                input={<OutlinedInput />}
                renderValue={(selected) => renderValue(selected, listOfFramework)}
                MenuProps={MenuProps}
                sx={{
                  py: 0.35,
                  '.MuiSelect-select': {
                    py: '5.5px'
                  },
                  fontSize: '14px',
                  color: '#696969',
                  minWidth: { xxs: 0, md: '150px' },
                  backgroundColor: '#FFFFFF'
                }}
                inputProps={{ 'aria-label': 'Without label' }}>
                {listOfFramework
                  ?.sort((a: any, b: any) => (a?.key > b?.key ? 1 : -1))
                  ?.map((name: any, index: any) => (
                    <MenuItem
                      sx={{
                        fontSize: '14px',
                        display: !name.value ? 'none' : 'flex',
                        py: 0,
                        '.MuiMenuItem-root': {
                          py: '0px'
                        }
                      }}
                      key={name.key}
                      value={name.value}>
                      <Checkbox
                        name={name?.position}
                        onChange={(e) => handleChangeServiceCheckBox(e, name, 'Framework')}
                        value={values.Framework[index]?.enabled}
                        defaultChecked={name?.enabled}
                        checkedIcon={<CheckBoxLightBlue />}
                        icon={
                          <CheckBoxOutLine
                            sx={{
                              fill: 'none',
                              width: '21px',
                              height: '20px'
                            }}
                          />
                        }
                        checked={filterData.Framework.indexOf(name.value) > -1}
                      />
                      <EllipsisContent
                        maxTextWidth={150}
                        text={name.key}
                        sx={{ fontSize: '14px' }}
                      />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Box sx={Style.CompanyManagement.CommonBoxes}>
              <FormGroup>
                <Grid container>
                  {values.Framework?.filter((el: any) =>
                    filterData.Framework?.filter((element: any) => element == el?.name)?.some(
                      (obj2: any) => obj2 === el?.name
                    )
                  )?.map((el, index) => (
                    <Grid key={index + 1} item xs={12} sm={6} lg={4}>
                      <FormControlLabel
                        sx={{ '.MuiFormControlLabel-label': { fontSize: '14px' } }}
                        control={
                          <Checkbox
                            disabled
                            name={el?.position}
                            onChange={(e) => handleChangeServiceCheckBox(e, el, 'Framework')}
                            value={values.Framework[index]?.enabled}
                            defaultChecked={el?.enabled}
                            checkedIcon={<CheckBoxLightBlue />}
                            icon={
                              <CheckBoxOutLine
                                sx={{
                                  fill: 'none',
                                  width: '21px',
                                  height: '20px'
                                }}
                              />
                            }
                          />
                        }
                        label={
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            {el.name}
                          </Typography>
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
            </Box>
          </>
          <Box sx={{ py: 1.5 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
              Mandatory Onboarding Steps
            </Typography>
          </Box>
          <Box sx={Style.CompanyManagement.CommonBoxes}>
            <FormGroup>
              <Grid container>
                {onBoardingSteps
                  ?.sort?.((a, b) => (a.position > b.position ? 1 : -1))
                  ?.map((el, index) => (
                    <Grid key={index + 1} item xs={12} sm={6} lg={4}>
                      <FormControlLabel
                        sx={{ '.MuiFormControlLabel-label': { fontSize: '14px' } }}
                        control={
                          <Checkbox
                            name={el?.position}
                            onChange={(e) => handleChangeCheckBox(e, el?.position)}
                            value={onBoardingSteps[index].enabled}
                            defaultChecked={el?.enabled}
                            checkedIcon={<CheckBoxLightBlue />}
                            icon={
                              <CheckBoxOutLine
                                sx={{
                                  fill: 'none',
                                  width: '21px',
                                  height: '20px'
                                }}
                              />
                            }
                          />
                        }
                        label={
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            {el.name}
                          </Typography>
                        }
                      />
                    </Grid>
                  ))}
              </Grid>
            </FormGroup>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ py: 1 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  {' '}
                  Integrations
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                width: 1,
                justifyContent: 'space-between',
                gap: 2,
                p: 3,
                mt: 1,

                backgroundColor: '#FFFFFF',
                borderRadius: '8px'
              }}>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  Jira Integration
                </Typography>
                <Typography variant="caption">
                  By enabling this, Trust Portal will Auto-Create a Jira Issue any time a
                  Remediation is created
                </Typography>
              </Box>
              <Box>
                {' '}
                <Switch
                  checked={companyData?.settings?.auto_create_jira_issue}
                  onChange={(e) =>
                    setCompanyData({
                      ...companyData,
                      settings: {
                        ...companyData?.settings,
                        auto_create_jira_issue: e.target.checked
                      }
                    })
                  }
                  sx={{
                    ml: 2,

                    '	.MuiSwitch-switchBase': { color: 'rgba(0, 0, 0, 0.25)' },
                    '	.MuiSwitch-track': {
                      backgroundColor: 'transparent',
                      border: '1px solid #ADB5BD',
                      borderRadius: '45px'
                    },
                    '.css-jsexje-MuiSwitch-thumb': {
                      boxShadow: 'none'
                    },
                    '	.Mui-disabled': {
                      cursor: 'not-allowed'
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
      <Box sx={{ py: 2 }}>
        <Box sx={{ display: 'flex', gap: 1.5, justifyContent: 'flex-end' }}>
          {companyData !== null && (
            <LoadingButton
              loading={isSubmitButtonLoading}
              disabled={companyData?.status?.toUpperCase() == 'INACTIVE'}
              onClick={handleSubmitPATCH}
              variant="contained"
              sx={{ minWidth: '160px' }}>
              Save
            </LoadingButton>
          )}
          <LoadingButton
            loading={isOnBoardButtonLoading}
            onClick={handleSubmitPOST}
            disabled={
              companyData === null ||
              searchParams.get('company_uuid') !== '' ||
              companyData?.status?.toUpperCase() == 'ACTIVE' ||
              companyData?.status?.toUpperCase() == 'PENDING'
            }
            variant="contained"
            sx={{ minWidth: '160px' }}>
            Onboard
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyManagementAccount;
