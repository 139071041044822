import HttpService from '@jibin/common/services/http/HttpService';

export const CompanyManagementApi = {
  // User section
  // PenetrationTests(userId: string, companyId: string) {
  //   //return HttpService.getAll("todos/1");
  //   return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/penetration/tests`);
  // },
  GetCompany(company_id: string, user_id: string, params: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${company_id}/users/${user_id}/managed-companies?` + params
    );
  },
  GetCompanyDetails(company_id: string, user_id: string, external_id: string) {
    return HttpService.getAll(
      `companies/${company_id}/users/${user_id}/managed-companies/${external_id}`
    );
  },
  GetCompanyContacts(company_id: string, user_id: string, external_id: string, params: string) {
    return HttpService.getAll(
      `companies/${company_id}/users/${user_id}/managed-companies/${external_id}/contacts?` + params
    );
  },
  InviteUser(company_id: string, user_id: string, external_id: string, data: any) {
    return HttpService.post(
      `companies/${company_id}/users/${user_id}/managed-companies/${external_id}/contacts`,
      data
    );
  },
  SubmitIntigration(company_id: string, user_id: string, external_id: string, data: any) {
    return HttpService.putAll(
      `companies/${company_id}/users/${user_id}/managed-companies/${external_id}/integrations`,
      data
    );
  },
  ChangeToggle(company_id: string, user_id: string, data: any) {
    return HttpService.patchAll(
      `companies/${company_id}/users/${user_id}/managed-companies/integrations/toggle`,
      data
    );
  },
  TestIntigration(company_id: string, user_id: string, data: any) {
    return HttpService.patchAll(
      `companies/${company_id}/users/${user_id}/managed-companies/integrations/test`,
      data
    );
  },

  GetComapnyDetails(company_id: string, user_id: string, externa_id: string) {
    return HttpService.getAll(
      `companies/${company_id}/users/${user_id}/managed-companies/${externa_id}`
    );
  },
  GetIntegrations(company_id: string, user_id: string, externa_id: string, params: string) {
    return HttpService.getAll(
      `companies/${company_id}/users/${user_id}/managed-companies/${externa_id}/integrations?${params}`
    );
  },
  // /quickbooks/customers
  GetCustomersList() {
    return HttpService.getAll(`quickbooks/customers`);
  },
  GetOrgList() {
    return HttpService.getAll(`ninja-one/organizations`);
  },
  ChangeStatusOfCompany(company_id: string, user_id: string, data: any) {
    return HttpService.patchAll(
      `companies/${company_id}/users/${user_id}/managed-companies/status`,
      data
    );
  },
  GetCompanyFrameworks(company_id: string, user_id: string) {
    return HttpService.getAll(`companies/${company_id}/users/${user_id}/gav2/frameworks`);
  },
  PostCompanyData(company_id: string, user_id: string, externa_id: string, data: any) {
    return HttpService.post(
      `companies/${company_id}/users/${user_id}/managed-companies/${externa_id}`,
      data
    );
  },
  UpadteCompanyData(company_id: string, user_id: string, externa_id: string, data: any) {
    return HttpService.putAll(
      `companies/${company_id}/users/${user_id}/managed-companies/${externa_id}`,
      data
    );
  },
  POSTCompanyData(company_id: string, user_id: string, externa_id: string, data: any) {
    return HttpService.post(
      `companies/${company_id}/users/${user_id}/managed-companies/${externa_id}`,
      data
    );
  }
};
