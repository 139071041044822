import HttpService from '@jibin/common/services/http/HttpService';

export const SecurityAwarenessApi = {
  // User section
  training(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/training`);
  },
  phishing(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/phishing`);
  },
  trainingCampaigns(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/training/campaigns`);
  },
  trainingCampaignsEnrollments(
    userId: string,
    companyId: string,
    campaign_id: string,
    params: string
  ) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/training/campaigns/${campaign_id}/enrollments?` +
        params
    );
  },
  phishingCampaigns(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/phishing/campaigns`);
  },
  phisingCampaignsEnrollments(
    userId: string,
    companyId: string,
    campaign_id: string,
    params: string
  ) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/phishing/campaigns/${campaign_id}/recipients-results?` +
        params
    );
  }
};
