import CheckEmptyCircle from '@jibin/common/icons/CheckEmptyCircle/CheckEmptyCircle';
import PinIcon from '@jibin/common/icons/PinIcon/PinIcon';
import WarningRed from '@jibin/common/icons/WarningRed/WarningRed';
import { CheckCircle } from '@mui/icons-material';
import {
  Typography,
  TextareaAutosize,
  TextField,
  SwipeableDrawer,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Divider,
  IconButton
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import GapAnalysisSideBarEngineer from '../GapAnalysisSideBar/GapAnalysisSideBarEngineer';
import GapAnalysisSideBar from '../GapAnalysisSideBar/GapAnalysisSideBar';
import { useSearchParams } from 'react-router-dom';
import UploadIconMin from '@jibin/common/icons/UploadIconMin/UploadIconMin';
import EditIconForPolicy from '@jibin/common/icons/EditIconForPolicy';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import { CompanyUserRoles } from '@jibin/common/utils/constants/ConstantName';
type Anchor = 'top' | 'left' | 'bottom' | 'right';
const GapAnlysisSubmited: FC<{
  data: any;
  setIsEdit: any;
  setSelected: any;
  getQuestions: any;
  index: any;
  GetCategories: any;
  isAllComplete: boolean;
  isEngineer: boolean;
}> = (props) => {
  const { data, index, isAllComplete, GetCategories, getQuestions, isEngineer } = props;
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [rowData, setRowData] = useState({} as any);
  const [state, setState] = useState({
    right: false
  });

  const toggleDrawer = (anchor: Anchor, open: boolean, row: any) => {
    setState({ ...state, [anchor]: open });
    setRowData(row);
  };
  const setParams = (index) => {};

  return (
    <Box key={index}>
      <Box sx={{ px: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: 2
          }}>
          <Box>
            <Typography
              variant="body1"
              sx={{ fontWeight: 500, mb: 1.25, color: '#464646', whiteSpace: 'pre-line' }}>
              {data?.question_id} - {data.question}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              size="small"
              variant="contained"
              sx={{
                color: '#000000',
                whiteSpace: 'nowrap',
                bgcolor: '#EBEBEB',
                ':hover': {
                  color: '#000000',
                  bgcolor: '#EBEBEB'
                }
              }}>
              {data?.answer?.answer}
            </Button>
            {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
              <IconButton
                onClick={() => {
                  props.setSelected(props.index);
                  props.setIsEdit(true);
                }}>
                <EditIconForPolicy />
              </IconButton>
            )}
          </Box>
        </Box>
        <Box onClick={() => toggleDrawer('right', true, data)} sx={{ my: 3 }}>
          <Typography sx={{ wordBreak: 'break-all' }} variant="body1">
            Comments: {data?.answer?.comment}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', my: 1.5 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 1
            }}></Box>
        </Box>
        {data?.answer?.answer_uuid && data?.answer?.files ? (
          <Box sx={{ display: 'block' }}>
            <Box>
              <Typography variant="caption" sx={{ fontWeight: 600, mb: 1, color: '#9D9D9D' }}>
                Submitted files:
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 1.5 }}>
              {data?.answer?.files?.map((el, index) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }} key={index}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      backgroundColor: '#D9EDFF',
                      py: '5px',
                      px: 1.5,
                      borderRadius: '100px',
                      cursor: 'pointer',
                      height: 1
                    }}>
                    <PinIcon />
                    <EllipsisContent text={el.file_name} maxWidth={377} />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          <Box></Box>
        )}
      </Box>
    </Box>
  );
};
export default GapAnlysisSubmited;
