import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CompanySettingsService } from '../CompanySettings.Api';
import moment from 'moment';
import { useLocation, useSearchParams } from 'react-router-dom';

const label = { inputProps: { 'aria-label': 'Switch demo' } };
function createData(Document: any, SignDate: any, Status: any, action: any) {
  return { Document, SignDate, Status, action };
}

export const AuthorizationsTable: FC = () => {
  const [authorizationListData, setAuthorizationListData] = useState({});
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const location = useLocation();
  const GetauthorizationListData = useCallback(() => {
    CompanySettingsService.GetSignatures(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setAuthorizationListData(q.data);
        setIsSkeleton(false);
        if (searchParams.get('signature_uuid')) {
          const filterdData = q.data.data?.filter(
            (el, index) => el.signature_uuid == searchParams.get('signature_uuid')
          );

          setTimeout(() => {
            const element = document.getElementById(filterdData[0].signature_uuid);
            if (element) {
              var headerOffset = 90;
              var elementPosition = element.getBoundingClientRect().top;
              var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
              });
              element.classList.add('flash');
              setTimeout(() => {
                element.classList.remove('flash');
              }, 1000);
            }
          }, 1);
          searchParams.delete('signature_uuid');
          setSearchParams(searchParams);
        }
      },
      (err: any) => {}
    );
  }, [commonDetails, location.search]);

  useEffect(() => {
    GetauthorizationListData();
  }, [GetauthorizationListData]);

  const getRowcolor = (e: any) => {
    if (e === 'Signed') return 'common.green';
    else return 'common.orange';
  };

  return (
    <Box sx={Style.SiteListTable.SiteListTableWrapper}>
      {!isSkeleton && (
        <Table sx={{ minWidth: 650 }} aria-label="site list table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 300 }}>Document</TableCell>
              <TableCell sx={{ minWidth: 150 }}>Sign Date</TableCell>
              <TableCell sx={{ minWidth: 150 }}>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {authorizationListData['data'].map((row) => (
              <>
                <Box sx={Style.Common.Divider}></Box>
                <TableRow
                  id={row.signature_uuid}
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>
                    {moment(row.date).isValid()
                      ? moment(row.date).format('DD MMM YYYY').toUpperCase()
                      : row.date}
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Box
                        sx={{
                          ...Style.SiteListTable.StatusActive,
                          bgcolor: getRowcolor(row.status)
                        }}></Box>
                      {row.status}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    {row.status === 'Signed' ? (
                      <Box sx={Style.SiteListTable.ActionIcons}>
                        <Button
                          onClick={() => {
                            window.open(
                              process.env.REACT_APP_API_HOST + row.document_view_uri,
                              '_blank'
                            );
                          }}
                          variant="outlined"
                          sx={{ minWidth: 120 }}>
                          Visualize
                        </Button>
                      </Box>
                    ) : (
                      <Box
                        onClick={() => {
                          window.open(row.document_url, '_blank');
                        }}
                        sx={Style.SiteListTable.ActionIcons}>
                        <Button variant="contained" sx={{ minWidth: 120 }}>
                          Sign
                        </Button>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default AuthorizationsTable;
