import { SvgIcon } from '@mui/material';

const OnboardingIcon = (prop) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...prop}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.82993 0C3.17367 0 3.45233 0.278661 3.45233 0.622407V1.70454L3.9512 1.58227C5.74768 1.14119 7.64055 1.41858 9.23277 2.35572L9.23319 2.35597C10.6761 3.20674 12.4199 3.39454 14.014 2.87316L16.9771 1.90623C17.1666 1.84437 17.3743 1.87707 17.5357 1.99415C17.6971 2.11124 17.7926 2.29855 17.7926 2.49792V12.8133C17.7926 13.0826 17.6193 13.3214 17.3633 13.405L14.401 14.3717C12.4679 15.0039 10.3572 14.7764 8.60155 13.744C7.28925 12.9716 5.72546 12.7428 4.24843 13.1064L3.45233 13.3016V19.3776C3.45233 19.7213 3.17367 20 2.82993 20C2.48618 20 2.20752 19.7213 2.20752 19.3776V0.622407C2.20752 0.278661 2.48618 0 2.82993 0ZM3.45233 12.0199L3.95085 11.8977C3.95101 11.8977 3.95117 11.8976 3.95133 11.8976C3.95129 11.8976 3.95138 11.8976 3.95133 11.8976C5.74376 11.4565 7.64055 11.734 9.23258 12.671C10.6803 13.5223 12.4202 13.7098 14.014 13.1885L16.5478 12.3617V3.35574L14.401 4.0563C12.4682 4.68843 10.3532 4.46133 8.60112 3.42837C7.28891 2.65613 5.72944 2.42739 4.24807 2.79117L3.45233 2.9862V12.0199Z"
        fill="white"
      />
    </SvgIcon>
  );
};
export const OnboardingIconBlue = (prop) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...prop}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.82993 0C3.17367 0 3.45233 0.278661 3.45233 0.622407V1.70454L3.9512 1.58227C5.74768 1.14119 7.64055 1.41858 9.23277 2.35572L9.23319 2.35597C10.6761 3.20674 12.4199 3.39454 14.014 2.87316L16.9771 1.90623C17.1666 1.84437 17.3743 1.87707 17.5357 1.99415C17.6971 2.11124 17.7926 2.29855 17.7926 2.49792V12.8133C17.7926 13.0826 17.6193 13.3214 17.3633 13.405L14.401 14.3717C12.4679 15.0039 10.3572 14.7764 8.60155 13.744C7.28925 12.9716 5.72546 12.7428 4.24843 13.1064L3.45233 13.3016V19.3776C3.45233 19.7213 3.17367 20 2.82993 20C2.48618 20 2.20752 19.7213 2.20752 19.3776V0.622407C2.20752 0.278661 2.48618 0 2.82993 0ZM3.45233 12.0199L3.95085 11.8977C3.95101 11.8977 3.95117 11.8976 3.95133 11.8976C3.95129 11.8976 3.95138 11.8976 3.95133 11.8976C5.74376 11.4565 7.64055 11.734 9.23258 12.671C10.6803 13.5223 12.4202 13.7098 14.014 13.1885L16.5478 12.3617V3.35574L14.401 4.0563C12.4682 4.68843 10.3532 4.46133 8.60112 3.42837C7.28891 2.65613 5.72944 2.42739 4.24807 2.79117L3.45233 2.9862V12.0199Z"
        fill="#436AF3"
      />
    </SvgIcon>
  );
};
export default OnboardingIcon;
