import Style from '@jibin/common/style/Styles';
import {
  Button,
  Checkbox,
  debounce,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  ListSubheader,
  ListSubheaderProps,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useLocation, useSearchParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { CompanyManagementApi } from '../../CompanyManagement.Api';
import { LoadingButton } from '@mui/lab';
import IntegrationCard from './IntegrationCard';
import React from 'react';
import SearchIcon from '@jibin/common/icons/Search/Search';
import { CompnayManageMentContex } from '..';
import { NoDataAvailable } from 'src/Pages/Common/NoDataAvailable';
function MyListSubheader(props: ListSubheaderProps) {
  return <ListSubheader {...props} />;
}

const CompanyManagementIntegrations: FC = () => {
  const { companyData } = useContext(CompnayManageMentContex);
  const [values, setValue] = useState<any>({ Framework: [], Service: [] });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };
  const [remediationFilterform, setRemediationFilterform] = useState({
    tool: ''
  });
  const [filterData, setFilterData] = useState({
    tool: ['']
  });
  const filterListRef = useRef<any>();
  const [onBoardingSteps, setOnBoardingStep] = useState([]);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [filterOption, setFilterOption] = useState([] as any);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isRefresh, setIsRefresh] = useState(false);
  const location = useLocation();
  const [search, setSearch] = useState('');

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [integrationsData, setIntegrationsData] = useState([] as any);
  const [customerList, setCustomerData] = useState([] as any);
  const [orgList, setOrgData] = useState([] as any);

  const GetIntegrations = async () => {
    try {
      const res = await CompanyManagementApi.GetIntegrations(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        searchParams.get('q'),
        new URLSearchParams(remediationFilterform).toString()
      );
      setIntegrationsData(res?.data?.data);
      makeFilterList(res?.data?.data);
    } catch (error) {}
  };
  const GetCustomersList = useCallback(async () => {
    try {
      const res = await CompanyManagementApi.GetCustomersList();
      setCustomerData(res?.data?.data);
    } catch (error) {}
  }, []);
  const GetOrgList = useCallback(async () => {
    try {
      const res = await CompanyManagementApi.GetOrgList();
      setOrgData(res?.data?.data);
    } catch (error) {}
  }, []);
  useEffect(() => {
    GetIntegrations();
  }, [commonDetails, isRefresh, remediationFilterform]);


 useEffect(() => {

    GetOrgList();

    if (process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true') {
      //GetCustomersList();
    }
  }, [GetCustomersList, GetOrgList]);

  function searchIntegration(searchQuery) {
    const result = [];

    filterOption.forEach((item) => {
      const integrationMatches = item.integration.filter((integration) =>
        integration.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      if (integrationMatches.length > 0) {
        // Create a new object with only the matching information
        const matchingItem = { ...item, integration: integrationMatches };
        result.push(matchingItem);
      }
    });
    return result;
  }
  const handleChange = (event: any) => {
    let {
      target: { value, name }
    } = event;
    const filterValue = value.filter((el: any) => el != '');

    let string;
    if (filterValue.length == 0) {
      string = '';
      const dataForApi = { ...remediationFilterform };

      dataForApi[name] = string;
      const item = { ...filterData };
      setRemediationFilterform(dataForApi);

      item[name] = typeof value === 'string' ? filterValue.split(',') : [''];

      setFilterData(item);
    } else {
      string = filterValue.join(',');
      const dataForApi = { ...remediationFilterform };

      dataForApi[name] = string;
      const item = { ...filterData };
      setRemediationFilterform(dataForApi);

      item[name] = typeof value === 'string' ? filterValue.split(',') : filterValue;

      setFilterData(item);
    }
  };

  const makeFilterList = (data: any) => {
    const serviceArray = [];
    const findUniqService = data?.map((el: any) =>
      el?.services?.map((element: any) => serviceArray.push(element))
    );
    //@ts-ignore
    const filterUniq = [...new Set(serviceArray)];
    const resultArray = filterUniq.map((serviceName) => {
      const integration = data.filter(
        (integration) => integration.services && integration.services.includes(serviceName)
      );

      return {
        name: serviceName,
        integration: integration?.map((el: any) => {
          return { name: el.name, key_name: el.key_name };
        })
      };
    });
    const findEntryWithoutSevice = data
      ?.filter((el: any) => el?.services?.length == 0 || !el?.services)
      ?.map((el: any) => {
        return {
          name: '',
          integration: [{ name: el?.name, key_name: el?.key_name }]
        };
      });
    resultArray.push(
      {
        name: 'Select Tools',
        integration: [{ name: 'Select Tools', key_name: '' }]
      },
      ...findEntryWithoutSevice
    );
    filterListRef.current = resultArray;
    setFilterOption(resultArray);
  };

  const [personName, setPersonName] = React.useState<string[]>(['']);
  const [selectedItems, setSelectedItems] = useState([]);

  // const handleChange = (event: SelectChangeEvent<typeof personName>) => {
  //   const {
  //     target: { value }
  //   } = event;
  //   setPersonName(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value
  //   );
  // };

  // const handleChange = (event: SelectChangeEvent<typeof personName>) => {
  //   const {
  //     target: { value }
  //   } = event;
  //   console.log(value);
  //   setPersonName(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value
  //   );
  // };

  function findIntegrationsByKeys(data, keyNames) {
    const foundIntegrations = [];

    data.forEach((entry) => {
      const integrations = entry.integration.filter((integration) =>
        keyNames.includes(integration.key_name)
      );

      if (integrations.length > 0) {
        foundIntegrations.push({
          name: entry.name,
          integration: integrations
        });
      }
    });

    return foundIntegrations;
  }
  const renderValue = (selected: any, options: any) => {
    const data = findIntegrationsByKeys(options, selected);

    const selectedLabels = []
      .concat(
        ...data.map((value) => {
          return value.integration;
        })
      )
      .map((el: any) => el?.name);
    //@ts-ignore
    const filterUniq = [...new Set(selectedLabels)];
    return filterUniq.join(', ');
  };

  const generateMenuItems = () => {
    return searchIntegration(search)
      ?.sort((a: any, b: any) => (a.name < b.name ? -1 : 1))
      .flatMap((item, index) => [
        item.name && (
          <Typography
            key={index}
            variant="body1"
            sx={{
              px: 2,
              py: 1,
              display: item.name == 'Select Tools' ? 'none' : 'flex',
              alignItems: 'center'
            }}>
            {<KeyboardArrowRightIcon />} {item.name}
          </Typography>
        ),
        item.integration
          ?.sort((a: any, b: any) => (a.name < b.name ? -1 : 1))
          ?.map((el, index) => (
            <MenuItem
              key={el?.key_name}
              value={el.key_name}
              sx={{ py: 0, display: el.name == 'Select Tools' ? 'none' : 'flex' }}>
              <Checkbox checked={filterData.tool.indexOf(el.key_name) > -1} />
              <ListItemText primary={el.name} />
            </MenuItem>
          ))
      ]);
  };
  const handleSearch = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setSearch(e.target.value);
  };
  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return companyData === null ? (
    <NoDataAvailable title="No Data Available" message="This company has not been onboarded" />
  ) : (
    <Box>
      <Box sx={{ py: 1.5 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#464646', py: 2 }}>
          {companyData?.name}
        </Typography>

        <FormControl sx={{ width: 300, my: 1.5 }}>
          <Select
            size="small"
            id="demo-multiple-checkbox"
            multiple
            defaultValue={['']}
            sx={{ fontSize: '14px' }}
            name="tool"
            labelId="demo-multiple-checkbox-label"
            value={filterData.tool}
            onChange={handleChange}
            renderValue={(selected) => renderValue(selected, filterOption)}
            MenuProps={MenuProps}>
            <Box
              sx={{
                fontSize: '14px',
                py: 0,
                px: 2,
                '.MuiMenuItem-root': {
                  py: '0px'
                }
              }}>
              <TextField
                fullWidth
                onClickCapture={stopImmediatePropagation}
                onKeyDown={(e) => e.stopPropagation()}
                placeholder="Search"
                hiddenLabel
                size="small"
                name="keyword"
                value={search}
                onChange={handleSearch}
                sx={{ backgroundColor: '#FFFFFF' }}
                inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            {generateMenuItems()}
          </Select>
        </FormControl>
      </Box>

      <Box>
        <Grid container spacing={3}>
          {integrationsData
            ?.filter((el: any) =>
              filterData.tool[0] ? filterData.tool.includes(el?.key_name) : el
            )

            ?.map((el: any, index: any) => {
              return (
                <Grid key={index} sx={{ flexGrow: 1 }} item xs={12} sm={6} md={4}>
                  <IntegrationCard
                    orgList={orgList}
                    customerList={customerList}
                    el={el}
                    isRefresh={isRefresh}
                    setIsRefresh={setIsRefresh}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </Box>
  );
};
export default CompanyManagementIntegrations;
