import SvgIcon from '@mui/material/SvgIcon';

export default function EndpointProtection(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1921 8.0707C14.7961 7.67468 14.5981 7.47668 14.5239 7.24835C14.4587 7.0475 14.4587 6.83116 14.5239 6.63031C14.5981 6.40199 14.7961 6.20398 15.1921 5.80796L18.0305 2.96959C17.2773 2.62895 16.4412 2.43933 15.5608 2.43933C12.2471 2.43933 9.56077 5.12562 9.56077 8.43933C9.56077 8.93037 9.61976 9.40763 9.73104 9.86442C9.8502 10.3536 9.90978 10.5981 9.8992 10.7527C9.88812 10.9144 9.86401 11.0005 9.78941 11.1444C9.71815 11.282 9.58163 11.4185 9.30859 11.6915L3.06077 17.9393C2.23235 18.7678 2.23235 20.1109 3.06077 20.9393C3.8892 21.7678 5.23235 21.7678 6.06077 20.9393L12.3086 14.6915C12.5816 14.4185 12.7182 14.282 12.8557 14.2107C12.9996 14.1361 13.0857 14.112 13.2474 14.1009C13.402 14.0903 13.6465 14.1499 14.1357 14.2691C14.5925 14.3803 15.0697 14.4393 15.5608 14.4393C18.8745 14.4393 21.5608 11.753 21.5608 8.43933C21.5608 7.55892 21.3711 6.7228 21.0305 5.96959L18.1921 8.80796C17.7961 9.20398 17.5981 9.40199 17.3698 9.47617C17.1689 9.54143 16.9526 9.54143 16.7518 9.47617C16.5234 9.40199 16.3254 9.20398 15.9294 8.80796L15.1921 8.0707Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
export function EndpointProtectionBlue(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1921 8.0707C14.7961 7.67468 14.5981 7.47668 14.5239 7.24835C14.4587 7.0475 14.4587 6.83116 14.5239 6.63031C14.5981 6.40199 14.7961 6.20398 15.1921 5.80796L18.0305 2.96959C17.2773 2.62895 16.4412 2.43933 15.5608 2.43933C12.2471 2.43933 9.56077 5.12562 9.56077 8.43933C9.56077 8.93037 9.61976 9.40763 9.73104 9.86442C9.8502 10.3536 9.90978 10.5981 9.8992 10.7527C9.88812 10.9144 9.86401 11.0005 9.78941 11.1444C9.71815 11.282 9.58163 11.4185 9.30859 11.6915L3.06077 17.9393C2.23235 18.7678 2.23235 20.1109 3.06077 20.9393C3.8892 21.7678 5.23235 21.7678 6.06077 20.9393L12.3086 14.6915C12.5816 14.4185 12.7182 14.282 12.8557 14.2107C12.9996 14.1361 13.0857 14.112 13.2474 14.1009C13.402 14.0903 13.6465 14.1499 14.1357 14.2691C14.5925 14.3803 15.0697 14.4393 15.5608 14.4393C18.8745 14.4393 21.5608 11.753 21.5608 8.43933C21.5608 7.55892 21.3711 6.7228 21.0305 5.96959L18.1921 8.80796C17.7961 9.20398 17.5981 9.40199 17.3698 9.47617C17.1689 9.54143 16.9526 9.54143 16.7518 9.47617C16.5234 9.40199 16.3254 9.20398 15.9294 8.80796L15.1921 8.0707Z"
        stroke="#436AF3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
