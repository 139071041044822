import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import Style from '@jibin/common/style/Styles';
import { PageRouteConstants } from '../utils/constants/PageRouteConstants';
import { useNavigate } from 'react-router-dom';
import HeadingHeader from 'src/Pages/Common/HeadingHeader';

const MenuItems = [
  { name: 'Applications', class: '', route: PageRouteConstants.Applications },
  { name: 'IP Ranges', class: '', route: PageRouteConstants.ExternalInfrastructureAssessment },
  { name: 'Wireless Networks', class: '', route: PageRouteConstants.WirelessAssessment }
];

export const TechnicalInformation: FC<{ children: React.ReactNode | React.ReactNode[] }> = (
  props
) => {
  const navigate = useNavigate();
  const menuItemsRefs = MenuItems.map(() => React.createRef<HTMLAnchorElement>());

  const handleItemClick = (index) => {
    navigate(MenuItems[index].route);
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Tab') {
      event.preventDefault(); // Prevent default tab behavior
      const nextIndex = (index + 1) % MenuItems.length;
      menuItemsRefs[nextIndex]?.current?.focus();
    } else if (event.key === 'Enter') {
      handleItemClick(index);
    }
  };

  return (
    <>
      <HeadingHeader subtitle1={'Technical Information'} />
      <List sx={Style.TechnicalInformation.MenuList}>
        {MenuItems.map((currenElement, index) => (
          <ListItem disablePadding key={index} sx={{ width: 'auto' }}>
            <Link
              ref={menuItemsRefs[index]}
              onClick={() => handleItemClick(index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              style={{ cursor: 'pointer', fontSize: '14px' }}
              className={window.location.pathname === currenElement.route ? 'active' : ''}
              sx={Style.TechnicalInformation.MenuListItem}
              tabIndex={0}
              >
              {currenElement.name}
            </Link>
          </ListItem>
        ))}
      </List>
      {props.children}
    </>
  );
};

export default TechnicalInformation;
