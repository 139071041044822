import HttpService from '@jibin/common/services/http/HttpService';
export const PlanAndBillingApi = {
  ContactUs(user_id: string, company_id: string, data: any) {
    //return HttpService.getAll("todos/1");

    return HttpService.putAll(
      `companies/${company_id}/users/${user_id}/settings/plan-and-billing`,
      data
    );
  },
  Invoices(companyId: string, userId: string, params: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/invoices?` + params)
  },
  GetInvoicePDF(companyId: string, userId: string, invoiceId: string) {
    return HttpService.getDocument(`companies/${companyId}/users/${userId}/invoices/${invoiceId}/pdf`)
  },
};
