import React, { useState } from 'react'
import HelpSquareIcon from '../../../icons/HelpSquareIcon'
import { SwipeableDrawer } from '@mui/material';
import ResourceCenter from './ResourceCenter'

type Anchor = 'top' | 'left' | 'bottom' | 'right';


const HelpCenter = () => {

    const [state, setState] = useState({
        right: false
    });

    const toggleDrawer = (anchor: Anchor, open: boolean, row?: any) => {
        if (!open) {
        }
        setState({ ...state, [anchor]: open });
    };

    return (
        <>
            <HelpSquareIcon openPopup={() => toggleDrawer('right', true)} />

            <SwipeableDrawer
                BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
                sx={{
                    '.MuiDrawer-paper': {
                        boxShadow: 'none'
                    }
                }}
                anchor={'right'}
                open={state['right']}
                onClose={() => toggleDrawer('right', false)}
                onOpen={() => toggleDrawer('right', true)}>
                <ResourceCenter
                    closePopup={() => toggleDrawer('right', false)}
                    state={state}
                />
            </SwipeableDrawer>
        </>
    )
}

export default HelpCenter