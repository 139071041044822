import React from 'react';

function MachineIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_20499_1611)">
        <path
          d="M11.0239 14.6663H6.35726C4.35726 14.6663 3.02393 13.333 3.02393 11.333V4.66634C3.02393 2.66634 4.35726 1.33301 6.35726 1.33301H11.0239C13.0239 1.33301 14.3573 2.66634 14.3573 4.66634V11.333C14.3573 13.333 13.0239 14.6663 11.0239 14.6663Z"
          stroke="#436AF3"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.02393 10H14.3573"
          stroke="#436AF3"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.02393 8H5.69059"
          stroke="#436AF3"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.02393 6.33301H5.69059"
          stroke="#436AF3"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.02393 4.66699H5.69059"
          stroke="#436AF3"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3536 12.1663H11.3596"
          stroke="#436AF3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_20499_1611">
          <rect width="16" height="16" fill="white" transform="translate(0.357178)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MachineIcon;
