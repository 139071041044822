import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useContext } from 'react';
import { CompnayManageMentContex } from '..';
import CompanyManagementContactTable from '../../CompanyManagementContactTable';
import { NoDataAvailable } from 'src/Pages/Common/NoDataAvailable';

const CompanyManagementContacts: FC = () => {
  const { companyData } = useContext(CompnayManageMentContex);
  return companyData === null ? (
    <NoDataAvailable title="No Data Available" message="This company has not been onboarded" />
  ) : (
    <Box>
      <Box sx={{ py: 1.5 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#464646', py: 2 }}>
          {companyData?.name}
        </Typography>
      </Box>
      <CompanyManagementContactTable />
    </Box>
  );
};
export default CompanyManagementContacts;
