import { useState } from 'react';
import { Button, Checkbox, Typography, Menu, MenuItem } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

function FilterDropdown(props) {
  const { setFilterData, filterData, toolsOption, filterAssets, statusOption } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [subMenuAnchorElTools, setSubMenuAnchorElTools] = useState(null);
  const [subMenuAnchorElStatus, setSubMenuAnchorElStatus] = useState(null);

  const handleMainMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubMenuClick = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };
  const handleSubMenuClickTools = (event) => {
    setSubMenuAnchorElTools(event.currentTarget);
  };
  const handleSubMenuClickStatus = (event) => {
    setSubMenuAnchorElStatus(event.currentTarget);
  };
  const handleCloseMain = () => {
    setAnchorEl(null);
  }

  const handleClose = () => {
    setSubMenuAnchorEl(null);
    setSubMenuAnchorElTools(null);
    setSubMenuAnchorElStatus(null);
  };

  const handleMenuItemClick = (type, item) => {
    setFilterData((prevState) => {
      const updatedArray = [...prevState[type]];
      const itemIndex = updatedArray.indexOf(item);
      if (itemIndex !== -1) {
        updatedArray.splice(itemIndex, 1);
      } else {
        updatedArray.push(item);
      }
      return {
        ...prevState,
        [type]: updatedArray
      };
    });
  };

  return (
    <div className="multi-dropdown">
      <Button aria-controls="main-menu" aria-haspopup="true" onClick={handleMainMenuClick}>
        <FilterAltOutlinedIcon /> +
      </Button>
      <Menu id="main-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMain}>
        <MenuItem sx={{ fontWeight: 600 }} onClick={handleSubMenuClick}>
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Assets
        </MenuItem>
        <MenuItem sx={{ fontWeight: 600 }} onClick={handleSubMenuClickTools}>
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Tools
        </MenuItem>
        <MenuItem sx={{ fontWeight: 600 }} onClick={handleSubMenuClickStatus}>
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Status
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={subMenuAnchorEl}
        open={Boolean(subMenuAnchorEl)}
        onClose={handleClose}
        sx={{ height: '350px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, padding: '8px' }}
          variant="body1">
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Assets
        </Typography>
        {filterAssets?.map((el: any, index) => (
          <MenuItem
            onClick={() => handleMenuItemClick('assets', el)}
            sx={{ fontSize: '14px' }}
            value={el}
            autoFocus={index === 0}>
            <Checkbox checked={filterData.assets.includes(el)} /> {el}
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={subMenuAnchorElTools}
        open={Boolean(subMenuAnchorElTools)}
        onClose={handleClose}
        sx={{ height: '350px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, padding: '8px' }}
          variant="body1">
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Tools
        </Typography>
        {toolsOption?.map((el: any, index) => (
          <MenuItem
            onClick={() => handleMenuItemClick('tool_uuids', el?.value)}
            sx={{ fontSize: '14px' }}
            value={el?.value}
            autoFocus={index === 0}>
            <Checkbox checked={filterData.tool_uuids.includes(el?.value)} /> {el?.name}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={subMenuAnchorElStatus}
        open={Boolean(subMenuAnchorElStatus)}
        onClose={handleClose}
        sx={{ height: '350px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, padding: '8px' }}
          variant="body1">
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Status
        </Typography>
        {statusOption?.map((el: any, index) => (
          <MenuItem
            onClick={() => handleMenuItemClick('statuses', el?.value)}
            sx={{ fontSize: '14px' }}
            value={el?.value}
            autoFocus={index === 0}>
            <Checkbox checked={filterData.statuses.includes(el?.value)} /> {el?.key}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default FilterDropdown;
