import ColorDot from '@jibin/common/components/ColorDot';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import OnboardingIcon from '@jibin/common/icons/OnboardingIcon';
import Style from '@jibin/common/style/Styles';
import { Grid, Box, Typography } from '@mui/material';

const ActivityBoxes = (props: any) => {
  const { icon, heading, users, sessions } = props;
  return (
    <Grid item xs={6} sm={3}>
      <Box
        sx={{
          border: '1px solid #EBEBEB',
          bgcolor: '#FFFFFF',
          borderRadius: 1,
          display: 'flex',
          p: 2.5,
          gap: 2
        }}>
        <Box>
          <Box sx={Style.PortalActivity.CircleWithIcon}> {icon}</Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            flexGrow: 1,
            overflow: 'hidden',
            width: 'max-content'
          }}>
          <EllipsisContent
            variant="body1"
            maxWidth={'content-fit'}
            sx={{
              color: '#696969',
              fontWeight: 600,
              width: 'content-fit'
            }}
            text={heading}
          />

          <Grid container>
            <Grid item xs={6}>
              <Box
                sx={{
                  borderRadius: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  py: 0.5
                }}>
                <Box>
                  <Typography variant="body1" sx={{ fontSize: '23px', fontWeight: 700 }}>
                    {users}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <ColorDot color={'#10B981'} />
                  <Typography
                    variant="caption"
                    sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#9D9D9D' }}>
                    {' '}
                    Users
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  borderRadius: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  py: 0.5
                }}>
                <Box>
                  <Typography variant="body1" sx={{ fontSize: '23px', fontWeight: 700 }}>
                    {sessions}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <ColorDot color={'#FDE68A'} />
                  <Typography
                    variant="caption"
                    sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#9D9D9D' }}>
                    {' '}
                    Sessions
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};
export default ActivityBoxes;
