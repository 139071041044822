import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import FileIcon from '@jibin/common/icons/FileIcon/FileIcon';
import GallaryIcon from '@jibin/common/icons/GallaryIcon/GallaryIcon';
import PinIcon from '@jibin/common/icons/PinIcon/PinIcon';
import UploadIcon from '@jibin/common/icons/UploadIcon/UploadIcon';
import VideoPlay from '@jibin/common/icons/VideoPlay/VideoPlay';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import Shareicon from '@jibin/common/icons/Shareicon/Shareicon';
import {
  Box,
  List,
  Typography,
  Button,
  TextField,
  ListItem,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  IconButton
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { GapAnalysisApi } from '../../GapAnalysis.Api';
import { notifySuccess } from '@jibin/common/services/http/http-common';
import { useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { CompanyUserRoles } from '@jibin/common/utils/constants/ConstantName';
var debounce = require('lodash.debounce');
export interface GapAnalysisSideBarUploadDocProp {
  rowData: any;

  dataForSideBar: any;

  closePopup(event: React.KeyboardEvent | React.MouseEvent, toggled?: boolean): void;
}
const GapAnalysisSideBarUploadDoc: FC<GapAnalysisSideBarUploadDocProp> = (prop) => {
  const [fileData, setFileData] = useState([] as any);

  const [error, setError] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([{ name: 'xyz' }]);
  const { rowData, closePopup, dataForSideBar } = prop;
  const [postData, setPostData] = useState<any>({ answer: '' });
  const [isUploade, setIsUpload] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [postDataEngineer, setPostDataEngineer] = useState({
    status: 'pending',
    severity: 'high',
    title: '',
    description: '',
    recommendation: ''
  });
  const [values, setValues] = useState('high');
  const [isAnswerButtonLoading, setIsAnswerButtonLoading] = useState(false);
  const [isFileRemoveButtonLoading, setIsFileRemoveButtonLoading] = useState(false);
  const [stateRowData, setStateRowData] = useState(rowData);
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  useEffect(() => {
    setStateRowData(rowData);
  }, [rowData]);

  function niceBytes(x) {
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }
  const handleChangeEngineer = (e: any) => {
    if (e.target.name == 'recommendation') {
      if (e.target.value.length < 1000) {
        setPostDataEngineer({ ...postDataEngineer, [e.target.name]: e.target.value });
      } else {
        setPostDataEngineer({
          ...postDataEngineer,
          [e.target.name]: postDataEngineer.recommendation
        });
      }
    } else {
      setPostDataEngineer({ ...postDataEngineer, [e.target.name]: e.target.value });
    }
  };

  const handleChange = (event: any) => {
    setError(false);
    if (event.target.value.length < 1000) {
      setPostData({ ...postData, answer: event.target.value });
    } else {
      setPostData({ ...postData, answer: postData.answer });
    }
  };
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const handleRemoveFile = (i) => {
    const upadatedFileData = fileData.filter((el, index) => index != i);
    setFileData(upadatedFileData);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      let files = [];
      setIsAnswerButtonLoading(true);

      for (let i = 0; i < fileData.length; i++) {
        if (fileData[i].size > 10485760) {
          toast.error('Please upload file with size less than 10 MB');

          return;
        }
        files.push({
          file_name: fileData[i].name,
          file_size: fileData[i].size,
          file_type: fileData[i].type
        });
      }
      let data;

      if (rowData?.evidences) {
        files = [...rowData?.evidences, ...files];
        data = {
          ...rowData,

          description: postData.answer,
          evidences: files
        };
      } else {
        files = files;
        data = {
          description: postData.answer,

          evidences: files
        };
      }

      const res = await GapAnalysisApi.UploadGroupDocuments(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        dataForSideBar?.control_uuid,
        data
      );

      await GapAnalysisApi.UploadDocuments(res, fileData);

      closePopup(e);
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        setIsAnswerButtonLoading(false);
      }, 1000);
    }
  };

  const handleImageUpload = (e, files) => {
    stopReload(e);

    if (!e) return;

    setFileData([...fileData, ...files]);
  };

  const stopReload = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleBrowseEvent = (e: any) => {
    setFileData([...fileData, ...e.target.files]);
  };

  const handleDragEvent = (e: any) => {
    handleImageUpload(e, e.dataTransfer.files);
  };

  const getFile = async (el) => {
    const res = await GapAnalysisApi.GetFile(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      dataForSideBar?.control_uuid,
      rowData.id,
      el.id
    );
    console.log(el);
    const a = document.createElement('a');
    a.href = res?.data.data;
    a.target = '_blank';
    a.download = `${el.file_name}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const removeFile = async (e, el) => {
    try {
      setIsFileRemoveButtonLoading(true);
      stopReload(e);
      const res = await GapAnalysisApi.RemoveFile(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        dataForSideBar?.control_uuid,
        rowData.id,
        el.id
      );

      const filterFile = stateRowData?.answer?.files?.filter((element) => element.id !== el.id);

      setStateRowData({
        ...stateRowData,
        answer: {
          ...stateRowData?.answer,
          evidences: filterFile
        }
      });
    } catch (error) {
    } finally {
      setTimeout(() => {
        setIsFileRemoveButtonLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    setPostData({ ...postData, answer: rowData?.description });
    // setPostDataEngineer({ ...postDataEngineer, ...rowData?.answer?.feedback });

    setUploadedFiles([]);
    setIsUpload(false);
    return () => {
      setPostData({});
      setFileData([]);
    };
  }, [commonDetails, prop, rowData]);

  return (
    <>
      <Box
        sx={{
          width: { md: 520, xs: 300 },

          position: 'relative',
          minHeight: '100vh',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
        role="presentation">
        <List sx={{ p: 0 }}>
          <Box sx={{ backgroundColor: '#003260', px: 2.5, py: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 1,
                alignItems: 'center'
              }}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 600, fontSize: '19px', color: 'white' }}>
                  {dataForSideBar?.controle_id}: {dataForSideBar?.controle_title}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  onClick={(event) => {
                    closePopup(event);
                  }}>
                  <CrossCancle
                    sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </List>
        {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
          <List
            sx={{
              p: 3
            }}>
            <Box>
              <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
                Upload Evidence
              </Typography>
              <Box
                onDragOver={(e) => stopReload(e)}
                onDrop={(e) => handleDragEvent(e)}
                sx={{
                  width: '100%',
                  backgroundColor: '#FBFBFF',
                  py: 4,
                  display: 'flex',
                  border: '1px dashed #CCCCCC',
                  borderRadius: '8px',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2.5
                }}>
                <UploadIcon />
                <Typography variant="body1" sx={{ fontWeight: 600, color: '#464646' }}>
                  Drag & Drop or{' '}
                  <label style={{ color: '#436AF3', cursor: 'pointer' }}>
                    <input
                      multiple
                      hidden
                      onChange={(e) => handleBrowseEvent(e)}
                      style={{
                        backgroundColor: 'transparent',
                        color: 'transparent'
                      }}
                      type={'file'}
                    />{' '}
                    Choose file{' '}
                  </label>
                  to upload
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                    width: '100%',
                    alignItem: 'center',
                    m: 'auto'
                  }}>
                  {fileData?.length == 0 || isUploade ? (
                    <Typography textAlign={'center'} variant="body2" sx={{ color: '#787878' }}>
                      Document, Image or Video
                    </Typography>
                  ) : (
                    fileData?.map((el, index) => (
                      <Box
                        key={index + 1}
                        sx={{
                          display: 'flex',
                          width: '80%',
                          m: 'auto',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          p: 1.5,
                          borderRadius: '8px',
                          backgroundColor: '#FFFFFF',
                          border: '1px solid #EBEBEB'
                        }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {el.type.split('/')[0] == 'image' ? (
                            <GallaryIcon />
                          ) : el.type.split('/')[0] == 'video' ? (
                            <VideoPlay />
                          ) : (
                            <FileIcon />
                          )}

                          <EllipsisContent
                            sx={{
                              fontSize: 14,
                              color: '#2D2D2D',
                              width: 'max-content',
                              maxWidth: {
                                xxs: 150,
                                md: 150
                              }
                            }}
                            text={el.name}
                          />

                          <Typography variant="body2" sx={{ color: '#787878' }}>
                            {niceBytes(el.size)}
                          </Typography>
                        </Box>
                        <Box onClick={() => handleRemoveFile(index)}>
                          <CrossCancle
                            sx={{
                              background: 'rgba(120, 120, 120,0.1)',
                              width: '20px',
                              height: '20px',
                              color: '#787878',
                              p: '6px',
                              borderRadius: '50%',
                              cursor: 'pointer'
                            }}
                          />
                        </Box>
                      </Box>
                    ))
                  )}
                </Box>
              </Box>
            </Box>
            {/* )} */}
          </List>
        )}

        <List sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, mb: 2 }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 600,
                mb: 1.25,
                color: '#2D2D2D',
                fontSize: '19px',
                display: 'flex',
                gap: 1
              }}></Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 600,
                mb: 1.25,
                color: '#2D2D2D',
                fontSize: '19px',
                textAlign: 'justify'
              }}>
              {rowData.question}
            </Typography>
          </Box>

          <Box sx={{ position: 'relative' }}>
            <Box>
              {' '}
              <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
                Evidence Description
              </Typography>
            </Box>
            <TextField
              autoComplete={'none'}
              error={error}
              size="small"
              disabled={commonDetails?.data?.company?.user_role === CompanyUserRoles.AUDITOR}
              helperText={error && 'This field is Required'}
              value={postData?.answer ? postData?.answer : ''}
              onChange={handleChange}
              sx={{ fontSize: '14px', textAlign: 'justify', width: 1 }}
              placeholder="Type your answer here..."
            />
          </Box>

          <Box></Box>
        </List>
        <List sx={{ p: 3, flexGrow: 1 }}>
          {stateRowData?.evidences?.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
                Files:
              </Typography>

              <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 1.5 }}>
                {stateRowData?.evidences?.map((el, index) => (
                  <Box sx={{ display: 'flex', alignItems: 'center' }} key={index}>
                    <Box
                      onClick={() => getFile(el)}
                      sx={{
                        display: 'flex',
                        gap: 1,
                        alignItems: 'center',
                        backgroundColor: '#D9EDFF',
                        py: '5px',
                        px: 1.5,
                        borderRadius: '100px',
                        cursor: 'pointer',
                        height: 1
                      }}>
                      <PinIcon />
                      <Typography
                        variant="body1"
                        sx={{ color: '#436AF3', display: 'flex', alignItems: 'center', gap: 1 }}>
                        <EllipsisContent text={el.file_name} maxWidth={377} />

                        {process.env.REACT_APP_SHOW_PARTIAL_FEATURES == 'true' && (
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => removeFile(e, el)}
                            disabled={isFileRemoveButtonLoading}>
                            <Close
                              sx={{
                                fontSize: '18px'
                              }}
                            />
                          </IconButton>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </List>
        {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
          <Box
            sx={{
              textAlign: 'end',

              mx: 3,
              py: 1,
              right: 0
            }}>
            <LoadingButton
              loading={isAnswerButtonLoading}
              onClick={handleSubmit}
              disabled={!postData.answer}
              sx={{
                justifySelf: 'flex-end',
                minWidth: 163,
                backgroundColor: '#436AF3',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#436AF3'
                },
                '&.Mui-disabled': {
                  backgroundColor: '#EBEBEB',
                  color: isAnswerButtonLoading ? '#EBEBEB' : '#9D9D9D'
                }
              }}>
              Save
            </LoadingButton>
          </Box>
        )}
      </Box>
    </>
  );
};
export default GapAnalysisSideBarUploadDoc;
