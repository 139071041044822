import Style from '@jibin/common/style/Styles';
import { Button, Menu, Skeleton, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import SearchIcon from '@jibin/common/icons/Search/Search';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FC, useState, useContext, useCallback, useEffect, useMemo } from 'react';

import DropDown from '@jibin/common/icons/Dropdown/Dropdown';
import { useNavigate } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';

import { shallowEqual, useSelector } from 'react-redux';
import CompanyManagementTable from './CompanyManagementTable';
import HeadingHeader from '../Common/HeadingHeader';

var debouce = require('lodash.debounce');
export const CompanyManagement: FC = (props: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const nevigate = useNavigate();
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [search, setSearch] = useState({ keyword: '', status: '' });
  const [isRefresh, setIsRefresh] = useState(false);
  const handleSearch = (e: any) => {
    const name: any = e.target.name;
    const item = { ...search };
    item[name] = e.target.value;
    setSearch(item);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const renderColorBackGround = (severity: any = 'active') => {
    // Active = Green
    // Bypass = Red
    // Deregistered = Yellow
    // Errors = Red
    // Inactive = Red
    // Pending Install = Red
    // Pending Update = Yellow
    // Quarantine = Red
    // Sensor Out of Date = Red
    var color = '#27AE60';
    switch (severity.toLowerCase()) {
      case 'active':
        color = '#27AE60';
        break;

      case 'bypass':
      case 'errors':
      case 'inactive':
      case 'pending install':
      case 'quarantine':
      case 'sensor out of date':
        color = '#EF4444';
        break;

      case 'deregistered':
      case 'pending':
        color = '#FCD34D';
        break;
    }

    return color;
  };
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  useEffect(() => {
    if (commonDetails?.data?.company?.type != 'engineering') {
      nevigate(PageRouteConstants.DashBoard);
    }
  }, [commonDetails]);
  return (
    <>
      <HeadingHeader subtitle1={'Company Management'} subtitle2={'Manage easily your companies in one place'} />

      <Box sx={{ px: 4, py: { xs: 4, md: 3 } }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <TextField
            placeholder="Search Company"
            hiddenLabel
            size="small"
            name="keyword"
            value={search.keyword}
            onChange={handleSearch}
            sx={{ mr: 2, bgcolor: '#FFFFFF' }}
            inputProps={{ sx: { py: '10px', fontSize: '12px' } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <Select
            size="small"
            displayEmpty
            id="type"
            value={search.status}
            onChange={handleSearch}
            defaultValue={'-1'}
            name="status"
            sx={{
              fontSize: '14px',
              color: '#696969',
              minWidth: { xxs: 0, md: '188px' },
              backgroundColor: '#FFFFFF'
            }}
            inputProps={{ 'aria-label': 'Without label' }}>
            <MenuItem sx={{ fontSize: '14px' }} value="Active">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    width: '14px',
                    height: '14px',
                    backgroundColor: renderColorBackGround('Active'),
                    borderRadius: '50%'
                  }}></Box>
                <Typography variant="caption">Active</Typography>
              </Box>
            </MenuItem>
            <MenuItem sx={{ fontSize: '14px' }} value="Inactive">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    width: '14px',
                    height: '14px',
                    backgroundColor: renderColorBackGround('inactive'),
                    borderRadius: '50%'
                  }}></Box>
                <Typography variant="caption">Inactive</Typography>
              </Box>
            </MenuItem>
            <MenuItem sx={{ fontSize: '14px' }} value="Pending">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    width: '14px',
                    height: '14px',
                    backgroundColor: renderColorBackGround('pending'),
                    borderRadius: '50%'
                  }}></Box>
                <Typography variant="caption">Pending</Typography>
              </Box>
            </MenuItem>
            <MenuItem sx={{ fontSize: '14px' }} value="">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    width: '14px',
                    display: 'none',
                    height: '14px',
                    backgroundColor: renderColorBackGround('active'),
                    borderRadius: '50%'
                  }}></Box>
                <Typography variant="caption">All</Typography>
              </Box>
            </MenuItem>
            <MenuItem sx={{ fontSize: '14px', display: 'none' }} key="-1" value="-1">
              Status
            </MenuItem>
          </Select>
        </Box>

        {/*         
            <Box
              sx={{ display: 'flex', justifyContent: 'center', width: '100%', m: 'auto', my: 5 }}>
              <Box sx={{ textAlign: 'center' }}>
                <img width={'50%'} src="/images/NoSearchFound.png" />
                <Typography variant="h2" sx={{ fontWeight: 600 }}>
                  No Search Results
                </Typography>
                <Typography sx={{ mt: 2, textAlign: 'center', color: '#696969' }} variant="body2">
                  Try adjusting your search or filter to find what you are looking for
                </Typography>
              </Box>
            </Box> */}

        <CompanyManagementTable search={search} isSkeleton={isSkeleton} isRefresh={isRefresh} />
      </Box>
    </>
  );
};

export default CompanyManagement;
