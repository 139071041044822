import { useState } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  Typography,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  Tooltip
} from '@mui/material';
import moment from 'moment';
import TablePagination from '../Common/TablePagination';
import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { TickMarkBlue } from '@jibin/common/icons/Tickmark/Tickmark';
import CheckBoxOutLine from '@jibin/common/icons/CheckBoxOutLine/CheckBoxOutLine';
import CheckBoxLightBlue from '@jibin/common/icons/CheckBoxLightBlue/CheckBoxLightBlue';
import DeleteDialog from './DeleteDialog';
import { useNavigate } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';

const RowData = (props: any) => {
  const {
    row,
    index,
    setOpenDelete,
    handleRowClick,
    stopImmediatePropagation,
    toggleDrawer,
    setRowData,
    rowData,
    setIsCreateRemediation,
    handleClickCheck,
    isItemSelected,
    commonDetails
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const openDD = Boolean(anchorEl);
  const handleClick = (event: any, row?: any) => {
    setRowData(row);
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const handleClose = () => {
    setRowData(null);
    setAnchorEl(null);
  };
  return (
    <>
      <TableRow
        id={row?.asset_uuid}
        key={row?.asset_uuid + index}
        onClick={(e) => handleRowClick(e, row)}
        sx={{
          cursor: 'pointer',
          bgcolor: '#fff',
          '.MuiTableCell-root': {
            px: '20px',

            borderBottom: '1px solid  #CCCCCC',
            borderTopRadius: '4px',
            borderTop: '1px solid  #CCCCCC',
            '&:last-child': {
              borderRight: '1px solid  #CCCCCC',
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px'
            },
            '&:first-child': {
              borderLeft: '1px solid  #CCCCCC',
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px'
            }
          }
        }}>
        <TableCell>
          <Box
            key={row.name}
            onClick={(event) => {
              // if (!isDataLoaded) {
              handleClickCheck(event, row?.asset_uuid);
              stopImmediatePropagation(event);
              // }
            }}
            role="checkbox"
            // aria-checked={isItemSelected}
            tabIndex={-1}
            padding="checkbox">
            <Checkbox
              // disabled={isDataLoaded}
              checked={isItemSelected}
              checkedIcon={<CheckBoxLightBlue />}
              inputProps={
                {
                  // 'aria-labelledby': labelId
                }
              }
              icon={
                <CheckBoxOutLine
                  sx={{
                    fill: 'none',
                    width: '21px',
                    height: '20px'
                  }}
                />
              }
            />
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <Typography sx={{ fontWeight: 600 }} variant="body2">
                {row?.name}
              </Typography>
              <Typography variant="body2">{row?.type}</Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Typography sx={{ fontWeight: 600 }} variant="body2">
            {'NinjaOne'}
          </Typography>
          <Typography variant="body2">{row?.agent_version}</Typography>
        </TableCell>
        <TableCell align="left">
          {row?.last_check_in && moment(row?.last_check_in).format('DD MMM YYYY').toUpperCase()}
        </TableCell>
        <TableCell>{row?.last_login_user}</TableCell>
        <TableCell>
          <>
            <Tooltip arrow placement="top" title={row?.ip_address}>
              <Typography variant="body2">
                {row?.ip_address.length > 1 ? row?.ip_address[0] : row?.ip_address}
              </Typography>
            </Tooltip>
            {row?.ip_address.length > 1 && (
              <span style={{ color: '#436AF3' }}>+{row?.ip_address.length - 1}</span>
            )}
          </>
        </TableCell>
        <TableCell>
          <>
            <Tooltip arrow placement="top" title={row?.mac_address}>
              <Typography variant="body2">
                {row?.mac_address.length > 1 ? row?.mac_address[0] : row?.mac_address}
              </Typography>
            </Tooltip>
            {row?.mac_address.length > 1 && (
              <span style={{ color: '#436AF3' }}>+{row?.mac_address.length - 1}</span>
            )}
          </>
        </TableCell>
        <TableCell align="left">
          <Typography variant="caption">
            {' '}
            {row.status == 'Healthy' ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    minWidth: '14px',
                    height: '14px',
                    width: '14px',
                    backgroundColor: '#10B981',
                    borderRadius: '50%'
                  }}></Box>
                <Typography variant="caption">Healthy</Typography>
              </Box>
            ) : row.status == 'Unhealthy' ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    minWidth: '14px',
                    width: '14px',
                    height: '14px',
                    backgroundColor: '#F87171',
                    borderRadius: '50%'
                  }}></Box>
                <Typography variant="caption">Unhealthy</Typography>
              </Box>
            ) : row.status == 'Unknown' ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    minWidth: '14px',
                    width: '14px',
                    height: '14px',
                    backgroundColor: '#B7B7B7',
                    borderRadius: '50%'
                  }}></Box>
                <Typography variant="caption">Unknown</Typography>
              </Box>
            ) : row.status == 'Alert' ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    minWidth: '14px',
                    width: '14px',
                    height: '14px',
                    backgroundColor: '#FCD34D',
                    borderRadius: '50%'
                  }}></Box>
                <Typography variant="caption">Alert</Typography>
              </Box>
            ) : null}
          </Typography>
        </TableCell>
        <TableCell>
          <IconButton
            onClick={(e) => {
              handleClick(e, row);
            }}
            id="basic-button"
            aria-controls={openDD ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openDD ? 'true' : undefined}>
            <MoreHorizIcon color="primary" />
          </IconButton>
          {(row?.remediation_uuid === '' || commonDetails?.data?.group !== 'customer') && (
            <Menu
              anchorEl={anchorEl}
              id={'account-menu'}
              open={openDD}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
              {!!row?.remediation_uuid ? (
                <MenuItem
                  onClick={() => {
                    navigate({
                      pathname: PageRouteConstants.Remediation,
                      search: `remediation_uuids=${row.remediation_uuid}`
                    });
                  }}>
                  <TickMarkBlue />
                  <Typography sx={{ ml: 1 }} variant="caption">
                    {' '}
                    Open Remediation
                  </Typography>
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    setIsCreateRemediation(true);
                    setAnchorEl(null);
                    toggleDrawer('right', true, rowData);
                  }}>
                  <TickMarkBlue />
                  <Typography sx={{ ml: 1 }} variant="caption">
                    {' '}
                    Create Remediation
                  </Typography>
                </MenuItem>
              )}
              {commonDetails?.data?.group !== 'customer' && (
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setOpenDelete(true);
                  }}>
                  <DeleteOutlineOutlinedIcon sx={{ color: '#EF4444' }} />
                  <Typography sx={{ ml: 1 }} variant="caption">
                    {' '}
                    Delete Record
                  </Typography>
                </MenuItem>
              )}
            </Menu>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

const AssetListTable = (props) => {
  const {
    assetsList,
    totalCount,
    page,
    setRowsPerPage,
    rowsPerPage,
    setPage,
    handleRowClick,
    stopImmediatePropagation,
    toggleDrawer,
    setRowData,
    rowData,
    setIsCreateRemediation,
    handleClickCheck,
    isSelected,
    selectedAssetList,
    isMultipleDelete,
    setIsMultipleDelete,
    GetAssets,
    handleSelectAllClick,
    commonDetails
  } = props;

  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => {
    setOpenDelete(false);
    setIsMultipleDelete(false);
    setRowData(null);
  };
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ border: 'none', boxShadow: 'none', bgcolor: 'transparent' }}>
        <Table
          sx={{
            minWidth: 650,
            borderCollapse: 'separate',
            boxShadow: 'none',
            borderSpacing: '0px 16px',
            border: 'none'
          }}
          className="p10Table"
          aria-label="site list table">
          <TableHead>
            <TableRow sx={{ '.MuiTableCell-root': { border: 'none' } }}>
              <TableCell sx={{ px: '20px' }}>
                <Checkbox
                  indeterminate={
                    selectedAssetList?.length > 0 && selectedAssetList?.length < assetsList?.length
                  }
                  indeterminateIcon={
                    selectedAssetList?.length > 0 &&
                    selectedAssetList?.length < assetsList?.length && (
                      <CheckBoxOutLine
                        sx={{
                          fill: 'none',
                          width: '21px',
                          height: '20px'
                        }}
                      />
                    )
                  }
                  checked={
                    assetsList?.length > 0 && selectedAssetList?.length === assetsList?.length
                  }
                  onChange={handleSelectAllClick}
                  checkedIcon={<CheckBoxLightBlue />}
                  icon={
                    <CheckBoxOutLine
                      sx={{
                        fill: 'none',
                        width: '21px',
                        height: '20px'
                      }}
                    />
                  }
                />
              </TableCell>
              <TableCell>
                <Typography sx={{ fontWeight: 500 }} variant="body2">
                  Asset
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontWeight: 500 }} variant="body2">
                  Tool
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontWeight: 500 }} variant="body2">
                  Last Check-in
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontWeight: 500 }} variant="body2">
                  Last Login User
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontWeight: 500 }} variant="body2">
                  IP Address
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">MAC Address</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Status</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2"></Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assetsList?.map((row, index) => {
              const isItemSelected = isSelected(row?.asset_uuid);
              return (
                <RowData
                  row={row}
                  index={index}
                  setOpenDelete={setOpenDelete}
                  handleRowClick={handleRowClick}
                  stopImmediatePropagation={stopImmediatePropagation}
                  toggleDrawer={toggleDrawer}
                  setRowData={setRowData}
                  rowData={rowData}
                  setIsCreateRemediation={setIsCreateRemediation}
                  handleClickCheck={handleClickCheck}
                  isItemSelected={isItemSelected}
                  commonDetails={commonDetails}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        totalCount={totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <DeleteDialog
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        handleCloseDelete={handleCloseDelete}
        rowData={rowData}
        selectedAssetList={selectedAssetList}
        isMultipleDelete={isMultipleDelete}
        GetAssets={GetAssets}
        commonDetails={commonDetails}
      />
    </>
  );
};

export default AssetListTable;
