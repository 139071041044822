function IpIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_20499_2747)">
        <path
          d="M4.60391 6.34044C1.88391 6.53377 1.89057 10.4738 4.60391 10.6671H11.0439C11.8239 10.6738 12.5773 10.3805 13.1573 9.85379C15.0639 8.18712 14.0439 4.85376 11.5306 4.53376C10.6306 -0.892907 2.77058 1.16711 4.63058 6.34044"
          stroke="#436AF3"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.35718 10.667V12.667"
          stroke="#436AF3"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.35726 15.3337C9.09364 15.3337 9.69059 14.7367 9.69059 14.0003C9.69059 13.2639 9.09364 12.667 8.35726 12.667C7.62088 12.667 7.02393 13.2639 7.02393 14.0003C7.02393 14.7367 7.62088 15.3337 8.35726 15.3337Z"
          stroke="#436AF3"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.3571 14H9.69043"
          stroke="#436AF3"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.02384 14H4.35718"
          stroke="#436AF3"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_20499_2747">
          <rect width="16" height="16" fill="white" transform="translate(0.357178)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IpIcon;
