const useFilterRoutes = (servicesDetials, location) => {
  const filter = servicesDetials.filter((el) => el.route.split('/')[1] == el?.supported_route);
  // selectedItem.split('/')[1] == service?.supported_route;
  if (filter.length > 0) {
    return true;
  } else {
    return false;
  }
};
export default useFilterRoutes;
