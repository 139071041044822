import HttpService from '@jibin/common/services/http/HttpService';

export const PanetrationApi = {
  // User section
  // PenetrationTests(userId: string, companyId: string) {
  //   //return HttpService.getAll("todos/1");
  //   return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/penetration/tests`);
  // },
  PenetrationStats(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/penetration/stats`);
  },
  PenetrationStatsData(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/penetration/remediation/stats`);
  },
 

}