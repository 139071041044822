import HttpService from '@jibin/common/services/http/HttpService';

export const SIEMApi = {
  GetAllEndPoint(companyId: string, userId: string, params: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/events?` + params);
  },
  GetAllDeviceDetails(userId: string, companyId: string, event_id: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/siem/events/${event_id}`);
  },
  MakeFalsePositive(userId: string, companyId: string, event_ids: string) {
    //return HttpService.getAll("todos/1");
    // /companies/{company_id}/users/{user_id}/events/mark-false-positive
    return HttpService.patchAll(
      `companies/${companyId}/users/${userId}/events/mark-false-positive?event_uuids=` + event_ids,
      {}
    );
  },
  PostRemediationData(userId: string, companyId: string, event_id: string, data: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.post(
      `companies/${companyId}/users/${userId}/siem/events/${event_id}/remediations`,
      data
    );
  },
  GetServices(companyId: string, userId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/v2/services`);
  },
  GetTools(companyId: string, userId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/v2/tools`);
  },
  GetToolsByService(companyId: string, userId: string, serviceId: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/v2/services/${serviceId}/tools`
    );
  },
  GetToolsByServiceV2(companyId: string, userId: string, serviceId: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/v2/vendor-tools?service_ids=` + serviceId
    );
  },
  CreateEvent(companyId: string, userId: string, data: any) {
    return HttpService.post(`companies/${companyId}/users/${userId}/events`, data);
  },
  PutEvent(companyId: string, userId: string, eventId: string, data: any) {
    return HttpService.putAll(`companies/${companyId}/users/${userId}/events/${eventId}`, data);
  },
  RemoveEvent(companyId: string, userId: string, eventId: string) {
    return HttpService.remove(`companies/${companyId}/users/${userId}/events`, eventId);
  },
  EventAssets(companyId: string, userId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/event-assets`);
  },
  VendorTools(companyId: string, userId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/v2/vendor-tools`);
  },
  CreateRemediation(companyId: string, userId: string, data: any) {
    return HttpService.post(`companies/${companyId}/users/${userId}/events/remediation`, data);
  },
  GetEventSummary(companyId: string, userId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/event/summary`);
  }
};
