import styled from '@emotion/styled';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  Skeleton
} from '@mui/material';
import { Box } from '@mui/system';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';

import { FC, useCallback, useContext, useEffect, useState } from 'react';
// import BorderLinearProgress from '@jibin/common/components/LinearLoading';
import BorderLinearProgress from '@jibin/common/components/LinearLoading/BorderLinearGreen';
import CheckCircle from '@jibin/common/icons/CheckCircle/CheckCircle';
import CheckCircleBorderLess from '@jibin/common/icons/CheckCircle/CheckCircleBorderLess';
import GapAnalysisForm from '../GapAnalysisForm';
import SearchIcon from '@jibin/common/icons/Search/Search';
import { shallowEqual, useSelector } from 'react-redux';
import { GapAnalysisApi } from '../GapAnalysis.Api';
import { useLocation, useSearchParams } from 'react-router-dom';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import FilterCancelIcon from '@jibin/common/icons/FilterCancelIcon';
import ExpandMoreIcon from '@jibin/common/icons/ExpandMore/ExpandMore';
import { NoSearchDataAvailable, NoDataAvailable } from '../../Common/NoDataAvailable';
import { gapContex } from '..';
import DownArrow from '@jibin/common/icons/DownArrow/DownArrow';
import DownloadIcon from '@jibin/common/icons/DownloadIcon/DownloadIcon';
import { LoadingButton } from '@mui/lab';
import { CompanyUserRoles } from '@jibin/common/utils/constants/ConstantName';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

const GapAnalysis: FC = () => {
  const [value, setValue] = useState(0);
  const [useParams, setUseParam] = useSearchParams();
  const { engineer } = useContext(gapContex);
  const [controles, setControles] = useState([] as any);
  const [frameWorks, setFrameWorks] = useState([] as any);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const handleChangeControle = (el: any, control_uuid: any) => {
    setParams(el?.domain_uuid);

    setSubParams(control_uuid);

    setControles(el.controls);
  };

  const selectCategory = (index, el) => {
    setParams(el?.domain_uuid);

    setValue(index);
    setCategory(el);
  };
  const setSubCategory = (index, el) => {
    setSubParams(el?.control_uuid);

    setValue(index);
    setCategory(el);
  };
  const setParams = (index) => {
    const params = {
      q: index
    };
    setMultiplaleParams(params);
  };
  const setMultiplaleParams = (params: any) => {
    for (const key in params) {
      useParams.set(key, params[key]);
      setUseParam(useParams);
    }
  };
  const setSubParams = (index) => {
    const params = {
      controle: index
    };
    setMultiplaleParams(params);
  };

  const [completedScore, setCompletedScore] = useState(0);
  const [category, setCategory] = useState({} as any);
  const [isFunctionCalled, setIsFuctionCalled] = useState(false);
  const [categories, setCategories] = useState([] as any);
  const [isAllComplete, setIsAllComplete] = useState(false);
  const [initResults, setInitResults] = useState(true);
  const initialFilterData =
    commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR
      ? {
          framework: [''],
          control_status: ['']
        }
      : {
          framework: [''],
          control_status: [
            'Not Applicable',
            'Partially Meets Control',
            'Does Not Meet Control',
            'Meets Control'
          ]
        };

  const initialGapFilterData =
    commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR
      ? {
          framework: '',
          control_status: '',
          keyword: ''
        }
      : {
          framework: '',
          control_status:
            'Not Applicable,Partially Meets Control,Does Not Meet Control,Meets Control',
          keyword: ''
        };

  const [filterData, setFilterData] = useState(initialFilterData);
  const [gapFilterData, setGapFilterData] = useState(initialGapFilterData);

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        fontSize: '14px',
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 180
      }
    }
  };

  const handleChangeFilter = (event: any) => {
    let {
      target: { value, name }
    } = event;
    const filterValue = value.filter((el: any) => el != '');

    let string;
    if (filterValue.length == 0) {
      string = '';
      const dataForApi = { ...gapFilterData };

      dataForApi[name] = string;
      setGapFilterData(dataForApi);
      const item = { ...filterData };

      item[name] = typeof value === 'string' ? filterValue.split(',') : [''];

      setFilterData(item);
    } else {
      string = filterValue.join(',');
      const dataForApi = { ...gapFilterData };

      dataForApi[name] = string;
      setGapFilterData(dataForApi);
      const item = { ...filterData };

      item[name] = typeof value === 'string' ? filterValue.split(',') : filterValue;

      setFilterData(item);
    }
  };
  const location = useLocation();
  const handleSearchTextFeild = (e) => {
    setGapFilterData({ ...gapFilterData, [e.target.name]: e.target.value });
  };

  const GetCategories = (isFilter: any) => {
    GapAnalysisApi.GetCategories(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      new URLSearchParams({
        ...gapFilterData,
        control_status:
          commonDetails?.data?.company?.user_role === CompanyUserRoles.AUDITOR &&
          !gapFilterData?.control_status
            ? 'Not Applicable,Partially Meets Control,Does Not Meet Control,Meets Control'
            : gapFilterData?.control_status
      }).toString()
    ).then(
      (q: any) => {
        setIsFuctionCalled(true);
        setCategories(q.data.data);
        setControles(q.data.data[0]?.controles);

        if (useParams.get('q')) {
          const filterdData = q.data.data?.filter(
            (el, index) => el.domain_uuid == useParams.get('q')
          );
          const findeIndex = q.data.data.findIndex(
            (el, index) => el.domain_uuid == useParams.get('q')
          );
          if (findeIndex != -1 && !isFilter) {
            setExpanded(`panel${findeIndex + 1}`);
            if (q.data.data[0]) {
              const findControle = filterdData[0].controls?.filter(
                (el, index) => el?.control_uuid == useParams.get('controle')
              );
              const findIndex = filterdData[0].controls?.findIndex(
                (el, index) => el?.control_uuid == useParams.get('controle')
              );
              selectCategory(findeIndex, filterdData[0]);
              if (findIndex != -1) {
                handleChangeControle(filterdData[0], findControle[0].control_uuid);

                setSubCategory(findIndex, findControle[0]);
              } else {
                handleChangeControle(filterdData[0], filterdData[0].controls[0].control_uuid);
                setSubCategory(0, filterdData[0].controls[0]);
              }
            }
          } else {
            if (q?.data?.data.length) {
              setExpanded(`panel${1}`);
              selectCategory(0, q?.data?.data?.[0]);
              handleChangeControle(
                q?.data?.data?.[0],
                q?.data?.data?.[0].controls?.[0]?.control_uuid
              );
              if (q.data.data[0]) {
                setSubCategory(0, q?.data?.data?.[0]?.controls?.[0]);
              }
              setInitResults(true);
            } else {
              if (!isFilter) {
                setInitResults(false);
              }
            }
          }
        } else {
          selectCategory(0, q?.data?.data?.[0]);

          if (q.data.data[0]) {
            handleChangeControle(
              q?.data?.data?.[0],
              q?.data?.data?.[0]?.controls?.[0]?.control_uuid
            );
            setSubCategory(0, q?.data?.data?.[0]?.controls?.[0]);
          }
        }
        let total = 0;
        let completed = 0;

        q.data.data.forEach((element) => {
          completed += element.stats.completed;
          total += element.stats.total;
        });
        let isAllComplete = q.data.data?.filter((q) => q.stats.total != q.stats.completed).length;
        if (isAllComplete < 1) {
          setIsAllComplete(true);
        } else {
          setIsAllComplete(false);
        }
        setCompletedScore(Math.round((completed / total) * 100));
        setIsSkeleton(false);
      },
      (err: any) => {
        setIsFuctionCalled(true);
        setIsSkeleton(false);
      }
    );
  };
  const handleExportGap = () => {
    setIsLoading(true);

    GapAnalysisApi.GetExportData(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      new URLSearchParams({
        ...gapFilterData,
        control_status:
          commonDetails?.data?.company?.user_role === CompanyUserRoles.AUDITOR &&
          !gapFilterData?.control_status
            ? 'Not Applicable,Partially Meets Control,Does Not Meet Control,Meets Control'
            : gapFilterData?.control_status
      }).toString()
    ).then(
      (q: any) => {
        const blob = new Blob([q.data], { type: q.headers['content-type'] });
        console.log(q.headers);
        // Creating an object for downloading url
        console.log(q.headers);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `GapAnalysis_2.0_Report.xlsx`;
        a.target = '_blank';
        // a.download = q.headers[`Content-Disposition`].split('=')[1]; // use the name from response
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setIsLoading(false);
      },
      (err: any) => {
        setIsLoading(false);
      }
    );
  };
  const handleClearFuntion = () => {
    let item = { ...filterData };

    item = { ...item, control_status: [''], framework: [''] };
    setGapFilterData({
      framework: '',
      control_status: '',
      keyword: ''
    });
    setFilterData(item);
  };

  const FilterStatus = [
    {
      key: 'Fully Meets',
      value: 'Meets Control'
    },
    {
      key: 'Select Control Status',
      value: ''
    },
    {
      key: 'Does Not Meet',
      value: 'Does Not Meet Control'
    },
    {
      key: 'Partially Meets',
      value: 'Partially Meets Control'
    },
    {
      key: 'Not Submitted',
      value: 'Not Submitted'
    },
    {
      key: 'Not Applicable',
      value: 'Not Applicable'
    }
  ];

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChangeAccordian =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setValue(0);
      setExpanded(isExpanded ? panel : false);
    };

  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });

    return selectedLabels.join(', ');
  };
  useEffect(() => {
    GetCategories(false);
  }, [commonDetails]);
  useEffect(() => {
    if (isFunctionCalled) {
      GetCategories(true);
    }
  }, [gapFilterData]);
  useEffect(() => {
    getFrameWorks();
  }, [commonDetails]);
  const getFrameWorks = async () => {
    try {
      const res = await GapAnalysisApi.GetFrameWorks(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );
      const keyValueFrameWork = res?.data?.data
        ?.filter((el: any) => el?.enabled)
        ?.map((el: any) => {
          return {
            key: el?.name,
            value: el?.name
          };
        });

      keyValueFrameWork.push({
        key: 'Select Framework',
        value: ''
      });
      setFrameWorks(keyValueFrameWork);
    } catch (error) {}
  };

  return (
    <Box>
      <Box sx={{ p: 4 }}>
        <Box>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: 600, my: 1 }}>
              Filter Controls
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, mb: 3, alignItems: 'center' }}>
            <FormControl sx={{ width: 250 }}>
              <Select
                size="small"
                displayEmpty
                multiple
                name="framework"
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={filterData.framework}
                onChange={handleChangeFilter}
                input={
                  <OutlinedInput
                    sx={{
                      py: '5.5px'
                    }}
                  />
                }
                renderValue={(selected) => renderValue(selected, frameWorks)}
                MenuProps={MenuProps}
                sx={{
                  py: 0.35,
                  fontSize: '14px',
                  color: '#696969',
                  '.MuiSelect-select': {
                    py: '5.5px'
                  },
                  minWidth: { xxs: 0, md: '150px' },
                  backgroundColor: '#FFFFFF'
                }}
                inputProps={{ 'aria-label': 'Without label' }}>
                {frameWorks.map((name) => (
                  <MenuItem
                    sx={{
                      fontSize: '14px',
                      display: !name.value ? 'none' : 'flex',

                      py: 0,
                      '.MuiMenuItem-root': {
                        py: '0px'
                      }
                    }}
                    key={name.key}
                    value={name.value}>
                    <Checkbox checked={filterData.framework.indexOf(name.value) > -1} />
                    <EllipsisContent maxTextWidth={150} text={name.key} sx={{ fontSize: '14px' }} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: 250 }}>
              <Select
                size="small"
                displayEmpty
                multiple
                name="control_status"
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={filterData.control_status}
                onChange={handleChangeFilter}
                input={<OutlinedInput />}
                renderValue={(selected) => renderValue(selected, FilterStatus)}
                MenuProps={MenuProps}
                sx={{
                  py: 0.35,
                  fontSize: '14px',
                  '.MuiSelect-select': {
                    py: '5.5px'
                  },
                  color: '#696969',
                  minWidth: { xxs: 0, md: '150px' },
                  backgroundColor: '#FFFFFF'
                }}
                inputProps={{ 'aria-label': 'Without label' }}>
                {FilterStatus?.filter((el) => {
                  if (
                    commonDetails?.data?.company?.user_role === CompanyUserRoles.AUDITOR &&
                    el?.value !== 'Not Submitted'
                  ) {
                    return el;
                  } else if (commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR) {
                    return el;
                  }
                })?.map((name) => (
                  <MenuItem
                    sx={{
                      fontSize: '14px',
                      py: 0,
                      display: !name.value ? 'none' : 'flex',
                      '.MuiMenuItem-root': {
                        py: '0px'
                      }
                    }}
                    key={name.key}
                    value={name.value}>
                    <Checkbox checked={filterData.control_status.indexOf(name.value) > -1} />

                    <EllipsisContent maxTextWidth={150} text={name.key} sx={{ fontSize: '14px' }} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box>
              <LoadingButton
                loading={isLoading}
                onClick={handleExportGap}
                sx={{ display: 'flex', gap: 0.5, alignItems: 'center', py: 0.5 }}
                variant="outlined">
                {' '}
                <DownloadIcon />
                Export List
              </LoadingButton>
            </Box>
            <Box>
              <Button
                onClick={handleClearFuntion}
                sx={{ display: 'flex', gap: 0.5, alignItems: 'center', py: 0.5 }}
                variant="text">
                {' '}
                <FilterCancelIcon /> Clear filter
              </Button>
            </Box>
          </Box>

          <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
            {filterData?.framework
              ?.filter((el: any) => el != '')
              ?.map((el: any, index: any) => (
                <Chip
                  variant="outlined"
                  sx={{ bgcolor: '#D9EDFF', border: 'none' }}
                  label={
                    frameWorks[frameWorks.findIndex((element: any) => element.value == el)]?.key
                  }
                  onDelete={() => {
                    const findEelemt = filterData?.framework?.filter(
                      (element: any) => element != el
                    );
                    const event = {
                      target: {
                        value: findEelemt,
                        name: 'framework'
                      }
                    };

                    handleChangeFilter(event);
                  }}
                />
              ))}
            {filterData?.control_status
              ?.filter((el: any) => el != '')
              ?.map((el: any, index: any) => (
                <Chip
                  variant="outlined"
                  sx={{ bgcolor: '#D9EDFF', border: 'none' }}
                  label={
                    FilterStatus[FilterStatus.findIndex((element: any) => element.value == el)]?.key
                  }
                  onDelete={() => {
                    const findEelemt = filterData?.control_status?.filter(
                      (element: any) => element != el
                    );
                    const event = {
                      target: {
                        value: findEelemt,
                        name: 'control_status'
                      }
                    };

                    handleChangeFilter(event);
                  }}
                />
              ))}
          </Box>
        </Box>

        {isSkeleton ? (
          <Box sx={{ px: 4, py: 3 }}>
            <Skeleton sx={{ p: 5 }} />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={3.25}>
              <Box
                sx={{
                  border: '1px solid #D5D5D5',
                  // height: '73vh',
                  minHeight: '100vh',
                  maxHeight: '100vh',
                  overflowY: 'hidden',
                  borderRadius: 2,
                  backgroundColor: '#FFFFFF'
                }}>
                <Box>
                  <Box sx={{ mb: 2, px: 2, pt: 2 }}>
                    <Typography sx={{ fontWeight: 600, color: '#696969' }} variant="body2">
                      Gap Analysis Progress
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}>
                      <Box
                        sx={{
                          width: 0.8,

                          p: 0.5,
                          borderRadius: '10px',
                          backgroundColor: '#F2F9FF'
                        }}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={completedScore}
                          sx={{
                            '.MuiLinearProgress-bar': {
                              borderRadius: 5,
                              backgroundColor: '#059669'
                            }
                          }}
                        />
                      </Box>
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        {completedScore
                          ? Math.round((completedScore + Number.EPSILON) * 10) / 10
                          : 0}
                        %
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ px: 2 }}>
                    <TextField
                      id="keyword"
                      name="keyword"
                      value={gapFilterData.keyword}
                      onChange={(e) => {
                        handleSearchTextFeild(e);
                      }}
                      InputProps={{
                        sx: { fontSize: '14px', height: '36px' },
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                      sx={{ width: 1, minWidth: 0 }}
                      placeholder="Search Category"
                    />
                  </Box>
                  {categories?.length == 0 &&
                    (initResults ? (
                      <Typography variant="caption" sx={{ color: '#EF4444', px: 2 }}>
                        No Search Results
                      </Typography>
                    ) : (
                      <Typography variant="caption" sx={{ color: '#EF4444', px: 2 }}>
                        No Results Found
                      </Typography>
                    ))}

                  <Box
                    sx={{
                      flexGrow: 1,
                      mt: 2,
                      mb: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      overflow: 'auto',
                      minHeight: '100vh',
                      maxHeight: '100vh'
                    }}>
                    {categories.map((el: any, index: any) => (
                      <Accordion
                        expanded={expanded === `panel${index + 1}`}
                        onChange={handleChangeAccordian(`panel${index + 1}`)}
                        sx={{
                          px: 2,

                          boxShadow: 'none',
                          '&.MuiAccordion-root:before': {
                            position: 'relative'
                          },
                          '&.MuiPaper-root:before': {
                            position: 'relative'
                          }
                        }}
                        disableGutters>
                        <AccordionSummary
                          onClick={() => handleChangeControle(el, el?.controls[0].control_uuid)}
                          sx={{
                            p: 0,
                            m: 0,
                            alignItems: 'center',
                            display: 'flex',
                            '&:hover': {
                              bgcolor: '#f4f4f4'
                            },
                            bgcolor: useParams.get('q') == el?.domain_uuid ? '#F2F9FF' : '#FFFFFF'
                          }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index + 1}bh-content`}
                          id={`panel${index + 1}bh-header`}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',

                              gap: 1,
                              cursor: 'pointer',
                              wordWrap: 'break-word'
                            }}>
                            {useParams.get('q') != el?.domain_uuid ? (
                              el.stats.completed != 0 && el.stats.total != el.stats.completed ? (
                                <Box sx={{ width: '20px', height: '20px' }}>
                                  <CheckCircleBorderLess color="#B7B7B7" />
                                </Box>
                              ) : el.stats.total == el.stats.completed ? (
                                <Box sx={{ width: '20px', height: '20px' }}>
                                  <CheckCircleBorderLess color="#27AE60" />
                                </Box>
                              ) : (
                                <Box sx={{ width: '20px', height: '20px' }}>
                                  <CheckCircle
                                    sx={{
                                      fontSize: '20px',
                                      height: '20px',
                                      width: '20px',
                                      borderRadius: '50%',
                                      bgcolor: '#B7B7B7',

                                      color: '#FFF'
                                    }}
                                  />
                                </Box>
                              )
                            ) : (
                              <Box sx={{ width: '20px', height: '20px' }}>
                                <CheckCircle
                                  sx={{
                                    bgcolor: '#436AF3',
                                    fontSize: '20px',
                                    borderRadius: '50%',
                                    color: '#FFF'
                                  }}
                                />
                              </Box>
                            )}{' '}
                            {/* color: useParams.get('q') != el?.domain_uuid ? el.stats.total ==
                          el.stats.completed ? '#27AE60' : '#464646' : '#436AF3' */}
                            <Typography
                              sx={{
                                whiteSpace: 'normal'
                              }}
                              variant={'body2'}>
                              {el?.name}
                              <span>
                                ({el?.stats?.completed}/{el?.stats?.total})
                              </span>
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            TabIndicatorProps={{
                              style: {
                                width: 0
                              }
                            }}
                            scrollButtons={false}
                            onChange={handleChange}
                            aria-label="Vertical tabs example">
                            {el?.controls?.map((el, index) => (
                              <Tab
                                icon={<CheckCircle sx={{ color: value != index && '#27AE60' }} />}
                                iconPosition="start"
                                sx={{
                                  textJustify: 'start',
                                  display: 'flex',
                                  p: 0,
                                  mb: 2,
                                  minHeight: 0,
                                  minWidth: 0,
                                  justifyContent: 'flex-start',
                                  textAlign: 'start',

                                  textTransform: 'none'
                                }}
                                component={() => (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      mb: 2,
                                      gap: 1,
                                      '&:hover': {
                                        bgcolor: '#f4f4f4'
                                      },
                                      bgcolor:
                                        useParams.get('controle') != el?.control_uuid
                                          ? '#FFFFFF'
                                          : '#F2F9FF',
                                      cursor: 'pointer',
                                      wordWrap: 'break-word'
                                    }}>
                                    {useParams.get('controle') != el?.control_uuid ? (
                                      el.stats.completed != 0 &&
                                      el.stats.total != el.stats.completed ? (
                                        <Box sx={{ width: '20px', height: '20px' }}>
                                          <CheckCircleBorderLess color="#B7B7B7" />
                                        </Box>
                                      ) : el.stats.total == el.stats.completed ? (
                                        <Box sx={{ width: '20px', height: '20px' }}>
                                          <CheckCircleBorderLess color="#27AE60" />
                                        </Box>
                                      ) : (
                                        <CheckCircle
                                          sx={{
                                            fontSize: '20px',
                                            borderRadius: '50%',
                                            bgcolor: '#B7B7B7',

                                            color: '#FFF'
                                          }}
                                        />
                                      )
                                    ) : (
                                      <Box sx={{ width: '20px', height: '20px' }}>
                                        <CheckCircle
                                          sx={{
                                            bgcolor: '#436AF3',
                                            fontSize: '20px',
                                            borderRadius: '50%',
                                            color: '#FFF'
                                          }}
                                        />
                                      </Box>
                                    )}{' '}
                                    <Typography
                                      sx={{
                                        whiteSpace: 'normal'
                                      }}
                                      variant={'body2'}
                                      onClick={() => setSubCategory(index, el)}>
                                      <span style={{ fontWeight: 600 }}> {el?.control_id}:</span>
                                      {el?.control_title}
                                    </Typography>
                                  </Box>
                                )}
                                label={`${el.category}(${el.stats.completed}/${el.stats.total} )`}
                                {...a11yProps(index)}
                              />
                            ))}
                          </Tabs>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={8.75}>
              {controles?.length == 0 || typeof controles == 'undefined' ? (
                initResults ? (
                  <NoSearchDataAvailable
                    title="No Search Results"
                    message="Try adjusting your search or filter to find what you are looking for"
                  />
                ) : (
                  <NoDataAvailable title="No Results Found" />
                )
              ) : (
                <Box
                  sx={{
                    maxHeight: '72vh',
                    minHeight: '60vh'
                  }}>
                  {controles?.map((el, index) => (
                    <TabPanel value={value} index={index}>
                      <GapAnalysisForm
                        el={el}
                        selectCategory={setSubCategory}
                        index={index}
                        filteredCategories={controles}
                        length={controles?.length}
                        value={value}
                        setValue={setValue}
                        GetCategories={() => GetCategories(false)}
                        isEngineer={engineer}
                        isAllComplete={isAllComplete}
                      />
                    </TabPanel>
                  ))}
                </Box>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};
export default GapAnalysis;
