import SuccessConfirmationUpadated from '@jibin/common/components/ConformationModel/SuccessConfirmationUpadated';

import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import ExpandMoreIcon from '@jibin/common/icons/ExpandMore/ExpandMore';

import Shareicon from '@jibin/common/icons/Shareicon/Shareicon';
import LinkIcon from '@jibin/common/icons/LinkIcon';

import Style from '@jibin/common/style/Styles';

import {
  Box,
  List,
  Typography,
  Tabs,
  Tab,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Divider,
  ListItem,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListSubheader,
  IconButton,
  Autocomplete,
  InputAdornment,
  Skeleton,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { resolve } from 'dns';
import moment from 'moment';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useFormik } from 'formik';

import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { useNavigate } from 'react-router-dom';
import { notifySuccess } from '@jibin/common/services/http/http-common';
import { LoadingButton } from '@mui/lab';
import { ConstantName } from '@jibin/common/utils/constants/ConstantName';
import { NetwrokDetectionApi } from '../NetwrokDetection.Api';
import { GetAssignImage } from '@jibin/common/utils/Common';
import CopyUrl from '@jibin/common/components/ConformationModel/CopyUrl';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import CloseIcon from '@jibin/common/icons/Close/Close';
import { SOURCE } from '@jibin/common/utils/enums/SourceTypeEnums';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import { RemediationApi } from 'src/Pages/Remediation/Remediation.Api';

const momentTimezone = require('moment-timezone');
var debounce = require('lodash.debounce');

const NetwrokDetectionSideBarEngineer: FC<{
  rowData: any;
  assignees: any;
  isCreateRemediation: boolean;
  onClose(event: React.KeyboardEvent | React.MouseEvent): void;
}> = (prop) => {
  const [create, setCreate] = useState(false);
  const [compalate, setComplate] = useState(true);
  const [remediationData, setRemediationData] = useState<any>({});
  const [datasaved, setDataSaved] = useState(false);
  const [option, setOption] = useState([]);
  const [skeleton, setSkeleton] = useState(false);
  const [isRemediationButtonLoading, setIsRemediationButtonLoading] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteOption, setDeleteOption] = useState('');
  const [tagErrorMessage, setTagErrorMessage] = useState({ maxLimit: '', tagLimit: '' });
  const [facilities, setFacilities] = useState([]);
  const [assets, setAssets] = useState([]);

  const AssetList = [
    { key: 'Select Asset', value: '', placeholder: '' },

    { key: 'Domain/URL', value: 'hostname', placeholder: 'Eg: www.redesign-group.com' },
    { key: 'IP Address', value: 'ip', placeholder: 'Eg: 192.158.1.38' },
    { key: 'MAC Address', value: 'mac', placeholder: 'Eg: 00-B0-D0-63-C2-26' },
    {
      key: 'Machine/Server Name',
      value: 'machine',
      placeholder: 'Eg: Teletraan'
    },
    { key: 'Other', value: 'custom', placeholder: 'Type here' }
  ];

  const navigate = useNavigate();
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [deviceData, setDeviceData] = useState<any>({});

  const validate = (value: any) => {
    const errors: any = {};
    const isEmpty = value.Assets.every(item => item.trim() === '');
    const requiredFields = ["hostname", "ip", "mac", "machine", "custom"];

    for (const field of requiredFields) {
      if (value.Assets.includes(field) && !value[field]) {
        setComplate(true);
        return; 
      }
    }

    if (!value.description || !value.issue_name || isEmpty) {
      setComplate(true);
    } else {
      setComplate(false);
    }

    return errors;
  };

  const GetTags = useCallback(async () => {
    const query: any = {
      include_non_associated_tags: true
    };
    try {
      const res = await RemediationApi.TagsList(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        new URLSearchParams(query).toString()
      );

      if (res.data.data) {
        const TagList = res.data.data.map((el) => el);
        setTagOptions(TagList);
      }
    } catch (error) {}
  }, [commonDetails]);

  const formik = useFormik({
    initialValues: {
      issue_name: '',
      assign: null,
      description: '',
      recommendation: '',
      Assets: [''],
      tags: [],
      source: 'network_protection',
      severity: 'medium'
    },
    validate,
    onSubmit: useCallback(
      debounce(
        async () => {
          try {
            setIsRemediationButtonLoading(true);
            const data = {
              issue_name: formik.values.issue_name,
              description: formik.values.description,
              recommendation: formik.values.recommendation,
              severity: formik.values.severity,
              owner_uuid: formik?.values?.assign?.user_uuid,
              source: formik.values.source,
              asset: {
                ip: formik.values.ip,
                mac: formik.values.mac,
                custom: formik.values.custom,
                machine: formik.values.machine,
                hostname: formik.values.hostname ? formik.values.hostname.split(',') : []
              },
              tags: facilities
            };
            const res = await NetwrokDetectionApi.PostRemediationData(
              commonDetails.data.user_uuid,
              commonDetails.data.company.company_uuid,
              prop?.rowData?.id,
              data
            );
            setRemediationData(res.data.data);

            setDataSaved(true);
            setCreate(false);
          } catch (error) {
          } finally {
            setTimeout(() => {
              setIsRemediationButtonLoading(false);
            }, 1000);
          }
        },
        500,
        true
      ),
      [compalate, deviceData, commonDetails, facilities]
    )
  });

  const renderOption = (assignees) => {
    const options = assignees.data.map((option) => {
      const firstLetter = option.company_name.toUpperCase();

      const data = option?.users.map((el: any) => {
        return { firstLetter: firstLetter, ...el };
      });
      return data;
    });

    const optionarray = options.concat.apply([], options).flat();
    setOption(optionarray);
  };

  const renderColor = (severity: any) => {
    var color = '#F87171';
    if (severity == 'critical') {
      color = '#F87171';
    } else if (severity == 'suspicious') {
      color = '#F59E0B';
    } else if (severity == 'compliance') {
      color = '#FBBF24';
    } else if (severity == 'low') {
      color = '#436AF3';
    }
    return color;
  };

  useEffect(() => {
    if (prop.rowData) {
      if (prop?.rowData?.remediations?.length != 0) {
        const sortRemediation = prop?.rowData?.remediations?.sort((a: any, b: any) => {
          return a?.created_at < b?.created_at ? 1 : -1;
        });
        if (sortRemediation)
          if (sortRemediation[0]?.status == 'resolved') {
            setRemediationData({});
          } else {
            setRemediationData(sortRemediation[0]);

            let severity = prop?.rowData?.severity || 'compliance';
            switch (prop?.rowData?.severity) {
              case 'critical':
                severity = 'critical';
                break;
              case 'suspicious':
                severity = 'high';
                break;
              case 'compliance':
                severity = 'medium';
                break;
            }
            formik.setFieldValue('source', 'network_protection');
            formik.setFieldValue('severity', severity);
          }
      }

      setDataSaved(false);
      renderOption(prop.assignees);
      GetTags();
      setAssets([]);
    }
    if (prop.isCreateRemediation) {
      handleCreate();
      setDataSaved(false);
    } else {
      setCreate(false);
    }
    return () => {
      setRemediationData({});
      setDataSaved(false);
      formik.resetForm();
      renderOption(prop.assignees);
    };
  }, [prop]);

  const renderColorBackGround = (severity: any = 'active') => {
    // Active = Green
    // Bypass = Red
    // Deregistered = Yellow
    // Errors = Red
    // Inactive = Red
    // Pending Install = Red
    // Pending Update = Yellow
    // Quarantine = Red
    // Sensor Out of Date = Red
    var color = '#27AE60';
    switch (severity.toLowerCase()) {
      case 'active':
        color = '#27AE60';
        break;

      case 'bypass':
      case 'errors':
      case 'inactive':
      case 'pending install':
      case 'quarantine':
      case 'sensor out of date':
        color = '#EF4444';
        break;

      case 'deregistered':
      case 'pending update':
        color = '#FCD34D';
        break;
    }

    return color;
  };
  const handleCreate = () => {
    if (!formik.errors.flag && create) {
      setDataSaved(true);
    }
    setCreate(true);
    let severity = prop?.rowData?.severity || 'compliance';
    switch (severity) {
      case 'critical':
        severity = 'critical';
        break;
      case 'suspicious':
        severity = 'high';
        break;
      case 'compliance':
        severity = 'medium';
        break;
      case 'low':
        severity = 'low';
        break;
    }
    formik.setFieldValue('source', 'network_protection');
    formik.setFieldValue('severity', severity);
  };

  const handleChangeAsset = (event: any) => {
    let {
      target: { value, name }
    } = event;
    const filterValue = value.filter((el: any) => el != '');
    // setFormikValue(filterValue);
    setAssets(filterValue);
    let string;
    if (filterValue.length == 0) {
      string = '';
      const item = typeof value === 'string' ? filterValue.split(',') : [''];

      formik.setFieldValue('Assets', item);
    } else {
      string = filterValue.join(',');

      const item = typeof value === 'string' ? filterValue.split(',') : filterValue;
      formik.setFieldValue('Assets', item);
    }
    // if (value.length > formik?.values?.Assets?.length) {
    //   setComplate(true);
    // }
  };

  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected?.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });

    return selectedLabels?.join(', ');
  };

  const RenderAssetList = (assest: any) => {
    let data = [];
    if (assest?.length > 0) {
      data = AssetList.filter((el: any) => {
        return el?.value && assest?.includes(el.value) && el;
      });
    } else {
      data = [];
    }

    return data;
  };
  const handleClickOpenDelete = (option, value) => {
    setDeleteOption(option);

    formik.setFieldValue('tags', value);
    setOpenDelete(true);
  };
  const maketags = (data: any) => {
    const facility = [];

    data.forEach((element) => {
      let facilitytype = tagOptions.filter((q) => q.tag_name == element);
      if (facilitytype?.length > 0) {
        facility.push({ tag_name: element, tag_uuid: facilitytype[0].tag_uuid });
      } else {
        facility.push({ tag_name: element });
      }
    });
    setFacilities([...facility]);
  };
  const handleTagChange = (event, value) => {
    var newArray = value.filter(function (elem, pos) {
      return value.indexOf(elem) == pos;
    });
    if (tagErrorMessage.maxLimit) {
      setTagErrorMessage({
        ...tagErrorMessage,
        maxLimit: ''
      });
      return;
    }

    formik.setFieldValue('tags', newArray);
    maketags(newArray);
  };
  function validateString(inputString) {
    if (!/^[a-zA-Z_\s]+$/.test(inputString)) {
      return false;
    }
    if (/^\d+$/.test(inputString)) {
      return false;
    }
    return true;
  }
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleConfirmDelete = (deleteFacility) => {
    const updatedFacility = formik.values.tags.filter((el, index) => el !== deleteFacility);
    formik.setFieldValue('tags', updatedFacility);

    maketags(updatedFacility);
    setOpenDelete(false);
  };

  const renderBackGroundColor = (severity: any = 'critical') => {
    var color = '#FFE6E2';
    if (severity == 'high') {
      color = '#FCD34D';
    } else if (severity == 'critical') {
      color = '#FFE6E2';
    } else if (severity == 'medium') {
      color = '#FEF3C7';
    } else if (severity == 'low') {
      color = '#D0DAFC';
    } else if (severity == 'status') {
      color = '#EBEBEB';
    }
    return color;
  };

  if (skeleton) {
    return <Box></Box>;
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          width: { md: 520, xs: 300 },
          position: 'relative',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }}
        role="presentation">
        <List sx={{ p: 0 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: 1,
              justifyContent: 'space-between',
              backgroundColor: '#003260',
              px: 2.5,
              alignItems: 'center',
              py: 1
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',

                width: 1,
                alignItems: 'center'
              }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700, color: 'white', my: 1.25 }}>
                ID: {prop?.rowData?.id}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CopyUrl
                  title="Copy and share this page"
                  handleClose={() => {}}
                  icon={<Shareicon />}
                  mainBtnTitle={''}
                  url={window.location.href}
                  handleConfirm={() => {
                    navigator.clipboard.writeText(window.location.href);
                    notifySuccess('Copied');
                  }}
                />

                <Box
                  onClick={(event) => {
                    prop.onClose(event);
                  }}>
                  <CrossCancle
                    sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </List>
        <Box sx={{ p: 3, flex: 1 }}>
          {!create && (
            <Box>
              <List>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600 }}>
                    Incident Status
                  </Typography>
                  <Box
                    sx={{
                      py: 1,
                      px: 2,
                      border: '1px solid #EBEBEB',
                      borderRadius: '100px'
                    }}>
                    {' '}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '14px',
                          height: '14px',
                          backgroundColor: renderColor(prop?.rowData?.severit),
                          borderRadius: '50%'
                        }}></Box>
                      <Typography variant="caption" sx={{ textTransform: 'capitalize' }}>
                        {' '}
                        {prop?.rowData?.severity}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </List>
              <List>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600 }}>
                    Incident Description
                  </Typography>
                </Box>
                <Box sx={{ py: 2 }}>
                  <Typography variant="body2">{prop?.rowData?.summary}</Typography>
                </Box>
              </List>
              <List>
                {' '}
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    '.MuiButtonBase-root,.MuiAccordionSummary-root.Mui-expanded': {
                      minHeight: '46.22px'
                    }
                  }}>
                  <AccordionSummary
                    sx={{
                      border: '1px solid #EBEBEB',
                      borderRadius: '4px',

                      '.Mui-expanded': {
                        my: 0,
                        minHeight: 0
                      }
                    }}
                    // sx={{
                    //   p: 3,
                    //   '.MuiAccordionSummary-content': {
                    //     m: 0
                    //   }
                    // }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600 }}>
                      Additional Information
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ px: 0 }}>
                    <Box sx={Style.EndPointProtection.EndPointDataTable}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ minWidth: 200 }}>
                          <Typography variant="body2" sx={{ color: '#696969' }}>
                            Device
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body2" sx={{ color: '#696969' }}>
                            {prop?.rowData?.hostname}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ minWidth: 200 }}>
                          <Typography variant="body2" sx={{ color: '#696969' }}>
                            Received on
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body2" sx={{ color: '#696969' }}>
                            {momentTimezone(prop?.rowData?.received_at)
                              .tz('utc')
                              .format('DD MMM YYYY')
                              .toUpperCase()}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </List>
            </Box>
          )}

          {create && !datasaved && !remediationData?.remediation_uuid && (
            <List sx={{ py: 0 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}>
                <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                  Criticality Level:
                </Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    value={formik.values.severity || 'medium'}
                    name="severity"
                    // defaultValue={formik.values.severity}
                    // onChange={(value:any) => {
                    //   console.log('VALUE ::', value);
                    // }}
                    onChange={formik.handleChange}
                    displayEmpty
                    sx={{
                      '.MuiSelect-select': {
                        py: 1,
                        display: 'flex',
                        alignItems: 'center'
                      },
                      '&.MuiInputBase-root': {
                        backgroundColor: renderBackGroundColor(formik.values.severity)
                      },
                      borderRadius: '100px'
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem sx={{ backgroundColor: '#FFE6E2' }} value="critical">
                      <Box>
                        <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F73939' }} />
                      </Box>
                      <Typography variant="body2">Critical</Typography>
                    </MenuItem>
                    <MenuItem sx={{ backgroundColor: '#FCD34D' }} value="high">
                      <Box>
                        <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F59E0B' }} />
                      </Box>
                      <Typography variant="body2">High</Typography>
                    </MenuItem>

                    <MenuItem sx={{ backgroundColor: '#FEF3C7' }} value={'medium'}>
                      <Box>
                        <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#FBBF24' }} />
                      </Box>
                      <Typography variant="body2">Medium</Typography>
                    </MenuItem>

                    <MenuItem sx={{ backgroundColor: '#D0DAFC' }} value={'low'}>
                      {' '}
                      <Box>
                        <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#436AF3' }} />
                      </Box>
                      <Typography variant="body2">Low</Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <List sx={{ py: 1.5 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 2
                  }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: '#696969', fontSize: '14px', fontWeight: 600 }}>
                    Source
                  </Typography>
                  <Box sx={{ width: 1 }}>
                    <Select
                      size="small"
                      displayEmpty
                      fullWidth
                      id="type"
                      sx={{
                        fontSize: '14px',
                        color: '#696969',
                        minWidth: { xxs: 0, md: '188px' },
                        backgroundColor: '#FFFFFF'
                      }}
                      defaultValue=""
                      disabled
                      name="source"
                      value={formik.values.source}
                      onChange={formik.handleChange}
                      inputProps={{ 'aria-label': 'Without label' }}>
                      <MenuItem value="" sx={{ display: 'none' }}>
                        Select Source
                      </MenuItem>
                      {SOURCE?.map((el: any) => (
                        <MenuItem sx={{ fontSize: '14px' }} value={el?.value}>
                          {el?.key}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
              </List>
              <Box sx={{ my: 1 }}>
                <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600, pb: 2 }}>
                  Issue Name
                </Typography>
                <TextField
                  autoComplete={'none'}
                  size={'small'}
                  value={formik.values.issue_name}
                  onChange={formik.handleChange}
                  name="issue_name"
                  rows={7}
                  sx={{
                    fontSize: '14px',

                    border: 'none',
                    textAlign: 'justify',
                    width: 1,
                    backgroundColor: 'white',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CCCCCC'
                    }
                  }}
                  placeholder="Type here..."
                />
              </Box>
              <List sx={{ pt: 2, pb: 0 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 2
                  }}>
                  <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600 }}>
                    Asset Type
                  </Typography>
                  <Box sx={{ width: 1 }}>
                    <FormControl sx={{ width: 1 }}>
                      <Select
                        size="small"
                        displayEmpty
                        multiple
                        fullWidth
                        placeholder="Select Asset"
                        name="Assets"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        value={formik.values.Assets}
                        onChange={handleChangeAsset}
                        input={
                          <OutlinedInput
                            sx={{
                              py: '5.5px'
                            }}
                          />
                        }
                        renderValue={(selected) => renderValue(selected, AssetList)}
                        sx={{
                          py: 0.35,
                          width: 1,
                          fontSize: '14px',
                          color: '#696969',
                          '.MuiSelect-select': {
                            py: '5.5px'
                          },
                          minWidth: { xxs: 0, md: '150px' },
                          backgroundColor: '#FFFFFF'
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}>
                        {AssetList.map((name, index) => (
                          <MenuItem
                            sx={{
                              fontSize: '14px',
                              display: !name.value ? 'none' : 'flex',

                              py: 0,
                              '.MuiMenuItem-root': {
                                py: '0px'
                              }
                            }}
                            key={name.key}
                            autoFocus={index === 1}
                            value={name.value}>
                            <Checkbox checked={formik?.values?.Assets?.indexOf(name.value) > -1} />
                            <ListItemText
                              sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                              primary={name.key}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </List>

              <List sx={{ py: 0 }}>
                {RenderAssetList(formik?.values?.Assets)?.map((el) => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        my: 3,
                        alignItems: 'flex-start',
                        gap: 2
                      }}>
                      <Typography variant="subtitle2" sx={{ fontSize: '14px', fontWeight: 600 }}>
                        {el?.key}
                      </Typography>
                      <Box sx={{ width: 1 }}>
                        <TextField
                          size="small"
                          name="asset"
                          fullWidth
                          onChange={(e: any) => formik.setFieldValue(el?.value, e.target.value)}
                          value={formik.values[el.value]}
                          inputProps={{ sx: { fontSize: '14px' } }}
                          variant="outlined"
                          placeholder={el?.placeholder}
                          sx={{ fontSize: '14px' }}
                          // defaultValue={rowData?.recommendation}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </List>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: '#696969',
                    fontWeight: 600,
                    pt: assets?.length > 0 ? 0 : 3,
                    pb: 2
                  }}>
                  Description
                </Typography>
                <TextField
                  autoComplete={'none'}
                  multiline
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  name="description"
                  rows={7}
                  sx={{
                    fontSize: '14px',

                    border: 'none',
                    textAlign: 'justify',
                    width: 1,
                    backgroundColor: 'white',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CCCCCC'
                    }
                  }}
                  placeholder="Type your answer here..."
                />
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  sx={{ color: '#696969', fontWeight: 600, pt: 3, pb: 2 }}>
                  Recommendation
                </Typography>
                <TextField
                  autoComplete={'none'}
                  multiline
                  value={formik.values.recommendation}
                  onChange={formik.handleChange}
                  name="recommendation"
                  rows={7}
                  sx={{
                    fontSize: '14px',

                    border: 'none',
                    textAlign: 'justify',
                    width: 1,
                    backgroundColor: 'white',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CCCCCC'
                    }
                  }}
                  placeholder="Type your answer here..."
                />
              </Box>
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 600, color: '#696969', mt: 3, mb: 2 }}>
                    Remediation Owner
                  </Typography>

                  <Typography
                    onClick={() =>
                      formik.setFieldValue('assign', {
                        first_name: commonDetails.data.first_name,
                        last_name: commonDetails.data.last_name,
                        user_uuid: commonDetails.data.user_uuid,
                        ico: commonDetails.data.ico
                      })
                    }
                    variant="body2"
                    sx={{
                      fontWeight: 600,
                      color: '#436AF3',
                      my: 2,
                      cursor: 'pointer'
                    }}>
                    {'Assign to me'}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1.5 }}>
                  <FormControl sx={{ width: '100%' }}>
                    <Autocomplete
                      id="grouped-demo"
                      size="small"
                      value={formik.values.assign}
                      fullWidth={true}
                      options={option}
                      onChange={(e, value) => formik.setFieldValue('assign', value)}
                      groupBy={(option: any) => option.firstLetter}
                      getOptionLabel={(option: any) => {
                        return `${option.first_name} ${option.last_name}`;
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ fontSize: '14px' }}
                          {...params}
                          placeholder="Assign to"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: formik.values.assign ? (
                              <InputAdornment position="start">
                                <Box>
                                  {formik.values.assign.ico ? (
                                    <Box
                                      sx={{
                                        ...Style.versionsCard.infoRound,
                                        width: '24px',
                                        height: '24px',
                                        fontSize: '14px'
                                      }}>
                                      <img
                                        width={'100%'}
                                        height="100%"
                                        style={{
                                          objectFit: 'cover',
                                          borderRadius: '50%'
                                        }}
                                        src={GetAssignImage(
                                          formik.values.assign.firstLetter,
                                          formik.values.assign.ico
                                        )}
                                        alt=""
                                      />
                                    </Box>
                                  ) : (
                                    <Typography
                                      sx={{
                                        ...Style.versionsCard.infoRound,
                                        width: '24px',
                                        height: '24px',
                                        fontSize: '14px'
                                      }}>
                                      {formik.values.assign?.first_name?.charAt(0) +
                                        '' +
                                        formik.values.assign?.last_name?.charAt(0)}
                                    </Typography>
                                  )}
                                </Box>
                              </InputAdornment>
                            ) : (
                              ''
                            )
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          key={option.user_uuid}>
                          <Box>
                            {option.ico ? (
                              <Box
                                sx={{
                                  ...Style.versionsCard.infoRound,
                                  width: '24px',
                                  height: '24px',
                                  fontSize: '14px'
                                }}>
                                <img
                                  width={'100%'}
                                  height="100%"
                                  style={{
                                    objectFit: 'cover',
                                    borderRadius: '50%'
                                  }}
                                  src={GetAssignImage(option.firstLetter, option.ico)}
                                  alt=""
                                />
                              </Box>
                            ) : (
                              <Typography
                                sx={{
                                  ...Style.versionsCard.infoRound,
                                  width: '24px',
                                  height: '24px',
                                  fontSize: '14px'
                                }}>
                                {' '}
                                {option.first_name.charAt(0) + '' + option.last_name.charAt(0)}
                              </Typography>
                            )}
                          </Box>
                          <Typography variant="body2">
                            {' '}
                            {option.first_name} {option.last_name}
                          </Typography>
                        </Box>
                      )}
                      renderGroup={(params) => (
                        <Box key={params.key}>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              padding: '10px 10px'
                            }}>
                            {params.group}
                          </Typography>
                          {params.children}
                        </Box>
                      )}
                    />
                  </FormControl>
                </Box>
              </Box>
              <List sx={{ py: 2 }}>
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
                    Tag
                  </Typography>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    freeSolo
                    options={tagOptions.map((el) => el.tag_name)}
                    value={formik.values.tags}
                    renderTags={(value, getTagProps) => {
                      return value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                          onDelete={() => handleClickOpenDelete(option, value)}
                        />
                      ));
                    }}
                    selectOnFocus
                    onChange={(event: any, newValue, reason) => {
                      if (
                        reason == 'selectOption' ||
                        reason == 'removeOption' ||
                        reason == 'clear'
                      ) {
                        handleTagChange(event, newValue);
                      } else {
                        const optionToSelect = tagOptions.find((option) => {
                          if (
                            option?.tag_name?.toLowerCase() === event.target.value.toLowerCase()
                          ) {
                            return option;
                          }
                        });
                        if (optionToSelect) {
                          handleTagChange(event, [...formik.values.tags, optionToSelect.tag_name]);
                        } else {
                          handleTagChange(event, newValue);
                        }
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        error={tagErrorMessage.maxLimit || tagErrorMessage.tagLimit ? true : false}
                        helperText={
                          tagErrorMessage.maxLimit
                            ? tagErrorMessage.maxLimit
                            : tagErrorMessage.tagLimit
                        }
                        {...params}
                        onChange={(e: any) => {
                          if (e.target.value) {
                            if (e.target.value.startsWith('_')) {
                              setTagErrorMessage({
                                ...tagErrorMessage,
                                maxLimit: `Please Don't Start the tag with (_)`
                              });
                            } else if (!validateString(e.target.value)) {
                              setTagErrorMessage({
                                ...tagErrorMessage,
                                maxLimit: `Please don't add specific characters or numbers just Alphapetical Letters ONLY`
                              });
                            } else if (e.target.value.length > 50) {
                              setTagErrorMessage({
                                ...tagErrorMessage,
                                maxLimit: 'You have reached the maximum limit of characters per Tag'
                              });
                            } else {
                              setTagErrorMessage({
                                ...tagErrorMessage,
                                maxLimit: ''
                              });
                            }
                          } else {
                            setTagErrorMessage({
                              ...tagErrorMessage,
                              maxLimit: ''
                            });
                          }
                        }}
                        placeholder={formik?.values?.tags.length > 0 ? '' : 'Add Tags'}
                        // InputProps={{
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //       <SearchIcon sx={{ color: "grey.500" }} />
                        //     </InputAdornment>
                        //   ),
                        // }}
                      />
                    )}
                    sx={{
                      '&.MuiAutocomplete-root': {
                        '.MuiAutocomplete-input': {
                          minWidth: '70px'
                        },
                        '.MuiOutlinedInput-root': {
                          p: 0,
                          pl: 1,
                          pr: 1,
                          '.MuiAutocomplete-clearIndicator': {
                            margin: '0px -6px 0px 0px'
                          }
                        },
                        '.MuiAutocomplete-tag': {
                          m: '7px',
                          bgcolor: 'common.lightBlue',
                          border: 'none'
                        },
                        '.MuiAutocomplete-endAdornment': {
                          right: '5px'
                        }
                      }
                    }}
                  />
                  <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={openDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle
                      id="alert-dialog-title"
                      sx={{
                        px: { xs: 1, md: 2 },
                        py: { xs: 0.5, md: 1 },
                        bgcolor: 'error.red100',
                        mb: { xs: 2, md: 3 }
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: 'error.main',
                            fontWeight: '600',
                            maxWidth: 'calc(100% - 40px)',
                            p: 1
                          }}>
                          <WarningIcon sx={{ mr: 1 }} />
                          Confirm Deletion
                        </Typography>
                        <IconButton
                          sx={{ color: 'text.primary' }}
                          aria-label="close"
                          onClick={handleCloseDelete}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </DialogTitle>
                    <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
                      <DialogContentText
                        id="alert-dialog-description"
                        sx={{ color: 'text.primary', fontWeight: 700, mb: 1 }}>
                        Are you sure do you want to delete this Tag?
                      </DialogContentText>

                      <DialogContentText
                        id="alert-dialog-description"
                        sx={{ color: 'text.primary' }}>
                        {deleteOption}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0 }}>
                      <Button variant="outlined" sx={{ minWidth: 100 }} onClick={handleCloseDelete}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ minWidth: 100 }}
                        color="error"
                        onClick={() => handleConfirmDelete(deleteOption)}>
                        {' '}
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>
                </FormControl>
              </List>
            </List>
          )}
          {remediationData?.remediation_uuid && (
            <Accordion
              sx={{
                boxShadow: 'none',
                '.MuiButtonBase-root,.MuiAccordionSummary-root.Mui-expanded': {
                  minHeight: '46.22px'
                }
              }}>
              <AccordionSummary
                sx={{
                  border: '1px solid #EBEBEB',
                  borderRadius: '4px',

                  '.Mui-expanded': {
                    my: 0,
                    minHeight: 0
                  }
                }}
                // sx={{
                //   p: 3,
                //   '.MuiAccordionSummary-content': {
                //     m: 0
                //   }
                // }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header">
                <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600 }}>
                  Remediation History
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 0 }}>
                <Box sx={Style.EndPointProtection.EndPointDataTable}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Issue Name
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        {remediationData?.issue_name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Assignee
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        {remediationData?.owner?.first_name} {remediationData?.owner?.last_name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Status
                      </Typography>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          py: '6px',

                          borderRadius: '100px'
                        }}>
                        <Typography variant="body2" sx={{ color: '#696969' }}>
                          {' '}
                          {remediationData?.status == 'in_progress' ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Box
                                sx={{
                                  width: '14px',
                                  height: '14px',
                                  backgroundColor: '#FBBF24',
                                  borderRadius: '50%'
                                }}></Box>
                              <Typography variant="body2" sx={{ color: '#696969' }}>
                                In Progress
                              </Typography>
                            </Box>
                          ) : remediationData?.status == 'not_started' ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Box
                                sx={{
                                  width: '14px',
                                  height: '14px',
                                  backgroundColor: '#EF4444',
                                  borderRadius: '50%'
                                }}></Box>
                              <Typography variant="body2" sx={{ color: '#696969' }}>
                                Not Started
                              </Typography>
                            </Box>
                          ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Box
                                sx={{
                                  width: '14px',
                                  height: '14px',
                                  backgroundColor: '#10B981',
                                  borderRadius: '50%'
                                }}></Box>
                              <Typography variant="body2" sx={{ color: '#696969' }}>
                                {remediationData?.status.charAt(0).toUpperCase() +
                                  '' +
                                  remediationData?.status.slice(1)}
                              </Typography>
                            </Box>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Date
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        {momentTimezone(deviceData?.created_at)
                          .tz('utc')
                          .format('DD MMM YYYY')
                          .toUpperCase()}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Reported by
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        {remediationData?.created_by?.first_name}{' '}
                        {remediationData?.created_by?.last_name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Remediation History
                      </Typography>
                    </Box>
                    <Box
                      onClick={() =>
                        navigate(
                          `${PageRouteConstants.Remediation}?remediation_uuids=${remediationData.remediation_uuid}`
                        )
                      }
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography variant="body2" sx={{ color: '#436AF3' }}>
                        Track Remediation{' '}
                      </Typography>
                      <Box>
                        <LinkIcon />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
        <Box
          sx={{
            px: 2,
            py: 2,
            textAlign: 'end',
            // bottom: 0,
            // right: 0,
            borderTop: '1px solid #0000000D',
            // position: !create ? 'absolute' : 'relative',
            width: 1
          }}>
          {create || remediationData?.remediation_uuid ? (
            remediationData?.remediation_uuid ? (
              <Box></Box>
            ) : (
              <Box sx={{ display: 'flex', gap: 3, justifyContent: 'end' }}>
                <Button
                  variant="outlined"
                  sx={{ minWidth: 160 }}
                  onClick={() => {
                    setCreate(false);
                    formik.resetForm();
                    setAssets([]);
                  }}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={isRemediationButtonLoading}
                  disabled={
                    (create && compalate) || datasaved || remediationData?.remediation_uuid
                      ? true
                      : false
                  }
                  variant="contained"
                  sx={{ minWidth: 160 }}
                  type="submit">
                  Save
                </LoadingButton>
              </Box>
            )
          ) : (
            <Button
              disabled={(create && compalate) || datasaved ? true : false}
              variant="contained"
              sx={{ minWidth: 160 }}
              onClick={handleCreate}>
              {'Create Remediation'}
            </Button>
          )}
        </Box>
      </Box>
    </form>
  );
};
export default NetwrokDetectionSideBarEngineer;
