import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
  linearProgressClasses
} from '@mui/material';
import CloseIcon from '@jibin/common/icons/Close/Close';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { useNavigate } from 'react-router-dom';
import BorderLinearProgress from '@jibin/common/components/LinearLoading/BorderLinearGreen';

function FrameWorkPopup(props: any) {
  const navigate = useNavigate();
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props?.openFramework}
        onClose={props?.handleCloseFramework}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            px: { xs: 1, md: 3 },
            py: { xs: 0.5, md: 3 },
            bgcolor: 'primary.main100'
          }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5' }}>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',

                  fontWeight: '500'
                }}>
                Controls in Compliance
              </Typography>
              {!props.isGap && (
                <Typography
                  onClick={() => navigate(PageRouteConstants.GapAnalysisV2)}
                  sx={{
                    display: 'flex',
                    cursor: 'pointer',
                    alignItems: 'center',
                    color: '#436AF3',
                    fontWeight: '600',
                    fontSize: '14px'
                  }}>
                  Go to Framework Page
                </Typography>
              )}
            </Box>

            <IconButton
              sx={{ color: 'text.primary' }}
              aria-label="close"
              onClick={props?.handleCloseFramework}>
              <CloseIcon sx={{ color: '#B7B7B7', fontSize: '16px' }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          <Box
            sx={{
              width: 1,
              display: 'flex',
              justifyContent: 'center',
              px: 2.5,
              py: 3,
              border: '1px solid #CCCCCC',
              borderRadius: '8px',
              maxHeight: '300px',
              overflowY: 'auto'
            }}>
            <Box sx={{ width: 1 }}>
              <Typography variant="body1" sx={{ fontWeight: 500, pb: 1 }}>
                Frameworks in Scope
              </Typography>
              {props?.data?.map((el: any, index: any) => (
                <Box
                  key={index + 1}
                  sx={{
                    mb: 1.5,
                    border: '1px solid #EAECF0',
                    p: 2,
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <Box>
                    <Box
                      sx={{
                        border: '2px solid #B7B7B7',
                        borderRadius: '50%',
                        width: '50px',
                        height: '50px',
                        overflow: 'hidden'
                      }}>
                      <img src={el?.image} style={{ width: '100%', height: '100%' }} />
                    </Box>
                  </Box>
                  <Box sx={{ width: '100%', ml: 1 }}>
                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      gap={1}
                      alignItems={'center'}>
                      <Typography sx={{ fontWeight: 600, mb: 1 }}>{el.name}</Typography>
                      <Typography variant="caption" sx={{ mb: 1 }}>
                        {el?.version}
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1.5,
                        justifyContent: 'space-between'
                      }}>
                      <Box
                        sx={{
                          width: 0.85
                        }}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={(el.completed / el?.total) * 100}
                          sx={{
                            [`.${linearProgressClasses.bar}`]: {
                              borderRadius: 5,
                              backgroundColor:
                                (el.completed / el?.total) * 100 <= 20
                                  ? '#F87171'
                                  : (el.completed / el?.total) * 100 >= 21 &&
                                    (el.completed / el?.total) * 100 <= 79
                                  ? '#FBBF24'
                                  : '#34D399'
                            }
                          }}
                        />
                      </Box>

                      <Typography variant="caption" sx={{ color: '#696969', whiteSpace: 'nowrap' }}>
                        {Math.round((el.completed / el?.total) * 100)}% <span> </span>
                        {`(${el.completed}/${el?.total})`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default FrameWorkPopup;
